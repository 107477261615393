import React from 'react';
import navigate from 'app/navigate';
import styled from 'styled-components';
import { colors } from '../../theme';
import { Link } from 'react-router-dom';

const StyledLinkToDo = styled(Link)`
  text-decoration: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

const UnstyledCardToDo = ({ count, label, className = '' }) => {
  return (
    <li className={className}>
      <span className="card-count mr-1">{count}</span>
      <div className="px-3 d-flex align-items-center">
        <div className="d-flex flex-column ">
          <h6 className="font-weight-bold mb-2">{label}</h6>
          {count > 0 ? (
            <div className="text-secondary small">Enter here to review them</div>
          ) : (
            <div className="text-secondary small">You’re up to date!</div>
          )}
        </div>
      </div>
    </li>
  );
};

const ToDoCardStyled = styled(UnstyledCardToDo)`
  ${props => `
      height:  ${props.blue ? '90px' : '70px'};
      /* Active */
      background: ${props.blue ? colors.activeState : colors.gray300};
      border-radius: 10px;
      display: flex;
      flex-direction: row;
      margin-bottom: 1.75rem;
      color:${colors.black};
      &:hover{
        background:${props.blue ? colors.blue_30 : 'rgba(205, 220, 243, 0.2)'};
        cursor:pointer;
        span.card-count {
          background:${props.blue ? colors.blue400 : colors.gray400};
        }
      }
  
      span.card-count {
        background: ${props.blue ? colors.blue300 : colors.gray200};
  
        width: 89px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 40px;
        line-height: 54px;
        font-weight: bold;
        color: ${colors.white};
      }
      
      a {
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.2px;
        color: ${colors.gray800};
      }
  `}
`;

// TODO: deprecate `selectedDate` on `state` data.
const linkToTaskToDo = selectedDate => {
  return {
    to: {
      pathname: navigate.to.ToDo({}, false),
      state: { date: selectedDate },
    },
  };
};

export const ToDoCard = ({ toDoItem, index, selectedDate }) => {
  const { name, totalCount } = toDoItem;

  return (
    <StyledLinkToDo {...linkToTaskToDo(selectedDate)} key={index}>
      <ToDoCardStyled count={totalCount} label={name} blue={index === 0} />
    </StyledLinkToDo>
  );
};
