import { fromJS, List } from 'immutable';
import { setCheckedInStatus } from '../utils';
import { FILTER_NAME } from '../Filters/Implementation/filter.name';
import { getQueryParam } from './getQueryParams';
export * from './Requests/RequestAndOverdueActions';
export * from './ToDo/ToDoActions';
export * from './Upcoming/UpcomingActions';
export * from './ToDo/ToDoActions';
export * from './RecentActivity/RecentActivityActions';

export function readUpdateRequestsDashboard() {
  const filter = this.filtersState.getIn([FILTER_NAME.UR_OVERDUE, 'values']).toJS();
  const query = getQueryParam({ request: true }, filter);
  return this.dashboardService
    .readTasks({}, { query })
    .then(fromJS)
    .then(tasksUpdateRequest => tasksUpdateRequest.map(setCheckedInStatus))
    .then(tasksUpdateRequest => state => {
      this.invalidateCounters({ requestCount: tasksUpdateRequest.size });

      return state.set('tasksUpdateRequest', tasksUpdateRequest);
    })
    .catch(() => state => state.set('tasksUpdateRequest', List()));
}

export function readTasksOverdueDashboard() {
  const filter = this.filtersState.getIn([FILTER_NAME.UR_OVERDUE, 'values']).toJS();
  const query = getQueryParam({ overdue: 'all' }, filter);
  return this.dashboardService
    .readTasks({}, { query })
    .then(fromJS)
    .then(tasksOverdue => tasksOverdue.map(setCheckedInStatus))
    .then(tasksOverdue => state => {
      this.invalidateCounters({ overdueCount: tasksOverdue.size });

      return state.set('tasksOverdue', tasksOverdue);
    })
    .catch(() => state => state.set('tasksOverdue', List()));
}
