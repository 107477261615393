import React from 'react';
import { Modal, Form } from 'react-bootstrap';
import { PasswordWrapper } from '../index';
import { PrimaryButton } from '../Button';
import { Initials } from '../Tags';
import { getUserDomain } from '../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as Yup from 'yup';
import cn from 'classnames';

const Allowed = {
  Uppers: 'QWERTYUIOPASDFGHJKLZXCVBNM', // pragma: allowlist secret
  Lowers: 'qwertyuiopasdfghjklzxcvbnm', // pragma: allowlist secret
  Numbers: '1234567890',
  Symbols: '!@#$%^&*',
};

const getRandomCharFromString = str => {
  const position = Math.floor(Math.random() * str.length);
  return str.charAt(position);
};

export const randomString = (length = 12) => {
  let pwd = '';
  pwd += getRandomCharFromString(Allowed.Uppers);
  pwd += getRandomCharFromString(Allowed.Lowers);
  pwd += getRandomCharFromString(Allowed.Numbers);
  pwd += getRandomCharFromString(Allowed.Symbols);
  for (let i = pwd.length; i < length; i++) pwd += getRandomCharFromString(Object.values(Allowed).join(''));
  return pwd;
};

const generateSchema = (withCurrentPassword = false) => {
  let schema = Yup.object().shape({
    password: Yup.string()
      .required('Password is required')
      .matches(/^[a-zA-Z0-9~!@#$%^&*_\-+=`|\\(){}[\]:;'<>,.?/]+$/, 'Character not allowed')
      .matches(/^.*[0-9].*$/, 'Must include at least one number')
      .matches(/^.*[a-zA-Z].*$/, 'Must include at least one letter')
      .matches(/^.*[A-Z].*$/, 'Must include at least one uppercase letter')
      .matches(/^.*[a-z].*$/, 'Must include at least one lowercase letter')
      .matches(
        /^.*[~!@#$%^&*_\-+=`|\\(){}[\]:;'<>,.?/].*$/,
        "Must include at least one special character ~!@#$%^&*_-+=`|\\(){}[]:;'<>,.?/"
      )
      .min(7, 'Min. 7 characters')
      .max(64, 'Max. 64 characters'),
  });

  if (withCurrentPassword) {
    schema = schema.shape({
      currentPassword: Yup.string()
        .required('Current Password is required')
        .min(7, 'Min. 7 characters')
        .matches(/^[a-zA-Z0-9~!@#$%^&*_\-+=`|\\(){}[\]:;'<>,.?/]+$/, 'Character not allowed'),
    });
  }

  return schema;
};

export function ResetPassword({ close, user, isSelfReset, sendResetPassword, resetPasswordByEmail, alert }) {
  const { isInstaller } = getUserDomain(user);
  const [hidePassword, setHidePassword] = React.useState(true);
  const [hideCurrentPassword, setHideCurrentPassword] = React.useState(true);
  const schema = generateSchema(!isInstaller);

  const { register, setError, errors, clearErrors, setValue, trigger, formState, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: { password: '' },
  });
  const resetPassword = async ({ password, currentPassword }) => {
    const isValid = await trigger('password');
    if (isValid) {
      try {
        await sendResetPassword(user, password, !isInstaller ? currentPassword : null);
        close();
      } catch (error) {
        if (error.type === 'password-same-as-previous') {
          setError('password', {
            type: 'notMatch',
            message: 'Can not be the same as your previous password',
          });
        } else if (error.type === 'reset-password-invalid-credentials') {
          setError('currentPassword', {
            type: 'notMatch',
            message: 'This password does not match our records. Please enter it again.',
          });
        }
      }
    }
  };
  const generatePassword = e => {
    e.preventDefault();
    setValue('password', randomString(), { shouldDirty: true });
    clearErrors('password');
  };
  const togglePassword = pwd =>
    pwd === 'currentPassword' ? setHideCurrentPassword(!hideCurrentPassword) : setHidePassword(!hidePassword);

  return (
    <Modal show={true} onHide={close}>
      <Form noValidate validated={formState.isValid} onSubmit={handleSubmit(resetPassword)}>
        <Modal.Header closeButton>
          <Modal.Title>Reset Password</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          <MiniProfile initials={user.get('initials')} user={user} />

          {!isInstaller && (
            <div className="d-flex flex-column mt-3">
              <div className="d-flex flex-row justify-content-between">
                <span className="form-label">Current Password</span>
              </div>
              <Form.Group controlId="currentPassword" className="mb-3">
                <PasswordWrapper onClick={() => togglePassword('currentPassword')} isPlain={!hideCurrentPassword}>
                  <Form.Control
                    type={hideCurrentPassword ? 'password' : 'text'}
                    name="currentPassword"
                    size="lg"
                    isInvalid={!!errors.currentPassword}
                    ref={register}
                  />
                  <Form.Control.Feedback type="invalid">
                    <FontAwesomeIcon icon="circle-exclamation" /> {errors.currentPassword?.message}
                  </Form.Control.Feedback>
                </PasswordWrapper>
              </Form.Group>
            </div>
          )}

          <div className="d-flex flex-column mt-3">
            <div className="d-flex flex-row justify-content-between">
              <span className="form-label">{isInstaller ? 'Password' : 'New Password'}</span>
              <button type="button" onClick={generatePassword} className="btn btn-link btn-sm p-0">
                Generate strong password
              </button>
            </div>
            <Form.Group controlId="password" className="mb-3">
              <PasswordWrapper onClick={() => togglePassword('password')} isPlain={!hidePassword}>
                <Form.Control
                  type={hidePassword ? 'password' : 'text'}
                  name="password"
                  size="lg"
                  isInvalid={!!errors.password}
                  ref={register}
                />
                <Form.Control.Feedback type="invalid">
                  <FontAwesomeIcon icon="circle-exclamation" /> {errors.password?.message}
                </Form.Control.Feedback>
              </PasswordWrapper>
            </Form.Group>
          </div>
          {!isSelfReset && (
            <p className="text-secondary pt-3 small">
              <strong>Important:</strong> Write down these credentials to share them with installer user later.
            </p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div
            className={cn('d-flex justify-content-between  w-100 mt-4', {
              'align-items-center': !isInstaller,
              'flex-row-reverse': isInstaller,
            })}
          >
            {!isInstaller && (
              <button
                className="btn btn-link btn-action font-size-14 pl-0 ml-0"
                onClick={e => {
                  e.preventDefault();
                  resetPasswordByEmail(user, alert);
                }}
                style={{ boxShadow: 'none' }}
                type="button"
              >
                Forgot Password?
              </button>
            )}
            <PrimaryButton type="submit" className="mr-n2">
              Reset Password
            </PrimaryButton>
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export const MiniProfile = ({ user, size = 60 }) => {
  const name = `${user.get('firstName')} ${user.get('lastName')}`;

  return (
    <div className="d-flex flex-row">
      <Initials name={user.get('initials') || name} size={size} />
      <div className="d-flex flex-column justify-content-center">
        <strong>{name}</strong>
        <span className="text-muted form-label">{user.get('email')}</span>
      </div>
    </div>
  );
};
