import React from 'react';
import styled from 'styled-components';
import { EmptyState } from '../../../EmptyState';
import { colors } from '../../../theme';
import { RelevantTask } from './RelevantTask';

export const Tasks = ({ state, expanded, expand, ...rest }) => {
  const tasks = state.get('relevantTasks');
  const toggle = task => expand(task.get('_id') === expanded ? null : task.get('_id'));

  if (!tasks.size) {
    return (
      <EmptyState
        icon="wrench"
        title="No Relevant Tasks Added"
        body="When you add a Relevant Task, it will appear here."
      />
    );
  }

  return (
    <UL data-testid="list-relevant-tasks">
      {tasks
        .map(task => <RelevantTask expanded={task.get('_id') === expanded} toggle={toggle} task={task} {...rest} />)
        .toArray()}
    </UL>
  );
};

const UL = styled.ul`
  margin-top: 0.25rem;
  list-style: none;
  padding: 0;
  margin-right: 16px;

  li {
    border-top: solid 1px ${colors.gray100};
    padding: 1rem 1rem 1.5rem 1rem;

    div {
      display: flex;
      flex-direction: row;
    }

    &.expanded {
      background-color: ${colors.activeState};
      flex-direction: column;
      margin-bottom: 1px;
      border-radius: 6px;
    }
  }
`;
