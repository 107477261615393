import styled from 'styled-components';
import { colors } from '@tradetrax/web-common';

export const UL = styled.ul`
  list-style: none;
  padding: 0;

  li {
    display: flex;
    justify-content: space-between;
    border-top: solid 1px ${colors.gray100};
    padding: 1rem 1rem 1.5rem 0.5rem;
  }
`;
