import React from 'react';
import { Modal } from 'react-bootstrap';
import { PrimaryButton } from '@tradetrax/web-common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function DeactivateUserModal({ accept, cancel }) {
  return (
    <Modal show={true} onHide={cancel}>
      <Modal.Header closeButton>
        <Modal.Title>
          <FontAwesomeIcon icon="circle-exclamation" className="text-danger" />
          Deactivate User
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>This will immediately revoke the user’s access to TradeTrax. You can reactivate the user later.</p>
      </Modal.Body>
      <Modal.Footer>
        <PrimaryButton variant="secondary" className="mr-3" onClick={() => cancel()}>
          Cancel
        </PrimaryButton>
        <PrimaryButton className="" onClick={() => accept()}>
          Yes, Deactivate User
        </PrimaryButton>
      </Modal.Footer>
    </Modal>
  );
}
