import React from 'react';
import { TextPlaceholder } from '@tradetrax/web-common/lib/LoadingPlaceholder';

export const LoadingPlaceholder = ({ visible = false, rows = 6 }) => {
  if (!visible) return null;

  return [...Array(rows)].map((_, i) => {
    const width = 250 + 100 * Math.floor(Math.random() * 2);
    return (
      <li key={i} className="is-loading">
        <TextPlaceholder style={{ width: `${width}px` }} />
      </li>
    );
  });
};
