import React from 'react';
import { AlbumController, getCanRemoveAndDownload } from '../../Photos/AlbumController';
import { CarouselOverlay } from '../../Photos/Carousel/CarouselOverlay';

export const Attachments = ({ task, attachmentInfo, appContext, dashboardService, setAttachmentInfo }) => {
  const { state, controller } = AlbumController(task, null, dashboardService, appContext, true);
  const user = appContext.appState.get('user');
  const { attachments, isCarouselOpen, currentPhotoIndex } = state.toObject();

  React.useEffect(() => {
    if (attachments.get('isLoading') !== true && attachments.size > 0) {
      const attachment = attachments.find(a => a.get('_id') === attachmentInfo.get('_id'));
      controller.showCarousel(attachment);
    }
  }, [attachments, attachmentInfo, controller]);

  const props = { user, task, isTask: true, getCanRemoveAndDownload: getCanRemoveAndDownload(user, task) };

  return (
    <CarouselOverlay
      {...props}
      photos={attachments}
      defaultIndex={currentPhotoIndex}
      show={isCarouselOpen}
      onHide={controller.hideCarousel}
      onRemove={controller.removeAttachment}
      onDownload={controller.downloadAttachment}
      setAttachmentInfo={setAttachmentInfo}
    />
  );
};
