import React from 'react';
import { EmptyState } from '@tradetrax/web-common';
import { plural } from '@tradetrax/web-common/lib/utils';
import { ToDoCard } from './ToDoCard';
import { LoadingCards } from './LoadingCards';

export const StartToday = ({ state, ...todoContext }) => {
  const kind = 'startToday';
  const tasks = state.getIn([kind, 'tasks']);
  const totalTasks = state.getIn([kind, 'totalCount']) || 0;
  const isEmpty = totalTasks === 0;

  if (state.getIn([kind, 'isLoading'])) return <LoadingCards howMany={3} />;

  return (
    <>
      <h6 className="font-weight-bold ml-2 mb-1">
        {totalTasks} {plural(totalTasks, 'Task')} Expected to Start
      </h6>
      {!isEmpty && (
        <span className="font-size-12 text-secondary ml-2 mb-4 d-block">Move to In Progress or Update Start Date.</span>
      )}
      <EmptyState
        visible={isEmpty}
        icon="circle-check"
        title="No Tasks to Start"
        body="When you have a Task to Start Today, it will appear here."
      />
      {tasks.map(task => (
        <ToDoCard task={task} key={task.get('key')} kind={kind} {...todoContext} />
      ))}
    </>
  );
};
