import { useEffect, useRef } from 'react';
import { fromJS } from 'immutable';
import { useController } from '@tradetrax/web-common';
import { useInfiniteScroll } from '@tradetrax/web-common/lib/ToDo';
import { useChannel, CHANNELS } from '@tradetrax/web-common/lib/useRealTime';
import { updateFiltersOnDataBase } from '@tradetrax/web-common/lib/Dashboard/DashboardActions';
import { buildersService as upcomingService } from 'services';
import * as upcomingActions from './UpcomingActions';
import { toggleView } from '@tradetrax/web-common/lib/CalendarView/CalendarActions';
import { CALENDAR_VIEW, LIST_VIEW } from '@tradetrax/web-common/lib/CalendarView/CalendarContext';
import { useFilterContext, FILTER_NAME } from '@tradetrax/web-common/lib/Filters/Filters.context';

const emptyState = fromJS({
  isLoading: true,
  totalCount: 0,
  tasks: [],
  isManageTasks: false,
  disabledBulk: false,
});

const actions = { updateFiltersOnDataBase, toggleView, ...upcomingActions };

export function UpcomingContext(appContext) {
  const filterContext = useFilterContext(FILTER_NAME.UPCOMING);
  const { filterState } = filterContext;
  const loaderRef = useRef();
  const currentURL = new URL(document.URL);
  const initView = currentURL.hash === '#calendar' ? CALENDAR_VIEW : LIST_VIEW;
  const [state, controller] = useController(actions, emptyState.set('view', initView), {
    loaderRef,
    ...appContext,
    upcomingService,
    dashboardService: upcomingService,
    filterState,
  });
  const account = appContext.appState.get('account');
  const containerRef = useInfiniteScroll(controller.loadMoreRows, 100);
  const filter = filterState.get('values');

  useEffect(() => {
    controller.invalidateFilter();
  }, [filter, controller]);

  useChannel(CHANNELS.ACCOUNT_TASK, `${account.get('_id')}`, controller.updateUpcomingRealTime);

  return { state, controller, containerRef, upcomingService, filterContext };
}
