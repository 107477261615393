import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { Dropdown, Form, Col } from 'react-bootstrap';
import { colors } from '../../theme';
import { plural } from '../../utils';
import { ValidationFeedback } from './Filter.feedback';
import { DropdownToggle } from './Filter.advanced.toggle.button';

const OPTIONS = {
  any: 'Any',
  equal: 'Equal To',
  more: 'More Than',
  less: 'Less Than',
};

export function DurationOption({ filterContext, className = 'mt-3' }) {
  const { filterState, filterController } = filterContext;
  const durationRef = React.useRef();
  const errors = filterState.get('errors');
  const duration = filterState.getIn(['values', 'duration']);
  const durationType = duration.get('type');
  const durationValue = duration.get('value');

  const dropDownOnChange = type => {
    filterController.defer.updateFieldValue('duration', duration.merge({ type }));
  };
  const durationOnChange = ({ target }) => {
    const parsed = Number.parseInt(target.value, 10);
    const value = isNaN(parsed) ? null : parsed;
    filterController.defer.updateFieldValue('duration', duration.merge({ value }));
  };

  return (
    <div className={`d-flex flex-row align-items-center ${className}`}>
      <span className="text-capitalize w-25 text-gray-400">Duration</span>
      <Dropdown onSelect={dropDownOnChange}>
        <Dropdown.Toggle as={DropdownToggle} label={OPTIONS[durationType]} />
        <Dropdown.Menu>
          <DropDownOption eventKey="any" active={durationType === 'any'}>
            Any
          </DropDownOption>
          <DropDownOption eventKey="equal" active={durationType === 'equal'}>
            Equal To
          </DropDownOption>
          <DropDownOption eventKey="more" active={durationType === 'more'}>
            More Than
          </DropDownOption>
          <DropDownOption eventKey="less" active={durationType === 'less'}>
            Less Than
          </DropDownOption>
        </Dropdown.Menu>
      </Dropdown>
      <Form.Group
        as={Col}
        md="6"
        controlId="formDuration"
        className={cn('mb-0 pl-0', { 'd-none': durationType === 'any' })}
      >
        <div className="d-flex flex-row align-items-center">
          <Form.Control
            type="number"
            className="size-sm"
            min="1"
            max="999"
            placeholder="0"
            ref={durationRef}
            value={durationValue || ''}
            onChange={durationOnChange}
            isInvalid={!!errors?.has('duration.value')}
          />
          <span className="ml-3">{plural(parseInt(durationValue), 'day')}</span>
        </div>
        <ValidationFeedback field="duration.value" errors={errors} />
      </Form.Group>
    </div>
  );
}

const DropDownOption = styled(Dropdown.Item)`
  &.dropdown-item.active {
    background-color: ${colors.blue100};
    color: ${colors.blue300};
  }
`;
