import React from 'react';
import cn from 'classnames';
import { Section } from '../index';
import { EmptyState } from '../../EmptyState';
import { RecentActivityList } from './RecentActivityList';
import { Attachments } from './Attachments';
import { mapActivitiesByDate } from '../../utils';
import { useFilterContext } from '../../Filters/Filters.context';
import { FilterImplementation } from '../../Filters/Implementation/Filter.implementation';
import { FILTER_NAME } from '../../Filters/Implementation/filter.name';

export const RecentActivitySection = ({ context, appContext, className = '', linkToTask, linkToJob }) => {
  const { state, dashboardService, containerRef } = context;
  const isLoading = state.getIn(['recentActivities', 'isLoading']);
  const activities = state.getIn(['recentActivities', 'activities']);
  const recentActivities = React.useMemo(() => mapActivitiesByDate(activities), [activities]);
  const isEmpty = !isLoading && !recentActivities.size;
  const [{ task, attachmentInfo }, setAttachmentInfo] = React.useState({});
  const filterContext = useFilterContext(FILTER_NAME.RECENT_ACTIVITY);

  return (
    <>
      <Section
        data-testid="dashboard-recent-activity"
        className={cn(`d-flex flex-column ${className}`)}
        style={{ maxHeight: '35rem' }}
      >
        <div className="d-flex justify-content-between align-items-center">
          <h5 className="section-title">Recent Activity</h5>
        </div>
        <div className="d-flex flex-row justify-content-end">
          <FilterImplementation filterContext={filterContext} />
        </div>
        {isEmpty && (
          <EmptyState
            className="my-5 py-5"
            icon="hourglass-end"
            title="No Activity yet"
            body="When there’s an Activity, it will appear here."
          />
        )}
        <RecentActivityList
          showCarousel={setAttachmentInfo}
          recentActivities={recentActivities}
          linkToTask={linkToTask}
          linkToJob={linkToJob}
          appState={appContext.appState}
          containerRef={containerRef}
        />
        {task && (
          <Attachments
            appContext={appContext}
            dashboardService={dashboardService}
            task={task}
            attachmentInfo={attachmentInfo}
            setAttachmentInfo={setAttachmentInfo}
          />
        )}
      </Section>
    </>
  );
};
