import React from 'react';
import { fromJS } from 'immutable';
import { useAppContext } from '../../globalAppContext';
import { useController } from '../../useController';
import { useEvent, useEvents } from '../../useEvents';
import * as actions from './FilterSets.actions';

const emptyState = fromJS({
  active: null,
  filterSets: [],
});

//TODO: deprecate this file...
export const FilterSetsContext = (filtersService, applyFilters) => {
  const appContext = useAppContext();
  const events = useEvents();
  const [state, controller] = useController(actions, emptyState, {
    ...appContext,
    filtersService,
    events,
    applyFilters,
    onActiveFilterSet,
  });
  const isManager = appContext.appState.getIn(['user', 'role']) === 'manager';
  const activeFilterSet = state.get('active');

  React.useEffect(() => {
    controller.readFilterSets();
  }, [controller]);

  return { state, controller, isManager, activeFilterSet };
};

function onActiveFilterSet(filterSet) {
  const { emptyFilter } = this.filtersService;
  const form = (filterSet?.get('filter') ?? emptyFilter).toJS();
  const filterSetId = filterSet?.get('_id');
  this.applyFilters(form, filterSetId);
}
