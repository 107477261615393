import React from 'react';
import { fromJS } from 'immutable';
import { useController } from '@tradetrax/web-common/lib/useController';
import { useAppContext } from '@tradetrax/web-common/lib/globalAppContext';
import * as actions from './CustomRoles.actions';

const emptyState = fromJS({
  customRoles: { isLoading: true },
  roleId: null,
  active: [],
  inactive: [],
});

export function CustomRolesContext() {
  const appContext = useAppContext();
  const [state, controller] = useController(actions, emptyState, appContext);

  React.useEffect(() => {
    controller.readCustomRoles();
  }, [controller]);

  return { state, controller };
}
