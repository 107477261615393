import React from 'react';

export const RadioBox = ({ filterContext, children, fieldName = 'status', suffix }) => {
  const { filterState, filterController } = filterContext;
  const fieldValue = filterState.getIn(['values', fieldName]);

  const onCheckChange = e => {
    const { value } = e.target;
    e.preventDefault();
    e.stopPropagation();
    filterController.defer.updateFieldValue(fieldName, value);
  };

  const mapChild = child => {
    if (child.type.displayName !== 'Filter.FormRadio') return child;
    const { value } = child.props;
    const props = {
      name: fieldName,
      type: 'radio',
      id: `${value}${suffix}`,
      checked: value === fieldValue,
      onChange: onCheckChange,
    };
    return React.cloneElement(child, props);
  };

  const recursiveMap = children => {
    return React.Children.map(children, child => {
      if (!React.isValidElement(child)) {
        return child;
      }

      if (child.props.children) {
        child = React.cloneElement(child, {
          children: recursiveMap(child.props.children),
        });
      }

      return mapChild(child);
    });
  };

  return recursiveMap(children);
};
