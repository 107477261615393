import React from 'react';
import styled from 'styled-components';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { colors } from '../../theme';

const option = (label, key) => ({ label, key });

const DATE_CONFIRMATION = [
  option('All', 'all'),
  option('Confirmed', 'confirmed'),
  option('Not Confirmed', 'not-confirmed'),
];

const CRITICAL_TASKS = [
  option('All', 'all'),
  option('Critical Only', 'critical-only'),
  option('Non Critical Only', 'non-critical-only'),
];

// export const ToggleOption = ({ name, control, onChange, updateCounter, className = '' }) => {
export const ToggleOption = ({ filterContext, options, name, label, className = '' }) => {
  const { filterState, filterController } = filterContext;
  const value = filterState.getIn(['values', name]);
  const onChange = option => {
    filterController.defer.updateFieldValue(name, option);
  };

  return (
    <div className={`d-flex flex-row align-items-center ${className}`}>
      <span className="text-capitalize w-25 text-gray-400">{label}</span>
      <ToggleButtons className="mb-2 mr-auto" type="radio" name={name} value={value} onChange={onChange}>
        {options.map(option => (
          <ToggleButton key={option.key} className="text-capitalize" value={option.key}>
            {option.label}
          </ToggleButton>
        ))}
      </ToggleButtons>
    </div>
  );
};

ToggleOption.DateConfirmation = ({ ...props }) => (
  <ToggleOption {...props} name="dateConfirmation" label="Date Confirmation" options={DATE_CONFIRMATION} />
);

ToggleOption.CriticalTasks = ({ ...props }) => (
  <ToggleOption {...props} name="criticalTasks" label="Critical Tasks" options={CRITICAL_TASKS} />
);

const ToggleButtons = styled(ToggleButtonGroup)`
  width: 380px;

  label.btn {
    background: ${colors.gray100};
    border: none;
    color: ${colors.gray400};
    min-width: 80px;
    font-weight: 400;
    text-transform: capitalize;

    &:hover {
      background: ${colors.activeState};
      color: ${colors.gray800};
    }

    &.active:not(:disabled):not(.disabled),
    &:active:not(:disabled):not(.disabled) {
      color: ${colors.gray800};
      background: ${colors.white};
      border-bottom-style: solid;
      border-width: 3px;
      border-radius: 0px;
      border-color: ${colors.blue300};
    }
  }
`;
