import React from 'react';
import { fromJS } from 'immutable';
import { useController, markAsSync } from '@tradetrax/web-common';
import { useAppContext } from 'app/App.context';
import * as stagesActions from './Stages/StagesSettingsActions';
import * as startDateConfirmationActions from './StartDateConfirmations/StartDateConfirmationActions';

export const ENUM_TIME_COMPLETION = {
  finish: 'Finishes',
  start: 'Starts',
};

const emptyState = fromJS({
  template: {
    isLoading: true,
    name: 'loading...',
    stages: [],
    tasks: [],
    isComplete: true,
    hasCompleteAccountabilities: true,
    hasCompleteStages: true,
  },
  accountability: {
    tasks: {
      isLoading: true,
    },
    recommended: {
      isLoading: true,
    },
  },
});

const actions = { setTab, ...stagesActions, ...startDateConfirmationActions };

export function StagesSettingsContext(templateId, tab) {
  const appContext = useAppContext();
  const [state, controller] = useController(actions, emptyState.set('tab', tab), appContext);
  const globalTasks = appContext.appState.get('gtl');

  React.useEffect(() => {
    controller.readTemplate(templateId);
  }, [controller, templateId]);

  return { state, controller, globalTasks, templateId };
}

markAsSync(setTab);
function setTab(state, tab) {
  return state.set('tab', tab);
}
