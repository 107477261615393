import React from 'react';
import { RadioCommonFilter } from './Filter.radio.common';
import { FormRadio } from '../Components/Filter.form.radio';

export const UsersFilter = ({ filterContext }) => {
  return (
    <RadioCommonFilter filterContext={filterContext} suffix="Users" title="User Filters">
      <FormRadio value="all" label="All Users" />
      <FormRadio value="active" label="Active Users" />
      <FormRadio value="inactive" label="Deactivated Invites" />
      <FormRadio value="pending" label="Pending Users" />
    </RadioCommonFilter>
  );
};
