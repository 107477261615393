import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { JobDetailsContext } from './JobDetails/JobDetailsContext';
import { Paths } from 'app/navigate';
import JobTaskDetails from './JobTaskDetails/JobTaskDetails';
import JobDetails from './JobDetails/JobDetails';
import { JobsPage } from './JobsPage';
import { NotFound } from '@tradetrax/web-common';

export const JobRoutes = () => {
  return (
    <Switch>
      <Route exact path={Paths.Job} component={JobsPage} />
      <Route path={Paths.JobDetails} component={JobDetailsRoutes} />
      <Route component={NotFound} />
    </Switch>
  );
};

const JobDetailsRoutes = ({ match, location }) => {
  const { jobId } = match.params;
  const defaultTab = location.hash
    ? location.hash.replace('#', '')
    : location.state
    ? location.state.defaultTab
    : 'schedule';
  const jobContext = JobDetailsContext(jobId, defaultTab);
  return (
    <Switch>
      <Route exact path={Paths.JobDetails} render={props => <JobDetails {...props} jobContext={jobContext} />} />
      <Route
        exact
        path={Paths.JobTaskDetails}
        render={props => <JobTaskDetails {...props} jobContext={jobContext} />}
      />
      <Route component={NotFound} />
    </Switch>
  );
};
