import React from 'react';
import { List, fromJS } from 'immutable';
import { markAsSync, markAsSideEffect } from '../../useController';
import { FilterSetsManagementModal } from './FilterSetsManagementModal';
import { NewFilterSetNameModal } from './NewFilterSetNameModal';
import { CustomDialog } from '../../Dialog/Dialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

markAsSideEffect(readFilterSets);
export function readFilterSets() {
  let _id = null;
  const { filterName, emptyFilter } = this.filtersService;
  const jobFilter = localStorage.getItem(filterName);
  if (jobFilter) _id = JSON.parse(jobFilter)?.filterSetId;

  this.filtersService
    .readFilters({})
    .then(fromJS)
    .then(filterSets => {
      const active = filterSets.find(f => f.get('_id') === _id);
      saveFilterOnLocalStorage(filterName, active, emptyFilter);
      // this.events.emit('filter-sets:restore-active', active);
      // this.events.emit('filter-sets:changed', filterSets);
      this.controller.dispatch([state => state.merge({ filterSets, active })]);
    });
}

markAsSync(selectFilter);
export function selectFilter(state, filter) {
  const { filterName, emptyFilter } = this.filtersService;
  // this.events.emit('filter-sets:active', filter);
  this.onActiveFilterSet(filter);
  saveFilterOnLocalStorage(filterName, filter, emptyFilter);
  return state.set('active', filter);
}

markAsSync(clearFilterSet);
export function clearFilterSet(state) {
  const { filterName, emptyFilter } = this.filtersService;
  // this.events.emit('filter-sets:active', filter);
  // this.onActiveFilterSet(null);
  saveFilterOnLocalStorage(filterName, null, emptyFilter);
  return state.set('active', null);
}

function saveFilterOnLocalStorage(filterName, filter, emptyFilter) {
  let persistedFilter = emptyFilter.toJS();

  if (filter) {
    persistedFilter = filter.get('filter').toJS();
    persistedFilter.filterSetId = filter.get('_id');
    persistedFilter.taskTypes = persistedFilter.taskTypes?.map(({ name }) => ({ name })) ?? [];
  }

  localStorage.setItem(filterName, JSON.stringify(persistedFilter));
}

markAsSync(removeFilter);
export function removeFilter(state, filter) {
  return state.update('filterSets', fs => fs.filter(f => f !== filter));
}

markAsSync(updateFilterName);
export function updateFilterName(state, filter, name) {
  return state.update('filterSets', fs => fs.map(f => (f === filter ? f.set('name', name) : f)));
}

markAsSync(updateFilterSharedState);
export function updateFilterSharedState(state, index, isShared) {
  return state.setIn(['filterSets', index, 'isShared'], isShared);
}

markAsSideEffect(openChangeFilterOrderModal);
export async function openChangeFilterOrderModal() {
  const activeId = this.state.getIn(['active', '_id']);
  const filterSets = this.state.get('filterSets').toArray();
  const updateFilterSharedState = this.controller.updateFilterSharedState;
  const { addAlert, filtersService, appController, appState, alert } = this;
  const { ordered } = await this.modal.open(FilterSetsManagementModal, {
    filterSets,
    addAlert,
    filtersService,
    appController,
    appState,
    alert,
    updateFilterSharedState,
  });

  if (!ordered) return;

  try {
    const newFilterSets = List(ordered)
      .filter(f => !f.get('toBeRemoved'))
      .map((f, index) => f.set('order', index + 1));
    // this.events.emit('filter-sets:changed', newFilterSets);
    const active = newFilterSets.find(fs => fs.get('_id') === activeId);

    const body = newFilterSets.toJS();
    await this.filtersService.batchUpdateFilters(body);
    this.controller.dispatch([state => selectFilter.call(this, state.set('filterSets', newFilterSets), active)]);
    this.addAlert('Filter Sets successfully updated.');
  } catch (ex) {
    this.addAlert('There was a problem updating the Filter Sets. Please try again.', 'danger');
  }
}

markAsSideEffect(saveFilterSet);
export async function saveFilterSet(filter) {
  const active = this.state.get('active');
  if (!active) return; // there is no active filter into which save the settings.
  const { filterName } = this.filtersService;

  const confirmSaveFilter = {
    title: (
      <>
        <FontAwesomeIcon icon="circle-exclamation" className="text-danger" />
        Filter Set Impact
      </>
    ),
    message: `This is a shared Filter Set. By confirming its changes, they will be applied to the Users and/or Custom Roles you shared this Filter Set with.`,
    titleAccept: 'Confirm Changes',
    titleCancel: 'Cancel',
  };

  if (active.get('isShared')) {
    const { isAccept } = await this.modal.open(CustomDialog, confirmSaveFilter);
    if (!isAccept) return;
  }

  const filterId = active.get('_id');
  // remove unwanted fields from taskType
  filter = filter.update('taskTypes', types => types.map(type => ({ name: type.get('name') }))).toJS();
  localStorage.setItem(filterName, JSON.stringify(filter));

  try {
    await this.filtersService.updateFilter({ filter }, { params: { filterId } });
    this.addAlert('Filter Set successfully updated.');
  } catch (ex) {
    this.addAlert('There was a problem updating this Filter Set. Please try again.', 'danger');
    throw ex;
  }

  this.controller.dispatch([
    state => {
      const filterSet = active.set('filter', fromJS(filter));
      const index = state.get('filterSets').indexOf(active);
      // this.events.emit('filter-sets:active', filterSet);
      this.onActiveFilterSet(filterSet);
      return state.setIn(['filterSets', index], filterSet).set('active', filterSet);
    },
  ]);
}

markAsSideEffect(saveNewFilterSet);
export async function saveNewFilterSet(filterSet) {
  const { state } = this;
  const { filterName } = this.filtersService;
  const { filterSetName: name } = await this.modal.open(NewFilterSetNameModal, { filterSets: state.get('filterSets') });

  if (!name) return; // it was canceled

  // remove unwanted fields from taskType
  const filter = filterSet.update('taskTypes', types => types.map(type => ({ name: type.get('name') }))).toJS();

  this.filtersService
    .createFilter({ name, filter })
    .then(fromJS)
    .then(filterSet => {
      this.addAlert('Filter Set successfully created.');
      this.controller.dispatch([
        state =>
          state
            .update('filterSets', fs => {
              const newFilterSets = fs.push(filterSet);
              // this.events.emit('filter-sets:changed', newFilterSets);
              return newFilterSets;
            })
            .set('active', filterSet),
      ]);
      // this.events.emit('filter-sets:active', filterSet);
      this.onActiveFilterSet(filterSet);
      const filter = filterSet
        .get('filter')
        .set('filterSetId', filterSet.get('_id'))
        .toJS();
      localStorage.setItem(filterName, JSON.stringify(filter));
    })
    .catch(err => {
      this.addAlert('There was a problem creating this Filter Set. Please try again.', 'danger');
    });
}
