import React from 'react';
import styled from 'styled-components';
import { colors } from '@tradetrax/web-common';

const TextPlaceholder = styled.span`
  ${props => `
    display: inline-block;
    width: ${props.width};
    height: 12px;
    background-color: ${colors.gray500};
    border-radius: 5px;
  `}
`;

const LoadingElement = () => (
  <JobProgressContainer>
    <div className="d-flex flex-column mt-4 mb-3">
      <div className="d-flex flex-column">
        <div className="d-flex">
          <TextPlaceholder width="338px" className="mb-3" />
          <TextPlaceholder width="122px" className="ml-4" />
          <TextPlaceholder width="122px" className="ml-2" />
        </div>
        <TextPlaceholder width="170px" />
      </div>
      <ProgressContainer>
        {[...Array(10)].map((e, i) => (i !== 3 ? <MilestonePlaceholder key={i} /> : <ProgressPlaceholder />))}
        <Line />
      </ProgressContainer>
      <div className="d-flex justify-content-end">
        <TextPlaceholder width="191px" className="mt-4" />
      </div>
    </div>
  </JobProgressContainer>
);

const ProgressPlaceholder = () => (
  <DataProgressContainer>
    <DataProgressPlaceholder>
      <TextPlaceholder width="122px" className="mb-2" />
      <TextPlaceholder width="80px" />
    </DataProgressPlaceholder>
  </DataProgressContainer>
);

const ProgressContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-top: 75px;
  margin-bottom: 50px;
`;

const Line = styled.div`
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: ${colors.gray500};
  top: calc(50% - 2px);
`;

const MilestonePlaceholder = styled.div`
  height: 12px;
  width: 12px;
  background-color: ${colors.gray500};
  border-radius: 50%;
`;

const DataProgressContainer = styled.div`
  height: 12px;
  width: 12px;
  background-color: ${colors.gray500};
  border-radius: 50%;
  z-index: 1;
  position: relative;
`;

const DataProgressPlaceholder = styled.div`
  position: absolute;
  bottom: -50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  left: -55px;
`;

const JobProgressContainer = styled.div`
  background-color: ${colors.pear};
  border: 1px solid ${colors.gray};
  margin-top: 42px;
  padding: 36px 42px;
  border-radius: 20px;
  box-shadow: 0px 1px 2px rgba(51, 51, 51, 0.2);
`;

export const LoadingPlaceHolder = () => <LoadingElement />;
