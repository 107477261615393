import React from 'react';
import { SearchGTL } from '@tradetrax/web-common/lib/Inputs/SearchGTL';
import { Tasks } from './Tasks';

export const LeadTimeTasks = props => {
  const [expanded, expand] = React.useState(null);
  const { controller, state } = props;
  const leadTimeTasks = state.get('leadTimeTasks');

  const addTask = task => {
    const taskName = task.get('name');
    const taskId = task.get('_id');
    controller.createLeadTimeTask(taskId, taskName);
  };

  return (
    <div>
      <h5 className="font-weight-bold mb-4">Lead Time</h5>
      <p className="mb-4">Select the Tasks for which you want to be notified days before they start.</p>
      <SearchGTL selectedTasks={leadTimeTasks} expand={expand} addTask={addTask} state={state} />
      <Tasks {...props} expanded={expanded} expand={expand} />
    </div>
  );
};
