import { fromJS } from 'immutable';
import { markAsSync } from '@tradetrax/web-common/lib/useController';
import { buildersService } from 'services';

const keyCode = 'stages-configuration';

export function readTemplate(templateId) {
  return buildersService
    .readTemplate({}, { params: { templateId } })
    .then(fromJS)
    .then(mapStageTasks)
    .then(template => state => state.set('template', template))
    .catch(err => {
      // const message = (
      //   <>
      //     There was a problem reading the Stages Configuration.
      //     <button className="btn btn-link" onClick={() => this.controller.readTemplate(templateId)}>
      //       Try again
      //     </button>
      //   </>
      // );
      const message = 'There was a problem reading the Stages Configuration.';
      this.alert.add({ message, keyCode, variant: 'danger' });
      throw err;
    });
}

markAsSync(setStageStartTask);
export function setStageStartTask(state, stage, task) {
  const templateId = state.getIn(['template', '_id']);
  const stageId = stage.get('_id');
  const path = ['template', 'stages'];
  const stages = state.getIn(path);
  const startTaskId = task?.get('id') || null;

  buildersService
    .updateTemplateStage({ startTaskId }, { params: { templateId, stageId } })
    .then(fromJS)
    .then(mapStageTasks)
    .then(template => {
      this.controller.dispatch([state => state.set('template', template)]);
      const message = 'Stages Configuration successfully saved.';
      this.alert.add({ message, keyCode });
    })
    .catch(() => {
      this.controller.dispatch([state => state.setIn(path, stages)]);
      const message = 'There was a problem saving the Stages Configuration. Please try again.';
      this.alert.add({ message, keyCode, variant: 'danger' });
    });

  return state.updateIn(['template', 'stages'], stages => {
    const index = stages.findIndex(stg => stg.get('_id') === stageId);
    return stages.setIn([index, 'startTaskId'], startTaskId);
  });
}

markAsSync(setStageFinishTask);
export function setStageFinishTask(state, stage, task) {
  const templateId = state.getIn(['template', '_id']);
  const stageId = stage.get('_id');
  const path = ['template', 'stages'];
  const stages = state.getIn(path);
  const endTaskId = task?.get('id') || null;

  buildersService
    .updateTemplateStage({ endTaskId }, { params: { templateId, stageId } })
    .then(fromJS)
    .then(mapStageTasks)
    .then(template => {
      this.controller.dispatch([state => state.set('template', template)]);
      const message = 'Stages Configuration successfully saved.';
      this.alert.add({ message, keyCode });
    })
    .catch(() => {
      this.controller.dispatch([state => state.setIn(path, stages)]);
      const message = 'There was a problem saving the Stages Configuration. Please try again.';
      this.alert.add({ message, keyCode, variant: 'danger' });
    });

  return state.updateIn(['template', 'stages'], stages => {
    const index = stages.findIndex(stg => stg.get('_id') === stageId);
    return stages.setIn([index, 'endTaskId'], endTaskId);
  });
}

markAsSync(Reorder);
export function Reorder(state, stageId, startIndex, endIndex) {
  const path = ['template', 'stages'];
  const templateId = state.getIn(['template', '_id']);
  const stages = state.getIn(path);
  const order = endIndex + 1;
  const result = stages.toArray();
  const [removed] = result.splice(startIndex, 1);

  result.splice(endIndex, 0, removed);

  buildersService
    .updateTemplateStage({ order }, { params: { templateId, stageId } })
    .then(fromJS)
    .then(mapStageTasks)
    .then(template => {
      this.controller.dispatch([state => state.set('template', template)]);
      const message = 'Stages Configuration successfully saved.';
      this.alert.add({ message, keyCode });
    })
    .catch(() => {
      this.controller.dispatch([state => state.setIn(path, stages)]);
      const message = 'There was a problem saving the Stages Configuration. Please try again.';
      this.alert.add({ message, keyCode, variant: 'danger' });
    });

  const reorderedStages = fromJS(result).map((stage, index) => stage.set('order', index + 1));

  return state.setIn(path, reorderedStages);
}

function mapStageTasks(template) {
  const tasks = template.get('tasks');

  return template.update('stages', stages =>
    stages.map(stage =>
      stage.set(
        'tasks',
        tasks.filter(task => task.get('stageId') === stage.get('_id'))
      )
    )
  );
}
