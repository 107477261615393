import { Filters } from './TaskOverdue.filters';
import { TaskOverdueReportByAssigneeAccount } from './TaskOverdue.AssigneeAccount';
import { TaskOverdueReportPercentageByAssigneeAccount } from './TaskOverdue.PercentageByAssigneeAccount';
import { TaskOverdueReportPercentageByMonth } from './TaskOverdue.PercentageByMonth';
import { TaskOverdueReportTotalAmountByMonth } from './TaskOverdue.TotalAmountByMonth';

export const TaskOverdueByAssigneeAccount = {
  Filters,
  filter: [],
  _id: 'task_overdue_total_by_assignee_account',
  name: 'Task Overdue: Total by Assignee Account',
  Report: TaskOverdueReportByAssigneeAccount,
  apply: controller => controller.loadTaskOverdueReport(),
};

export const TaskOverduePercentageByAssigneeAccount = {
  Filters,
  filter: [],
  _id: 'task_overdue_percentage_by_assignee_account',
  name: 'Task Overdue: Percentage by Assignee Account',
  Report: TaskOverdueReportPercentageByAssigneeAccount,
  apply: controller => controller.loadTaskOverdueReport(),
};

export const TaskOverdueTotalAmountByMonth = {
  Filters,
  filter: [],
  _id: 'task_overdue_total_amount_by_month',
  name: 'Task Overdue: Total Amount by Month',
  Report: TaskOverdueReportTotalAmountByMonth,
  apply: controller => controller.loadTaskOverdueReport(),
};

export const TaskOverduePercentageByMonth = {
  Filters,
  filter: [],
  _id: 'task_overdue_percentage_by_month',
  name: 'Task Overdue: Percentage by Month',
  Report: TaskOverdueReportPercentageByMonth,
  apply: controller => controller.loadTaskOverdueReport(),
};
