import React from 'react';
import { FilterTypeahead } from '../Components/Filter.typeahead';

export const OuttakeFilter = ({ filterContext, lookups = {}, field, filterField, label }) => {
  const { filterController } = filterContext;
  const clearFilter = () => filterController.defer.reset();
  const { assignees } = lookups;
  const hasAssignees = assignees?.size > 0;
  const { isFiltering } = filterContext;
  const Typeahead = FilterTypeahead(filterContext);

  return (
    <div className="d-flex settings-container flex-column justify-content-start">
      <div className="d-flex justify-content-between">
        <h5 className="mb-4 font-weight-bold" style={{ width: '250px' }}>
          Outtake Filters
        </h5>
        {isFiltering ? (
          <button className="btn btn-link text-decoration-none" onClick={clearFilter}>
            Clear all filters
          </button>
        ) : null}
      </div>
      {hasAssignees && (
        <Typeahead
          id="account-assignee"
          showUnnasigned
          name={filterField}
          field={field}
          options={assignees}
          text={label || 'Assignee'}
        />
      )}
    </div>
  );
};

export const OuttakeFilterByAccount = ({ ...props }) => (
  <OuttakeFilter {...props} filterField="assigneeAccountIds" field="subAccountId" label="Account Assignee" />
);

export const OuttakeFilterByUser = props => <OuttakeFilter {...props} filterField="assigneeUserIds" field="_id" />;
