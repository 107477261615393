import React from 'react';
import { fromJS } from 'immutable';
import { isEqual } from 'lodash';
import { Form } from 'react-bootstrap';
import { useForm, useWatch, Controller as FormController } from 'react-hook-form';
import { Filter } from './Filter';
import styled from 'styled-components';
import { colors } from '../theme';

export const emptyFilter = fromJS({
  'task-assigned': true,
  attachments: true,
  'update-request': true,
  notes: true,
  'filter-sets': true,
});

export function RecentActivityFilter({ loggedUser, ...props }) {
  const { controller, filter } = props;
  const onApplyFilters = controller.applyRecentActivityFilter;
  const defaultValues = filter ? filter?.toJS() : emptyFilter.toJS();
  const formContext = useForm({ defaultValues });
  const isManager = loggedUser.get('role') === 'manager';

  const isFilterSet = filter !== null && !isEqual(filter?.toJS(), emptyFilter.toJS());
  return (
    <Filter formContext={formContext} isFilterSet={isFilterSet} {...props}>
      <div className="settings-container">
        <div className="d-flex justify-content-between">
          <h5 className="mb-4 font-weight-bold" style={{ width: '250px' }}>
            Recent Activity Filters
          </h5>
        </div>
        <CheckboxForm
          formContext={formContext}
          onApplyFilters={onApplyFilters}
          initLastChecked={getIsLastChecked(filter?.toJS())}
          isManager={isManager}
        />
      </div>
    </Filter>
  );
}
const watch = control => name => useWatch({ control, name });

const getInitialValues = control => {
  const getValue = watch(control);
  const taskAssigned = getValue('task-assigned');
  const attachments = getValue('attachments');
  const updateRequest = getValue('update-request');
  const notes = getValue('notes');
  const array = [taskAssigned, attachments, updateRequest, notes];
  if (array.every(item => !item)) return true;
  if (array.some(item => !item)) return false;
  if (array.every(item => item)) return true;
  return false;
};

const getIsLastChecked = filter => () => {
  if (!filter) return '';
  const checked = Object.entries(filter).filter(entry => entry[1]);
  if (checked.length === 1) return checked[0];
  return '';
};

const CheckboxForm = ({ formContext, onApplyFilters, initLastChecked, isManager }) => {
  const { handleSubmit, control, setValue, getValues } = formContext;
  const onSubmit = handleSubmit(onApplyFilters);
  const [isCheckedAll, setCheckedAll] = React.useState(getInitialValues(control));
  const [lastChecked, setLastChecked] = React.useState('');
  React.useEffect(() => {
    const isAnyLastChecked = initLastChecked();
    if (isAnyLastChecked) setLastChecked(isAnyLastChecked[0]);
  }, [setLastChecked]);

  const onCheckAll = () => {
    const auxChecked = !isCheckedAll;
    setValue('task-assigned', auxChecked);
    setValue('attachments', auxChecked);
    setValue('update-request', auxChecked);
    setValue('notes', auxChecked);
    setValue('filter-sets', auxChecked);
    setCheckedAll(auxChecked);
    setLastChecked('');
    onSubmit();
  };
  const onOptionChanged = (name, value) => {
    setValue(name, value);
    const values = getValues();
    const isAllTrue = !Object.entries(values).some(entry => !entry[1]);
    const checked = Object.entries(values).filter(entry => entry[1]);
    if (checked.length === 1) {
      const [nameChecked] = checked;
      setLastChecked(nameChecked[0]);
    } else if (lastChecked) setLastChecked('');
    setCheckedAll(isAllTrue);
    onSubmit();
  };
  return (
    <Form onSubmit={onSubmit}>
      <div className="d-flex flex-column border-top pt-2">
        <StyledCheckBox
          id="option-all"
          label="All"
          name="all"
          checked={isCheckedAll}
          disabled={isCheckedAll}
          onChange={onCheckAll}
        />
        <div className="d-flex flex-row">
          <div className="pl-4 mt-3 d-flex flex-column">
            <CheckBox
              control={control}
              onSubmit={onOptionChanged}
              lastChecked={lastChecked}
              label="Assigned to me"
              name="task-assigned"
            />
            <CheckBox
              control={control}
              onSubmit={onOptionChanged}
              lastChecked={lastChecked}
              label="Notes"
              name="notes"
            />
            <CheckBox
              control={control}
              onSubmit={onOptionChanged}
              lastChecked={lastChecked}
              label="Attachments"
              name="attachments"
            />
            <CheckBox
              control={control}
              onSubmit={onOptionChanged}
              lastChecked={lastChecked}
              label="Update Requests"
              name="update-request"
            />
          </div>
          <div className="pl-4 mt-3 d-flex flex-column">
            {isManager ? null : (
              <CheckBox
                control={control}
                onSubmit={onOptionChanged}
                lastChecked={lastChecked}
                label="Shared Filter Sets"
                name="filter-sets"
              />
            )}
          </div>
        </div>
      </div>
    </Form>
  );
};

const CheckBox = ({ control, name, label, onSubmit, lastChecked }) => {
  return (
    <FormController
      control={control}
      name={name}
      render={({ onChange, onBlur, value, ref }) => (
        <StyledCheckBox
          id={`option-${name}`}
          label={label}
          onBlur={onBlur}
          disabled={lastChecked === name}
          onChange={({ target }) => onChange(onSubmit(name, target.checked))}
          checked={value}
          ref={ref}
        />
      )}
    />
  );
};

const StyledCheckBox = styled(Form.Check)`
  display: flex;
  align-items: center;

  input[disabled] {
    background: ${colors.blue200};
  }

  .form-check-label {
    margin-top: 4px;
    margin-left: 0.5rem;
  }
  .form-check-input {
    width: 15px;
    height: 15px;
  }
`;
