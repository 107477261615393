import React from 'react';
import { List, fromJS } from 'immutable';
import { buildersService } from 'services';

export const useStages = () => {
  const [stages, setStages] = React.useState(List());

  React.useEffect(() => {
    buildersService
      .viewGlobalStages()
      .then(fromJS)
      .then(setStages);
  }, []);

  return stages;
};
