// TODO: deprecate this file, its almost duplicated from
// { PopoverApprovalStatus } from '../../Popover/PopoverApprovalStatus';

import React from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { mongoToText } from '../../utils';
import { colors } from '../../theme';

const PendingStatusContainer = styled.div`
  border-radius: 20px;
  color: ${colors.gray400};
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 4px;
  margin-left: 0.5rem;

  &:hover {
    cursor: pointer;
  }

  &.with-label {
    background-color: ${colors.gray100};
    padding: 5px 8px;
    margin-top: 9px;
    max-width: 150px;
    justify-content: space-around;
  }
`;

const EmptyCell = styled.div`
  width: 20px;
  display: flex;
  padding: 2px 4px;
  margin-top: 9px;
  content: &nbsp;
`;

const PendingTooltip = styled.div`
  color: ${colors.gray800};
  font-size: 12px;
  padding: 5px 17px 10px 10px;
  .PendingTooltip_msg {
    margin-top: 3px;
    color: ${colors.gray400};
  }
`;

function getChangeRequestData(task) {
  const type = task.getIn(['changeRequestOnReviewByBuilder', 'type']) || task.getIn(['changeRequest', 'type']); // changeRequest.get('type');

  let changeRequestType = 'Finish';
  let changeRequestDate =
    task.getIn(['changeRequestOnReviewByBuilder', 'proposedFinishDate']) ||
    task.getIn(['changeRequest', 'proposedFinishDate']);

  if (type === 'new-start-date-request') {
    changeRequestType = 'Start';
    changeRequestDate =
      task.getIn(['changeRequestOnReviewByBuilder', 'proposedStartDate']) ||
      task.getIn(['changeRequest', 'proposedStartDate']) ||
      task.getIn(['changeRequestForStartDateConfirmation', 'newStartDate']);
  }

  return { changeRequestDate, changeRequestType };
}

export const UpdateRequestStatus = ({ task, isOverdue = false, isBuilder = false }) => {
  const builderName = task.getIn(['job', 'builderName']);
  const isOnReviewByTrade =
    task.getIn(['changeRequest', 'state']) === 'review-by-sub' &&
    task.getIn(['changeRequest', 'activeForCurrentUser']) &&
    !task.get('startDateConfirmationRequest');
  const fieldUR = isBuilder ? 'changeRequest' : 'changeRequestOnReviewByBuilder';
  const isOnReviewByBuilder = task.getIn([fieldUR, 'state']) === 'review-by-builder';
  const isRejectedByBuilder = task.getIn([fieldUR, 'state']) === 'rejected-by-builder';

  if (!isOnReviewByTrade && !isOnReviewByBuilder && !isRejectedByBuilder) return <EmptyCell />;

  const { changeRequestType, changeRequestDate } = getChangeRequestData(task);

  const popover = (
    <Popover>
      <Popover.Content>
        <PendingTooltip>
          <strong className="PendingTooltip_date mb-2 ">{`Proposed ${changeRequestType} ${mongoToText(
            changeRequestDate
          )} `}</strong>
          {isBuilder ? null : isOnReviewByBuilder ? (
            <div className="PendingTooltip_msg d-flex justify-content-start align-items-center">
              <FontAwesomeIcon icon="clock" className="mr-2" />
              <span>Pending approval on </span>
            </div>
          ) : isRejectedByBuilder ? (
            <div className="PendingTooltip_msg d-flex justify-content-start align-items-center">
              <FontAwesomeIcon icon="exclamation-circle" className="mr-2 text-danger" />
              <span>Declined by </span>
            </div>
          ) : null}
          <span className={cn('PendingTooltip_builder', { 'd-none': isBuilder })}>{builderName}</span>
        </PendingTooltip>
      </Popover.Content>
    </Popover>
  );

  return (
    <OverlayTrigger placement="bottom" overlay={popover} open>
      <PendingStatusContainer className={cn({ 'with-label': isOverdue })}>
        <FontAwesomeIcon
          className={cn({ 'text-danger': isRejectedByBuilder })}
          icon={isRejectedByBuilder ? 'exclamation-circle' : 'clock'}
        />
        {isOverdue && <span>{isOnReviewByBuilder ? 'Pending approval' : 'Request declined'}</span>}
      </PendingStatusContainer>
    </OverlayTrigger>
  );
};
