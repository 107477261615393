import React from 'react';
import styled from 'styled-components';
import { colors } from '@tradetrax/web-common';

const LoadingElement = () => (
  <div className="mt-4">
    {[...Array(2)].map((e, index) => (
      <CardPlaceHolder key={index} />
    ))}
  </div>
);

const CardPlaceHolder = () => {
  return (
    <Card>
      <div className="d-flex ">
        <TextPlaceholder width="460px" />
        <TextPlaceholder width="52px" className="ml-5" />
      </div>
      <TextPlaceholder width="259px" />
      <TextPlaceholder width="383px" />
    </Card>
  );
};

const TextPlaceholder = styled.span`
  ${props => `
    display: inline-block;
    width: ${props.width};
    height: 12px;
    background-color: ${colors.gray500};
    border-radius: 5px;
  `}
`;

const Card = styled.div`
  background: ${colors.pearl};
  box-sizing: border-box;
  border-radius: 0.375rem;
  margin-bottom: 0.63rem;
  padding: 1rem 1rem 0.5rem 3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: relative;
  height: 231px;
  width: 660px;
`;

export const LoadingPlaceHolder = () => <LoadingElement />;
