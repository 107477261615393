import React from 'react';
import { AddTaskInput } from './AddTaskInput';
import { Tasks } from './Tasks';
import { RecommendedTasks } from './RecommendedTasks';
import { OverlayIncompleteStartDateConfirmation } from '../OverlayIncompleteTemplate';

export function StartDateConfirmationSettings({ context }) {
  const { controller, state, globalTasks } = context;
  const template = state.get('template');
  const { _id: templateId, tasks: templateTasks } = template.toObject();
  const accountabilityTasks = state.getIn(['accountability', 'tasks']);

  // we will need gtl._id that's why we map template tasks with gtl tasks.
  const templateGlobalTasks = React.useMemo(() => {
    return globalTasks.filter(gt => templateTasks.find(tt => tt.get('name') === gt.get('name'))).toArray();
  }, [globalTasks, templateTasks]);

  // tasks that exists on template but are not already added to the SDC list.
  const addTasks = React.useMemo(() => {
    const { isEmpty, isLoading } = accountabilityTasks.toObject();
    if (isLoading || isEmpty) return [];
    return templateGlobalTasks.filter(tgt => !accountabilityTasks.find(at => at.get('name') === tgt.get('name')));
  }, [templateGlobalTasks, accountabilityTasks]);

  React.useEffect(() => {
    if (templateId) {
      controller.readTemplateAccountabilities(templateId);
      controller.readRecommendedAccountabilities(templateId);
    }
  }, [controller, templateId]);

  return (
    <>
      <div className="d-flex flex-row justify-content-between align-items-center">
        <span className="font-size-14 text-gray-400 my-4">
          Add the Task(s) you want to confirm its Start Date from the Trade for this Template
        </span>
        {!template.get('hasCompleteAccountabilities') && (
          <div className="d-none d-lg-block">
            <OverlayIncompleteStartDateConfirmation tasks={state.getIn(['accountability', 'tasks'])} />
          </div>
        )}
      </div>
      <div className="d-flex flex-column">
        <AddTaskInput context={context} tasks={addTasks} />
        {!template.get('hasCompleteAccountabilities') && (
          <div className="d-lg-none d-flex justify-content-end mb-3">
            <OverlayIncompleteStartDateConfirmation tasks={state.getIn(['accountability', 'tasks'])} />
          </div>
        )}
        <RecommendedTasks context={context} />
      </div>
      <Tasks context={context} globalTasks={templateGlobalTasks} />
    </>
  );
}
