import { DateRange, Community /*, Job*/ } from '@tradetrax/web-common/lib/Reports/Filters';

export const Filters = [
  {
    _id: 'select_communities',
    field: 'communities',
    label: 'By Community',
    report_label: 'Communities',
    defaultValue: Community.defaultValue,
    Component: Community,
  },
  // {
  //   _id: 'select_jobs',
  //   field: 'jobs',
  //   label: 'Select Jobs',
  //   report_label: 'Jobs',
  //   defaultValue: Job.defaultValue,
  //   Component: Job,
  // },
  {
    _id: 'start_date_range',
    field: 'startDate',
    label: 'Start Date Range',
    report_label: 'Jobs Start Date Range',
    defaultValue: DateRange.defaultValue,
    Component: DateRange,
  },
  {
    _id: 'finish_date_range',
    field: 'endDate',
    label: 'Finish Date Range',
    report_label: 'Jobs Finish Date Range',
    defaultValue: DateRange.defaultValue,
    Component: DateRange,
  },
];
