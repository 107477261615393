import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from '@tradetrax/web-common';
import { plural } from '@tradetrax/web-common/lib/utils';
import { debounce } from 'lodash';

export const IncompleteMilestones = ({ state }) => {
  const { milestones } = state.toObject();
  const milestonesIncompletes = milestones.filter(
    milestone =>
      !milestone.get('startTriggerTaskId') ||
      !milestone.get('endTriggerTaskId') ||
      milestone.get('missingEndTriggerTask') ||
      milestone.get('missingStartTriggerTask')
  ).size;

  if (milestonesIncompletes < 1 || milestones.size < 1) return null;

  return (
    <div className="d-flex justify-content-end mt-3 mr-3">
      <IncompleteMilestoneContainer>
        <Label
          onMouseEnter={() => {
            hideWarningMsg.cancel();
            document.querySelector('.warningMsg').style.opacity = '1';
          }}
          onMouseLeave={() => {
            hideWarningMsg();
          }}
        >
          Incomplete Milestones
        </Label>
        <WarningMsg
          className="warningMsg"
          onMouseEnter={() => {
            hideWarningMsg.cancel();
          }}
          onMouseLeave={() => {
            hideWarningMsg();
          }}
        >
          <FontAwesomeIcon icon="triangle-exclamation" className="text-yellow-100 font-size-14 mr-2 mt-1" />
          <p>
            There {`${plural(parseInt(milestonesIncompletes), 'is', 'are')} `}
            <span className="font-weight-bold">
              {`${milestonesIncompletes} ${plural(parseInt(milestonesIncompletes), 'Milestone', 'Milestones')}`}
            </span>{' '}
            that {`${plural(parseInt(milestonesIncompletes), 'has', 'have')} `} not been saved. Make sure to set{' '}
            {`${plural(parseInt(milestonesIncompletes), 'it', 'them')} `} correctly.
          </p>
        </WarningMsg>
      </IncompleteMilestoneContainer>
    </div>
  );
};

const IncompleteMilestoneContainer = styled.div`
  position: relative;
  cursor: default;

  .warningMsg {
    display: flex;
    opacity: 0;
    transition: opacity 0.4s ease-in-out;
  }
`;

const Label = styled.span`
  background-color: ${colors.yellow};
  padding: 4px 8px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 6px;
`;

const WarningMsg = styled.div`
  display: none;
  box-sizing: border-box;
  padding: 19px 0px 18px 15px;
  position: absolute;
  top: 35px;
  right: 0;
  width: 324px;
  height: 98px;
  box-shadow: 0px 1px 2px rgba(51, 51, 51, 0.2);
  border-radius: 8px;
  font-size: 14px;
  z-index: 2;
  background-color: ${colors.white};
`;

const hideWarningMsg = debounce(() => {
  const warningMsgContainer = document.querySelector('.warningMsg');
  if (warningMsgContainer) {
    document.querySelector('.warningMsg').style.opacity = '0';
  }
}, 600);
