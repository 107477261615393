import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from 'react-bootstrap';
import { PrimaryButton } from '@tradetrax/web-common';

export const RemoveMilestoneModal = ({ close, accept }) => {
  return (
    <Modal centered show={true} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>
          <FontAwesomeIcon icon="circle-exclamation" className="text-danger" />
          Remove Milestone
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>By removing it, Homeowners will no longer see progress on this Milestone.</Modal.Body>
      <Modal.Footer className="d-flex flex-row justify-content-between">
        <PrimaryButton variant="secondary" onClick={() => close()}>
          Cancel
        </PrimaryButton>
        <PrimaryButton onClick={() => accept()}>Yes, Remove Milestone</PrimaryButton>
      </Modal.Footer>
    </Modal>
  );
};
