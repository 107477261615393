import React, { useEffect } from 'react';
import { fromJS } from 'immutable';
import { markAsSync, markAsSideEffect, useController } from '@tradetrax/web-common';
import { ToDoActions as toDoActions, useInfiniteScroll } from '@tradetrax/web-common/lib/ToDo';
import { updateFiltersOnDataBase } from '@tradetrax/web-common/lib/Dashboard/DashboardActions';
import { hasPermissionForAssignedTask } from '@tradetrax/web-common/lib/utils';
// import { useChannel, CHANNELS } from '@tradetrax/web-common/lib/useRealTime';
import { buildersService as todoService } from 'services';
import navigate from 'app/navigate';
import { useEvent } from '@tradetrax/web-common/lib/useEvents';
import { useFilterContext, FILTER_NAME } from '@tradetrax/web-common/lib/Filters/Filters.context';

const emptyList = {
  isLoading: true,
  totalCount: 0,
  tasks: [],
};
const emptyState = fromJS({
  startToday: emptyList,
  finishToday: emptyList,
  updateToday: emptyList,
  tab: 'expectedStart',
});

const tdvLink = (taskId, jobId) => navigate.to.JobTaskDetails({ taskId, jobId }, false);

const todoQuery = { isAssignedToSelfAccount: true };
const actions = { ...toDoActions, refresh, onCountersChange, updateFiltersOnDataBase, setTab };

export function ToDoContext(appContext) {
  const filterContext = useFilterContext(FILTER_NAME.TO_DO);
  const { filterState } = filterContext;
  const [state, controller] = useController(actions, emptyState, {
    ...appContext,
    todoService,
    dashboardService: todoService, // TODO: check this twice implementation of todoService
    todoQuery,
    isBuilder: true,
    filterState,
  });
  const containerRef = useInfiniteScroll(controller.loadMoreRows);
  const { appState, hasPermission } = appContext;
  const user = appState.get('user');
  const userId = user.get('_id');
  const selectedDate = filterState.getIn(['sessionValues', 'selectedDate']);
  const filter = filterState.get('values');

  useEvent('task-counters:update', controller.onCountersChange);

  const canEditTaskStatus = React.useMemo(() => {
    return task => hasPermissionForAssignedTask('task_update_status', hasPermission, task, userId);
  }, [userId, hasPermission]);

  const canSendUpdateRequest = React.useMemo(() => {
    return task => hasPermissionForAssignedTask('manage_update_request', hasPermission, task, userId);
  }, [userId, hasPermission]);

  useEffect(() => {
    controller.refresh();
  }, [filter, controller, selectedDate]);

  // Commented while waiting for improved tech approach
  // const refreshFromWebhook = React.useMemo(
  //   () => foo => {
  //     controller.refresh();
  //   },
  //   [controller]
  // );
  // useChannel(CHANNELS.ACCOUNT_TASK, user.get('accountId'), refreshFromWebhook);

  return { state, controller, canEditTaskStatus, tdvLink, containerRef, canSendUpdateRequest, filterContext, user };
}

markAsSideEffect(refresh);
function refresh() {
  this.controller.loadStartTodayTasks();
  this.controller.loadFinishTodayTasks();
  this.controller.loadUpdateTodayTasks();
}

markAsSideEffect(onCountersChange);
function onCountersChange(taskCounters) {
  const { toDoStartCount, toDoEndCount, toDoUpdateCount } = taskCounters.toObject();
  const totalStartToday = this.state.getIn(['startToday', 'totalCount']) || 0;
  const totalFinishToday = this.state.getIn(['finishToday', 'totalCount']) || 0;
  const totalUpdateToday = this.state.getIn(['updateToday', 'totalCount']) || 0;

  if (totalStartToday !== toDoStartCount) {
    this.controller.loadStartTodayTasks();
  }

  if (totalFinishToday !== toDoEndCount) {
    this.controller.loadFinishTodayTasks();
  }

  if (totalUpdateToday !== toDoUpdateCount) {
    this.controller.loadUpdateTodayTasks();
  }
}

markAsSync(setTab);
function setTab(state, tab) {
  return state.set('tab', tab);
}
