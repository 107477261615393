import React from 'react';
import styled from 'styled-components';
import { colors } from '../theme';

export const InProgressData = ({ milestone, show, isFirstMilestone, isHomeowner, isLastMilestone }) => {
  const { name } = milestone.toObject();

  return (
    <InProgressDataContainer
      isFirstMilestone={isFirstMilestone}
      isHomeowner={isHomeowner}
      isLastMilestone={isLastMilestone}
      data-testid="in-progress-data-container"
    >
      <InProgressName>{name}</InProgressName>
      <InProgressStatus show={show}>In Progress</InProgressStatus>
    </InProgressDataContainer>
  );
};

const InProgressDataContainer = styled.div`
  ${props => `
      text-align: center;
        ${!props.isHomeowner &&
          `
          text-align: ${props.isFirstMilestone ? 'left' : props.isLastMilestone ? 'right' : 'center'};
          ${props.isLastMilestone && 'right:0px;'};
          ${props.isFirstMilestone && 'left:0px;'};
        `};
        top: ${!props.isHomeowner ? '15px' : '90px'};
      `}
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100px;
`;

const InProgressStatus = styled.span`
  ${props => `
      visibility:${props.show ? 'visible' : 'hidden'};
  `}
    color: ${colors.green400};
    font-size: 12px;
  `;

const InProgressName = styled.div`
  color: ${colors.gray800};
  font-size: 14px;
  font-weight: bold;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  margin-top: 10px;
`;
