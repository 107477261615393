import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from '../../theme';

export const DropdownToggle = React.forwardRef(({ onClick, label }, ref) => {
  return (
    <ToggleButton className="btn btn-lnk d-flex justify-content-between text-capitalize" ref={ref} onClick={onClick}>
      {label}
      <FontAwesomeIcon icon="chevron-down" className="ml-2" />
    </ToggleButton>
  );
});

export const ToggleButton = styled.span`
  cursor: pointer;
  padding: 5px 8px 5px 12px;
  margin-right: 0.5rem;
  gap: 2px;

  width: 149px;
  height: 40px;

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  flex-direction: row;
  align-items: center;
  letter-spacing: 0.2px;
  border: 1px solid ${colors.gray200};
  white-space: nowrap !important;
  color: ${colors.gray800};
  background: ${colors.white};

  /* Active */
  &.active {
    background: ${colors.blue100};
    border-radius: 8px;
    color: ${colors.blue300};
  }
`;
