import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Paths } from 'app/navigate';
import { TemplatesPage } from './TemplatesPage';
import { TemplateDetails } from './TemplateDetails/TemplateDetails';
import { NotFound } from '@tradetrax/web-common';
import { TemplateSettings } from './Settings/TemplateSettings';

export function TemplateRoutes() {
  return (
    <Switch>
      <Route path={Paths.TemplateSettings} component={TemplateSettings} />
      <Route path={Paths.TemplateDetails} component={TemplateDetails} />
      <Route exact path={Paths.Templates} component={TemplatesPage} />
      <Route component={NotFound} />
    </Switch>
  );
}
