import React from 'react';
import { RadioCommonFilter } from './Filter.radio.common';
import { FormRadio } from '../Components/Filter.form.radio';

export const TemplatesFilter = ({ filterContext }) => {
  return (
    <RadioCommonFilter filterContext={filterContext} suffix="Templates" title="Template Filters">
      <FormRadio value="all" label="All Templates" />
      <FormRadio value="active" label="Active Templates" />
      <FormRadio value="deactivated" label="Deactivated Templates" />
      <FormRadio value="incomplete" label="Incomplete Templates" />
    </RadioCommonFilter>
  );
};
