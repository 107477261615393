import moment from 'moment';
import { fromJS } from 'immutable';
import { colors } from '../theme';
import { formatISO } from '../utils';
import { markAsSync, markAsSideEffect } from '../useController';
import { TIME_PERIOD, momentIncrease, momentDecrease } from './CalendarContext';
import { STATUS_COLOR } from './CalendarWrapper';
import { LIST_VIEW, CALENDAR_VIEW } from './CalendarContext';

markAsSync(setIsLoading);
export function setIsLoading(state, isLoading) {
  return state.set('isLoading', isLoading);
}

markAsSideEffect(showMoreClick);
export function showMoreClick(date, allSegs) {
  const view = 'dayGridDay';
  const newDate = momentIncrease(date, TIME_PERIOD[view]);
  this.controller.dispatch([
    state =>
      state
        .set('date', newDate)
        .set('calendarView', view)
        .set('totalTasksOnDay', allSegs.length),
  ]);
}

markAsSideEffect(datePickerClick);
export function datePickerClick(date) {
  this.calendarRef.current?.getApi().gotoDate(date);
  this.controller.dispatch([state => state.set('date', date)]);
}

markAsSideEffect(onClickPrevNext);
export function onClickPrevNext() {
  const isMonthView = this.calendarRef.current.getApi().getCurrentData().viewApi.type === 'dayGridMonth';
  const startDateType = isMonthView ? 'currentStart' : 'activeStart';
  const newDate = this.calendarRef.current.getApi().getCurrentData().viewApi[startDateType];
  this.controller.dispatch([state => state.set('date', newDate)]);
}

markAsSideEffect(onClickSelectView);
export function onClickSelectView(view) {
  this.controller.dispatch([state => state.set('calendarView', view)]);
}

function getReadTasksEndpoint(startDate, endDate, view = null) {
  const { jobId } = this;
  const query = getQueryParam.call(this, formatISO(startDate), formatISO(endDate), view);
  const request = { query };
  if (jobId) request.params = { jobId };
  const readTasks = jobId
    ? this.calendarService.readJobTasks({}, request)
    : this.calendarService.readTasks({}, request);
  return readTasks;
}

markAsSideEffect(updateTasksCount);
export async function updateTasksCount(updatedState, view, date) {
  if (view === 'dayGridDay') {
    const readTasks = getReadTasksEndpoint.call(this, date, date, view);
    const tasks = await readTasks;
    this.controller.dispatch([() => updatedState.set('totalTasksOnDay', tasks.length)]);
  } else this.controller.dispatch([() => updatedState]);
}

markAsSideEffect(eventsSet);
export function eventsSet(events) {
  const [event] = events;
  const isDayView = this.calendarRef.current?.getApi().view.type === 'dayGridDay';
  if (event?.extendedProps.isPlaceholder) {
    if (isDayView) this.controller.dispatch([state => state.set('totalTasksOnDay', '-')]);
    this.controller.setIsLoading(false).then(() => {
      this.calendarRef.current.getApi().refetchEvents();
    });
  } else {
    this.controller.setIsLoading(true);
    const calendarDay = this.calendarRef.current?.getApi().getDate();
    if (isDayView) {
      const totalTasks = getTotalTasks(events, calendarDay);
      this.controller.dispatch([state => state.set('totalTasksOnDay', totalTasks)]);
    }
  }
}

const getTotalTasks = (tasks, calendarDay) => {
  return tasks.reduce((counter, task) => {
    const { start, end } = task.toJSON();
    const isOnCurrentDay = moment(calendarDay).isBetween(moment(start), moment(end), undefined, '[)');
    if (isOnCurrentDay) return ++counter;
    return counter;
  }, 0);
};

markAsSideEffect(getCalendarTasks);
export function getCalendarTasks(info, successCallback, failureCallback) {
  if (this.state.get('isLoading')) return successCallback(loadPlaceHolderEvents.call(this));

  const readTasks = getReadTasksEndpoint.call(this, info.start, info.end);
  return readTasks
    .then(data => {
      const tasks = data.map(formatTaskEvents);
      successCallback(tasks);
    })
    .catch(error => {
      failureCallback(error);
    });
}

const formatTaskEvents = task => {
  let textColor = colors.white;
  if (task.ctr && task.ctr.status === 'almost-ready-to-start') textColor = colors.gray400;
  const start = task.startDate.replace('Z', '');
  const end = formatISO(task.expectedFinishDate);
  const endDate = moment(end, 'YYYY-MM-DD').add(1, 'days'); // had to increment one day to properly display on calendar
  return {
    title: task.name,
    start: new Date(start),
    end: new Date(endDate),
    color: STATUS_COLOR[task.ctr?.status],
    textColor,
    allDay: true,
    task: fromJS(task),
  };
};

function loadPlaceHolderEvents() {
  const { calendarView, date } = this.state.toObject();
  let n = 15;
  if (calendarView === 'dayGridDay') n = 4;
  if (calendarView === 'dayGridWeek') n = 8;
  return [...Array(n)].map((e, i) => {
    const [start, end] = getDates(date, calendarView);
    return {
      title: `event-${i}`,
      start,
      end,
      color: colors.gray500,
      isPlaceholder: true,
      task: fromJS({}),
      display: 'block',
    };
  });
}

const getDates = (date, view) => {
  if (view === 'dayGridDay') {
    return [momentDecrease(date, 'd'), momentIncrease(date, 'd')];
  }
  if (view === 'dayGridWeek') {
    const dayStart = Math.floor(Math.random() * 6 + 1);
    const dayEnd = Math.floor(Math.random() * 2 + 1);
    const start = moment(date).day(dayStart);
    const end = moment(date).day(dayStart + dayEnd);
    return [start.toDate(), end.toDate()];
  }
  // view === month
  const dayStart = Math.floor(Math.random() * 28 + 1);
  const dayEnd = Math.floor(Math.random() * 2 + 1);
  const monthStart = moment(date).startOf('month');
  const start = monthStart.clone().day(dayStart);
  const end = monthStart.clone().day(dayStart + dayEnd);
  return [start.toDate(), end.toDate()];
};

function getQueryParam(startIndex, stopIndex, view) {
  const { isUpcoming, isTrade, jobId } = this;
  const filter = this.filter.toJS();
  const isJobScheduleBuilder = !isTrade && !!jobId;
  const query = { from: startIndex, to: stopIndex };
  if (isTrade) query.includeCtrStatus = true;
  if (isJobScheduleBuilder) query.includeStageTasks = true;
  if (isUpcoming) {
    const latestDate = moment()
      .add(30, 'days')
      .format('YYYY-MM-DD');
    query.from = view === 'dayGridDay' ? startIndex : moment().format('YYYY-MM-DD');
    query.status = ['not-started', 'not-ready-to-start'];
    query.startDateQuery = `gte:${moment().format('YYYY-MM-DD')}&lte:${latestDate}`;
  }

  if (!filter) return query;
  if (filter.myTasks) query.myTasks = 'true';
  if (filter.taskNames?.length) query.taskNames = filter.taskNames;
  if (filter.communityIds?.length) query.communityIds = filter.communityIds;

  if (filter.assigneeAccounts?.length) {
    query.assigneeAccounts = filter.assigneeAccounts;
  }

  if (filter.dateConfirmation && filter.dateConfirmation !== 'all')
    query.startDateConfirmed = filter.dateConfirmation === 'confirmed';
  if (filter.criticalTasks && filter.criticalTasks !== 'all')
    query.isCritical = filter.criticalTasks === 'critical-only';

  const { duration } = filter;
  if (duration && duration?.type !== 'any') {
    const { value } = duration;
    if (value) {
      if (duration.type === 'equal') query.duration = `eq:${value}`;
      if (duration.type === 'more') query.duration = `gt:${value}`;
      if (duration.type === 'less') query.duration = `lt:${value}`;
    }
  }

  const { startDate, finishDate } = filter;
  if (startDate && startDate.type !== 'any') {
    if (startDate.type === 'missed') query.overdue = 'start';
    else if (startDate.type === 'equal' && startDate.dateEqual)
      query.startDateQuery = `eq:${formatISO(startDate.dateEqual)}`;
    else if (startDate.type === 'from' && startDate.dateFrom && startDate.dateTo)
      query.startDateQuery = `gte:${formatISO(startDate.dateFrom)}&lte:${formatISO(startDate.dateTo)}`;
  }
  if (finishDate && finishDate.type !== 'any') {
    if (finishDate.type === 'missed') query.overdue = query.overdue === 'start' ? 'all' : 'finish';
    else if (finishDate.type === 'equal' && finishDate.dateEqual)
      query.endDateQuery = `eq:${formatISO(finishDate.dateEqual)}`;
    else if (finishDate.type === 'from' && finishDate.dateFrom && finishDate.dateTo)
      query.endDateQuery = `gte:${formatISO(finishDate.dateFrom)}&lte:${formatISO(finishDate.dateTo)}`;
  }

  const { inProgressStatus, completedStatus, notStartedStatus, notReadyStatus } = filter;
  const hasStatus = inProgressStatus || completedStatus || notStartedStatus || notReadyStatus;
  if (hasStatus) {
    query.status = [];
    if (inProgressStatus) query.status.push('in-progress');
    if (completedStatus) query.status.push('completed');
    if (notStartedStatus) query.status.push('not-started');
    if (notReadyStatus) query.status.push('not-ready-to-start');
  }

  return query;
}

markAsSync(toggleView);
export function toggleView(state) {
  const view = state.get('view');
  const isCalendarView = view === CALENDAR_VIEW;
  const currentURL = new URL(document.URL);
  const newView = isCalendarView ? LIST_VIEW : CALENDAR_VIEW;

  currentURL.hash = !isCalendarView ? '#calendar' : '';
  window.history.replaceState({}, 'TradeTrax', currentURL.href);

  return state.set('view', newView);
}
