import React from 'react';
import moment from 'moment';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SecondaryButton } from '../Button';

const FULL_DATE = 'YYYY-MM-DD';
const SHORT_DATE = 'MMM D';

export const TodoSelectDate = ({ filterState, filterController }) => {
  const selectedDate = filterState.getIn(['sessionValues', 'selectedDate']);

  const onDateSelect = date => filterController.defer.updateFieldValue('selectedDate', date, true);

  const startDateLabel = React.useMemo(() => {
    const date = moment().startOf('day');
    if (date.isSame(selectedDate)) return 'Today';
    if (date.add(1, 'days').isSame(selectedDate)) return 'Tomorrow';
    return moment(selectedDate, FULL_DATE).format(SHORT_DATE);
  }, [selectedDate]);

  const dates = React.useMemo(() => {
    const date = moment();
    return [
      [date.format(FULL_DATE), 'Today'],
      [date.add(1, 'days').format(FULL_DATE), 'Tomorrow'],
      [date.add(1, 'days').format(FULL_DATE), date.format(SHORT_DATE)],
      [date.add(1, 'days').format(FULL_DATE), date.format(SHORT_DATE)],
      [date.add(1, 'days').format(FULL_DATE), date.format(SHORT_DATE)],
      [date.add(1, 'days').format(FULL_DATE), date.format(SHORT_DATE)],
    ];
  }, []);

  return (
    <Dropdown onSelect={onDateSelect}>
      <Dropdown.Toggle as={Toggle} variant="outline-primary" id="select-start-date">
        {startDateLabel}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {dates.map(([date, label]) => (
          <Dropdown.Item key={date} eventKey={date}>
            {label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

const Toggle = React.forwardRef(({ children, onClick }, ref) => (
  <SecondaryButton onClick={onClick} variant="outline-primary" className="px-4" ref={ref}>
    {children} <FontAwesomeIcon icon="chevron-down" className="ml-3" />
  </SecondaryButton>
));
