import React from 'react';
import styled from 'styled-components';
import { colors } from '@tradetrax/web-common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PRECEDING_STATUS_MAP = {
  'ready-to-start': ['Ready', 'to Start'],
  'almost-ready-to-start': ['Almost Ready', 'to Start'],
  'not-ready-to-start': ['Not Ready', 'to Start'],
  'not-ctr': ['No Definition', 'of Ready'],
};

export const CTR = ({ className, task, controller, direction = 'column' }) => {
  const ctr = task.get('ctr');
  if (!ctr) return null;

  const ctrStatus = ctr.get('status');
  const inJobPrecedingTasksNumber = ctr.get('inJobPrecedingTasksNumber');
  const resolvedPrecedingTasksNumber = ctr.get('resolvedPrecedingTasksNumber');
  const [line1, line2] = PRECEDING_STATUS_MAP[ctrStatus];
  const showWarning = ctr.get('precedingTasksNotOnJob');

  const onClick = e => {
    e.stopPropagation();
    controller?.showPrecedingTasks(task);
  };

  return (
    <SytledCTR className={className} status={ctrStatus} isColumn={direction === 'column'}>
      <div className="d-flex align-items-center">
        <Warning onClick={onClick} show={showWarning} />
        <div className="badge" onClick={onClick}>
          {resolvedPrecedingTasksNumber}/{inJobPrecedingTasksNumber}
        </div>
      </div>
      <span
        onClick={onClick}
        className={`font-weight-bold label-status ${ctrStatus} text-nowrap d-block`}
        style={{ marginLeft: '-10px' }}
      >
        {line1} {line2}
      </span>
    </SytledCTR>
  );
};

export const CTR_COLOR = {
  'ready-to-start': colors.green300,
  'almost-ready-to-start': colors.gray200,
  'not-ready-to-start': colors.red,
  'not-ctr': colors.gray400,
};

const SytledCTR = styled.div`
  ${props => `
   width: 160px;
  display: flex;
  flex-direction: ${props.isColumn ? 'column' : 'row'};
  align-items: ${props.isColumn ? 'flex-end' : 'center'};

  .label-status {
    font-size: 12px;
    margin-left: ${props.isColumn ? 'auto' : '4px'};
    color: ${CTR_COLOR[props.status]};
  }

  .badge {
    color: ${colors.white};
    font-size: 12px;
    font-weight: bold;
    padding: 0.5rem;
    border-radius: 0.5rem;
    border-style: none;
    background-color: ${CTR_COLOR[props.status]};
    cursor: ${props.isColumn ? 'pointer' : 'text'};
  }
`}
`;

const UnstyledWarning = ({ show, className }) => {
  if (!show) return null;
  return (
    <span className={className}>
      <FontAwesomeIcon icon="exclamation" className="mx-auto" />
    </span>
  );
};

const Warning = styled(UnstyledWarning)`
  background-color: ${colors.yellow100};
  display: inline-block;
  font-size: 8px;
  border-radius: 50%;
  color: white;
  width: 13px;
  height: 12px;
  text-align: center;
  margin-right: 4px;
`;
