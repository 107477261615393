import React from 'react';
import moment from 'moment';

export const LoadingPlaceHolder = () => {
  const segments = [0, 1, 2].map(week => {
    const from = moment()
      .add(week, 'weeks')
      .startOf('week');
    const to = moment()
      .add(week, 'weeks')
      .endOf('week');
    const isSameMonth = from.month() === to.month();
    const weekLabel = `${from.format('MMMM[ ]D[ - ]')} ${to.format(isSameMonth ? 'D' : 'MMMM D')}`;
    return weekLabel;
  });

  return (
    <>
      {segments.map(weekLabel => (
        <div key={weekLabel}>
          <h4 className="font-weight-bold mt-4">Week: {weekLabel}</h4>
          <PlaceHolder />
        </div>
      ))}
    </>
  );
};

const PlaceHolder = () => (
  <svg
    className="mt-3 mb-4"
    width="992"
    height="397"
    viewBox="0 0 992 397"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="992" height="36" rx="8" fill="#F9F9FA" />
    <rect y="162" width="992" height="36" rx="8" fill="#F9F9FA" />
    <rect y="324" width="992" height="36" rx="8" fill="#F9F9FA" />
    <rect x="51" y="12" width="131" height="12" rx="5" fill="#E0E2E7" />
    <rect x="15" y="12" width="17" height="12" rx="5" fill="#E0E2E7" />
    <rect x="51" y="61" width="118" height="12" rx="5" fill="#E0E2E7" />
    <rect x="904" y="61" width="51" height="12" rx="5" fill="#E0E2E7" />
    <rect x="51" y="104" width="118" height="12" rx="5" fill="#E0E2E7" />
    <rect x="15" y="61" width="17" height="12" rx="5" fill="#E0E2E7" />
    <rect x="904" y="104" width="51" height="12" rx="5" fill="#E0E2E7" />
    <rect x="15" y="104" width="17" height="12" rx="5" fill="#E0E2E7" />
    <rect x="51" y="174" width="131" height="12" rx="5" fill="#E0E2E7" />
    <rect x="15" y="174" width="17" height="12" rx="5" fill="#E0E2E7" />
    <rect x="51" y="223" width="118" height="12" rx="5" fill="#E0E2E7" />
    <rect x="904" y="223" width="51" height="12" rx="5" fill="#E0E2E7" />
    <rect x="635" y="223" width="176" height="12" rx="5" fill="#E0E2E7" />
    <rect x="51" y="336" width="131" height="12" rx="5" fill="#E0E2E7" />
    <rect x="15" y="336" width="17" height="12" rx="5" fill="#E0E2E7" />
    <rect x="51" y="385" width="118" height="12" rx="5" fill="#E0E2E7" />
    <rect x="904" y="385" width="51" height="12" rx="5" fill="#E0E2E7" />
    <rect x="635" y="385" width="176" height="12" rx="5" fill="#E0E2E7" />
    <rect x="15" y="223" width="17" height="12" rx="5" fill="#E0E2E7" />
    <rect x="15" y="385" width="17" height="12" rx="5" fill="#E0E2E7" />
    <rect x="635" y="61" width="176" height="12" rx="5" fill="#E0E2E7" />
    <rect x="51" y="266" width="118" height="12" rx="5" fill="#E0E2E7" />
    <rect x="904" y="266" width="51" height="12" rx="5" fill="#E0E2E7" />
    <rect x="635" y="266" width="147" height="12" rx="5" fill="#E0E2E7" />
    <rect x="635" y="104" width="147" height="12" rx="5" fill="#E0E2E7" />
    <rect x="15" y="266" width="17" height="12" rx="5" fill="#E0E2E7" />
  </svg>
);
