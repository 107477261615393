import { fromJS, Map } from 'immutable';
import { markAsSideEffect } from '@tradetrax/web-common';
import { buildersService } from 'services';

export * from '../HomeownerDetails/HomeownerDetailsActions';

markAsSideEffect(getHomeownerProfile);
export function getHomeownerProfile(userId) {
  buildersService
    .getHomeowner({}, { params: { userId } })
    .then(fromJS)
    .then(response => this.controller.dispatch([state => state.set('homeownerDetails', response).set('job', Map())]))
    .catch(() => this.alert.error({ message: 'There was a problem to get Homeowner profile. Please try again.' }));
}

markAsSideEffect(getJobProgress);
export function getJobProgress(jobId) {
  buildersService
    .viewJobHomeOwnerProgress({}, { params: { jobId } })
    .then(fromJS)
    .then(job => this.controller.dispatch([state => state.set('job', job).set('isLoading', false)]))
    .catch(() => {
      this.controller.dispatch([state => state.set('isLoading', false)]);
      this.alert.error({ message: 'There was a problem to get Job progress data. Please try again.' });
    });
}
