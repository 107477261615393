import React from 'react';
import { PrimaryButton } from '@tradetrax/web-common';
import styled from 'styled-components';

export const Header = ({ controller }) => (
  <div className="d-flex flex-column flex-lg-row">
    <LabelContainer>
      With Custom Roles you can set default configurations for the different types of customers you may have. Create,
      edit and apply these pre-configured settings for your new users.
    </LabelContainer>
    <PrimaryButton className="ml-auto mr-5 text-nowrap" onClick={() => controller.openCreateRoleModal()}>
      Create Custom Role
    </PrimaryButton>
  </div>
);

const LabelContainer = styled.p`
  width: 100%;
  padding-right: 80px;
  @media (min-width: 992px) {
    padding-right: 50px;
  }
`;
