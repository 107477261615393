import React from 'react';
import { Modal, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { PrimaryButton } from '@tradetrax/web-common/lib/Button';
import { useAppContext } from 'app/App.context';
import { emptyStringToNull } from '@tradetrax/web-common/lib/EditableField';
import cn from 'classnames';
import styled from 'styled-components';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';

export function TemplateNew({ controller, isRename }) {
  const { modal } = useAppContext();
  const title = 'Create New Template';
  const message = 'Template Name';
  const buttonText = 'Create Template';
  const submitForm = form => controller.createTemplate(form);
  const onClick = () => modal.open(TemplateForm, { title, message, buttonText, submitForm, isRename });

  return (
    <PrimaryButton data-testid="btn-new-template" onClick={onClick}>
      Create Template
    </PrimaryButton>
  );
}

const schema = yup.object().shape({
  name: yup
    .string()
    .required('Name is required')
    .min(2, 'Min. 2 characters')
    .max(99, 'Max. 99 characters')
    .matches(/^[a-zA-Z0-9~!#$%^&*_\-+=`|{}'.?/\s]+$/, 'Character not allowed'),
  targetCycleTime: yup
    .number()
    .integer()
    .typeError('#')
    .min(0, '0')
    .max(9999, '9999')
    .notRequired()
    .transform(emptyStringToNull)
    .nullable(),
});

export function TemplateForm({ title, message, buttonText, name = '', submitForm, isRename, close }) {
  const {
    register,
    handleSubmit,
    errors,
    setError,
    getValues,
    formState: { dirtyFields },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: { name: name, targetCycleTime: '' },
  });
  const onSubmit = async form => {
    submitForm(form)
      .then(() => {
        close();
      })
      .catch(err => {
        if (err.type === 'entity-conflict') {
          setError('name', { type: 'notMatch', message: 'Template name already exists' });
        }
      });
  };
  const nameValue = getValues('name');
  const icon = errors.targetCycleTime && errors.targetCycleTime.type === 'min' ? faArrowUp : faArrowDown;

  return (
    <ModalContainer show={true} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onSubmit)} id="form-new-template">
        <Modal.Body>
          <Form.Group controlId="formTemplateName">
            <Form.Label>{message}</Form.Label>
            <Form.Control
              type="text"
              name="name"
              ref={register}
              placeholder="Ex. Template 1234"
              isInvalid={!!errors.name}
              isValid={!errors.name && nameValue}
            />
            <Form.Control.Feedback type="invalid">
              <FontAwesomeIcon icon="circle-exclamation" className="mr-1" />
              {errors.name && errors.name.message}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid">
              <FontAwesomeIcon icon="check" className="mr-1" />
              Template name is valid
            </Form.Control.Feedback>
          </Form.Group>
          {!isRename && (
            <Form.Group controlId="formTargetCycleTime" className="mt-4 mb-0">
              <Form.Label>Target Cycle Time</Form.Label>
              <div className="d-flex align-items-center">
                <Form.Control
                  type="number"
                  style={{ width: 90, paddingRight: 12 }}
                  name="targetCycleTime"
                  ref={register}
                  placeholder="0"
                  isInvalid={!!errors.targetCycleTime}
                  isValid={dirtyFields.targetCycleTime && !errors.targetCycleTime}
                  onChange={e => (Number.isInteger(e.target.value) ? e.target.value : '')}
                  onKeyDown={e => ['e', 'E', '-', '.'].includes(e.key) && e.preventDefault()}
                />
                <span className="ml-2 font-size-14">Days</span>
              </div>
              <span className="font-weight-light text-gray-400 font-size-14">* Optional</span>
              <Form.Control.Feedback type="invalid" className={cn({ 'd-inline': !!errors.targetCycleTime })}>
                <FontAwesomeIcon icon="circle-exclamation" />
                {` `}
                <FontAwesomeIcon icon={icon} />
                {errors.targetCycleTime && ` ${errors.targetCycleTime.message}`}
              </Form.Control.Feedback>
            </Form.Group>
          )}
          <Modal.Footer className="pr-0">
            <PrimaryButton type="submit" className="mt-4">
              {buttonText}
            </PrimaryButton>
          </Modal.Footer>
        </Modal.Body>
      </Form>
    </ModalContainer>
  );
}

const ModalContainer = styled(Modal)`
  padding-top: calc((100vh / 2) - 228px);
`;
