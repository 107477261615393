import { useEffect } from 'react';
import { fromJS } from 'immutable';
import { useController } from '@tradetrax/web-common';
import { useChannel, CHANNELS } from '@tradetrax/web-common/lib/useRealTime';
import { useAppContext } from 'app/App.context';
import * as actions from './CommunityDetailsActions';

const emptyState = fromJS({
  community: {
    _id: 'aaaaaabbbbbbccccccdddddd',
    name: '',
    endDate: '',
    areaManagers: [],
  },
  users: [],
  jobs: [],
  totalCount: 10,
  hasPermission: true,
  communities: [],
});

export const CommunityDetailsContext = communityId => {
  const appContext = useAppContext();
  const [state, controller] = useController(actions, emptyState, appContext);
  const { modal } = appContext;

  useEffect(() => {
    controller.readCommunity(communityId);
    controller.readCommunityUsers(communityId);
    controller.readCommunities();
  }, [controller, communityId]);

  useChannel(CHANNELS.COMMUNITY, communityId, controller.updateCommunityHeader);

  return {
    state,
    controller,
    modal,
  };
};
