import React from 'react';
import cn from 'classnames';
import { Map } from 'immutable';
import { ReportContainer, ReportEmptyState, AppliedFilters } from '@tradetrax/web-common/lib/Reports';
import { AreaManager } from '../Common';

export const AverageCycleTimeOfJobsReport = ({ context }) => {
  const { state } = context;
  const [collapsed, setCollapsed] = React.useState(Map({}));
  const data = state.get('data');

  React.useEffect(() => {
    setCollapsed(Map());
  }, [data]);

  if (data.size === 0) return <ReportEmptyState />;

  return (
    <ReportContainer>
      <h4 className="font-weight-bold text-center mx-3 mt-4 pt-3">Average Cycle Time for Jobs</h4>
      <AppliedFilters filters={state.get('appliedFilters')} />
      <div className="report-body">
        {data
          .map(community => (
            <Community key={community.get('communityId')} community={community}>
              <div className="group-inner-header">
                <div className="cell-w250">Job</div>
                <div className="cell-w250">Total Avg Days</div>
                <div className="cell-w250">Behind</div>
                <div className="cell-w250">Ahead</div>
              </div>
              {community
                .get('jobsByMonth')
                .map((jobs, month) => (
                  <Month
                    key={month}
                    communityId={community.get('communityId')}
                    setCollapsed={setCollapsed}
                    collapsed={collapsed}
                    month={month}
                  >
                    {jobs.map(job => <Job key={job.get('_id')} job={job} />).toArray()}
                  </Month>
                ))
                .toList()}
            </Community>
          ))
          .toArray()}
      </div>
    </ReportContainer>
  );
};

const Community = ({ community, children }) => {
  return (
    <div className="group-outter">
      <div className="group-outter-header d-flex flex-row justify-content-between mb-2">
        <span>
          Community:&nbsp;
          <span className="h5 font-weight-bold">{community.get('communityName')}</span>
        </span>
        <span>
          Area Manager: <AreaManager manager={community.getIn(['areaManagers', 0, 'name'])} />
        </span>
      </div>
      {children}
    </div>
  );
};

const Job = ({ job }) => {
  const daysBehind = job.get('daysBehind') ?? 0;

  return (
    <div className="d-flex flex-row mb-1">
      <div className="cell-w250">{job.get('name')}</div>
      <div className="cell-w250">{job.get('totalAverageDays') || ''}</div>
      <div className={cn('cell-w250', { behind: daysBehind < 0 })}>{Math.abs(Math.min(daysBehind, 0))} days</div>
      <div className={cn('cell-w250', { ahead: daysBehind > 0 })}>{Math.max(daysBehind, 0)} days</div>
    </div>
  );
};

const Month = ({ setCollapsed, collapsed, children, month, communityId }) => {
  const key = `${communityId}_${month}`;
  const isCollapsed = !!collapsed.get(key);

  return (
    <div className="group-inner">
      <button
        onClick={() => setCollapsed(collapsed => collapsed.set(key, !isCollapsed))}
        className="btn btn-link p-0 font-weight-bold text-dark"
      >
        {month}
      </button>
      <div className={cn('group-inner-body', { 'd-none': isCollapsed })}>{children}</div>
    </div>
  );
};
