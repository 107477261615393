import { DateRange, Community, TradeAccounts } from '@tradetrax/web-common/lib/Reports/Filters';

export const Filters = [
  {
    _id: 'select_accounts',
    field: 'assigneeAccounts',
    label: 'By Account',
    report_label: 'Accounts',
    defaultValue: TradeAccounts.defaultValue,
    Component: TradeAccounts,
  },
  {
    _id: 'select_communities',
    field: 'communities',
    label: 'By Community',
    report_label: 'Communities',
    defaultValue: Community.defaultValue,
    Component: Community,
  },
  {
    _id: 'start_date_range',
    field: 'startDate',
    label: 'Start Date Range',
    report_label: 'Jobs Start Date Range',
    defaultValue: DateRange.defaultValue,
    Component: DateRange,
  },
  {
    _id: 'finish_date_range',
    field: 'endDate',
    label: 'Finish Date Range',
    report_label: 'Jobs Finish Date Range',
    defaultValue: DateRange.defaultValue,
    Component: DateRange,
  },
];
