import React from 'react';
import { Item, Name, CreatedBySameAccount, UserName } from './common';
import { isUserInactive } from '../../utils';

export default ({ item, account, user, isInactive, accountUsers }) => {
  const _id = item.getIn(['data', 'builderUserSuper', '_id']);
  const name = item.getIn(['data', 'builderUserSuper', 'name']);
  const firstTime = item.getIn(['data', 'firstTime']);
  const isSuperInactive = isUserInactive(_id, accountUsers);

  const message = (
    <>
      {firstTime ? 'set' : 'updated'} Builder Super assignee
      <CreatedBySameAccount item={item} user={user}>
        {` to `}
        <UserName user={user} userName={name} userId={_id} isInactive={isSuperInactive} />
      </CreatedBySameAccount>
    </>
  );

  const removedAssigneeMessage = 'removed Builder Super assignee';

  return (
    <Item item={item} user={user}>
      <Name item={item} account={account} user={user} isInactive={isInactive} />{' '}
      {_id ? message : removedAssigneeMessage}
    </Item>
  );
};
