import { fromJS, Repeat } from 'immutable';
import { buildersService } from 'services';
import { markAsSync, markAsSideEffect, EMPTY_ROW } from '@tradetrax/web-common';
import { setCheckedInStatus, COMPLETED } from '@tradetrax/web-common/lib/utils';
import { LIST_VIEW } from '@tradetrax/web-common/lib/CalendarView/CalendarContext';
import { MINIMUM_BATCH_SIZE } from './TasksPageContext';
export { toggleView } from '@tradetrax/web-common/lib/CalendarView/CalendarActions';

markAsSync(setTab);
export function setTab(state, tab) {
  return state.set('tab', tab).set('view', LIST_VIEW);
}

markAsSync(loadMoreOpenTasks);
export function loadMoreOpenTasks(state, { startIndex, stopIndex }) {
  const filter = this.filterState.get('values').toJS();
  const query = { open: 'true', start_index: startIndex, stop_index: stopIndex };
  if (filter.myTasks) query.myTasks = 'true';
  if (filter.taskNames?.length) query.taskNames = filter.taskNames;
  if (filter.communityIds?.length) query.communityIds = filter.communityIds;
  if (filter.assigneeAccounts?.length) {
    query.assigneeAccounts = filter.assigneeAccounts;
    // TODO: Why is it pushing `null`? should we remove current user.accountId from the list too?
    if (query.assigneeAccounts.includes(this.appState.getIn(['user', 'accountId']))) {
      query.assigneeAccounts.push('null');
    }
  }
  buildersService.readTasks({}, { query }).then(data => {
    const totalCount = data.metadata.pagination ? data.metadata.pagination.totalCount : 0;
    const openTasks = fromJS(data).map(setCheckedInStatus);
    this.controller.dispatch([
      state =>
        state
          .setIn(['open', 'totalCount'], totalCount)
          .setIn(['open', 'maxCount'], stopIndex + MINIMUM_BATCH_SIZE + 1)
          .updateIn(['open', 'tasks'], tasks =>
            tasks.splice(startIndex, stopIndex - startIndex + 1, ...openTasks.toArray())
          ),
    ]);
  });
  const data = Repeat(EMPTY_ROW, stopIndex - startIndex);
  return state.updateIn(['open', 'tasks'], tasks =>
    tasks.splice(startIndex, stopIndex - startIndex + 1, ...fromJS(data).toArray())
  );
}

markAsSync(loadMoreCompletedTasks);
export function loadMoreCompletedTasks(state, { startIndex, stopIndex }) {
  buildersService
    .readTasks({}, { query: { open: 'false', start_index: startIndex, stop_index: stopIndex } })
    .then(data => {
      const totalCount = data.metadata.pagination ? data.metadata.pagination.totalCount : 0;
      this.controller.dispatch([
        state =>
          state
            .setIn(['completed', 'totalCount'], totalCount)
            .setIn(['completed', 'maxCount'], stopIndex + MINIMUM_BATCH_SIZE + 1)
            .updateIn(['completed', 'tasks'], tasks =>
              tasks.splice(startIndex, stopIndex - startIndex + 1, ...fromJS(data).toArray())
            ),
      ]);
    });

  const data = Repeat(EMPTY_ROW, stopIndex - startIndex);
  return state.updateIn(['completed', 'tasks'], tasks =>
    tasks.splice(startIndex, stopIndex - startIndex + 1, ...fromJS(data).toArray())
  );
}

markAsSync(invalidateFilter);
export function invalidateFilter(state) {
  setTimeout(() => this.loaderRef.current?.resetLoadMoreRowsCache(true), 1);
  return state.set('open', fromJS({ tasks: [], totalCount: 10, maxCount: 10 }));
}

markAsSync(changeStatus);
export function changeStatus(state, task, kind, status) {
  const tasks = state.getIn([kind, 'tasks']);
  const index = tasks.indexOf(task);
  const path = [kind, 'tasks', index];
  const taskId = task.get('id');
  const jobId = task.getIn(['job', 'id']);
  const lastTaskIndex = tasks.size - 1;

  buildersService
    .updateTask({ status }, { params: { jobId, taskId } })
    .then(() => {
      if ((kind === 'open' && status === COMPLETED) || (kind === 'completed' && status !== COMPLETED)) {
        this.controller.dispatch([
          state => state.removeIn(path).updateIn([kind, 'totalCount'], totalCount => totalCount - 1),
        ]);
      }
    })
    .then(() => this.controller.loadMoreOpenTasks({ startIndex: lastTaskIndex, stopIndex: tasks.size }))
    .catch(err => {
      this.addAlert('There was a problem updating this Task. Please try again.', 'danger');
      this.controller.dispatch([state => state.setIn(path, task)]);
    });

  return state.setIn([...path, 'status'], status);
}

markAsSideEffect(updateTaskRealTime);
export function updateTaskRealTime({ action, ...rest }) {
  if (action !== 'update') return;

  const { task: updated, jobId, taskId } = rest;
  const tasksType = this.state.get('tab');
  const taskList = this.state.getIn([tasksType, 'tasks']);
  const oldTask = taskList.find(task => task.getIn(['job', 'id']) === jobId && task.get('id') === taskId);
  const index = taskList.indexOf(oldTask);
  if (index < 0) return;
  const assigneeAccount = buildAssigneeAccount(updated);

  const isRemoveFromList =
    (updated.status === 'completed' && tasksType === 'open') ||
    (updated.status !== 'completed' && tasksType === 'completed');
  if (isRemoveFromList) return this.controller.changeStatus(oldTask, tasksType, updated.status);

  this.controller.dispatch([
    state =>
      state.updateIn([tasksType, 'tasks', index], task => {
        return task
          .set('assigneeAccount', assigneeAccount)
          .set('startDateConfirmed', updated.startDateConfirmed)
          .set('startDate', updated.startDate)
          .set('expectedFinishDate', updated.endDate)
          .set('durationDays', updated.duration)
          .set('predecessors', fromJS(updated.predecessors))
          .set('status', updated.status)
          .set('overdue', updated.overdue)
          .set('lateStartDate', updated.lateStartDate)
          .set('lateEndDate', updated.lateEndDate)
          .set('missingReference', updated.missingReference)
          .set('daysBehind', updated.daysBehind);
      }),
  ]);
}

function buildAssigneeAccount(updated) {
  return fromJS({
    company: updated.assigneeAccountName,
    companyId: updated.assigneeAccountId,
    companyName: updated.assigneeAccountName,
    companyType: updated.assigneeAccountType,
    installerId: updated.installerId,
    installerName: updated.installerName,
    installerStatus: updated.installerStatus,
  });
}
