import React, { useState, useRef, useLayoutEffect } from 'react';
import styled from 'styled-components';
import { colors } from '../theme';

export const MilestoneData = ({ milestone, show, isFirstMilestone, isLastMilestone, newTextStatus }) => {
  const { name, status } = milestone?.toObject();
  const ref = useRef(null);
  const [height, setHeight] = useState(0);
  useLayoutEffect(() => {
    setHeight(ref.current.offsetHeight);
  }, [ref, setHeight]);
  const textStatus = status === 'completed' ? 'Completed' : 'Not Started';

  return (
    <MilestoneDataContainer
      show={show}
      ref={ref}
      elmHeight={height}
      isFirstMilestone={isFirstMilestone}
      isLastMilestone={isLastMilestone}
      data-testid="milestone-data"
    >
      <span className="name">{name}</span>
      <span className="status">{newTextStatus ? newTextStatus : textStatus}</span>
    </MilestoneDataContainer>
  );
};
const MilestoneDataContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 140px;
  ${props => `
            ${props.isFirstMilestone && 'left:0px;'};
            ${props.isLastMilestone && 'right:0px;'};
            text-align: ${props.isFirstMilestone ? 'left' : props.isLastMilestone ? 'right' : 'center'};
            top:calc( -${props.elmHeight}px - 10px);
            visibility:${props.show ? 'visible' : 'hidden'};
            .name {
                font-size: 14px;
                color: ${colors.gray400};
                -webkit-line-clamp: 2;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
            }
            .status {
                font-size: 12px;
                color: ${colors.gray200};
            }
        `}
`;
