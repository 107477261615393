import React from 'react';
import styled from 'styled-components';
import { colors } from '@tradetrax/web-common';

export const PlanningWeeks = ({ context, visible }) => {
  const { state } = context;
  const weeks = state.get('deniedRequests');

  if (!visible) return null;
  if (weeks.get('isLoading')) return <div>Loading....</div>;

  return weeks.map((tasks, week) => <PlanningWeek context={context} week={week} key={week} tasks={tasks} />).valueSeq();
};

const PlanningWeek = ({ className, context, week, tasks }) => {
  return (
    <div className={className}>
      <h4 className="font-weight-bold mt-4">Week: {week}</h4>
      {tasks.map((task, gtlTaskId) => <PlanningTask task={task} context={context} key={gtlTaskId} />).valueSeq()}
    </div>
  );
};

const UnstyledPlanningTask = ({ className, task }) => {
  const taskName = task.getIn([0, 'gtlTaskName']);
  const taskNumber = task.reduce((total, task) => total + task.get('taskNumber'), 0);

  return (
    <div className={className}>
      <header className="d-flex flex-row">
        <span className="task-count mb-2">{taskNumber}</span>
        <label className="mr-auto font-weight-bold">{taskName}</label>
      </header>
      <div>
        {task.map(community => <CommunityTask community={community} key={community.get('communityId')} />).valueSeq()}
      </div>
    </div>
  );
};

const CommunityTask = ({ community }) => {
  const status = community.get('status');

  return (
    <div className="task-row d-flex flex-row my-2 align-items-center">
      <span className="task-count">{community.get('taskNumber')}</span>
      <span className="task-name  d-block  text-truncate mr-auto pr-2">{community.get('communityName')}</span>
      <span className="sub-name">By: {community.get('subName')}</span>
      <div className="button-wrapper">
        <StatusLabel kind="deny">{status}</StatusLabel>
      </div>
    </div>
  );
};

const StatusLabel = styled.span`
  font-weight: bold;
  font-size: 14px;
  text-transform: capitalize;
  color: ${colors.red_30};
`;

export const PlanningTask = styled(UnstyledPlanningTask)`
  background: #f9f9fa;
  border-radius: 8px;
  padding: 22px 42px 16px 8px;
  margin-top: 24px;
  margin-bottom: 8px;

  header {
    border-bottom: solid 1px ${colors.gray500};
  }

  .button-wrapper {
    width: 120px;
    text-align: right;
    > .btn:not(.deny-task) {
      font-weight: bold;
    }
  }

  .task-count {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
  }

  .sub-name {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
