import React from 'react';
import cn from 'classnames';
import { List } from 'immutable';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EDIT_VIEW, LIST_VIEW, MultipleAssigneeContext } from './MultipleAssignee.context';
import { ListView } from './ListView';
import { EditView } from './EditView';
import styled from 'styled-components';

export function MultipleAssigneeModal({ community, options, accept, close, ...rest }) {
  const { state, controller } = MultipleAssigneeContext(community, rest);
  const { view } = state.toObject();
  const hideBackButton = state.get('hideBackButton') || view === LIST_VIEW;
  const areaManagers = community.get('areaManagers').toJS();
  return (
    <StyledModal show={true} onHide={close}>
      <Modal.Header closeButton>
        <div className="d-flex flex-column align-items-start ">
          <button
            onClick={controller.setListView}
            className={cn('btn text-muted position-relative', { 'd-none': hideBackButton })}
            style={{ bottom: '0.5rem', right: '0.5rem' }}
          >
            <FontAwesomeIcon icon="arrow-left" />
          </button>
          <Modal.Title>
            Area Manager
            <h5 className="font-weight-bold font-size-16">Community: {community.get('name')}</h5>
          </Modal.Title>
        </div>
      </Modal.Header>
      {view === LIST_VIEW && <ListView assigneeList={List(areaManagers)} controller={controller} close={close} />}
      {view === EDIT_VIEW && <EditView state={state} controller={controller} allOptions={options} accept={accept} />}
    </StyledModal>
  );
}

const StyledModal = styled(Modal)`
  .modal-content {
    min-height: 400px;
    width: 600px;
  }
`;
