import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Section } from './Section';
import { colors } from '../../theme';

export const CommitmentRequests = ({ children = null, className = '' }) => {
  return (
    <Section className={className} data-testid="dashboard-commitment-requests">
      <h5 className="section-title mb-3">Commitment Requests</h5>
      {children}
    </Section>
  );
};

const UnstyledCard = ({ count, className, to, label }) => {
  return (
    <Link to={to} className={className}>
      <span className="card-count mr-1">{count}</span>
      <div className="px-3 d-flex align-items-center">
        <div className="d-flex flex-column">
          <h6 className="font-weight-bold text-dark">{label}</h6>
          <span>Enter here to review them</span>
        </div>
      </div>
    </Link>
  );
};

const themes = {
  danger: {
    color: colors.red,
    borderColor: colors.red_30,
  },
  primary: {
    color: colors.blue300,
    borderColor: colors.blue_30,
  },
};

export const CommitmentRequestCard = styled(UnstyledCard)`
  ${({ variant = 'danger' }) => `
  height: 100px;
  /* Active */
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
  border: 1px solid #ededed;
  position: relative;
  text-decoration: none !important;

  span.card-count {
    width: 89px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 40px;
    line-height: 54px;
    font-weight: bold;
    color: ${themes[variant].color};
  }

  h6 + span {
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.2px;
    color: ${colors.gray800};
  }

  &::before {
    position: absolute;
    background-color: ${themes[variant].borderColor};
    left: 0;
    bottom: 0;
    right: 0;
    height: 8px;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    content: '';
  }

  &:hover {
    background-color: ${colors.pearl};
  }
}`}
`;

CommitmentRequests.Card = CommitmentRequestCard;
