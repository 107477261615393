import * as overdueActions from './Overdues.actions';
import * as updateRequestActions from './UpdateRequests.actions';
import * as upcomingActions from './Upcoming.actions';

import { BulkActionsContext } from './BulkActions.context';

export { BulkHeader } from './BulkHeader';

export const BulkOverdueContext = (overdueService, parentContext) =>
  BulkActionsContext({ ...overdueActions, ...updateRequestActions }, overdueService, parentContext);

export const BulkUpcomingContext = (upcomingService, parentContext) =>
  BulkActionsContext(upcomingActions, upcomingService, parentContext);
