import React from 'react';
import cn from 'classnames';
import { InlineInputEdit } from './InlineInputEdit';

export const TargetDate = ({ title, date, truncate, className = 'mr-5 w-9rem', ...rest }) => (
  <div className={`d-flex flex-column ${className}`} {...rest}>
    <small className="text-muted text-nowrap">{title}</small>
    <span className={cn({ 'text-gray-200': date === null, 'w-auto text-truncate': truncate })}>
      {date === null ? 'Not Set' : date}
    </span>
  </div>
);

export const EditableField = props => {
  const { editableProps, title, truncate, className = 'mr-5 w-9rem', inputSize = 'auto', collapsed, ...rest } = props;
  const { value } = editableProps;

  return (
    <div className={cn(`d-flex flex-column ${className}`, { 'mb-5': collapsed })} {...rest}>
      <small className="text-muted">{title}</small>
      <InlineInputEdit
        labelClassName={cn({ 'text-gray-200': value === null, 'w-auto text-truncate': truncate })}
        labelText={() => (value === null ? 'Not Set' : value)}
        {...editableProps}
        title={title}
        inputSize={inputSize}
      />
    </div>
  );
};

export const emptyStringToNull = (value, originalValue) =>
  typeof originalValue === 'string' && originalValue === '' ? null : value;
