import React from 'react';
import { Redirect } from 'react-router-dom';
import { Context } from '@tradetrax/web-common/lib/Reports/Report.context';
import { ReportPage } from '@tradetrax/web-common/lib/Reports/Report.page';
import { useAppContext } from 'app/App.context';
import { buildersService } from 'services';
import { Paths } from 'app/navigate';
import { AverageCycleTimeOfJobs, AverageCycleTimeOfJobsBySuperAsignee } from './CycleTime/index';
import {
  TaskOverdueByAssigneeAccount,
  TaskOverduePercentageByAssigneeAccount,
  TaskOverdueTotalAmountByMonth,
  TaskOverduePercentageByMonth,
} from './TaskOverdue';
import * as cycleTimeActions from './CycleTime/CycleTime.actions';
import * as taskOverdueActions from './TaskOverdue/TaskOverdue.actions';

const reportActions = { ...cycleTimeActions, ...taskOverdueActions };
const reportTypes = [
  AverageCycleTimeOfJobs,
  AverageCycleTimeOfJobsBySuperAsignee,
  TaskOverdueByAssigneeAccount,
  TaskOverduePercentageByAssigneeAccount,
  TaskOverdueTotalAmountByMonth,
  TaskOverduePercentageByMonth,
];

export const BuilderReportPage = () => {
  const appContext = useAppContext();
  const context = Context(reportActions, buildersService, appContext);
  const { hasPermission } = appContext;

  if (!hasPermission('custom_reports')) return <Redirect to={Paths.NotPermission} />;
  return <ReportPage context={context} reportTypes={reportTypes} />;
};
