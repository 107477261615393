import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const StyledEmptyState = styled.span`
  background-color: #cddcf3;
  border-radius: 50%;
  height: 52px;
  width: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledIcon = styled(FontAwesomeIcon)`
  background-color: #cddcf3;
  color: #fff;
  font-size: 24px;
`;

export const EmptyState = ({ icon, title, body, visible = true }) =>
  visible ? (
    <div
      className="d-flex justify-content-start align-items-center ml-4 w-75"
      style={{ top: '-20px', position: 'relative' }}
    >
      <StyledEmptyState>
        <StyledIcon icon={icon} />
      </StyledEmptyState>

      <div className="ml-3">
        <h5 className="font-weight-bold text-muted ">{title}</h5>
        <div className="text-muted " style={{ fontSize: '12px' }}>
          {body}
        </div>
      </div>
    </div>
  ) : null;
