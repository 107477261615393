import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrimaryButton, colors, ChipStatus, STATUS_MAP } from '../../index';
import moment from 'moment';
import navigate from 'app/navigate';

export function PrecedingStatusModal({ accept, cancel, task, precedingTasks, loadTasksOfInterest, canEdit }) {
  const [tasksOfInterest, setTasksOfInterst] = React.useState(null);
  const job = task.get('job');
  const hasPreceding = precedingTasks.size > 0;
  const lotNumber = job.get('lotNumber');
  const truncatedLotNumber = lotNumber?.length > 10 ? lotNumber.slice(0, 10) + '...' : lotNumber;

  React.useEffect(() => {
    loadTasksOfInterest().then(setTasksOfInterst);
  }, [loadTasksOfInterest, setTasksOfInterst]);

  return (
    <Modal show={true} onHide={cancel} dialogClassName="width-600">
      <Modal.Header closeButton>
        <Modal.Title>Definition of Ready Status</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column">
          <span className="font-weight-bold">{task.get('name')}</span>
          {lotNumber ? (
            <span className="mb-4 text-gray-400">{`LOT # ${truncatedLotNumber}`}</span>
          ) : (
            <span className="font-size-14 mb-4">{job.get('name')}</span>
          )}
          <div className="mt-3 ml-n4 pl-4" style={{ overflowY: 'scroll', maxHeight: 330 }}>
            {precedingTasks.map(task => (
              <PrecedingTask task={task} key={task.get('id')} />
            ))}
            {!hasPreceding && (
              <p>
                This Task has no Definition of Ready Set.
                {canEdit
                  ? ' Please, configure it on the Definition of Ready Panel.'
                  : ' The Account Manager needs to configure it on the Definition of Ready Panel.'}
              </p>
            )}
            <TasksOfInterest tasksOfInterest={tasksOfInterest} />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <Link
          to={navigate.to.JobDetails({ jobId: job.get('id') }, false)}
          className="d-inline-block text-truncate mr-1"
          onClick={cancel}
        >
          View in Job Schedule
        </Link>
        <PrimaryButton onClick={() => accept()}>
          {hasPreceding || !canEdit ? 'OK' : 'Set Up Definition of Ready'}
        </PrimaryButton>
      </Modal.Footer>
    </Modal>
  );
}

const PrecedingTask = ({ task }) => {
  const {
    presentOnJob,
    name,
    status: taskStatus,
    startDate,
    expectedFinishDate,
    checkedIn,
    isChildTask,
    parentTaskName,
  } = task.toObject();
  const status = checkedIn ? 'checked-in' : taskStatus;
  const [dateLabel, date] =
    isChildTask || status === 'in-progress' || (taskStatus === 'in-progress' && checkedIn)
      ? ['Exp. Finish', expectedFinishDate]
      : ['Exp. Start', startDate];
  const subTaskLabel = parentTaskName ? `Sub-Task from ${parentTaskName}` : "This Sub-Task doesn't belong to any Task";

  return (
    <div className="d-flex flex-row mb-3">
      <span className="font-weight-bold mr-auto position-relative">
        <Warning show={!presentOnJob} />
        {name}
        {isChildTask ? (
          <div className="d-block mr-3 small text-secondary text-truncate" style={{ maxWidth: '300px' }}>
            {subTaskLabel}
          </div>
        ) : null}
      </span>
      <div className="d-flex flex-column" style={{ width: 160 }}>
        {presentOnJob ? <ChipStatus {...STATUS_MAP[status]} /> : <span className="mx-auto">Not in this job</span>}
        {presentOnJob && status !== 'completed' && (
          <span className="mx-auto font-size-12">
            {dateLabel} {moment(date, 'YYYY-MM-DD').format('MMMM D')}
          </span>
        )}
        <span className="mx-auto font-size-12 text-gray-400">{task.get('hourRange')}</span>
      </div>
    </div>
  );
};

const TasksOfInterest = ({ tasksOfInterest }) => {
  if (tasksOfInterest === null) {
    return (
      <TasksOfInterestSection>
        <span>Loading Tasks of Interest...</span>
      </TasksOfInterestSection>
    );
  }

  if (tasksOfInterest.size === 0) return null;

  return (
    <TasksOfInterestSection>
      {tasksOfInterest.map(toi => (
        <TaskOfInterest task={toi} key={toi.get('_id')} />
      ))}
    </TasksOfInterestSection>
  );
};

const TasksOfInterestSection = ({ children }) => {
  return (
    <>
      <div className="text-secondary small">Tasks of Interest</div>
      <hr />
      {children}
    </>
  );
};

const TaskOfInterest = ({ task }) => {
  const {
    presentOnJob,
    name,
    status: taskStatus,
    startDate,
    endDate,
    checkedIn,
    isChildTask,
    parentTaskName,
  } = task.toObject();
  const status = checkedIn ? 'checked-in' : taskStatus;
  const [dateLabel, date] =
    isChildTask || status === 'in-progress' || (taskStatus === 'in-progress' && checkedIn)
      ? ['Exp. Finish', endDate]
      : ['Exp. Start', startDate];
  const subTaskLabel = parentTaskName ? `Sub-Task from ${parentTaskName}` : "This Sub-Task doesn't belong to any Task";

  return (
    <div className="d-flex flex-row mb-3">
      <span className="font-weight-bold mr-auto position-relative">
        <Warning show={!presentOnJob} />
        {name}
        {isChildTask ? (
          <div className="d-block mr-3 small text-secondary text-truncate" style={{ maxWidth: '300px' }}>
            {subTaskLabel}
          </div>
        ) : null}
      </span>
      <div className="d-flex flex-column" style={{ width: 160 }}>
        {presentOnJob ? <ChipStatus {...STATUS_MAP[status]} /> : <span className="mx-auto">Not in this job</span>}
        {presentOnJob && status !== 'completed' && (
          <span className="mx-auto font-size-12">
            {dateLabel} {moment(date, 'YYYY-MM-DD').format('MMMM D')}
          </span>
        )}
        <span className="mx-auto font-size-12 text-gray-400">{task.get('hourRange')}</span>
      </div>
    </div>
  );
};

const UnstyledWarning = ({ show, className }) => {
  if (!show) return null;
  return (
    <span className={className}>
      <FontAwesomeIcon icon="exclamation" />
    </span>
  );
};

const Warning = styled(UnstyledWarning)`
  background-color: ${colors.yellow100};
  display: inline;
  font-size: 8px;
  border-radius: 50%;
  position: relative;
  color: white;
  position: absolute;
  left: -20px;
  top: 6px;
  text-align: center;
  width: 13px;
  height: 12px;
`;
