import React from 'react';
import styled from 'styled-components';
import { List } from 'immutable';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'react-bootstrap';
import { PrimaryButton, ToggleButtons, ToggleButton } from '@tradetrax/web-common';
import { OuttakeController } from './OuttakeController';
import { TasksBySelect, ALL_TASKS, TASK_TYPE } from '@tradetrax/web-common/lib/OuttakeIntake/TasksBySelect';
import { OuttakeTasks } from './OuttakeTasks';
import { OuttakeTasksByType } from './OuttakeTasksByType';
import navigate, { Paths } from 'app/navigate';
import { useAppContext } from 'app/App.context';
import { useFilterContext } from '@tradetrax/web-common/lib/Filters/Filters.context';
import { FilterImplementation } from '@tradetrax/web-common/lib/Filters/Implementation/Filter.implementation';

export const Outtake = ({ match }) => {
  const { communityId } = match.params;
  const { hasPermission, account, appState } = useAppContext();
  const outtakeContext = OuttakeController(communityId);
  const { state, controller, filterName } = outtakeContext;
  const { tab, tasksBy, community } = state.toObject();
  const filterContext = useFilterContext(filterName);
  const isBulkAssignmentEnabled = tab === 'account' || hasPermission('task_user_assignment');
  const assignees = tab === 'account' ? List(appState.get('companies')) : account.get('usersActive');
  const lookups = { assignees };
  const path = tasksBy === ALL_TASKS ? ['selectedTasks', tab, 'taskIds'] : ['selectedTasks', tab, 'taskNames'];
  const isAssignButtonDisabled =
    (!isBulkAssignmentEnabled || !state.getIn(path).size) && !state.getIn(['selectedTasks', tab, 'selectAll']);
  const isFilterDisabled =
    state.getIn(['selectedTasks', tab, 'selectAll']) ||
    tasksBy === TASK_TYPE ||
    state.getIn(['selectedTasks', tab, 'taskIds']).size > 0;

  return (
    <>
      <Breadcrumb className="mx-4 mt-4" tag="nav">
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: Paths.Community }}>
          Communities
        </Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: navigate.to.CommunityDetails({ communityId }, false) }}>
          {community.get('name')}
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Outtake</Breadcrumb.Item>
      </Breadcrumb>
      <div className="mx-4 d-flex flex-row align-items-center justify-content-between">
        <span className="h4 pt-0 pt-md-3 mt-1 mb-0 font-weight-bold">{`Outtake for ${community.get('name')}`}</span>
        <div className="d-flex flex-row">
          <PrimaryButton
            className="mr-3 text-nowrap"
            disabled={isAssignButtonDisabled}
            onClick={() => controller.openBulkAssignmentModal(tab, tasksBy)}
          >
            Assign Tasks
          </PrimaryButton>
          <FilterImplementation filterContext={filterContext} disabled={isFilterDisabled} lookups={lookups} />
        </div>
      </div>
      <div className="d-flex  flex-column flex-lg-row justify-content-between mt-4 mr-4 mb-2">
        <ToggleButtons onChange={controller.setTab} type="radio" name="options" value={tab}>
          <ToggleButtonContainer value="account" className="p-0">
            Account
          </ToggleButtonContainer>
          <ToggleButtonContainer value="super">Super</ToggleButtonContainer>
          <ToggleButtonContainer value="scheduler">Scheduler</ToggleButtonContainer>
        </ToggleButtons>
        <div className="mt-4 ml-3 mb-4 mt-lg-2 ml-lg-3 mb-lg-0">
          <TasksBySelect tasksBy={tasksBy} onChange={controller.setTasksBy} />
        </div>
      </div>
      {tasksBy === ALL_TASKS && <OuttakeTasks tab={tab} communityId={communityId} {...outtakeContext} />}
      {tasksBy === TASK_TYPE && <OuttakeTasksByType tab={tab} {...outtakeContext} />}
    </>
  );
};

const ToggleButtonContainer = styled(ToggleButton)`
  min-width: 135px !important;
  max-width: 200px;

  @media (min-width: 992px) {
    width: 135px;
    max-width: 135px;
  }
  @media (min-width: 1200px) {
    width: 200px;
    max-width: 200px;
  }
`;
