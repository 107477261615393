import React from 'react';
import { fromJS, Map, List } from 'immutable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { markAsSideEffect, markAsSync, CustomDialog } from '@tradetrax/web-common';
import { buildersService } from 'services';

markAsSync(expand);
export function expand(state, key) {
  return state.set('expanded', key);
}

const dashKey = task =>
  task
    .get('taskName')
    .toLowerCase()
    .replace(/\s/g, '-');

markAsSync(readTasks);
export function readTasks(state) {
  buildersService
    .viewAccountabilities()
    .then(fromJS)
    .then(tasks => tasks.map(task => task.set('key', dashKey(task))))
    .then(tasks =>
      this.controller.dispatch([
        state => {
          const isEmpty = !tasks.size;
          if (isEmpty) return state.set('selectedTaskTypes', Map({ isEmpty }));
          else return state.set('selectedTaskTypes', tasks);
        },
      ])
    );
  return state.setIn(['selectedTaskTypes', 'isLoading'], true);
}

markAsSync(addTask);
export function addTask(state, task) {
  const taskType = fromJS({
    taskId: task.get('_id'),
    taskName: task.get('name'),
    daysTimeRange: 0,
  });
  const taskKey = dashKey(taskType);
  return state
    .update('selectedTaskTypes', tasks => {
      const isEmpty = tasks.get('isEmpty');
      if (isEmpty) return List([taskType.set('key', taskKey)]);
      return tasks.unshift(taskType.set('key', taskKey));
    })
    .set('expanded', taskKey);
}

markAsSideEffect(saveTaskCard);
export function saveTaskCard(task, form) {
  if (task.get('_id')) {
    this.controller.updateStartDateConfirmation(task, form);
  } else {
    this.controller.createStartDateConfirmation(task, form);
  }
}

markAsSideEffect(createStartDateConfirmation);
export function createStartDateConfirmation(task, { timeConditions, daysTimeRange, relatedTask, timeCompletion }) {
  const index = this.state.get('selectedTaskTypes').indexOf(task);
  const numberOfDays = daysTimeRange && daysTimeRange === 'Same' ? 0 : parseInt(daysTimeRange);
  const payload = {
    taskId: task.get('taskId'),
    taskName: task.get('taskName'),
    relatedTaskId: relatedTask.get('_id'),
    relatedTaskName: relatedTask.get('name'),
    timeCompletion: timeCompletion === 'Starts' ? 'start' : 'finish',
    daysTimeRange: numberOfDays,
  };
  if (timeConditions) {
    payload.timeConditions = timeConditions.toLowerCase();
  }

  return buildersService
    .createAccountability(payload)
    .then(response => {
      this.controller.dispatch([state => state.updateIn(['selectedTaskTypes', index], task => task.merge(response))]);
      this.addAlert(`${task.get('taskName')} Start Date Confirmation request successfully set.`, 'success');
    })
    .catch(() => {
      this.addAlert('There was a problem saving this setting. Please try again.', 'danger');
    });
}

markAsSideEffect(updateStartDateConfirmation);
export function updateStartDateConfirmation(task, { timeConditions, daysTimeRange, relatedTask, timeCompletion }) {
  const index = this.state.get('selectedTaskTypes').indexOf(task);
  const numberOfDays = daysTimeRange && daysTimeRange === 'Same' ? 0 : parseInt(daysTimeRange);
  const payload = {
    relatedTaskId: relatedTask.get('_id'),
    relatedTaskName: relatedTask.get('name'),
    timeCompletion: timeCompletion === 'Starts' ? 'start' : 'finish',
    daysTimeRange: numberOfDays,
  };
  if (timeConditions) {
    payload.timeConditions = timeConditions.toLowerCase();
  }
  this.controller.dispatch([state => state.updateIn(['selectedTaskTypes', index], task => task.merge(payload))]);

  return buildersService
    .updateAccountability(payload, { params: { accountabilityId: task.get('_id') } })
    .then(() => {
      this.addAlert(`${task.get('taskName')} Start Date Confirmation request successfully set.`, 'success');
    })
    .catch(error => {
      this.addAlert('There was a problem saving this setting. Please try again.', 'danger');
    });
}

markAsSideEffect(deleteTaskType);
export async function deleteTaskType(task) {
  const accountabilityId = task.get('_id');
  const { isAccept } = await this.modal.open(CustomDialog, {
    maxWidth: '511px',
    title: (
      <>
        <FontAwesomeIcon icon="circle-exclamation" className="text-danger" />
        Remove Task Type
      </>
    ),
    message:
      'By removing it, you will no longer request Trades to confirm the Start Date for this Task Type. You can add it again later from the Global Task Library.',
    titleAccept: 'Yes, Remove Task Type',
    titleCancel: 'Cancel',
  });
  if (!isAccept) return;

  const currentTaskTypes = this.state.get('selectedTaskTypes');
  const index = currentTaskTypes.indexOf(task);
  this.controller.dispatch([
    state =>
      state.update('selectedTaskTypes', taskTypes => {
        const updatedTaskTypes = taskTypes.remove(index);
        const isEmpty = !updatedTaskTypes.size;
        if (isEmpty) return Map({ isEmpty });
        else return updatedTaskTypes;
      }),
  ]);

  const displaySuccessMessage = () => this.addAlert('Task Type successfully removed.', 'success');

  if (accountabilityId) {
    buildersService
      .removeAccountability({}, { params: { accountabilityId } })
      .then(() => {
        displaySuccessMessage();
      })
      .catch(() => {
        this.controller.dispatch([state => state.set('selectedTaskTypes', currentTaskTypes)]);
        this.addAlert('There was a problem removing this Task Type and its setting. Please try again.', 'danger');
      });
  } else {
    displaySuccessMessage();
  }
}
