import React from 'react';
import { Form } from 'react-bootstrap';
import { UL } from './UL';

export const MyTasks = ({ controller, pushChecked, emailChecked }) => {
  const update = onlyChannels => controller.updateNotificationEventsSettings(onlyChannels);

  return (
    <div className="d-flex flex-column p-2">
      <h5 className="font-weight-bold mb-3">My Tasks</h5>
      <UL>
        <li>
          <span>Enable Push Notifications</span>
          <Form.Check
            id="settings-push"
            checked={!!pushChecked}
            type="switch"
            label=""
            onChange={({ target }) => update([target.checked ? 'push' : null, emailChecked ? 'email' : null])}
          />
        </li>
        <li>
          <span>Enable Email Notifications</span>
          <Form.Check
            id="settings-email"
            checked={!!emailChecked}
            type="switch"
            label=""
            onChange={({ target }) => update([target.checked ? 'email' : null, pushChecked ? 'push' : null])}
          />
        </li>
      </UL>
    </div>
  );
};
