import React from 'react';
import cn from 'classnames';
import { Item, SystemItem, Name, CreatedBySameAccount, isBuilder, getName } from './common';
import { isUserInactive } from '../../utils';
import { UserNameLabel } from '../../Tags';

export default ({ item, account, user, isInactive, accountUsers }) => {
  const { installerName, installerId, firstTime } = item.get('data').toObject();
  const isSameInstaller = installerId === user.get('_id');
  const isInstallerInactive = isUserInactive(installerId, accountUsers);
  const name = getName(item, user);
  const Component = name ? Item : SystemItem;

  const message = (
    <>
      {firstTime ? 'set' : 'updated'} Installer assignee
      <CreatedBySameAccount item={item} user={user}>
        {` to `}
        <UserNameLabel
          className={cn('text-dark highlight', { 'font-weight-bold': isSameInstaller })}
          isInactive={isInstallerInactive}
        >
          {installerName}
        </UserNameLabel>
      </CreatedBySameAccount>
    </>
  );

  const removedAssigneeMessage = 'removed Installer assignee';

  return (
    <Component item={item} user={user}>
      <Name item={item} account={account} user={user} isInactive={isInactive} />{' '}
      {installerId ? message : removedAssigneeMessage}
    </Component>
  );
};

module.exports.default.visibility = ({ item, user, account }) =>
  isBuilder(account) || item.get('assigneeAccountId') === user.get('accountId');
