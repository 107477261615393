import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Paths } from 'app/navigate';
import { NotFound } from '@tradetrax/web-common';
import { HomeownersPage } from './HomeownersPage';
import { HomeownerDetails } from './HomeownerDetails/HomeownerDetails';
import { HomeownerProfile } from './HomeownerProfile/HomeownerProfile';
import { HomeownerProfileContext } from './HomeownerProfile/HomeownerProfileContext';

export const HomeownerRoutes = () => {
  return (
    <Switch>
      <Route path={Paths.Homeowners} component={HomeownersPage} exact />
      <Route path={Paths.HomeownerProfile} component={HomeownerProfileRoute} />
      <Route component={NotFound} />
    </Switch>
  );
};

const HomeownerProfileRoute = ({ match }) => {
  const { userId } = match.params;
  const context = HomeownerProfileContext(userId);
  return (
    <Switch>
      <Route path={Paths.HomeownerDetails} render={props => <HomeownerDetails {...props} context={context} />} exact />
      <Route path={Paths.HomeownerProfile} render={props => <HomeownerProfile {...props} context={context} />} />
      <Route component={NotFound} />
    </Switch>
  );
};
