import React from 'react';
import styled from 'styled-components';
import { Card } from './ToDoCard';

export const LoadingCards = ({ howMany = 3 }) => {
  return (
    <div data-testid="loading-cards">
      <PlaceHolder className="h6" style={{ width: 256 }} />
      <PlaceHolder style={{ width: 219, marginBottom: '1.25rem' }} />
      {Array.from({ length: howMany }, (v, k) => (
        <Card key={k} status="not-started">
          <PlaceHolder className="mt-2 mb-3" style={{ width: 214 }} />
          <PlaceHolder className="mt-1 mb-3" style={{ width: 163 }} />
          <div className="d-flex flex-row pt-2 justify-content-between">
            <PlaceHolder style={{ width: 114 }} />
            <PlaceHolder style={{ width: 127 }} />
          </div>
        </Card>
      ))}
    </div>
  );
};

const PlaceHolder = styled.span`
  height: 12px;
  background-color: #e0e2e7;
  border-radius: 5px;
  display: inline-block;
`;
