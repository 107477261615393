import { fromJS, List, Map } from 'immutable';

export const emptyList = List();

export const emptyMap = Map({});

export const emptyAccount = fromJS({
  name: '',
  email: '',
  trades: [],
  workingDays: {},
  holidays: [],
  subs: [],
  installers: [],
  assignees: [
    {
      userId: '',
      name: '',
    },
  ],
  users: [],
});

export const emptyJob = fromJS({
  _id: 'aaaaaabbbbbbccccccdddddd',
  name: '',
  builderName: '',
  communityId: 'aaaaaabbbbbbccccccdddddd',
  communityName: '',
});

export const emptyTask = fromJS({
  id: 0,
  name: '',
  duration: 0,
  startDate: '',
  status: '',
  assigneeAccountId: '',
});

export const emptyTasks = fromJS([]);

export const emptyJobFeed = fromJS([
  {
    date: '',
    action: '',
    task: { _id: 0 },
  },
]);

export const emptyFeedCount = fromJS([{ count: 3 }, { count: 3 }]);
