import React from 'react';
import { Form, Col } from 'react-bootstrap';

export const TaskPreConstructionCheckbox = ({ controller, task, canEdit, className = '' }) => {
  const onChangeIsPreConstuction = e => {
    e.stopPropagation();
    const { checked } = e.target;
    controller.updateTaskPreConstruction({ task, isPreConstruction: checked });
  };

  return (
    <Form.Row className={className}>
      <Form.Group as={Col} md="12" controlId="formIsPreConstruction">
        <div className="d-flex flex-row align-items-center">
          <Form.Check
            type="checkbox"
            id="is-pre-construction"
            name="isPreConstruction"
            label="Pre-Construction Task. It will not affect Job Exp. Start Date"
            disabled={!canEdit}
            checked={!!task.get('isPreConstruction')}
            onChange={onChangeIsPreConstuction}
          />
        </div>
      </Form.Group>
    </Form.Row>
  );
};
