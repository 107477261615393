import React from 'react';
import styled from 'styled-components';

const CriticalPathContainer = styled.span`
  background-color: #fdcb9d;
  font-size: 12px;
  border-radius: 6px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
`;
const CriticalIcon = styled(CriticalPathContainer)`
  width: 24px;
  margin-left: 9px;
`;

const CriticalLabel = styled(CriticalPathContainer)`
  width: 91px;
  margin-top: 10px;
`;

export const CriticalPathIcon = () => {
  return (
    <CriticalIcon>
      <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="22px">
        <text x="8" y="15">
          C
        </text>
      </svg>
    </CriticalIcon>
  );
};

export const CriticalPathLabel = ({ className = '' }) => {
  return <CriticalLabel className={className}>Critical Task</CriticalLabel>;
};
