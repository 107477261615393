import React from 'react';
import { fromJS } from 'immutable';
import { useController } from '@tradetrax/web-common';
import { useAppContext } from 'app/App.context';
import * as actions from './HomeownerProfileActions';

const emptyHomeownerDetails = {
  jobs: [],
  builderAssignee: {},
  customerManager: {},
};

const emptyState = fromJS({
  homeownerDetails: emptyHomeownerDetails,
  job: {},
  tab: 'members',
  isLoading: true,
});

export function HomeownerProfileContext(userId) {
  const appContext = useAppContext();
  const user = appContext.appState.get('user');
  const [state, controller] = useController(actions, emptyState, appContext);
  const { homeownerDetails, isLoading } = state.toObject();

  React.useEffect(() => {
    controller.getHomeownerProfile(userId);
  }, [controller, userId]);

  React.useEffect(() => {
    if (homeownerDetails !== emptyHomeownerDetails) {
      const { jobs } = homeownerDetails.toObject();
      if (jobs?.size > 0 && isLoading) controller.getJobProgress(homeownerDetails.getIn(['jobs', 0, '_id']));
    }
  }, [controller, homeownerDetails, isLoading]);

  return {
    state,
    controller,
    user,
  };
}
