import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import * as yup from 'yup';
import { Form } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useForm, Controller } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers';
import { capitalize } from '@tradetrax/web-common/lib/utils';
import { PrimaryButton, TransparentButton } from '@tradetrax/web-common/lib/Button';
import { ENUM_TIME_COMPLETION } from './ConfirmationsContext';

const WHEN_OPTIONS = ['After', 'Before'];
const arrayNumbers = [...Array(30).keys()].map(i => (++i).toString());
const DAYS_OPTIONS = ['Same', ...arrayNumbers];
const COMPLETION_OPTIONS = ['Finishes', 'Starts'];

const schema = yup.object().shape({
  daysTimeRange: yup
    .string()
    .oneOf(DAYS_OPTIONS)
    .required(),
  timeConditions: yup.string().when('daysTimeRange', {
    is: val => val !== 'Same',
    then: yup
      .string()
      .required()
      .oneOf(WHEN_OPTIONS),
    otherwise: yup
      .string()
      .notRequired()
      .nullable(),
  }),
  timeCompletion: yup
    .string()
    .oneOf(COMPLETION_OPTIONS)
    .required(),
});

export function TaskCard({ expanded, controller, task, toggle, globalTasks }) {
  const defaultValues = getFormDefaultValues(task, globalTasks);
  const formContext = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });
  const { reset } = formContext;
  const onClickCancel = event => {
    event.preventDefault();
    reset(defaultValues);
  };
  const onToggle = () => {
    reset(defaultValues);
    toggle(task);
  };
  return (
    <li className={`task-card ${expanded ? 'expanded' : ''}`}>
      <div data-testid="accountability-task-name">
        <button
          data-testid="btn-remove"
          className="btn btn-link p-0 text-secondary"
          onClick={() => controller.deleteTaskType(task)}
        >
          <FontAwesomeIcon icon="trash" />
        </button>
        <strong data-testid="label-task-name" className="ml-3 mr-auto align-self-center">
          {task.get('taskName')}
        </strong>
        <button data-testid="btn-toggle" className="btn btn-link p-0 text-secondary" onClick={onToggle}>
          <FontAwesomeIcon icon={`chevron-${expanded ? 'up' : 'down'}`} className="mt-2" />
        </button>
      </div>
      <TaskCardDetails
        defaultValues={defaultValues}
        expanded={expanded}
        task={task}
        controller={controller}
        globalTasks={globalTasks}
        formContext={formContext}
        onClickCancel={onClickCancel}
      />
    </li>
  );
}

const TaskCardDetails = ({ task, expanded, controller, globalTasks, formContext, onClickCancel, defaultValues }) => {
  const { handleSubmit, control, watch, errors, setValue, formState, trigger, clearErrors, reset } = formContext;
  const { daysTimeRange, relatedTask, timeConditions } = watch();
  const timeConditionsValue = timeConditions || 'After';
  React.useEffect(() => {
    if (daysTimeRange === 'Same') {
      setValue('timeConditions', null);
    } else if (!isNaN(daysTimeRange)) {
      setValue('timeConditions', timeConditionsValue);
    }
  }, [daysTimeRange, setValue, timeConditionsValue]);
  const onSubmit = form => {
    reset(form);
    controller.saveTaskCard(task, form);
  };
  const onBlur = async (name, { target }) => {
    const isValid = await trigger(name);
    if (isValid) return;
    if ((!isValid && isNaN(target.value)) || !target.value) {
      !daysTimeRange && clearErrors(name);
      const value = name === 'timeConditions' ? 'After' : defaultValues[name];
      setValue(name, value);
    } else if (!daysTimeRange) {
      clearErrors(name);
      setValue(name, target.value);
    }
  };
  const options = React.useMemo(() => globalTasks.filter(gt => task.get('taskId') !== gt.get('_id')), [
    globalTasks,
    task,
  ]);

  const { isDirty } = formState;
  const isButtonDisabled = !isDirty || !relatedTask;
  return (
    <div data-testid="task-card-details" className={cn('font-size-14 mt-3 flex-column', { 'd-none': !expanded })}>
      <span className="mr-auto">TradeTrax will send automatic requests to the Trade:</span>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <InputsContainer className="my-3">
          <div className="d-flex align-items-center">
            <CardTypeahead
              id="daysTimeRange"
              checkIfValid={onBlur}
              className="flex-grow-1"
              control={control}
              defaultSelected={[DAYS_OPTIONS[0]]}
              name="daysTimeRange"
              options={DAYS_OPTIONS}
            />
            <span className="ml-2">day(s)</span>
            <CardTypeahead
              id="timeConditions"
              checkIfValid={onBlur}
              control={control}
              disabled={daysTimeRange === 'Same' && !errors.daysTimeRange}
              name="timeConditions"
              options={WHEN_OPTIONS}
              placeholder="When"
              className="flex-grow-1"
            />
          </div>
          <div className=" d-flex align-items-center">
            <Controller
              control={control}
              name="relatedTask"
              render={({ onChange, value }) => (
                <Typeahead
                  id="gtl"
                  className="ml-2 flex-grow-1"
                  options={options.toArray()}
                  filterBy={filterBy}
                  labelKey={option => option?.get('name')}
                  onChange={([selected]) => onChange(selected)}
                  placeholder="Choose Task"
                  selected={value ? [value] : []}
                >
                  <FontAwesomeIcon icon="magnifying-glass" />
                </Typeahead>
              )}
            />
            <CardTypeahead
              id="timeCompletion"
              checkIfValid={onBlur}
              control={control}
              defaultSelected={[COMPLETION_OPTIONS[0]]}
              name="timeCompletion"
              options={COMPLETION_OPTIONS}
            />
          </div>
        </InputsContainer>
        <div className="d-flex justify-content-end">
          <TransparentButton disabled={isButtonDisabled} onClick={onClickCancel}>
            Cancel
          </TransparentButton>
          <PrimaryButton className="ml-2" disabled={isButtonDisabled} type="submit">
            Save
          </PrimaryButton>
        </div>
      </Form>
    </div>
  );
};

const SmallTypeahead = styled(Typeahead)`
  @media (min-width: 860px) {
    max-width: 120px;
  }

  .rbt-input-main {
    padding-right: 2rem;
  }
`;

const filterBy = (option, props) => {
  if (props.selected.length) return props.labelKey(option) !== props.labelKey(props.selected[0]);
  return (
    props
      .labelKey(option)
      .toLowerCase()
      .indexOf(props.text.toLowerCase()) !== -1
  );
};

const CardTypeahead = ({
  icon = 'chevron-down',
  placeholder,
  name,
  control,
  checkIfValid,
  className = '',
  ...props
}) => (
  <Controller
    control={control}
    name={name}
    render={({ onBlur, onChange, value }) => (
      <SmallTypeahead
        {...props}
        className={`ml-2 ${className}`}
        filterBy={filterBy}
        labelKey={option => option}
        onBlur={event => onBlur(checkIfValid(name, event))}
        onChange={([selected]) => onChange(selected)}
        placeholder={placeholder || null}
        selected={value ? [value] : []}
      >
        <FontAwesomeIcon icon={icon} />
      </SmallTypeahead>
    )}
  />
);

const getFormDefaultValues = (task, globalTasks) => {
  const isSameDay = task.get('daysTimeRange') === 0;
  const daysTimeRange = isSameDay ? 'Same' : task.get('daysTimeRange').toString();
  const timeCompletion = ENUM_TIME_COMPLETION[task.get('timeCompletion')] || 'Finishes';
  const timeConditions = daysTimeRange === 'Same' ? null : capitalize(task.get('timeConditions'));
  const relatedTask = globalTasks.find(gt => gt.get('_id') === task.get('relatedTaskId'));

  return {
    daysTimeRange,
    timeCompletion,
    timeConditions,
    relatedTask,
  };
};

const InputsContainer = styled.div`
  display: flex;
  flex-direction: column !important;
  grid-gap: 10px;
  @media (min-width: 860px) {
    grid-gap: 0px;
    flex-direction: row !important;
  }
`;
