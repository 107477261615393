import React from 'react';
import { Modal, Form, Col } from 'react-bootstrap';
import { Datepicker, Typeahead } from '@tradetrax/web-common';
import { plural, calculateDiffDays } from '@tradetrax/web-common/lib/utils';
import { useForm, Controller as FormController } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { PrimaryButton, DurationInput } from '@tradetrax/web-common';
import { useAppContext } from 'app/App.context';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import cn from 'classnames';
import { DateUtil } from '@tradetrax/tasks-util';

const validationSchema = yup.object().shape({
  task: yup
    .array()
    .required('Task is required')
    .of(
      yup.object().shape({
        name: yup.string().required('Task is required'),
      })
    ),
  duration: yup
    .number()
    .typeError('#')
    .required()
    .min(1, '1')
    .max(999, '999'),
});

const validationSchemaDetails = yup.object().shape({
  task: yup
    .array()
    .required('Task is required')
    .of(
      yup.object().shape({
        name: yup.string().required('Task is required'),
      })
    ),
  startDate: yup
    .date()
    .typeError('Date required')
    .required('Date required'),
  finishDate: yup
    .date()
    .typeError('Date required')
    .required('Date required'),
  duration: yup
    .number()
    .typeError('#')
    .required()
    .min(1, '1')
    .max(999, '999'),
  assignee: yup.array().required('Assign this task to an account'),
});

const emptyState = {
  task: [],
  stage: [],
  duration: null,
  addAnother: false,
};

const emptyStateDetails = {
  task: [],
  assignee: [],
  super: [],
  scheduler: [],
  stage: [],
  duration: null,
  addAnother: false,
  startDate: '',
  endDate: '',
};

export function AddTaskModal({ stage, stages, isReleased, job, addTask, close, jobContext }) {
  const { appState } = useAppContext();
  const tasks = appState.get('gtl');
  const [subTasksNumber, setSubTasksNumber] = React.useState(false);

  const handleShowMessage = task => {
    setSubTasksNumber(task?.children?.length || false);
    return task ? [task] : [];
  };
  const calculateImpact = ({ newFinishDate }) => calculateDiffDays(job.get('expectedFinishDate'), newFinishDate);

  if (isReleased) {
    const dateUtils = new DateUtil(job.get('settings').toJS());
    return (
      <TaskDetailsAddForm
        dateUtils={dateUtils}
        calculateImpact={calculateImpact}
        loggedUserId={appState.getIn(['user', '_id'])}
        jobContext={jobContext}
        onChangeTask={handleShowMessage}
        subTasksNumber={subTasksNumber}
        setSubTasksNumber={setSubTasksNumber}
        addTask={addTask}
        tasks={tasks}
        stage={stage}
        stages={stages}
        close={close}
      />
    );
  } else
    return (
      <TaskAddForm
        tasks={tasks}
        addTask={addTask}
        onChangeTask={handleShowMessage}
        subTasksNumber={subTasksNumber}
        setSubTasksNumber={setSubTasksNumber}
        stage={stage}
        stages={stages}
        close={close}
      />
    );
}

const TaskAddForm = ({ stage, stages, tasks, addTask, close, onChangeTask, subTasksNumber, setSubTasksNumber }) => {
  const { register, handleSubmit, watch, errors, control, reset } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: { ...emptyState, stage },
  });
  const duration = watch('duration', 0);

  const onSubmit = async form => {
    const { addAnother } = form;
    const defaultStage = stage ? stage : [];
    try {
      await addTask(form);

      if (!addAnother) return close();
      setSubTasksNumber(false);
      reset({ ...emptyState, addAnother, stage: defaultStage });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal show={true} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>Add Task</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <Form.Group controlId="formName">
            <FormController
              name="task"
              ref={register}
              control={control}
              render={({ onChange, onBlur, value }) => (
                <Typeahead
                  id="new-task-name"
                  placeholder="Choose from global library"
                  labelKey={option => option.name}
                  filterSelected={(option, selected) => selected._id !== option._id}
                  isInvalid={!!errors.task}
                  options={tasks.toJS()}
                  onChange={([task]) => onChange(onChangeTask(task))}
                  onBlur={onBlur}
                  selected={value}
                  icon="magnifying-glass"
                />
              )}
            />
            <Form.Control.Feedback type="invalid" className={cn({ 'd-inline': !!errors.task })}>
              <FontAwesomeIcon icon="circle-exclamation" />
              {` `}
              {errors.task && ` ${errors.task.message}`}
            </Form.Control.Feedback>
            {subTasksNumber && (
              <span className="font-size-14 text-muted">{`*This Task contains ${subTasksNumber} ${plural(
                subTasksNumber,
                'Sub-Task',
                'Sub-Tasks'
              )}`}</span>
            )}
          </Form.Group>
          <Form.Group controlId="formDuration">
            <Form.Label>Duration</Form.Label>
            <div className="d-flex flex-row align-items-center">
              <DurationInput duration={duration} register={register} errors={errors} />
            </div>
            <Form.Control.Feedback type="invalid" className={cn({ 'd-inline': !!errors.duration })}>
              <FontAwesomeIcon icon="circle-exclamation" />
              {` `}
              {errors.duration && errors.duration.type === 'min' && <FontAwesomeIcon icon={faArrowUp} />}
              {errors.duration && errors.duration.type === 'max' && <FontAwesomeIcon icon={faArrowDown} />}
              {errors.duration && ` ${errors.duration.message}`}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formStage" className={cn({ 'd-none': !!stage || stages.size === 0 })}>
            <Form.Label>Stage</Form.Label>
            <FormController
              name="stage"
              ref={register}
              control={control}
              render={({ onChange, onBlur, value }) => (
                <Typeahead
                  id="stage-name"
                  placeholder="Choose Stage"
                  labelKey={option => option.name}
                  filterSelected={(option, selected) => selected._id !== option._id}
                  isInvalid={!!errors.stage}
                  options={stages.toJS()}
                  onChange={onChange}
                  onBlur={onBlur}
                  selected={value}
                  icon="magnifying-glass"
                />
              )}
            />
            <span className="text-gray-400 font-size-14">* Optional</span>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="d-flex flex-row justify-content-between">
          <Form.Check type="checkbox" id="add-another" name="addAnother" ref={register} label="Add Another Task" />
          <PrimaryButton type="submit">Add Task</PrimaryButton>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

const TaskDetailsAddForm = ({
  addTask,
  close,
  dateUtils,
  jobContext,
  loggedUserId,
  calculateImpact,
  onChangeTask,
  setSubTasksNumber,
  subTasksNumber,
  tasks,
  stage,
  stages,
}) => {
  const { account, companies, hasPermission } = jobContext;
  const [impactDays, setImpactDays] = React.useState(0);
  const hasImpact = impactDays > 0;
  const users = account.get('users').toArray();
  const { register, handleSubmit, setValue, errors, getValues, watch, control, reset } = useForm({
    resolver: yupResolver(validationSchemaDetails),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: { ...emptyStateDetails, stage },
  });
  const duration = watch('duration', 0);
  const tasksOptions = tasks.toJS();
  const handleChangeStartDate = selected => {
    if (!moment(selected).isValid()) return selected;
    const { duration } = getValues();
    const { newFinishDate, newDuration } = setStartDateForNewTask({
      startDate: selected,
      duration,
      dateUtils,
    });
    const diffDays = calculateImpact({ newFinishDate });
    if (!moment(newFinishDate).isValid()) return selected;
    setImpactDays(diffDays);
    setValue('finishDate', newFinishDate);
    setValue('duration', newDuration);
    return selected;
  };
  const handleChangeFinishDate = selected => {
    if (!moment(selected).isValid()) return selected;
    const { duration, startDate } = getValues();
    const { newStartDate, newDuration } = setFinishDateForNewTask({
      startDate,
      finishDate: selected,
      duration,
      dateUtils,
    });
    const diffDays = calculateImpact({ newFinishDate: selected });
    if (!moment(newStartDate).isValid()) return selected;
    setImpactDays(diffDays);
    setValue('startDate', newStartDate);
    setValue('duration', newDuration);
    return selected;
  };
  const handleChangeDuration = duration => {
    const { startDate } = getValues();
    if (!startDate) return;
    const newFinishDate = setDurationForNewTask({ startDate, duration, dateUtils });
    const diffDays = calculateImpact({ newFinishDate });
    setImpactDays(diffDays);
    if (duration) setValue('finishDate', newFinishDate);
  };

  const onSubmit = async form => {
    const { addAnother } = form;
    const defaultStage = stage ? stage : [];
    try {
      await addTask(form);

      if (!addAnother) return close();
      setSubTasksNumber(false);
      setImpactDays(0);
      reset({ ...emptyStateDetails, addAnother, stage: defaultStage });
    } catch (err) {
      console.log(err);
    }
  };

  const canAssignUsers = hasPermission('task_user_assignment');

  return (
    <Modal show={true} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>Add Task</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <Form.Group controlId="formName">
            <FormController
              name="task"
              ref={register}
              control={control}
              render={({ onChange, onBlur, value }) => (
                <Typeahead
                  id="new-task-name"
                  placeholder="Choose from global library"
                  labelKey={option => option.name}
                  filterSelected={(option, selected) => selected._id !== option._id}
                  options={tasksOptions}
                  isInvalid={!!errors.task}
                  onChange={([task]) => onChange(onChangeTask(task))}
                  onBlur={onBlur}
                  selected={value}
                  icon="magnifying-glass"
                />
              )}
            />
            <Form.Control.Feedback type="invalid" className={cn({ 'd-inline': !!errors.task })}>
              <FontAwesomeIcon icon="circle-exclamation" />
              {errors.task && ` ${errors.task.message}`}
            </Form.Control.Feedback>
            {subTasksNumber && (
              <span className="font-size-14 text-muted">{`*This Task contains ${subTasksNumber} ${plural(
                subTasksNumber,
                'Sub-Task',
                'Sub-Tasks'
              )}`}</span>
            )}
          </Form.Group>
          <Form.Row>
            <Form.Group as={Col} md="6" controlId="formStartDate" className="d-flex flex-column pr-3 pl-1">
              <Form.Label>Expected Start</Form.Label>
              <FormController
                render={({ onChange, onBlur, value }) => (
                  <Datepicker
                    onBlur={onBlur}
                    onChange={date => onChange(handleChangeStartDate(date))}
                    name="startDate"
                    selected={value}
                    placeholderText="MM/DD/YY"
                    isInvalid={errors.startDate}
                  />
                )}
                control={control}
                name="startDate"
              />
              <Form.Control.Feedback type="invalid" className={cn({ 'd-inline': !!errors.startDate })}>
                <FontAwesomeIcon icon="circle-exclamation" />
                {errors.startDate && ` ${errors.startDate.message}`}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="formEndDate" className="d-flex flex-column pl-3">
              <Form.Label>Expected Finish</Form.Label>
              <FormController
                render={({ onChange, onBlur, value }) => (
                  <Datepicker
                    onBlur={onBlur}
                    onChange={date => onChange(handleChangeFinishDate(date))}
                    name="finishDate"
                    selected={value}
                    placeholderText="MM/DD/YY"
                    isInvalid={errors.startDate}
                  />
                )}
                control={control}
                name="finishDate"
              />
              <Form.Control.Feedback type="invalid" className={cn({ 'd-inline': !!errors.finishDate })}>
                <FontAwesomeIcon icon="circle-exclamation" />
                {errors.finishDate && ` ${errors.finishDate.message}`}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Group controlId="formDuration">
            <Form.Label>Duration</Form.Label>
            <div className="d-flex flex-row align-items-center">
              <DurationInput
                duration={duration}
                register={register}
                errors={errors}
                handleChangeDuration={handleChangeDuration}
              />
            </div>
            <Form.Control.Feedback type="invalid" className={cn({ 'd-inline': !!errors.duration })}>
              <FontAwesomeIcon icon="circle-exclamation" />
              {` `}
              {errors.duration && errors.duration.type === 'min' && <FontAwesomeIcon icon={faArrowUp} />}
              {errors.duration && errors.duration.type === 'max' && <FontAwesomeIcon icon={faArrowDown} />}
              {errors.duration && ` ${errors.duration.message}`}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} className="pl-0 pr-0">
            <small>Account Assignee</small>
            <FormController
              name="assignee"
              ref={register}
              control={control}
              render={({ onChange, onBlur, value }) => (
                <Typeahead
                  id="new-task-assignee"
                  placeholder="Choose Assignee Account"
                  options={companies}
                  labelKey={option => option.get('name')}
                  filterSelected={(option, selected) => selected.get('subAccountId') !== option.get('subAccountId')}
                  isInvalid={!!errors.assignee}
                  onChange={onChange}
                  onBlur={onBlur}
                  selected={value}
                  isMyId={option => account.get('_id') === option.get('subAccountId')}
                />
              )}
            />
            <Form.Control.Feedback type="invalid" className={cn({ 'd-inline': !!errors.assignee })}>
              <FontAwesomeIcon icon="circle-exclamation" />
              {errors.assignee && ` ${errors.assignee.message}`}
            </Form.Control.Feedback>
          </Form.Group>
          {canAssignUsers && (
            <>
              <Form.Group as={Col} className="pl-0 pr-0">
                <small>Builder Super</small>
                <FormController
                  name="super"
                  ref={register}
                  control={control}
                  render={({ onChange, onBlur, value }) => (
                    <Typeahead
                      id="new-task-super"
                      placeholder="Choose Super"
                      options={users}
                      labelKey={option => `${option.get('firstName')} ${option.get('lastName')}`}
                      filterSelected={(option, selected) => selected.get('subAccountId') !== option.get('subAccountId')}
                      isInvalid={!!errors.super}
                      onChange={onChange}
                      onBlur={onBlur}
                      selected={value}
                      isMyId={option => loggedUserId === option.get('_id')}
                    />
                  )}
                />
                <span className="text-gray-400 font-size-14">* Optional</span>
              </Form.Group>
              <Form.Group as={Col} className="pl-0 pr-0">
                <small>Builder Scheduler</small>
                <FormController
                  name="scheduler"
                  control={control}
                  render={({ onChange, onBlur, value }) => (
                    <Typeahead
                      id="new-task-scheduler"
                      placeholder="Choose Scheduler"
                      options={users}
                      labelKey={option => `${option.get('firstName')} ${option.get('lastName')}`}
                      filterSelected={(option, selected) => selected.get('subAccountId') !== option.get('subAccountId')}
                      isInvalid={!!errors.scheduler}
                      onChange={onChange}
                      onBlur={onBlur}
                      selected={value}
                      isMyId={option => loggedUserId === option.get('_id')}
                    />
                  )}
                />
                <span className="text-gray-400 font-size-14">* Optional</span>
              </Form.Group>
            </>
          )}
          <Form.Group controlId="formStage" className={cn({ 'd-none': !!stage || stages.size === 0 })}>
            <Form.Label>Stage</Form.Label>
            <FormController
              name="stage"
              ref={register}
              control={control}
              render={({ onChange, onBlur, value }) => (
                <Typeahead
                  id="stage-name"
                  placeholder="Choose Stage"
                  labelKey={option => option.name}
                  filterSelected={(option, selected) => selected._id !== option._id}
                  isInvalid={!!errors.stage}
                  options={stages.toJS()}
                  onChange={onChange}
                  onBlur={onBlur}
                  selected={value}
                  icon="magnifying-glass"
                />
              )}
            />
            <span className="text-gray-400 font-size-14">* Optional</span>
          </Form.Group>
          <small>This task will be released to construction when added, since the job has already been released.</small>
          <div className={cn('mt-3 font-size-14', { 'd-none': !hasImpact, 'd-block': hasImpact })}>
            <div className="d-flex align-items-center">
              <FontAwesomeIcon icon="circle-exclamation" className="text-danger mr-1" />
              <span className="font-weight-bold">Schedule Impact</span>
            </div>
            <div
              className={cn('d-flex flex-row justify-content-between my-3 bg-pearl p-4 rounded-6', {
                'text-danger': impactDays > 0,
                'text-success': impactDays < 0,
              })}
            >
              <span className="text-nowrap font-weight-bold">Increase Cycle Time</span>
              <span className="text-nowrap">{plural.day(Math.abs(impactDays))}</span>
            </div>
            <div className="text-muted mb-3">
              By confirming these dates will delay the entire Job finish date. Please double-check before confirming.
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex flex-row justify-content-between">
          <Form.Check type="checkbox" id="add-another" name="addAnother" ref={register} label="Add Another Task" />
          <PrimaryButton type="submit">{`${hasImpact ? 'Confirm and Add Task' : 'Add Task'}`}</PrimaryButton>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

function setStartDateForNewTask({ startDate, duration, dateUtils }) {
  const newStartDate = moment.utc(startDate).format();
  const newDuration = duration || 1;
  const newFinishDate = dateUtils.addDays(newStartDate, newDuration);
  return {
    newStartDate,
    newFinishDate,
    newDuration,
  };
}

function setFinishDateForNewTask({ startDate, finishDate, duration, dateUtils }) {
  const newFinishDate = moment.utc(finishDate).toDate();
  let newDuration = null;
  let newStartDate = startDate;
  if (!startDate || moment.utc(finishDate).isBefore(startDate)) {
    newDuration = duration || 1;
    newStartDate = dateUtils.subtractDays(newFinishDate, newDuration);
  } else {
    newDuration = dateUtils.calculateDuration(startDate, newFinishDate);
  }
  return {
    newStartDate,
    newFinishDate,
    newDuration,
  };
}

function setDurationForNewTask({ startDate, duration, dateUtils }) {
  return dateUtils.addDays(startDate, duration - 1);
}
