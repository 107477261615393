import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { colors } from '@tradetrax/web-common';
import { MilestoneCard } from './MilestoneCard';
import { EmptyState, EmptyStateCenterContainer } from '@tradetrax/web-common/lib/EmptyState';
import styled from 'styled-components';

export const MilestonesCards = ({ controller, state }) => {
  const milestones = state.get('milestones');
  const tasksTemplates = state.get('templateTasks');
  const onDragEnd = result => {
    if (!result.destination || result.destination.index === result.source.index) {
      return;
    }
    controller.reorder(result.draggableId, result.source.index, result.destination.index);
  };

  if (milestones.size < 1)
    return (
      <EmptyStateCenterContainer top="-50px">
        <EmptyState
          icon="person-shelter"
          title="No Job Progress Milestones Added"
          body="When a Job Progress Mileston is added, it will appear here."
        />
      </EmptyStateCenterContainer>
    );

  return (
    <Container className="mt-4">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
              {milestones.map((milestone, index) => (
                <Draggable
                  key={milestone.get('_id')}
                  draggableId={milestone.get('_id')}
                  index={index}
                  isDragDisabled={milestones.size === 1}
                >
                  {provided => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(provided.draggableProps.style)}
                      onMouseDown={e => e.currentTarget.focus()}
                      className="draggableCard"
                    >
                      <MilestoneCard
                        key={milestone.get('_id')}
                        isSingleMilestone={milestones.size === 1}
                        milestone={milestone}
                        index={index}
                        tasksTemplates={tasksTemplates}
                        controller={controller}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div id="scrollNewMilestoneRef"></div>
    </Container>
  );
};

const Container = styled.div`
  .draggableCard:focus {
    outline-style: none;
  }
`;

const getItemStyle = draggableStyle => ({
  userSelect: 'none',
  padding: 0,
  margin: 0,
  ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? colors.pearl : '',
  marginRight: '20px',
});
