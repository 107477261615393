import { useEffect } from 'react';
import { fromJS, Map } from 'immutable';
import { useController } from '@tradetrax/web-common';
import * as userProfileCommonActions from '@tradetrax/web-common/lib/UserProfile/UserProfileCommonActions';
import { useAppContext } from 'app/App.context';
import { buildersService as userProfileService } from 'services';

export const emptyUser = Map({ isEmpty: true });
export const emptyState = fromJS({ user: emptyUser, hasPermission: true, tab: 'profile' });

export const UserProfileContext = userId => {
  const appContext = useAppContext();
  const [state, controller] = useController(userProfileCommonActions, emptyState, {
    ...appContext,
    userProfileService,
  });
  const { alert, appState } = appContext;
  const loggedInUserRole = appState.getIn(['user', 'role']);

  useEffect(() => {
    controller.getUser(userId);
  }, [controller, userId]);

  return { state, controller, alert, loggedInUserRole };
};
