import React from 'react';

const defaultState = {
  checked: null, // null|builder|task
  rcTaskId: undefined,
  rcTaskIdBuilder: undefined,
};

const iif = (a, fn) => {
  return typeof a !== 'undefined' ? a : fn();
};

export const UpdateRequestState = ({ task, jobDelay, isDueWeather }) => {
  isDueWeather = iif(isDueWeather, () => task.getIn(['changeRequest', 'reasons'])?.includes('weather'));
  jobDelay = iif(jobDelay, () => task.getIn(['changeRequest', 'jobDelay']));
  const [state, setState] = React.useState({ isDueWeather, ...defaultState });

  const setValue = (name, value) => {
    const update = val => {
      setState(state => ({
        ...state,
        [name]: val,
      }));
    };
    if (typeof value !== 'undefined') return update(value);

    return update;
  };

  const payload = { task, isDueWeather: state.isDueWeather };
  let hasErrors = false;

  if (state.checked !== null) {
    payload.builderRootCause = state.checked === 'builder';

    if (!payload.builderRootCause) {
      // selected `task` option.
      payload.taskIdRootCause = state.rcTaskId;
      hasErrors = state.rcTaskId === undefined;
    } else if (state.rcTaskIdBuilder) {
      // selected `builder` option.
      payload.taskIdRootCause = state.rcTaskIdBuilder;
    }
  }

  return {
    setValue,
    payload,
    jobDelay,
    task,
    hasErrors,
    ...state,
  };
};
