import React from 'react';
import moment from 'moment';
import { usersService } from '../../../web-users/src/services';
import { fromJS } from 'immutable';
import { ResendInviteModal } from './ResendInviteModal';
import { SendPasswordReset } from './SendPasswordResetModal';
import { markAsSideEffect, markAsSync } from '../index';
import { ResetPassword } from '../Modal/ResetPasswordModal';
import { DeactivateUserModal } from '../Modal/DeactivateUserModal';
import { getUserDomain } from '../utils';
export function getUser(userId) {
  return this.userProfileService
    .getUser({}, { params: { userId } })
    .then(user => state => state.set('user', fromJS(user)))
    .catch(error => {
      let hasPermission = true;
      if (error.httpCode === 404) hasPermission = false;
      return state => state.set('hasPermission', hasPermission);
    });
}

markAsSideEffect(sendPasswordReset);
export async function sendPasswordReset(user) {
  const { isCancel, ...rest } = await this.modal.open(SendPasswordReset, { user });

  const method = this.userProfileService[rest.option];

  if (isCancel || !method) return;

  const userId = user.get('_id');
  const { viaEmail, viaSMS } = rest;
  const params = { userId };
  const form = {};
  const query = {};

  if (viaEmail) {
    form.email = user.get('email');
    query.email = true;
  }
  if (viaSMS) {
    form.phone = user.get('phone');
    query.sms = true;
  }

  const message =
    rest.option === 'magicLinkUser' ? 'Magic link successfully sent.' : 'Link to reset password successfully sent.';

  return method(form, { params, query })
    .then(() => {
      this.alert.success({ message });
    })
    .catch(err => {
      this.alert.error({ message: 'There was a problem with this request. Please try again.' });
      throw err;
    });
}

markAsSideEffect(resendInvite);
export async function resendInvite(user, path = ['user']) {
  const { isCancel, ...rest } = await this.modal.open(ResendInviteModal, { user });

  if (isCancel) return;

  const userId = user.get('_id');
  const { viaEmail, viaSMS } = rest;
  const params = { userId };
  const form = {};
  const query = {};

  if (viaEmail) {
    form.email = user.get('email');
    query.email = true;
  }
  if (viaSMS) {
    form.phone = user.get('phone');
    query.sms = true;
  }

  this.controller.dispatch([state => state.updateIn(path, user => user.set('sendingInvite', true))]);

  return this.userProfileService
    .sendInviteUser(form, { params, query })
    .then(() => {
      this.alert.success({
        message: 'Invite successfully sent. User will receive an invite to join TradeTrax.',
      });
      const lastActivity = moment().toISOString();
      this.controller.dispatch([
        state => state.updateIn(path, user => user.merge({ lastActivity, sendingInvite: false })),
      ]);
    })
    .catch(err => {
      this.controller.dispatch([state => state.updateIn(path, user => user.set('sendingInvite', false))]);
      this.alert.error({ message: 'There was a problem sending this invite. Please try again.' });
    });
}

export function updateUser(user, properties) {
  const userId = user.get('_id');
  return this.userProfileService
    .updateUser(properties, { params: { userId } })
    .then(() => state => state.update('user', user => user.merge(properties)));
}

markAsSideEffect(resetPassword);
export function resetPassword(user, isSelfReset = false) {
  const sendResetPassword = (user, password, currentPassword) => {
    const updateUserPromise = currentPassword
      ? this.controller.updateSelf({ password, currentPassword })
      : this.controller.updateUser(user, { password });
    return updateUserPromise
      .then(() => this.alert.success(getResetPasswordSuccessMessage(user, password, isSelfReset, true)))
      .catch(err => {
        if (err.type === 'password-same-as-previous' || err.type === 'reset-password-invalid-credentials') {
          throw err;
        } else {
          this.alert.error({ message: 'There was a problem resetting this password. Please try again.' });
        }
      });
  };
  this.modal.open(ResetPassword, { user, isSelfReset, sendResetPassword, resetPasswordByEmail, alert: this.alert });
}

markAsSideEffect(resetPasswordByEmail);
export function resetPasswordByEmail(user, alert) {
  return usersService
    .resetPassword({ email: user.get('email') }, { query: { email: true } })
    .then(() => alert.success({ message: 'Link to reset password successfully sent to email.' }))
    .catch(() => alert.error({ message: 'There was a problem with this request. Please try again.' }));
}

function getResetPasswordSuccessMessage(user, password, isSelfReset, isReset = false) {
  const { protocol, host } = window.location;
  const loginPage = `${protocol}//${host.replace('sub.', 'my.')}/login`;
  const createdOrReset = isReset
    ? 'Password for this user has been successfully reset'
    : 'This user has been successfully created';

  if (isSelfReset) {
    return { message: 'Your password was successfully updated.' };
  }

  return {
    autoClose: false,
    size: 'xl',
    message: (
      <>
        <span className="mb-1">{`${createdOrReset}. Please write down these credentials to share them with user.`}</span>
        <br />
        Login:
        <a href={loginPage} className="mx-1 trx-link">
          {loginPage}
        </a>
        <br />
        Username:<strong className="mx-1">{user.get('username')}</strong>
        <br />
        Pass:<strong className="mx-1">{password}</strong>
      </>
    ),
  };
}

markAsSync(setTab);
export function setTab(state, tab) {
  return state.set('tab', tab);
}

markAsSideEffect(toggleActive);
export async function toggleActive(user) {
  const status = user.get('status');
  const { isTrade } = getUserDomain(user);
  if (status === 'inactive') {
    const status = user.get('statusBeforeInactive') || 'active';
    return this.controller
      .updateUser(user, { status })
      .then(() => {
        if (isTrade) this.appController.updateUserPermission(user, 'active');
        this.alert.success({ message: 'User successfully reactivated.' });
      })
      .catch(() => {
        this.alert.error({ message: `There was a problem reactivating this user. Please try again later.` });
      });
  } else {
    const statusBeforeInactive = user.get('status');
    const { isAccept } = await this.modal.open(DeactivateUserModal);
    if (isAccept) {
      this.controller
        .updateUser(user, { status: 'inactive' }, { statusBeforeInactive, deactivatedAt: new Date() })
        .then(() => {
          if (isTrade) this.appController.updateUserPermission(user, 'inactive');
          this.alert.success({ message: 'The user was successfully deactivated' });
        })
        .catch(() => {
          this.alert.error({ message: `There was a problem deactivating this user. Please try again later.` });
        });
    }
  }
}

export function updateUserPermissions(user, permission, value) {
  const userId = user.get('_id');

  return this.userProfileService
    .updateUserPermissions({ [permission]: value }, { params: { userId } })
    .then(() => state =>
      state.updateIn(['user', 'permissions'], permissions => (permissions || Map()).set(permission, value))
    )
    .catch(err => {
      this.alert.error({ message: 'There was a problem updating the permission. Please try again.' });
    });
}
