import React from 'react';
import { fromJS } from 'immutable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { markAsSideEffect, markAsSync, CustomDialog } from '@tradetrax/web-common';
import { ResendInviteModal } from '../ResendInviteModal';
import { buildersService } from 'services';

markAsSync(toggleTab);
export function toggleTab(state) {
  const tab = state.get('tab') === 'members' ? 'builders-jobs' : 'members';
  return state.set('tab', tab);
}

markAsSync(updateBuilderAssignee);
export function updateBuilderAssignee(state, [assignee]) {
  const { builderAssignee, _id: userId } = state.get('homeownerDetails').toObject();
  const assigneeId = assignee ? assignee._id : null;
  buildersService.updateHomeowner({ builderAssigneeId: assigneeId }, { params: { userId } }).catch(() => {
    this.alert.error({ message: 'There was a problem assigning a Builder to this Homeowner. Please try again.' });
    this.controller.dispatch([state => state.setIn(['homeownerDetails', 'builderAssignee'], builderAssignee)]);
  });

  const updated = assignee ? fromJS(assignee) : null;
  return state.setIn(['homeownerDetails', 'builderAssignee'], updated);
}

markAsSync(updateCustomerManager);
export function updateCustomerManager(state, [manager]) {
  const { customerManager, _id: userId } = state.get('homeownerDetails').toObject();
  const managerId = manager ? manager._id : null;
  buildersService.updateHomeowner({ customerManagerId: managerId }, { params: { userId } }).catch(() => {
    this.alert.error({
      message: 'There was a problem assigning a Customer Manager to this Homeowner. Please try again.',
    });
    this.controller.dispatch([state => state.setIn(['homeownerDetails', 'customerManager'], customerManager)]);
  });

  const updated = manager ? fromJS(manager) : null;
  return state.setIn(['homeownerDetails', 'customerManager'], updated);
}

markAsSideEffect(sendInvite);
export async function sendInvite() {
  const homeowner = this.state.get('homeownerDetails');
  const { status, _id: userId } = homeowner.toObject();

  if (status === 'invitation-sent') {
    const { isAccept } = await this.modal.open(ResendInviteModal, { homeowner });
    if (!isAccept) return;
  }

  this.controller.dispatch([state => state.setIn(['homeownerDetails', 'status'], 'sending-invite')]);

  buildersService
    .sendInviteHomeowner({}, { params: { userId }, query: { email: true } })
    .then(() => {
      this.alert.success({
        message:
          'Invite successfully sent. The Homeowner will receive an invite to join the TradeTrax Homeowner Portal.',
      });
      this.controller.dispatch([
        state =>
          state.update('homeownerDetails', homeownerDetails => {
            return homeownerDetails.set('status', 'invitation-sent').set('lastActivity', new Date());
          }),
      ]);
    })
    .catch(error => {
      this.alert.error({
        message: 'There was a problem sending this invite to the Homeowner. Please try again.',
      });
      this.controller.dispatch([state => state.set('homeownerDetails', homeowner)]);
    });
}

markAsSideEffect(toggleUserActive);
export async function toggleUserActive() {
  const prevHomeownerDetails = this.state.get('homeownerDetails');
  const { status, _id: userId } = prevHomeownerDetails.toObject();
  const isInactive = status === 'deactivated';
  if (!isInactive) {
    const { isAccept } = await this.modal.open(CustomDialog, {
      title: (
        <>
          <FontAwesomeIcon icon="ban" className="text-danger" />
          Deactivate Homeowner
        </>
      ),
      message:
        'This will immediately revoke the member’s access to the Homeowner portal. You can reactivate the member later.',
      titleAccept: 'Yes, deactivate Homeowner',
      titleCancel: 'Cancel',
    });
    if (!isAccept) return;
  }

  buildersService
    .updateHomeowner({ active: isInactive }, { params: { userId } })
    .then(fromJS)
    .then(response => {
      this.controller.dispatch([state => state.set('homeownerDetails', response)]);
      this.alert.success({
        message: `Homeowner successfully ${isInactive ? 're' : 'de'}activated ${
          !isInactive ? 'and revoked access to Tradetrax. You can reactivate Homeowner later.' : ''
        }`,
      });
    })
    .catch(() => {
      this.alert.error({
        message: `There was a problem ${isInactive ? 're' : 'de'}activating this Homeowner. Please try again. `,
      });
      this.controller.dispatch([state => state.set('homeownerDetails', prevHomeownerDetails)]);
    });
}

markAsSync(updatePhoneNumber);
export function updatePhoneNumber(state, phone) {
  const userId = state.getIn(['homeownerDetails', '_id']);
  buildersService.updateHomeowner({ phone }, { params: { userId } }).catch(error => {
    this.alert.error({
      message: 'There was a problem updating this user. Please try again.',
    });
    this.controller.dispatch([state => state.setIn(['homeownerDetails', 'phone'], null)]);
  });
  return state.setIn(['homeownerDetails', 'phone'], phone);
}
