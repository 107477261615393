import React from 'react';
import styled from 'styled-components';
import { Item, NoLinksItem, TaggedNoteItem } from './index';
import { colors } from '../../../theme';
import { CommentWithTags } from './../../../Notes/CommentWithTags';

const Thumbnail = styled.img`
  height: 80px;
  width: 80px;
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
  border: 1px solid ${colors.gray100};
  margin-left: 3rem;
  margin-bottom: 1rem;
  cursor: pointer;
`;

const buildAttachmentTask = activity => {
  const { sourceJob, sourceTask, attachmentInfo } = activity.toObject();
  const task = sourceTask.set('job', sourceJob.set('id', sourceJob.get('_id')));
  return {
    task,
    attachmentInfo,
  };
};

export const AttachmentAdded = ({ activity, showCarousel, ...props }) => {
  const extra = (
    <Thumbnail
      onClick={() => showCarousel(buildAttachmentTask(activity))}
      src={activity.getIn(['attachmentInfo', 'thumbnailUrl'])}
    />
  );
  return <Item activity={activity} extra={extra} {...props}>{` uploaded an Attachment to `}</Item>;
};

export const NoteAdded = ({ activity, ...props }) => {
  const { user } = props;
  const text = activity.getIn(['noteInfo', 'note']);
  const htmlText = activity.getIn(['noteInfo', 'htmlText']);
  const taggedParties = activity.getIn(['noteInfo', 'taggedParties'])?.toJS();

  const extra = (
    <CommentWithTags
      className="show-from-dashboard"
      text={htmlText || text}
      user={user}
      taggedParties={taggedParties}
    />
  );
  return <TaggedNoteItem activity={activity} extra={extra} {...props}>{` added a Note to `}</TaggedNoteItem>;
};

export const TaskAssigned = props => {
  return <Item {...props}>{` assigned you to `}</Item>;
};

export const URByInstaller = ({ activity, ...props }) => {
  const updateRequest = activity.get('changeRequestFromInstallerInfo');
  const isStartDate = !!updateRequest.get('newStartDate');
  const text = isStartDate ? 'start' : 'finish';
  return (
    <Item activity={activity} {...props}>
      {` requested an update to the ${text} date for `}
    </Item>
  );
};

export const URByTrade = ({ activity, ...props }) => {
  const updateRequest = activity.get('changeRequestFromSubInfo');
  const isStartDate = !!updateRequest.get('newStartDate');
  const text = isStartDate ? 'start' : 'finish';
  return (
    <Item activity={activity} {...props}>
      {` requested an update to the ${text} date for `}
    </Item>
  );
};

export const TaggedInNote = ({ activity, ...props }) => {
  const { user } = props;
  const text = activity.getIn(['noteInfo', 'note']);
  const htmlText = activity.getIn(['noteInfo', 'htmlText']);
  const taggedParties = activity.getIn(['noteInfo', 'taggedParties'])?.toJS();
  const taggedByAccount = activity.getIn(['noteInfo', 'taggedByAccount']);
  const name = user.get('accountName');
  const accountName = `${name.substring(0, 60)}${name.length > 60 ? '...' : ''}`;

  const extra = (
    <CommentWithTags
      className="show-from-dashboard"
      text={htmlText || text}
      user={user}
      taggedParties={taggedParties}
    />
  );

  const msg = taggedByAccount ? `tagged ${accountName} in a Note on ` : ` tagged you in a Note on `;

  return (
    <TaggedNoteItem activity={activity} extra={extra} {...props}>
      {msg}
    </TaggedNoteItem>
  );
};

export const SharedFilterSet = ({ activity, ...props }) => {
  const isSharedWithRole = !!activity.getIn(['filterSharedWithUserInfo', 'sharedBy', 'roleId']);
  const isJobFilterSet = activity.getIn(['filterSharedWithUserInfo', 'type']) === 'jobSchedule';
  return (
    <NoLinksItem activity={activity} {...props}>
      {` shared with you ${isSharedWithRole ? 'via Custom Role ' : ''}the `}
      <span className="text-dark d-inline-block text-truncate">
        {activity.getIn(['filterSharedWithUserInfo', 'name'])}
      </span>
      {` Filter Set for ${isJobFilterSet ? 'jobs' : 'task lists'}`}
    </NoLinksItem>
  );
};
