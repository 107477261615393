import React from 'react';
import moment from 'moment';
import cn from 'classnames';
import { Row, Col, Container } from 'react-bootstrap';
import { useCommunities, useTaskTypes } from '@tradetrax/web-common';
import { useAppContext } from 'app/App.context';
import { buildersService } from 'services';
import { UpcomingContext } from './UpcomingContext';
import { CALENDAR_VIEW } from '@tradetrax/web-common/lib/CalendarView/CalendarContext';
import { UpcomingCard } from './UpcomingCard';
import { LoadingCards } from './LoadingCards';
import { EmptyState, EmptyStateCenterContainer } from '@tradetrax/web-common/lib/EmptyState';
import { FilterSets } from '@tradetrax/web-common/lib/Filters/FilterSets';
import { BulkHeader, BulkUpcomingContext } from '@tradetrax/web-common/lib/BulkActions';
import { getUpcomingActions } from '@tradetrax/web-common/lib/BulkActions/common';
import { IconButton } from '@tradetrax/web-common/lib/Button';
import { CalendarView } from '@tradetrax/web-common/lib/CalendarView/index';
import navigate from 'app/navigate';
import { FilterImplementation } from '@tradetrax/web-common/lib/Filters/Implementation/Filter.implementation';

export const UpcomingPage = () => {
  const appContext = useAppContext();
  const communities = useCommunities(buildersService);
  const taskTypes = useTaskTypes(buildersService);
  const { assignees } = appContext.appState.toObject();
  const canEditDatesAndPredecessors = appContext.hasPermission('job_update_task_dependencies_and_dates');
  const upcomingContext = UpcomingContext(appContext);
  const { controller, state, containerRef, filterContext } = upcomingContext;
  const { tasks, groups, isLoading, totalCount, isManageTasks, view } = state.toObject();
  const { filterState } = filterContext;
  const filter = filterState.get('values');
  const { isFiltering } = filterContext;
  const title = isFiltering ? 'No Matches Found' : 'No Upcoming Tasks';
  const body = isFiltering
    ? "When there's a Task that meets the filter, it will appear here."
    : 'When a future Task is assigned to you, it will appear here.';
  const icon = isFiltering ? 'wrench' : 'circle-check';
  const lookups = { communities, taskTypes, assignees };

  const bulkContext = BulkUpcomingContext(buildersService, upcomingContext);
  const bulkActions = getUpcomingActions(bulkContext);
  const tasksSize = tasks.size;
  const showCalendar = view === CALENDAR_VIEW;
  const hideManageBtn = showCalendar || !tasksSize || !canEditDatesAndPredecessors;

  return (
    <>
      <div className="d-flex flex-column mt-4 mx-4">
        <span className="h4 mb-2 font-weight-bold d-block">Upcoming</span>
        <span className="d-block font-size-14 mb-4">Move to In Progress or Update Start Date.</span>
        <FilterSets.Panel filterContext={filterContext} visible disabled={isManageTasks} isUpcomingView>
          <div className="d-flex flex-row ">
            <span
              className={cn('btn btn-link mr-4 text-nowrap', {
                'd-none': hideManageBtn,
              })}
              onClick={controller.toggleManageTasks}
            >{`${isManageTasks ? 'Close ' : ''}Manage Tasks`}</span>
            <IconButton
              disabled={isManageTasks}
              icon={showCalendar ? 'list' : 'calendar-days'}
              onClick={() => controller.toggleView()}
              className="mr-3 active-on-hover"
            />
            <FilterImplementation
              filterContext={filterContext}
              lookups={lookups}
              title="Upcoming Tasks Filters"
              top="13rem"
              disabled={isManageTasks}
            >
              <FilterSets.Section filterContext={filterContext} />
            </FilterImplementation>
            {/* <TasksFilter top="13rem" iconCustomClass={{ 'ml-auto': true }} /> */}
          </div>
        </FilterSets.Panel>
      </div>
      <Container
        ref={containerRef}
        style={{ flex: '1 1 auto' }}
        fluid={showCalendar ? true : 'lg'}
        className=" pl-4 mr-auto ml-0"
      >
        {showCalendar ? (
          <div className="pt-2" style={{ marginLeft: '-30px' }}>
            <CalendarView
              filter={filter}
              appContext={appContext}
              calendarService={buildersService}
              navigate={navigate}
              isUpcoming
            />
          </div>
        ) : (
          <>
            <EmptyStateCenterContainer>
              <EmptyState visible={!isLoading && tasks.size === 0} icon={icon} title={title} body={body} />
            </EmptyStateCenterContainer>
            <BulkHeader
              context={bulkContext}
              show={isManageTasks}
              emptyTitle="Select multiple Tasks to change their dates."
              actions={bulkActions}
              upcomingView={true}
            >
              <Row className="pt-2">
                {!isLoading && tasks.size > 0 && (
                  <Col sm={12} data-testid="upcoming-cards">
                    {groups
                      .map((tasks, key) => (
                        <UpcomingDay tasks={tasks} date={key} key={key} isManageTasks={isManageTasks} />
                      ))
                      .valueSeq()}
                  </Col>
                )}
                <Col sm={12}>
                  {(isLoading || tasks.size < totalCount) && <LoadingCards howMany={isLoading ? 6 : 1} />}
                </Col>
              </Row>
            </BulkHeader>
          </>
        )}
      </Container>
    </>
  );
};

const DAYS = ['Today', 'Tomorrow'];

const UpcomingDay = ({ tasks, date, isManageTasks }) => {
  const [day, dayText] = React.useMemo(() => {
    const mDate = moment(date, 'YYYY-MM-DD');
    const diffDays = mDate.diff(moment().startOf('date'), 'days');
    return [mDate.format('MMM D'), DAYS[diffDays]];
  }, [date]);

  return (
    <div className="mb-4">
      <div className="mb-3 pb-1">
        <span className="h5 font-weight-bold">{day}</span>
        {dayText && <span className="font-size-14 font-weight-bold ml-2">{dayText}</span>}
      </div>
      {tasks.map(task => (
        <UpcomingCard key={task.get('key')} task={task} isManageTasks={isManageTasks} />
      ))}
    </div>
  );
};
