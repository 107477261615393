import React from 'react';
import { Item, Name, getName, SystemItem } from './common';
import { formatTrx } from '../../utils';
import { WeatherIndicator } from '../WeatherIndicator';

export default ({ item, account, user, isInactive }) => {
  const isSystemEvent = getName(item, user) === 'TradeTrax';
  const Component = isSystemEvent ? SystemItem : Item;
  return (
    <Component item={item} user={user}>
      <Name item={item} account={account} user={user} isInactive={isInactive} /> proposed exp. finish update request{' '}
      {formatTrx(item.getIn(['data', 'proposedFinishDate']))}
      <WeatherIndicator item={item} />
    </Component>
  );
};

module.exports.default.visibility = ({ item, user }) => item.get('assigneeAccountId') === user.get('accountId');
