import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import cn from 'classnames';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { colors } from '@tradetrax/web-common/src/theme';
import { PrimaryButton } from '@tradetrax/web-common/lib/Button';
import { isoToTrxFull, isValidTrx } from '@tradetrax/web-common/lib/utils';

const DAYS = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

const getHolidays = settings =>
  settings
    .get('holidays')
    .toArray()
    .map(isoToTrxFull)
    .join(', ');

function parseDatesString(value, originalValue) {
  const array = originalValue.replace(/\s+/g, '').split(',');
  return originalValue ? array : [];
}

const schema = yup.object().shape({
  workingDays: yup
    .object()
    .shape({
      monday: yup.boolean().required(),
      tuesday: yup.boolean().required(),
      wednesday: yup.boolean().required(),
      thursday: yup.boolean().required(),
      friday: yup.boolean().required(),
      saturday: yup.boolean().required(),
      sunday: yup.boolean().required(),
    })
    .test('workingDays', 'At least check one', value => DAYS.some(day => value[day])),
  holidays: yup
    .array()
    .transform(parseDatesString)
    .of(
      yup
        .string()
        .matches(/^\d{2}\/\d{2}\/\d{2}(?:\d{2})?$/, 'Invalid format')
        .test('holidays', 'Invalid Date', isValidTrx)
    ),
});

const UnstyledJobSettings = ({ className, controller, setOpenSettings, settings, jobId }) => {
  const { register, handleSubmit, errors } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      workingDays: settings.get('workingDays').toJS(),
      holidays: getHolidays(settings),
    },
  });
  const onSubmit = async form => {
    const isSuccessful = await controller.updateJobSettings(form, jobId);
    if (isSuccessful) setOpenSettings(false);
  };
  const holidaysError = errors.holidays?.find(err => err?.message);
  return (
    <div className={cn(className)}>
      <div className={cn('d-flex settings-container flex-column justify-content-start')}>
        <h5 className="mb-2 font-weight-bold">Job Settings</h5>
        <span className="font-size-12 text-gray-400 mb-3">
          This setting will only modify the dates of tasks with dependencies.
        </span>
        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Form.Group>
            <Form.Label className="secondary-title mt-3">Working Days</Form.Label>
            <Row className={cn('mx-4 my-1', { 'is-invalid': errors.workingDays })}>
              {DAYS.map(day => (
                <Col key={day} className="px-0">
                  <Form.Check
                    className="d-flex flex-column flex-column-reverse align-items-center pl-0"
                    type="checkbox"
                  >
                    <input type="checkbox" name={`workingDays.${day}`} ref={register} />
                    <label className="text-capitalize">{day.slice(0, 3)}</label>
                  </Form.Check>
                </Col>
              ))}
            </Row>
            <Form.Control.Feedback type="invalid">
              <FontAwesomeIcon icon="circle-exclamation" /> {errors.workingDays?.message}
            </Form.Control.Feedback>
            <hr className="border-muted w-100" />
          </Form.Group>
          <Form.Group className="d-flex flex-column">
            <Form.Label className="secondary-title">Non-Working Days</Form.Label>
            <small>Holidays and Exceptions</small>
            <Form.Control
              as="textarea"
              rows="3"
              name="holidays"
              ref={register}
              isInvalid={errors.holidays}
              placeholder="mm/dd/yyyy, mm/dd/yyyy"
            />
            <span className="font-size-12 text-gray-400">Type or paste dates separated by commas.</span>
            <Form.Control.Feedback type="invalid">
              <FontAwesomeIcon icon="circle-exclamation" /> {holidaysError?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Row className="mt-5">
            <PrimaryButton variant="secondary" onClick={() => setOpenSettings(false)}>
              Cancel
            </PrimaryButton>
            <PrimaryButton type="submit" className="ml-3">
              Apply
            </PrimaryButton>
          </Form.Row>
        </Form>
      </div>
    </div>
  );
};

export const JobSettings = props => {
  return <StyledJobSettings {...props} />;
};

const StyledJobSettings = styled(UnstyledJobSettings)`
  position: relative;
  transition: right 600ms ease;
  z-index: 1000;
  mix-blend-mode: normal;

  &.hidden {
    pointer-events: none;
  }

  .settings-container {
    background: ${colors.pearl};
    border-top: solid 10px ${colors.blue300};
    border-radius: 10px;
    padding: 1.75rem;
    position: absolute;
    width: 450px;
    right: 32px;
    top: 90px;

    &.hidden {
      display: none;
    }
  }

  .secondary-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
`;
