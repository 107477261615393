import React from 'react';
import { fromJS } from 'immutable';
import { useController } from '@tradetrax/web-common';
import { useAppContext } from 'app/App.context';
import * as actions from './HomeownerJobProgressActions';

const emptyState = fromJS({
  milestones: [],
  templateTasks: [],
  isLoading: true,
});

export function HomeownerJobProgressContext() {
  const appContext = useAppContext();
  const [state, controller] = useController(actions, emptyState, appContext);

  React.useEffect(() => {
    controller.readMilestonesData();
  }, [controller]);

  return {
    state,
    controller,
  };
}
