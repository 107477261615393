import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { InfiniteLoader, AutoSizer, Table, Column } from 'react-virtualized';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { EMPTY_ROW, renderCell, TableRowDropDown, colors } from '@tradetrax/web-common';
import { EmptyState, EmptyStateCenterContainer } from '@tradetrax/web-common/lib/EmptyState';
import { mongoToTemplateFormat, handleTableScrollStickyColumn2 } from '@tradetrax/web-common/lib/utils';
import { TemplatesController } from './TemplatesController';
import { TemplateNew } from './TemplateNew';
import navigate from 'app/navigate';
import { FilterImplementation } from '@tradetrax/web-common/lib/Filters/Implementation/Filter.implementation';

const COL_WIDTHS = {
  span: 32,
  tasks: 5 * 16,
  date: 10 * 16,
  action: 32,
};

export function TemplatesPage() {
  const { state, controller, filterContext } = TemplatesController();
  const templates = state.get('templates');
  const { totalCount } = state.toObject();

  const scrollDivRef = useRef(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const isInactive = index => templates.getIn([index, 'status']) === 'deactivated';

  useEffect(() => {
    const element = scrollDivRef.current;
    if (element) element.addEventListener('scroll', handleTableScrollStickyColumn2);

    return () => {
      if (element) element.removeEventListener('scroll', handleTableScrollStickyColumn2);
    };
  }, []);
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <div className="mx-4 my-4 d-flex flex-row justify-content-between">
        <h4 className="font-weight-bold">Templates</h4>
        <TemplateNew controller={controller} />
      </div>
      <div className="d-flex mx-4 justify-content-end ">
        <FilterImplementation filterContext={filterContext} />
      </div>
      <InfiniteLoader
        isRowLoaded={({ index }) => !!templates.get(index)}
        loadMoreRows={controller.loadMoreRows}
        rowCount={totalCount}
        style={{ position: 'relative' }}
      >
        {({ onRowsRendered, registerChild }) => (
          <div style={{ flex: '1 1 auto', overflowX: 'auto', overflowY: 'hidden' }} ref={scrollDivRef}>
            <AutoSizer>
              {({ width, height }) => {
                const minWidth = width > 700 ? width : 700;

                return (
                  <Table
                    ref={registerChild}
                    onRowsRendered={onRowsRendered}
                    className="trx-table stickyTable stickyColumn2"
                    headerHeight={40}
                    width={minWidth}
                    height={height}
                    overscanRowCount={2}
                    rowHeight={80}
                    rowCount={totalCount}
                    rowGetter={({ index }) => templates.get(index) || EMPTY_ROW}
                    rowClassName={({ index }) =>
                      cn({ loading: !templates.get(index), 'text-gray-200': isInactive(index) })
                    }
                    noRowsRenderer={() => (
                      <EmptyStateCenterContainer top="250px">
                        <EmptyState
                          icon="map"
                          title="No Templates Created"
                          body="When you create a Template it will appear here."
                        />
                      </EmptyStateCenterContainer>
                    )}
                    onScroll={event => handleTableScrollStickyColumn2(event, scrollDivRef)}
                  >
                    <Column dataKey="" width={COL_WIDTHS.span} label="" />

                    <Column
                      label="Template Name"
                      dataKey="name"
                      minWidth={200}
                      width={windowWidth < 1200 ? 200 : 350}
                      cellRenderer={renderCell(({ cellData, rowIndex, rowData }) => {
                        const isInactive = rowData.get('status') === 'deactivated';
                        return (
                          <>
                            {!isInactive && (
                              <Link
                                className="main-link"
                                to={{
                                  pathname: navigate.from.Templates.to.TemplateDetails(
                                    { templateId: templates.getIn([rowIndex, '_id']) },
                                    false
                                  ),
                                }}
                              >
                                <div className="text-truncate">{cellData}</div>
                              </Link>
                            )}
                            {isInactive && (
                              <div className="d-flex flex-row align-items-center">
                                <FontAwesomeIcon icon="ban" className="mr-1" style={{ color: colors.red_30 }} />
                                <div className="text-truncate text-gray-200">{cellData}</div>
                              </div>
                            )}
                          </>
                        );
                      })}
                    />

                    <Column
                      label="Tasks"
                      dataKey="tasks"
                      width={COL_WIDTHS.tasks}
                      cellRenderer={renderCell(({ cellData }) => (
                        <>{cellData.size}</>
                      ))}
                    />
                    <Column
                      label="Date Created"
                      dataKey="createdOn"
                      width={COL_WIDTHS.date}
                      cellRenderer={renderCell(({ cellData }) => (
                        <>{mongoToTemplateFormat(cellData) || 'Not Set'}</>
                      ))}
                    />
                    <Column
                      label="Status"
                      dataKey="status"
                      width={windowWidth < 1200 ? 100 : 350}
                      cellRenderer={renderCell(({ cellData, rowData }) => {
                        const status =
                          rowData.get('isComplete') || rowData.get('status') === 'deactivated'
                            ? cellData
                            : 'Incomplete';
                        return <div className="text-truncate text-capitalize">{status}</div>;
                      })}
                    />

                    <Column
                      label=""
                      dataKey=""
                      width={COL_WIDTHS.action}
                      cellRenderer={renderCell(({ rowData, rowIndex }) => (
                        <TableRowDropDown
                          rename
                          duplicate
                          deactivate={!isInactive(rowIndex)}
                          reactivate={isInactive(rowIndex)}
                          onSelect={option => controller.handleActions(option, rowData)}
                        />
                      ))}
                    />

                    <Column dataKey="" width={COL_WIDTHS.span} label="" />
                  </Table>
                );
              }}
            </AutoSizer>
          </div>
        )}
      </InfiniteLoader>
    </>
  );
}
