import React from 'react';
import styled from 'styled-components';
import { ToggleButtons, ToggleButton, UserProfileLoading, MiniProfile } from '@tradetrax/web-common';
import { MyProfileController } from './MyProfileController';
import { MyProfileDetails } from './MyProfileDetails';
import { Notifications } from './Notifications';

const Content = styled.div`
  height: 100%;
`;

export const MyProfile = ({ location }) => {
  const defaultTab = location.state?.tab || 'profile';
  const [tab, setTab] = React.useState(defaultTab);
  const { state, controller, alert, logout } = MyProfileController();
  const user = state.get('user');
  const isLoading = state.get('isLoading');
  const name = `${user.get('firstName')} ${user.get('lastName')}`;
  const email = user.get('email');

  React.useEffect(() => {
    setTab(defaultTab);
  }, [setTab, defaultTab]);

  if (isLoading) return <UserProfileLoading />;

  return (
    <div className="mt-4">
      <MiniProfile initials={user.get('initials')} name={name} email={email} />
      <ToggleButtons className="mb-2 mt-5 mr-auto" type="radio" name="options" value={tab} onChange={setTab}>
        <ToggleButton value="profile">My Profile</ToggleButton>
        <ToggleButton value="notifications">Notifications</ToggleButton>
      </ToggleButtons>
      <Content className="d-flex flex-row">
        {tab === 'profile' && (
          <MyProfileDetails user={user} name={name} controller={controller} alert={alert} logout={logout} />
        )}
        {tab === 'notifications' && <Notifications controller={controller} state={state} />}
      </Content>
    </div>
  );
};
