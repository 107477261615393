import React from 'react';
import { useController } from '@tradetrax/web-common';
import { fromJS } from 'immutable';
import * as actions from './WorkloadPlanningActions';

const emptyState = fromJS({
  deniedRequests: { isLoading: true },
});

export function WorkloadPlanningContext(appContext) {
  const [state, controller] = useController(actions, emptyState, appContext);

  React.useEffect(() => {
    controller.viewCommitments();
  }, [controller]);

  return { state, controller };
}
