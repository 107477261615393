import React from 'react';
import moment from 'moment';
import cn from 'classnames';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import navigate from 'app/navigate';
import { STATUS_MAP, ChipStatusDropdown } from '../../ChipStatus';
import { colors } from '../../theme';
import {
  NOT_STARTED,
  IN_PROGRESS,
  COMPLETED,
  mongoToText,
  getAssigneeBadgeClassName,
  getUserDomain,
  hasPermissionForAssignedTask,
  isURDateOverdue,
} from '../../utils';
import { UserNameLabel } from '../../Tags';
import { LoadingTaskCard } from '../Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fromJS } from 'immutable';
import { UpdateRequestStatus } from '../Upcoming/UpdateRequestStatus';
import { useBulkContext } from '../../BulkActions/BulkActions.context';
import { WeatherOverlay } from './WeatherIndicatorPopover';

const UnstyledLeft = styled.div`
  width: 230px;
  overflow: hidden;
`;

const TruncateTaskName = styled.div`
  ${props => `
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  color: ${props.isOverdue ? colors.red : colors.blue};
`}
`;

const LinkToJob = styled(Link)`
  max-width: 12.5rem;
  margin-top: 8px;
  width: fit-content;
`;

const getCreatedByName = (card, isTrade) => {
  const createdByType = card.getIn(['changeRequest', 'createdByType']);
  if (createdByType === 'system') return 'TradeTrax';
  if (isTrade) return card.getIn(['changeRequest', 'createdByName']);
  // else isBuilder
  if (createdByType === 'sub' || createdByType === 'installer') return card.getIn(['assigneeAccount', 'company']);

  return card.getIn(['changeRequest', 'createdByName']);
};

const Left = ({ children }) => <UnstyledLeft className="d-flex flex-column">{children}</UnstyledLeft>;

const Right = ({ children }) => <div className="d-flex flex-column align-content-end">{children}</div>;

const Card = styled.div`
  ${props => `
  width: 420px;
  height: 190px;
  border: 1px solid #e0e2e7;
  box-sizing: border-box;
  border-radius: 8px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  position: relative;
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  margin-bottom: ${props.isDetails ? '1.5rem' : '0rem'};

&::before {
  background-color: ${props.themeColor};
  position: absolute;
  left: 0;
  right: 0;
  top: -10px;
  height: 10px;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  content: '';
}

&:hover {
  text-decoration: none;
}

button.btn-link {
  font-size: 14px;
}

&.size-small {
  button.btn-link {
    font-size: 12px;
  }
}

.card-content {
  padding: 1.25rem 1.25rem 0 1.8rem;
  position: relative;

  i {
    width: 5px;
    height: 5px;
    background: ${colors.red};
    border-radius: 50%;
    position: absolute;
    top: 1.8rem;
    left: 1.375rem;
  }

  span {
    letter-spacing: 0.2px;
  }

  span.job-name {
    max-width: 12.5rem;
margin-top:8px;
  }

  strong {
    color: ${colors.red};
    text-decoration: none;
  }

  small {
    font-size: 14px;
  }

  small a {
    font-size: 14px;
    text-decoration: none;
    color: ${colors.blue400};
  }

  .sub-link {
    max-width: 150px;
    font-size: 14px;
  }

  > :last-child {
    text-align: right;
  }
}
`}
  .links-container {
    width: 100%;
    bottom: 0;
    left: 0;
  }
`;

function AssignedTo({ card, user, isTrade, canAssigneeInstaller, isOverdue = false }) {
  const assignee = isOverdue
    ? getOverdueAssignee(card, isTrade, canAssigneeInstaller)
    : getURTaskAssignee(card, user, isTrade);
  const isInactive = assignee?.get('status') === 'inactive';
  const assignType = getAssigneeBadgeClassName(
    assignee.get('userId'),
    user.get('accountId'),
    user.get('_id'),
    assignee.get('accountId')
  );
  const timeSpan = !isOverdue && moment.utc(card.getIn(['changeRequest', 'createdOn'])).fromNow();
  const name = assignee?.get('name');
  const isAssigned = name && name !== 'Not Assigned' && name !== 'Needs Assignment';
  const width = isOverdue ? '185px' : '120px';
  return (
    <span className="d-flex flex-row align-items-center mt-1 text-truncate">
      {isAssigned && <small className="text-muted text-nowrap mr-1">{isOverdue ? 'Assigned to' : 'By'} </small>}
      <UserNameLabel className={cn(`not-permission ${assignType} `)} isInactive={isInactive} maxWidth={width}>
        {assignee?.get('name')}
      </UserNameLabel>
      {!isOverdue && <small className="text-muted ml-1 text-nowrap">{timeSpan}</small>}
    </span>
  );
}

function getURTaskAssignee(card, loggedUser, isTrade) {
  const taskUR = card.get('changeRequest');
  const createdByName = getCreatedByName(card, isTrade);
  const isSystemUR = taskUR.get('createdByType') === 'system';
  const isBuilderUR = taskUR.get('createdByType') === 'builder';

  const name = createdByName || 'Not Assigned';
  const createdById = isTrade || isBuilderUR ? taskUR.get('createdById') : card.getIn(['assigneeAccount', 'companyId']);
  const userId = isSystemUR ? 'TradeTrax' : createdById;
  const accountId = isSystemUR
    ? 'TradeTrax'
    : isBuilderUR
    ? loggedUser.get('accountId')
    : card.getIn(['assigneeAccount', 'companyId']);
  const status = isTrade ? taskUR.get('createdByStatus') : 'active'; // active because as Builder cannot be an inactive Trade on UR
  return fromJS({
    userId,
    accountId,
    status,
    name,
  });
}

function getOverdueAssignee(card, isTrade, canAssigneeInstaller) {
  const assignee = card.get('assigneeAccount');
  const namePath = isTrade ? 'installerName' : 'companyName';
  const idPath = isTrade ? 'installerId' : 'companyId';
  const userId = assignee.get(idPath);
  const name = assignee.get(namePath) || (canAssigneeInstaller || !isTrade ? 'Needs Assignment' : 'Not Assigned');
  const status = isTrade ? assignee.get('installerStatus') : 'active'; // active because as Builder cannot be an inactive Trade on Overdues
  return fromJS({
    userId,
    accountId: assignee.get('companyId'),
    status,
    name,
  });
}

const canEditTaskStatus = (hasPermission, card, userId) =>
  hasPermissionForAssignedTask('task_update_status', hasPermission, card, userId);

const canSendUpdateRequest = (hasPermission, card, userId) =>
  hasPermissionForAssignedTask('manage_update_request', hasPermission, card, userId);

const hasPermissionToAssign = (hasPermission, card, userId) =>
  hasPermissionForAssignedTask('task_update_installer', hasPermission, card, userId);

const LotNumberLabel = ({ lotNumber }) => {
  if (!lotNumber) return null;
  const truncatedLotNumber = lotNumber.length > 10 ? lotNumber.slice(0, 10) + '...' : lotNumber;
  return <div className="mt-2 w-100 text-truncate text-gray-400">{`LOT # ${truncatedLotNumber}`}</div>;
};

// Cards
export const UpdateRequestCard = ({
  card,
  linkToTask,
  user,
  controller,
  isDetails,
  hasPermission,
  isManageTasks,
  isDisabled = false,
}) => {
  const { isTrade } = getUserDomain(user);
  const isEndDateChangeRequest = card.getIn(['changeRequest', 'type']) === 'new-expected-end-date';
  const originalDate = isEndDateChangeRequest ? card.get('expectedFinishDate') : card.get('startDate');
  const newDate = isEndDateChangeRequest
    ? card.getIn(['changeRequest', 'proposedFinishDate'])
    : card.getIn(['changeRequest', 'proposedStartDate']);
  const taskStatus = card.get('status');
  const buttonRef = React.useRef(null);
  const [showOverlay, setShowOverlay] = React.useState(false);

  if (card.get('isLoading')) return <LoadingTaskCard />;

  const bulkContext = useBulkContext();
  const bulkController = isManageTasks && bulkContext.controller;
  const selectedTasks = isManageTasks && bulkContext.state.get('selectedTasks');
  const isChecked = React.useMemo(() => {
    if (selectedTasks) return !!selectedTasks.find(task => task.get('key') === card.get('key'));
    else return false;
  }, [selectedTasks]);
  const canEditStatus = canEditTaskStatus(hasPermission, card, user.get('_id'));
  const tasksAffectedSize = card.getIn(['changeRequest', 'affectedTasks']).size;
  const lotNumber = card.getIn(['job', 'lotNumber']);
  const jobId = card.getIn(['job', 'id']);
  const showDueWeatherIndicator = card.getIn(['changeRequest', 'reasons'])?.includes('weather');

  return (
    <div className="d-flex flex-row">
      {isManageTasks && (
        <div className="mt-4 pt-1">
          <input
            type="checkbox"
            className="mr-3"
            disabled={isDisabled}
            checked={isChecked}
            onChange={({ target }) => bulkController.toggleCheckRequest(card, target.checked)}
          />
        </div>
      )}
      <Card themeColor={colors.blue400} isDetails={isDetails}>
        <div>
          <div className="d-flex flex-row justify-content-between card-content mb-0">
            <Left>
              {!card.getIn(['changeRequest', 'seen']) && <i className="mt-1" />}
              <Link {...linkToTask} className="font-weight-bold  mt-1" style={{ color: `${colors.blue400}` }}>
                <TruncateTaskName>{card.get('name')}</TruncateTaskName>
              </Link>
              {isTrade && lotNumber ? (
                <LotNumberLabel lotNumber={lotNumber} />
              ) : (
                <LinkToJob
                  className="text-gray-800 text-truncate job-name"
                  to={navigate.to.JobDetails({ jobId }, false)}
                >
                  {card.getIn(['job', 'name'])}
                </LinkToJob>
              )}
            </Left>
            <Right>
              <ChipStatusDropdown
                className="mb-1"
                {...STATUS_MAP[taskStatus]}
                notStarted={taskStatus !== NOT_STARTED}
                progress={taskStatus !== IN_PROGRESS}
                completed={taskStatus !== COMPLETED}
                onSelect={status => controller.updateStatus({ task: card, status })}
                disabled={isTrade && !canEditStatus}
              />
              <span className="text-muted mt-1">
                <small className={cn({ 'text-danger': isURDateOverdue(card, originalDate) })}>
                  {mongoToText(originalDate)}
                </small>
                <FontAwesomeIcon icon="right-left" className="mx-2" />
                <small className={cn({ 'text-danger': isURDateOverdue(card, newDate) })}>{mongoToText(newDate)}</small>
              </span>
            </Right>
          </div>
          <div className="d-flex flex-row justify-content-between" style={{ padding: '0 1.25rem 0 1.8rem' }}>
            <AssignedTo card={card} user={user} isTrade={isTrade} />
            <div
              className={cn('d-flex font-size-12 font-weight-bold align-items-center mt-1', {
                'text-danger': tasksAffectedSize > 0,
                'text-dark': tasksAffectedSize === 0,
              })}
            >
              <span
                className={cn({
                  'font-weight-normal text-gray-400': tasksAffectedSize === 0,
                  'd-none': isTrade,
                })}
              >{`Tasks Affected ${tasksAffectedSize}`}</span>
            </div>
          </div>
          {showDueWeatherIndicator && (
            <DueWeatherContainer className="mt-1">
              <FontAwesomeIcon icon="cloud" /> <span>Due to Weather</span>
            </DueWeatherContainer>
          )}
        </div>
        <div className="position-absolute links-container">
          <LineCard className="mx-3 p-0 " dueWeather={showDueWeatherIndicator} />
          <div
            className="d-flex flex-row align-items-center justify-content-around p-2"
            style={{ border: '0px solid red' }}
          >
            <button
              className="btn btn-link font-weight-bold text-secondary shadow-none"
              onClick={() =>
                isTrade
                  ? controller.openChangeDateModalUR(card, bulkContext)
                  : controller.openTasksAffectedModal(card, { isUpdateRequest: true, isInDashboard: true, bulkContext })
              }
            >
              View Update Request
            </button>
            <WeatherOverlay
              showOverlay={showOverlay}
              setShowOverlay={setShowOverlay}
              card={card}
              onSaveAndAccept={checked => {
                const updateRequest = isEndDateChangeRequest
                  ? controller.acceptExpFinishDateUpdateRequest
                  : controller.acceptStartDateUpdateRequest;

                updateRequest({
                  task: card,
                  isTrade,
                  bulkContext,
                  reasons: checked ? ['weather'] : undefined,
                });
              }}
              isTrade={isTrade}
            >
              <button
                ref={buttonRef}
                className="btn btn-link font-weight-bold shadow-none"
                onClick={() => setShowOverlay(!showOverlay)}
              >
                {isTrade ? 'Send to Builder' : 'Accept Date Change'}
              </button>
            </WeatherOverlay>
          </div>
        </div>
      </Card>
    </div>
  );
};

export const OverdueTaskCard = ({
  card,
  linkToTask,
  user,
  controller,
  isDetails,
  hasPermission,
  isManageTasks,
  isDisabled = false,
}) => {
  const { isTrade, isBuilder } = getUserDomain(user);
  const taskStatus = card.get('status');
  const jobId = card.getIn(['job', 'id']);

  if (card.get('isLoading')) return <LoadingTaskCard />;

  const bulkContext = useBulkContext();
  const bulkController = isManageTasks && bulkContext.controller;
  const selectedTasks = isManageTasks && bulkContext.state.get('selectedTasks');
  const isChecked = React.useMemo(() => {
    if (selectedTasks)
      return !!selectedTasks.find(
        task => task.get('id') === card.get('id') && task.getIn(['job', 'id']) === card.getIn(['job', 'id'])
      );
    else return false;
  }, [selectedTasks]);
  const canEditStatus = canEditTaskStatus(hasPermission, card, user.get('_id'));
  const canSendUR = canSendUpdateRequest(hasPermission, card, user.get('_id'));
  const canAssigneeInstaller = hasPermissionToAssign(hasPermission, card, user.get('_id'));
  const canChangeDate = user.getIn(['permissions', 'job_update_task_dependencies_and_dates']) === 'yes';
  const lotNumber = card.getIn(['job', 'lotNumber']);
  const showToTrade =
    isTrade &&
    (card.getIn(['changeRequestOnReviewByBuilder', 'createdByType']) === 'installer' ||
      card.getIn(['changeRequestOnReviewByBuilder', 'createdByType']) === 'sub');

  return (
    <div className="d-flex flex-row">
      {isManageTasks && (
        <div className="mt-4 pt-1">
          <input
            type="checkbox"
            className="mr-3"
            disabled={isDisabled}
            checked={isChecked}
            onChange={({ target }) => bulkController.toggleCheckOverdue(card, target.checked)}
          />
        </div>
      )}
      <Card themeColor={colors.red} isDetails={isDetails}>
        <div>
          <div className="d-flex flex-row justify-content-between card-content">
            <Left>
              {!card.get('seen') && <i className="mt-1" />}
              <Link
                {...linkToTask}
                className="font-weight-bold  mt-1"
                style={{ textDecorationColor: `${colors.red400}` }}
              >
                <TruncateTaskName isOverdue>
                  <strong>{card.get('name')}</strong>
                </TruncateTaskName>
              </Link>
              {isTrade && lotNumber ? (
                <LotNumberLabel lotNumber={lotNumber} />
              ) : (
                <LinkToJob
                  className="text-gray-800 text-truncate job-name mt-2 pt-1"
                  to={navigate.to.JobDetails({ jobId }, false)}
                >
                  {card.getIn(['job', 'name'])}
                </LinkToJob>
              )}

              <div className="d-flex">
                <AssignedTo
                  card={card}
                  user={user}
                  isTrade={isTrade}
                  canAssigneeInstaller={canAssigneeInstaller}
                  isOverdue
                />
              </div>
            </Left>
            <Right>
              <ChipStatusDropdown
                className="mb-1"
                {...STATUS_MAP[taskStatus]}
                notStarted={taskStatus !== NOT_STARTED}
                progress={taskStatus !== IN_PROGRESS}
                completed={taskStatus !== COMPLETED}
                onSelect={status => controller.updateStatusOverdue({ task: card, status, bulkContext })}
                disabled={isTrade && !canEditStatus}
              />
              <small className="text-danger mt-1 pt-1">
                {card.get('overdue') === 'finish'
                  ? `Missed Finish: ${mongoToText(card.get('expectedFinishDate'))}`
                  : ''}
                {card.get('overdue') === 'start' ? `Missed Start: ${mongoToText(card.get('startDate'))}` : ''}
              </small>
              {(showToTrade || isBuilder) && <UpdateRequestStatus task={card} isBuilder={isBuilder} isOverdue />}
            </Right>
          </div>
        </div>
        <div className="position-absolute links-container">
          <hr className="mx-3 my-0 p-0" />
          <div
            className="d-flex flex-row align-items-center justify-content-center p-2"
            style={{ border: '0px solid red' }}
          >
            <button
              className="btn btn-link font-weight-bold shadow-none"
              onClick={() => controller.openChangeDateModalOverdue({ task: card, canSendUR, bulkContext })}
              disabled={isTrade ? !canSendUR : !canChangeDate}
            >
              {isTrade ? 'Send Update Request' : 'Change Date'}
            </button>
          </div>
        </div>
      </Card>
    </div>
  );
};

const LineCard = styled.hr`
  margin-top: 0;
  margin-bottom: ${props => (props.dueWeather ? '-6px' : 0)};
`;

const DueWeatherContainer = styled.div`
  padding-left: 2rem;
  font-size: 12px;
  color: ${colors.gray400};
`;
