import React from 'react';
import { Router, Route, Redirect, Switch } from 'react-router-dom';
import { AppContext, Context } from './App.context';
import navigate, { history, Paths } from './navigate';
import * as Layout from './layout';
import * as Pages from './pages';
import { MiniAlerts, Dialog, Notifications, LayoutContent, LandingPage, NotFound } from '@tradetrax/web-common';
import '../styles/react-virtualized.css';
import { FiltersContext, createFiltersContext } from '@tradetrax/web-common/lib/Filters/Filters.context';
import { buildersService } from 'services';

const taskUrl = props => navigate.to.JobTaskDetails(props, false);
const jobUrl = props => navigate.to.JobDetails(props, false);
const jobsUrl = props => navigate.to.Job(props, false);
const dashboardUrl = props => navigate.to.DashboardV2(props, false);

function App({ user, device }) {
  const appContext = Context(user);
  const filtersContext = createFiltersContext(appContext, buildersService);
  const { appState, alert, miniAlertsRef } = appContext;
  const options = appState.get('options');
  const { add } = alert || {};

  const showLanding = device.isIOS || device.isAndroid;
  const [show, setShow] = React.useState(showLanding);

  React.useEffect(() => {
    if (showLanding && add) {
      add({
        message: 'For the best experience go to the desktop version or download the mobile app.',
        autoClose: false,
        variant: 'primary',
      });
    }
  }, [showLanding, add]);

  if (show)
    return (
      <LandingPage device={device}>
        <MiniAlerts ref={miniAlertsRef} />
        <button onClick={() => setShow(false)} className="btn btn-link">
          Skip and Continue
        </button>
      </LandingPage>
    );

  return (
    <AppContext.Provider value={appContext}>
      <FiltersContext.Provider value={filtersContext}>
        <Router history={history}>
          <Layout.SideBar />
          <Layout.TopBar />
          <Notifications
            context={appContext}
            taskUrl={taskUrl}
            jobUrl={jobUrl}
            jobsUrl={jobsUrl}
            dashboardUrl={dashboardUrl}
          />
          <LayoutContent expanded={options.expanded}>
            <Dialog controller={appContext.modal} />
            <MiniAlerts ref={miniAlertsRef} />
            <Switch>
              <Route exact path={Paths.Home}>
                <Redirect to={Paths.DashboardV2} />
              </Route>
              <Route path={Paths.DashboardV2} component={Pages.DashboardV2} />
              {/* <Redirect from={Paths.Dashboard} to={Paths.Home} /> */}
              <Route path={Paths.ToDo} component={Pages.ToDo} />
              <Route path={Paths.UpcomingTasks} component={Pages.Upcoming} />
              <Route path={Paths.Templates} component={Pages.Templates} />
              <Route path={Paths.CustomReport} component={Pages.ReportPage} />
              <Route path={Paths.CommunityOuttake} component={Pages.Outtake} />
              <Route path={Paths.Community} component={Pages.Community} />
              <Route path={Paths.Job} component={Pages.Job} />
              <Redirect from={Paths.TaskOpen} to={Paths.Task} />
              <Route path={Paths.Task} component={Pages.Tasks} />
              <Route path={Paths.Homeowners} component={Pages.Homeowner} />
              <Route path={Paths.SettingsTab} component={Pages.Settings} />
              <Route path={Paths.MyProfile} component={Pages.MyProfile} exact />
              <Route path={Paths.Users} component={Pages.Users} />
              <Route path={Paths.NotPermission} component={NotFound} exact />
              <Redirect from="/token" to="/" />
              <Route component={NotFound} />
            </Switch>
          </LayoutContent>
        </Router>
      </FiltersContext.Provider>
    </AppContext.Provider>
  );
}

export default App;
