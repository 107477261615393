import React from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrimaryButton } from '../../Button';
import { Initials } from '../../Tags';
import { colors } from '../../theme';

export function ListView({ assigneeList, controller, close }) {
  const isEmpty = !assigneeList.size;
  return (
    <>
      <Modal.Body>
        <ListContent className="mt-2 mb-3 pl-3">
          {assigneeList.map(({ name, status }, index) => (
            <div key={index} className="w-50 d-inline-block">
              <span className={`d-flex flex-row align-items-center my-2 ${status}`}>
                <Initials name={name} size={30} />
                <span className="text-truncate">
                  <FontAwesomeIcon icon="ban" className="inactive-icon" />
                  {name}
                </span>
              </span>
            </div>
          ))}
        </ListContent>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-between w-100 m-0">
          <button className={cn('btn btn-link p-0', { 'd-none': isEmpty })} onClick={controller.setEditView}>
            Edit Area Manager
          </button>
          <PrimaryButton onClick={() => close()}>OK</PrimaryButton>
        </div>
      </Modal.Footer>
    </>
  );
}

const ListContent = styled.div`
  max-height: 250px;
  overflow: scroll;

  .inactive-icon {
    display: none;
  }

  .inactive {
    color: ${colors.gray200};
    .inactive-icon {
      display: inline;
      margin-right: 0.5rem;
      color: ${colors.red_30};
    }
  }
`;
