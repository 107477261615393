import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from '../../theme';
import { getActiveFilterset } from '../Filter.shared.utils';

export const FilterSetsDropdownSection = ({ filterContext }) => {
  const { filterState, filterController, isManager, filterSets } = filterContext;
  const controller = filterController.filterSets;
  const activeFilterSet = getActiveFilterset(filterContext);
  const showEditedLabel = filterState.get('hasChanges');

  const isShared = filter => filter?.get('sharedBy') || (isManager && filter?.get('isShared'));
  const getIcon = filter => (isShared(filter) ? 'user-group' : 'filter');

  const onSelectFilter = fs => e => {
    e.preventDefault();
    e.stopPropagation();
    controller.selectFilter(fs === activeFilterSet ? null : fs);
  };

  if (filterSets.size < 1) return null;

  return (
    <div className="d-flex flex-column mb-4">
      <div className="d-flex flex-column  align-items-start">
        <div className={cn('text-secondary ', { 'd-none': !activeFilterSet })}>Filter Set Name</div>
        <StyledDropDown>
          <Dropdown.Toggle as={FilterSetToggle} id="dropdown-custom-components" isActive={activeFilterSet}>
            <FilterSelected isActive={activeFilterSet}>
              <div className="d-flex align-items-center w-100">
                <span className="d-block text-truncate" style={{ maxWidth: showEditedLabel ? '72%' : '100%' }}>
                  {activeFilterSet?.get('name') || 'Choose Filter Set'}
                </span>
                {activeFilterSet && getIcon(activeFilterSet) === 'user-group' ? (
                  <FontAwesomeIcon icon={getIcon(activeFilterSet)} className="ml-2 font-size-12 " />
                ) : null}
                {showEditedLabel && <span className="ml-2 font-size-16 text-gray-400 font-italic">[Edited] *</span>}
              </div>
            </FilterSelected>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {filterSets.toArray().map(fs => (
              <Dropdown.Item
                className={cn('do-not-close-popup')}
                key={fs.get('_id')}
                onClick={onSelectFilter(fs)}
                active={fs === activeFilterSet}
              >
                <FontAwesomeIcon icon={getIcon(fs)} className="mx-2 font-size-12" />
                &nbsp;{fs.get('name')}
                <FontAwesomeIcon
                  icon="check"
                  className={cn('ml-2 font-size-12', { 'd-none': fs !== activeFilterSet })}
                />
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </StyledDropDown>
      </div>
    </div>
  );
};

const StyledDropDown = styled(Dropdown)`
  > a {
    color: ${colors.gray400};
    text-decoration: none;
  }
  max-width: 95%;

  .dropdown-item {
    border-bottom: 1px solid ${colors.gray};
    color: ${colors.gray400};
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.2px;
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    &.active,
    &:hover {
      color: ${colors.blue300} !important;
      background-color: ${colors.activeState};
    }
  }
  .dropdown-menu {
    padding: 0;
    max-height: 365px;
    overflow-y: auto;
  }
`;

const FilterSetToggle = React.forwardRef(({ children, onClick, isActive }, ref) => (
  <FilterSetToggleSelect
    href=""
    ref={ref}
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
    isActive={isActive}
  >
    {children}
    <FontAwesomeIcon icon="chevron-down" className="ml-2 chevron-icon" />
  </FilterSetToggleSelect>
));

const FilterSetToggleSelect = styled.a`
  display: flex;
  align-items: center;

  .chevron-icon {
    display: none;
  }

  &:hover {
    .chevron-icon {
      display: inline-block;
      ${props => `
    color: ${props.isActive ? colors.blue300 : colors.gray400};
  `}
    }
  }
`;

const FilterSelected = styled.span`
  ${props => `
    color: ${props.isActive ? colors.blue300 : colors.gray400};
  `}
  font-size: 16px;
  display: flex;
  align-items: center;
  width: 100%;
`;
