import React from 'react';
import styled from 'styled-components';
import { colors } from '@tradetrax/web-common';

const UnstyledConfirm = ({ className, controller, task, openConfirmTask, canSendUpdateRequest }) => {
  if (!openConfirmTask) return null;
  const startDateConfirmationRequest = task.get('startDateConfirmationRequest');
  if (!startDateConfirmationRequest) return null;

  const onConfirm = e => {
    e.stopPropagation();
    openConfirmTask(e.target, task);
  };

  const onChangeDate = e => {
    e.stopPropagation();
    controller.changeDate(task, canSendUpdateRequest);
  };

  return (
    <div className={className}>
      <hr />
      <div className="d-flex flex-row justify-content-between px-3">
        <button onClick={onChangeDate} className="btn btn-link text-secondary" disabled={!canSendUpdateRequest(task)}>
          Change Date
        </button>
        <button onClick={onConfirm} data-popup-confirm className="btn btn-link">
          Confirm Start Date
        </button>
      </div>
    </div>
  );
};

export const Confirm = styled(UnstyledConfirm)`
  hr {
    border-top: 1px solid ${colors.gray500};
    height: 1px;
    width: 100%;
  }

  button {
    font-size: 14px;
    font-weight: bold;
    padding: 0;
    letter-spacing: 0.2px;
  }
`;
