import React from 'react';
import throttle from 'lodash/throttle';

export const useInfiniteScroll = (loadMore, offset = 0) => {
  const containerRef = React.createRef();

  React.useEffect(() => {
    const content = containerRef.current;
    const container = content.parentElement;

    const onScroll = throttle(() => {
      // 52 = container padding
      if (content.clientHeight - offset + 52 - container.scrollTop <= global.innerHeight) {
        loadMore();
      }
    }, 300);

    container.addEventListener('scroll', onScroll);
    return () => {
      container.removeEventListener('scroll', onScroll);
    };
  }, [loadMore, containerRef, offset]);

  return containerRef;
};
