import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const WeatherIndicator = ({ item }) => {
  if (!item.getIn(['data', 'reasons'])?.includes('weather')) return null;

  return (
    <div className="text-gray-400 font-size-14">
      <FontAwesomeIcon icon="cloud" /> <span>Due to Weather</span>
    </div>
  );
};
