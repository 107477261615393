import React from 'react';
import cn from 'classnames';
import { Form } from 'react-bootstrap';
import { UL } from './UL';
import { TimeSelect } from '@tradetrax/web-common';
import moment from 'moment';

const EVENTS = {
  'change-request': 'Update Requests from Trades',
  'builder-change-request': 'Details of Accepted Update Request',
  'schedule-update': 'Builder Schedule Update Details',
  'start-date-confirmed-task': 'Start Date Confirmations',
  'commitment-request': 'Denied Commitment Requests from Trades',
  'task-not-ready-to-start': 'Task not ready to start from Installers',
  'date-change': 'Start-Finish Date Changes',
  task_assignment: 'New tasks assigned to me',
  overdue: 'Overdue Tasks',
  'task-missed-check-in': 'Missed Check-in',
  'task-missed-check-out': 'Missed Check Out',
  'new-task-attachment-or-note': 'Notes/Attachments Added to Task',
  'tagged-in-notes': 'Tagged in Notes',
  'day-wrap-up': "Today's Wrap-Up",
};

export const ReceiveUpdates = ({ ...props }) => {
  const { events, pushDisabled, emailDisabled, onChange } = props;
  const notice_window = events.getIn(['date-change', 'notice_window']);
  const noticeTime = events.getIn(['task-missed-check-in', 'noticeTime']);
  const noticeTimeCheckOut = events.getIn(['task-missed-check-out', 'noticeTime']);
  const noticeTimeWrapUp = events.getIn(['day-wrap-up', 'noticeTime']);

  return (
    <div className="d-flex flex-column p-2">
      <div className="d-flex flex-row justify-content-between">
        <h5 className="font-weight-bold mb-3">Receive Updates</h5>
        <div className="font-weight-bold">
          <span>Push</span>
          <span className="mr-2 ml-4">Email</span>
        </div>
      </div>
      <UL>
        <Update event="change-request" {...props} />
        <Update event="builder-change-request" {...props} hidePush />
        <Update event="schedule-update" {...props} hidePush />
        <Update event="start-date-confirmed-task" {...props} />
        <Update event="commitment-request" {...props} />
        <Update event="task-not-ready-to-start" {...props} />
        <Update event="date-change" {...props}>
          {({ pushChecked, emailChecked }) => (
            <div className="d-flex flex-row justify-content-center mt-2">
              <Form.Control
                type="number"
                size="sm"
                min={0}
                max={99}
                style={{ width: 55 }}
                defaultValue={notice_window || 0}
                disabled={(pushDisabled && emailDisabled) || (!pushChecked && !emailChecked)}
                onChange={e => {
                  if (e.target.value.length > 2) e.target.value = e.target.value.substring(0, 2);
                  onChange('date-change', { notice_window: parseInt(e.target.value, 10) || 1 });
                }}
              />
              <span className="small text-secondary ml-2 pt-1">Days in advance notice window</span>
            </div>
          )}
        </Update>
        <Update event="task_assignment" {...props} />
        <Update event="overdue" {...props} />
        <Update event="task-missed-check-in" {...props}>
          {({ pushChecked, emailChecked }) => (
            <div className="d-flex flex-row justify-content-start mt-2">
              <TimeSelect
                disabled={(pushDisabled && emailDisabled) || (!pushChecked && !emailChecked)}
                onSelect={time => onChange('task-missed-check-in', { noticeTime: time || '2 pm' })}
                selected={(noticeTime && moment({ hours: noticeTime }).format('h a')) || '2 pm'}
              />
            </div>
          )}
        </Update>
        <Update event="task-missed-check-out" {...props}>
          {({ pushChecked, emailChecked }) => (
            <div className="d-flex flex-row justify-content-start mt-2">
              <TimeSelect
                disabled={(pushDisabled && emailDisabled) || (!pushChecked && !emailChecked)}
                onSelect={time => onChange('task-missed-check-out', { noticeTime: time || '2 pm' })}
                selected={(noticeTimeCheckOut && moment({ hours: noticeTimeCheckOut }).format('h a')) || '2 pm'}
                event="task-missed-check-out"
              />
            </div>
          )}
        </Update>
        <Update event="new-task-attachment-or-note" {...props} />
        <Update event="tagged-in-notes" {...props} />
        <Update event="day-wrap-up" {...props}>
          {({ pushChecked, emailChecked }) => (
            <div className="d-flex flex-row justify-content-start mt-2">
              <TimeSelect
                disabled={(pushDisabled && emailDisabled) || (!pushChecked && !emailChecked)}
                onSelect={time => onChange('day-wrap-up', { noticeTime: time || '4 pm' })}
                selected={(noticeTimeWrapUp && moment({ hours: noticeTimeWrapUp }).format('h a')) || '4 pm'}
                event="day-wrap-up"
              />
            </div>
          )}
        </Update>
      </UL>
    </div>
  );
};

const Update = ({ event, children, events, onChange, pushDisabled, emailDisabled, hidePush }) => {
  const channels = events.getIn([event, 'onlyChannels']);
  const pushChecked = channels && channels.indexOf('push') >= 0;
  const emailChecked = channels && channels.indexOf('email') >= 0;

  const update = onlyChannels => onChange(event, { onlyChannels });

  return (
    <li>
      <div className="d-flex flex-column mr-auto">
        <span>{EVENTS[event]}</span>
        {children && children({ pushChecked, emailChecked })}
      </div>
      <Form.Check
        className={cn('mr-1', { invisible: hidePush })}
        id={`${event}_push`}
        checked={!!pushChecked}
        disabled={pushDisabled}
        type="switch"
        label=""
        onChange={({ target }) => update([target.checked ? 'push' : null, emailChecked ? 'email' : null])}
      />
      <Form.Check
        className="ml-3"
        id={`${event}_email`}
        checked={!!emailChecked}
        disabled={emailDisabled}
        type="switch"
        label=""
        onChange={({ target }) => update([target.checked ? 'email' : null, pushChecked ? 'push' : null])}
      />
    </li>
  );
};
