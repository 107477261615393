import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from '../../theme';
import { IconItem, DropdownMenu } from '../../Table/RowDropdownAction';
import { TrxTooltip as Tooltip } from '../../Tooltip';
import { Filter } from './Filter';
import { useFilterMeasure } from './useFilterMeasure';
import { getActiveFilterset } from '../Filter.shared.utils';

const RIGHT_MENU_WIDTH = 33; // three dots + (n) more dropdown

const UnstyledFilterSets = ({ className, maxWidth, context, disabled = false, currectFilterApplied }) => {
  const { filterState, filterSets, filterController, isManager } = context;
  const measure = useFilterMeasure();
  const controller = filterController.filterSets;
  // const filterSets = filterState.get('filterSets');
  // const active = filterState.get('active');
  const active = getActiveFilterset(context);
  const hasChanges = filterState.get('hasChanges');
  // const activeFilterSetId = filterState.getIn(['values', 'filterSetId']);
  const [{ visibleFilters, hiddenFilters }, setFilters] = React.useState({
    visibleFilters: filterSets.take(1),
    hiddenFilters: filterSets.skip(1),
  });

  React.useEffect(() => {
    let width = 0;
    let max = 0;
    const x_more = measure('999 More');
    const widths = filterSets
      .map(fs => fs.get('name'))
      .toArray()
      .map(measure);
    const totalWidth = widths.reduce((total, width) => total + width, 0);

    if (totalWidth + RIGHT_MENU_WIDTH > maxWidth) {
      for (max = 0; max < filterSets.size; max += 1) {
        const next = widths[max];
        if (width + next + x_more > maxWidth) {
          break;
        }
        width += next;
      }
    } else {
      max = filterSets.size;
    }

    setFilters({
      visibleFilters: filterSets.take(max),
      hiddenFilters: filterSets.skip(max),
    });
  }, [maxWidth, measure, filterSets]);

  if (filterSets.size === 0) return <span className="d-flex mr-auto"></span>;

  return (
    <div data-testid="filter-sets-bar" className={cn(className, 'd-flex flex-row mr-auto align-items-center')}>
      <ul>
        {visibleFilters.map((filter, index) => (
          <Filter
            isActive={filter === active}
            index={index}
            key={filter.get('_id')}
            currectFilterApplied={currectFilterApplied}
            filter={filter}
            controller={controller}
            isManager={isManager}
            disabled={disabled}
            showEditedLabel={hasChanges && filter === active}
          />
        ))}
      </ul>
      <MoreFilters
        filters={hiddenFilters}
        active={active}
        controller={controller}
        isManager={isManager}
        disabled={disabled}
      />
      <div className="separator mb-3 ml-2">
        <Tooltip text="Manage Filter Sets" id="tt-filter-settings">
          <button
            disabled={disabled}
            className="btn btn-link p-2 mt-n1"
            onClick={() => controller.openChangeFilterOrderModal()}
          >
            <FontAwesomeIcon icon="gears" />
          </button>
        </Tooltip>
      </div>
    </div>
  );
};

export const FilterSets = styled(UnstyledFilterSets)`
  ul {
    display: flex;
    flex-direction: row;
    list-style: none;
    padding-left: 15px;
  }

  .separator {
    display: flex;
    align-items: start;
    height: 30px;
    padding-left: 6px;
    border-left: solid 1px ${colors.gray100};
  }

  button {
    height: 40px;
    color: ${colors.gray400};
    &:hover {
      background: ${colors.blue100};
      color: ${colors.blue300};
      border-radius: 8px;
    }
  }
`;

const MoreFiltersToggleButton = React.forwardRef(({ onClick, count, isActive = false, disabled = false }, ref) => (
  <ToggleButton
    className={cn('btn btn-lnk mb-3', { active: isActive })}
    ref={ref}
    onClick={e => {
      if (!disabled) {
        e.preventDefault();
        onClick(e);
      }
    }}
    disabled={disabled}
  >
    {count} More
  </ToggleButton>
));

function MoreFilters({ filters, active, controller, isManager, disabled = false }) {
  const onSelect = key => {
    const filter = filters.find(f => f.get('_id') === key);
    controller.selectFilter(active === filter ? null : filter);
  };

  if (filters.size === 0) return null;
  const isActive = filters.contains(active);

  const FilterLabel = ({ filter }) =>
    filter === active ? (
      <>
        {filter.get('name')}
        <FontAwesomeIcon className="ml-2" icon="check" />
      </>
    ) : (
      filter.get('name')
    );

  return (
    <Dropdown onSelect={onSelect}>
      <Dropdown.Toggle as={MoreFiltersToggleButton} isActive={isActive} count={filters.size} disabled={disabled} />
      <DropdownMenu>
        {filters.map(filter => {
          const icon = filter.get('sharedBy') || (isManager && filter.get('isShared')) ? 'user-group' : 'filter';
          return (
            <IconItem
              className={cn({ active: filter === active })}
              key={filter.get('_id')}
              icon={icon}
              label={<FilterLabel filter={filter} />}
              eventKey={filter.get('_id')}
            />
          );
        })}
      </DropdownMenu>
    </Dropdown>
  );
}

const ToggleButton = styled.span`
  cursor: pointer;
  padding: 5px 8px 5px 12px;
  margin-right: 2px;
  gap: 2px;

  //width: 111px;
  height: 40px;

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  flex-direction: row;
  align-items: center;
  letter-spacing: 0.2px;
  border: none;
  white-space: nowrap !important;
  color: ${props => (props.disabled ? colors.gray200 : colors.gray400)};
  background: ${colors.white};

  &:hover {
    cursor: ${props => props.disabled && 'auto'} !important;
    color: ${props => props.disabled && colors.gray200};
  }

  /* Active */
  &.active {
    background: ${colors.blue100};
    border-radius: 8px;
    color: ${colors.blue300};
  }
`;
