import React from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { useForm, Controller as FormController } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typeahead } from '@tradetrax/web-common';
import navigate from 'app/navigate';
import cn from 'classnames';
import * as yup from 'yup';

const assigneeSchema = yup.object().shape({
  _id: '',
  name: '',
  firstName: '',
  lastName: '',
});

const schema = yup.object().shape({
  builderAssignee: yup.array().of(assigneeSchema),
  customerManager: yup.array().of(assigneeSchema),
});

export function BuildersJobsTab({ homeownerDetails, controller, account }) {
  const builderUsers = account.get('usersActive');
  const { builderAssignee, customerManager } = homeownerDetails.toJS();
  const job = homeownerDetails.getIn(['jobs', 0]);
  const { control, errors } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      builderAssignee: builderAssignee ? [builderAssignee] : [],
      customerManager: customerManager ? [customerManager] : [],
    },
    resolver: yupResolver(schema),
  });

  const updateAssignee = assignee => {
    controller.updateBuilderAssignee(assignee);
    return assignee;
  };
  const updateCustomerManager = manager => {
    controller.updateCustomerManager(manager);
    return manager;
  };

  return (
    <div className="d-flex flex-column ml-4 mt-4 pt-2 w-50">
      <span className="font-size-14 font-weight-bold">Builders assigned</span>
      <Form.Group controlId="assignee" className="w-50 mt-3">
        <Form.Label>Builder Assignee</Form.Label>
        <FormController
          name="builderAssignee"
          control={control}
          render={({ onChange, onBlur, value }) => (
            <Typeahead
              id="assignee"
              placeholder="Choose Builder"
              icon="chevron-down"
              options={builderUsers.toJS()}
              isInvalid={!!errors.builderAssignee}
              labelKey={option => option.name ?? `${option.firstName} ${option.lastName}`}
              filterSelected={(option, selected) => selected._id !== option._id}
              onChange={option => onChange(updateAssignee(option))}
              onBlur={onBlur}
              selected={value}
            />
          )}
        />
        <Form.Control.Feedback type="invalid" className={cn({ 'd-inline': !!errors.builderAssignee })}>
          <FontAwesomeIcon icon="circle-exclamation" />
          {errors.builderAssignee && ` ${errors.builderAssignee.message}`}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="customerManager" className="w-50 mb-5">
        <Form.Label>Customer Manager</Form.Label>
        <FormController
          name="customerManager"
          control={control}
          render={({ onChange, onBlur, value }) => (
            <Typeahead
              id="manager"
              placeholder="Choose Manager"
              icon="chevron-down"
              options={builderUsers.toJS()}
              isInvalid={!!errors.customerManager}
              labelKey={option => option.name ?? `${option.firstName} ${option.lastName}`}
              filterSelected={(option, selected) => selected._id !== option._id}
              onChange={option => onChange(updateCustomerManager(option))}
              onBlur={onBlur}
              selected={value}
            />
          )}
        />
        <Form.Control.Feedback type="invalid" className={cn({ 'd-inline': !!errors.customerManager })}>
          <FontAwesomeIcon icon="circle-exclamation" />
          {errors.customerManager && ` ${errors.customerManager.message}`}
        </Form.Control.Feedback>
      </Form.Group>
      <span className="font-size-14 font-weight-bold my-3">Jobs assigned</span>
      <span>
        <Link className="main-link d-inline" to={navigate.to.JobDetails({ jobId: job.get('_id') }, false)}>
          <span className="font-size-14">{job.get('name')}</span>
        </Link>
      </span>
    </div>
  );
}
