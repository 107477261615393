import React from 'react';
import { SecondaryButton, CommunityHeader, UsersTags, ShowMoreUsersModal } from '@tradetrax/web-common';
import { useAppContext } from 'app/App.context';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function CommunityDetailsHeader({ communityContext, linkToOuttake }) {
  const { appState, modal } = useAppContext();
  const { state, controller } = communityContext;
  const { community } = state.toObject();
  const communityId = community.get('_id');

  const assignees = appState.getIn(['account', 'usersActive']);
  const users = appState.getIn(['account', 'users']);
  const schedulers = state
    .getIn(['users', 'builderUsersScheduler'])
    ?.map(scheduler => ({
      name: scheduler.get('name'),
      status: scheduler.get('status'),
      initials: scheduler.get('initials'),
    }))
    .sort();
  const supers = state
    .getIn(['users', 'builderUsersSuper'])
    ?.map(scheduler => ({
      name: scheduler.get('name'),
      status: scheduler.get('status'),
      initials: scheduler.get('initials'),
    }))
    .sort();

  const handleShowMoreUsers = () =>
    modal.open(ShowMoreUsersModal, { supers, schedulers, title: `Community: ${community.get('name')}` });

  return (
    <div className="d-flex">
      <CommunityHeader
        className="ml-4 px-1"
        community={community}
        controller={controller}
        assignees={assignees}
        users={users}
        domain="builders"
        loggedUserId={appState.getIn(['user', '_id'])}
        linkToOuttake={linkToOuttake}
      >
        <>
          <UsersTags
            className="mb-3 w-12rem"
            maxTags={2}
            title="Super Assigned"
            users={supers?.toArray().slice(0, 3) || []}
            onShowMore={handleShowMoreUsers}
          />
          <UsersTags
            className="mb-3 w-12rem"
            maxTags={2}
            title="Scheduler Assigned"
            users={schedulers?.toArray().slice(0, 3) || []}
            onShowMore={handleShowMoreUsers}
          />
        </>
      </CommunityHeader>
      <div className=" d-flex flex-column ml-auto mr-2">
        <ManageContainer className="mr-2 btn btn-link" onClick={linkToOuttake}>
          <FontAwesomeIcon icon="list-check" className="mr-2" />
          {`Manage Outtake`}
        </ManageContainer>
        <SecondaryButton className="d-inline-block text-center" onClick={() => controller.openNewJobModal(communityId)}>
          Create Job
        </SecondaryButton>
      </div>
    </div>
  );
}

const ManageContainer = styled.button`
  display: block;
  white-space: nowrap;
  padding-top: 0;
  margin-bottom: 15px;
`;
