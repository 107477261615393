import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from '@tradetrax/web-common';
import { IconButtonWrap } from '@tradetrax/web-common/lib/Button';
import { VIEWS_MAP } from './JobSchedule.views';

export function JobDropDownMenu({ toggleView, selectedView, showStages }) {
  const [isOpen, setIsOpen] = useState(false);
  const chevronIcon = isOpen ? 'chevron-up' : 'chevron-down';
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = option => {
    toggleView(option);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [dropdownRef, setIsOpen]);

  return (
    <div ref={dropdownRef} className="position-relative mr-3">
      <IconButtonWrap
        className={cn('btn', { 'active-on-hover': !isOpen, active: isOpen })}
        onClick={toggleDropdown}
        large
        activeWhiteTxtColor
      >
        <FontAwesomeIcon icon={selectedView.icon} className="mr-2" />
        <FontAwesomeIcon icon={chevronIcon} className="font-size-14" />
      </IconButtonWrap>
      {isOpen && (
        <DropdownContainer>
          {VIEWS_MAP.map((item, key) => {
            if (!showStages && item.id.startsWith('stages-')) return null;

            return (
              <DropDownOptionContainer
                key={item.id}
                item={item}
                onClick={() => handleOptionClick(item)}
                selectedOption={selectedView}
              />
            );
          })}
        </DropdownContainer>
      )}
    </div>
  );
}

const DropDownOptionContainer = ({ item, onClick, selectedOption }) => {
  let isChecked = item.radio && item.id === selectedOption.id;
  let isSelected = selectedOption === item && !item.radio;

  if (!isSelected && !item.radio && selectedOption.id.startsWith('stages-')) {
    isSelected = item.id.startsWith('stages-');
  }

  return (
    <DropDownOption onClick={onClick}>
      {item.radio ? (
        <input className="mx-2 cursor-pointer" type="radio" checked={isChecked} />
      ) : (
        <FontAwesomeIcon className="mr-2 font-size-14" icon={item.icon} />
      )}
      <span className="font-size-16 mr-2">{item.label}</span>
      {isSelected ? <FontAwesomeIcon className="ml-2 checkIcon font-size-14" icon="check" /> : null}
    </DropDownOption>
  );
};

const DropdownContainer = styled.div`
  background-color: ${colors.white};
  border: 2px solid ${colors.gray};
  border-radius: 10px;
  position: absolute;
  top: 42px;
  right: 0px;
  z-index: 2;
`;

const DropDownOption = styled.div`
  box-sizing: border-box;
  border-bottom: 1px solid ${colors.gray100};
  cursor: pointer;
  white-space: nowrap !important;
  color: ${colors.gray400};
  padding: 10px 10px 10px 15px;

  &:hover {
    color: ${colors.blue300};
    background-color: ${colors.blue100};
  }

  .checkIcon {
    color: ${colors.blue300};
  }
`;
