import React from 'react';
import cn from 'classnames';
import styled from 'styled-components';

export function SelectAllCheckbox({ selectedTasks, controller, show, disabled = false }) {
  if (!show) return 'Task Name';
  const isAnyChecked = selectedTasks.get('taskIds').size > 0;
  return (
    <Checkbox
      className={cn('checkbox-button', {
        'square-check': !!selectedTasks.get('notIncludeTaskIds').size || isAnyChecked,
      })}
    >
      <input
        disabled={disabled}
        type="checkbox"
        className="mr-2 checkbox-button__input"
        checked={selectedTasks.get('selectAll') || isAnyChecked}
        onChange={({ target }) => controller.toggleSelectAll(target.checked)}
      />
      <span className="checkbox-button__control"></span>
      Task Name
    </Checkbox>
  );
}

const Checkbox = styled.label`
  display: flex;
  align-items: center;

  &.square-check {
    input {
      font-size: 1rem;
      line-height: 1.5;
      padding: 11px 23px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 0;
      outline: 0;
      background-color: transparent;
    }

    .checkbox-button__input {
      opacity: 0;
      position: absolute;
    }

    .checkbox-button__control {
      position: relative;
      display: inline-block;
      width: 17px;
      height: 17px;
      margin-right: 4px;
      vertical-align: middle;
      background-color: inherit;
      border: 1.5px solid #767676;
      border-radius: 3px;
      right: 2px;
    }

    .checkbox-button__input:checked + .checkbox-button__control:after {
      content: '';
      display: block;
      position: absolute;
      top: 2px;
      left: 2px;
      width: 10px;
      height: 10px;
      background-color: #0275ff;
    }

    .checkbox-button__input:checked + .checkbox-button__control {
      border-color: #0275ff;
    }

    .checkbox-button__control {
      transform: scale(0.75);
    }
  }
`;
