import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Paths } from 'app/navigate';
import { ToDoPage } from './ToDoPage';
import { NotFound } from '@tradetrax/web-common';

export const ToDoRoutes = () => {
  return (
    <Switch>
      <Route path={Paths.ToDo} component={ToDoPage} exact />
      <Route component={NotFound} />
    </Switch>
  );
};
