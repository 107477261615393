import moment from 'moment';
import { fromJS } from 'immutable';
import { markAsSideEffect, markAsSync } from '../useController';
import { emitEvent } from '../useEvents';
import { throttle } from 'lodash';

// this should be a context method.
export function invalidateCounters(counters) {
  if (counters) return this.controller.invalidateCounters(counters);
}

export function getTaskCounterActions(service) {
  markAsSideEffect(readTaskCounters);
  function readTaskCounters() {
    const prev = this.state.get('counters');
    return service.readTaskCounters({}, { query: { date: moment().format('YYYY-MM-DD') } }).then(({ taskCounters }) => {
      const next = fromJS(taskCounters);
      this.controller.dispatch([state => state.set('counters', next)]);
      emitEvent('task-counters:update', next);
      return {
        prev,
        next,
      };
    });
  }

  markAsSync(invalidateCounters);
  function invalidateCounters(state, counters) {
    return state.update('counters', current => {
      const next = current.merge(counters);
      const {
        overdueCount,
        requestCount,
        toDoEndCount,
        toDoStartCount,
        toDoUpdateCount = 0,
        startDateConfirmationRequestCount = 0,
      } = next.toObject();

      const toDo = toDoEndCount + toDoStartCount + toDoUpdateCount + startDateConfirmationRequestCount;
      const dashboard = toDo + overdueCount + requestCount;

      return next.merge({ toDo, dashboard });
    });
  }

  return {
    readTaskCounters,
    invalidateCounters,
  };
}
