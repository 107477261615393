import React from 'react';
import cn from 'classnames';
import * as yup from 'yup';
import { Modal, Form } from 'react-bootstrap';
import { useForm, Controller as FormController } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typeahead, PrimaryButton } from '@tradetrax/web-common';
import { useAppContext } from 'app/App.context';

const emptyState = {
  name: '',
  lastName: '',
  email: '',
  phone: '',
  job: [],
  builderAssigneeId: '',
  customerManagerId: '',
};

export const AddHomeownerModal = ({ accept, close, jobs, checkValidEmail }) => {
  const { account } = useAppContext();
  const builderUsers = account.get('usersActive');
  const { control, register, handleSubmit, watch, setError, errors } = useForm({
    mode: 'onChange',
    defaultValues: emptyState,
    resolver: yupResolver(schema),
    shouldFocusError: true,
  });
  const onSubmit = form => accept({ form });
  const { name, lastName, email, phone } = watch();

  return (
    <Modal show={true} onHide={close} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Add Homeowner</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <Form.Group controlId="first-name">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              size="lg"
              placeholder="Ex. Elijah"
              ref={register}
              isValid={name && !errors.name}
              isInvalid={!!errors.name}
            />
            <Form.Control.Feedback type="valid">
              <FontAwesomeIcon icon="circle-check" /> First Name is valid
            </Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              <FontAwesomeIcon icon="circle-exclamation" />
              {errors.name && ` ${errors.name.message}`}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              name="lastName"
              size="lg"
              placeholder="Ex. Thompson"
              ref={register}
              isValid={lastName && !errors.lastName}
              isInvalid={!!errors.lastName}
            />
            <Form.Control.Feedback type="valid">
              <FontAwesomeIcon icon="circle-check" /> Last Name is valid
            </Form.Control.Feedback>
            <Form.Control.Feedback type="invalid" className={cn({ 'd-inline': !!errors.lastName })}>
              <FontAwesomeIcon icon="circle-exclamation" />
              {errors.lastName && ` ${errors.lastName.message}`}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="text"
              name="email"
              size="lg"
              placeholder="Ex. username@email.com"
              ref={register}
              isValid={email && !errors.email}
              isInvalid={!!errors.email}
              onBlur={({ target }) => {
                const { value } = target;
                value && checkValidEmail(value, setError);
              }}
            />
            <Form.Control.Feedback type="valid">
              <FontAwesomeIcon icon="circle-check" /> Email is valid
            </Form.Control.Feedback>
            <Form.Control.Feedback type="invalid" className={cn({ 'd-inline': !!errors.email })}>
              <FontAwesomeIcon icon="circle-exclamation" />
              {errors.email && ` ${errors.email.message}`}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Phone</Form.Label>
            <Form.Control
              type="text"
              name="phone"
              size="lg"
              placeholder="Ex. 602 555 7890"
              ref={register}
              isValid={phone && !errors.phone}
              isInvalid={!!errors.phone}
            />
            <span className={cn('text-gray-400', { 'd-none': !!errors.phone || (phone && !errors.phone) })}>
              * Optional
            </span>
            <Form.Control.Feedback type="valid">
              <FontAwesomeIcon icon="circle-check" /> Phone is valid
            </Form.Control.Feedback>
            <Form.Control.Feedback type="invalid" className={cn({ 'd-inline': !!errors.phone })}>
              <FontAwesomeIcon icon="circle-exclamation" />
              {errors.phone && ` ${errors.phone.message}`}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="job">
            <Form.Label>Job</Form.Label>
            <FormController
              name="job"
              control={control}
              render={({ onChange, onBlur, value }) => (
                <Typeahead
                  id="job"
                  placeholder="Choose Job"
                  icon="magnifying-glass"
                  options={jobs}
                  isInvalid={!!errors.job}
                  labelKey={option => option.name}
                  filterSelected={(option, selected) => selected._id !== option._id}
                  onChange={onChange}
                  onBlur={onBlur}
                  selected={value}
                />
              )}
            />
            <Form.Control.Feedback type="invalid" className={cn({ 'd-inline': !!errors.job })}>
              <FontAwesomeIcon icon="circle-exclamation" />
              {errors.job && ` ${errors.job.message}`}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="builderAssignee">
            <Form.Label>Builder Assignee</Form.Label>
            <FormController
              name="builderAssigneeId"
              control={control}
              render={({ onChange, onBlur, value }) => (
                <Typeahead
                  id="assignee"
                  placeholder="Choose Builder"
                  icon="chevron-down"
                  options={builderUsers.toJS()}
                  isInvalid={!!errors.builderAssigneeId}
                  labelKey={option => `${option.firstName} ${option.lastName}`}
                  filterSelected={(option, selected) => selected._id !== option._id}
                  onChange={onChange}
                  onBlur={onBlur}
                  selected={value}
                />
              )}
            />
            <Form.Control.Feedback type="invalid" className={cn({ 'd-inline': !!errors.builderAssigneeId })}>
              <FontAwesomeIcon icon="circle-exclamation" />
              {errors.builderAssigneeId && ` ${errors.builderAssigneeId.message}`}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="customerManager" className="mb-5">
            <Form.Label>Customer Manager</Form.Label>
            <FormController
              name="customerManagerId"
              control={control}
              render={({ onChange, onBlur, value }) => (
                <Typeahead
                  id="manager"
                  placeholder="Choose Manager"
                  icon="chevron-down"
                  options={builderUsers.toJS()}
                  isInvalid={!!errors.customerManagerId}
                  labelKey={option => `${option.firstName} ${option.lastName}`}
                  filterSelected={(option, selected) => selected._id !== option._id}
                  onChange={onChange}
                  onBlur={onBlur}
                  selected={value}
                />
              )}
            />
            <Form.Control.Feedback type="invalid" className={cn({ 'd-inline': !!errors.customerManagerId })}>
              <FontAwesomeIcon icon="circle-exclamation" />
              {errors.customerManagerId && ` ${errors.customerManagerId.message}`}
            </Form.Control.Feedback>
          </Form.Group>
          <span>To send the invitation, go to Homeowner Details.</span>
        </Modal.Body>
        <Modal.Footer>
          <PrimaryButton type="submit" className="ml-4">
            Add Homeowner
          </PrimaryButton>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

const emptyStringToNull = (value, originalValue) =>
  typeof originalValue === 'string' && originalValue === '' ? null : value;

const getId = (value, originalValue) =>
  typeof originalValue === 'object' && originalValue.length === 1 ? originalValue[0]._id : value;

const schema = yup.object().shape({
  name: yup
    .string()
    .required('First Name is required')
    .matches(/^[^+÷=<>≠/!?#$%{}[\]0-9]*$/, 'Character not allowed')
    .min(1, 'Min. 1 character')
    .max(30, 'Max. 30 characters'),
  lastName: yup
    .string()
    .required('Last Name is required')
    .matches(/^[^+÷=<>≠/!?#$%{}[\]0-9]*$/, 'Character not allowed')
    .min(1, 'Min. 1 character')
    .max(30, 'Max. 30 characters'),
  email: yup
    .string()
    .email('Invalid email address')
    .required('Email is required'),
  phone: yup
    .string()
    .matches(/^\+?[1-9]\d{9,14}$/, 'Invalid phone format')
    .transform(emptyStringToNull)
    .nullable(),
  job: yup
    .array()
    .required('Job is required')
    .of(
      yup.object().shape({
        name: yup.string().required('Job is required'),
      })
    ),
  builderAssigneeId: yup
    .string()
    .transform(getId)
    .required('Builder Assignee is required'),
  customerManagerId: yup
    .string()
    .transform(getId)
    .required('Builder Assignee is required'),
});
