import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Breadcrumb } from 'react-bootstrap';
import { ToggleButtons, ToggleButton } from '@tradetrax/web-common';
import { CommunityDetailsContext } from './CommunityDetailsContext';
import { CommunityDetailsHeader } from './CommunityDetailsHeader';
import { CommunityJobs } from './CommunityJobs';
import navigate, { Paths } from 'app/navigate';

export function CommunityDetails({ match }) {
  const { communityId } = match.params;
  const communityContext = CommunityDetailsContext(communityId);
  const { state, controller } = communityContext;
  const { community, jobs, totalCount } = state.toObject();

  if (!state.get('hasPermission')) return <Redirect to={Paths.NotPermission} />;
  const linkToOuttake = () => navigate.from.CommunityDetails.to.CommunityOuttake({ communityId });

  return (
    <>
      <Breadcrumb className="mx-4 mt-4" tag="nav">
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: Paths.Community }}>
          Communities
        </Breadcrumb.Item>
        <Breadcrumb.Item active>{community.get('name')}</Breadcrumb.Item>
      </Breadcrumb>
      <CommunityDetailsHeader communityContext={communityContext} linkToOuttake={linkToOuttake} />
      <ToggleButtons className="mb-2 mr-auto" type="radio" name="options" defaultValue={1}>
        <ToggleButton value={1}>{totalCount} jobs</ToggleButton>
        {/* <ToggleButton value={2} disabled>
          Subs
        </ToggleButton>
        <ToggleButton value={3} disabled>
          Templates
        </ToggleButton> */}
      </ToggleButtons>
      <CommunityJobs controller={controller} jobs={jobs} totalCount={totalCount} communityId={communityId} />
    </>
  );
}
