import React from 'react';
import { fromJS, Map } from 'immutable';
import { useController } from '@tradetrax/web-common';
import { useAppContext } from '@tradetrax/web-common/lib/globalAppContext';
import * as actions from './ConfirmationActions';

export const ENUM_TIME_COMPLETION = {
  finish: 'Finishes',
  start: 'Starts',
};

const emptyState = fromJS({
  expanded: null,
  globalTasks: [],
  selectedTaskTypes: Map({ isLoading: true }),
});

export function ConfirmationsContext() {
  const appContext = useAppContext();
  const globalTasks = appContext.appState.get('gtl');
  const [state, controller] = useController(actions, emptyState, appContext);

  React.useEffect(() => {
    controller.readTasks();
  }, [controller]);

  return {
    controller,
    state: state.set('globalTasks', globalTasks),
  };
}
