import React from 'react';

const FILTER_TAG = 52;

export function useFilterMeasure() {
  const spanRef = React.useRef();
  const measure = React.useCallback(
    text => {
      spanRef.current.innerText = text;
      return spanRef.current.clientWidth + FILTER_TAG;
    },
    [spanRef]
  );

  React.useEffect(() => {
    const span = global.document.createElement('span');
    spanRef.current = span;

    span.style.fontFamily = 'Open Sans';
    span.style.fontStyle = 'normal';
    span.style.fontSize = '14px';
    span.style.fontWeight = 400;
    span.style.lineHeight = '19px';
    span.style.display = 'inline-block';
    span.style.letterSpacing = '0.2px';
    span.style.border = 'none';
    span.style.whiteSpace = 'nowrap';
    span.style.visibility = 'hidden';
    span.innerText = 'Testing the span measurer';

    global.document.body.appendChild(span);

    return () => {
      global.document.body.removeChild(span);
    };
  }, [spanRef]);

  return measure;
}
