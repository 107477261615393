import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typeahead, Menu, MenuItem, Hint, Input } from 'react-bootstrap-typeahead';
import styled from 'styled-components';
import cn from 'classnames';
import { colors } from '../../theme';

const RootCauseTasksFilter = (option, props) => {
  const { text } = props;
  // by default show the previous 3 tasks, current and 3 subsequent, which are marked as `shoAsDefault`
  if (text.trim() === '') return option.showAsDefault;

  // otherwise use all job tasks to filter by the `text` criteria.
  return (
    props
      .labelKey(option)
      .toLowerCase()
      .indexOf(text.trim().toLowerCase()) !== -1
  );
};

export const RootCauseTypeAhead = ({ kind, jobTasks, selectTask, className = '', ...props }) => {
  const id = `${kind}-attribute-to-typeahead`;
  const [selected, setSelected] = React.useState([]);
  let placeholder = 'Search Task within this Job';
  const emptyLabel = 'No Matches Found';

  if (kind === 'builder') placeholder += ' (Optional)';

  return (
    <Typeahead
      {...props}
      id={id}
      name={id}
      bsSize="lg"
      className={cn(className, 'mr-auto w-100 ml-5')}
      placeholder={placeholder}
      options={jobTasks}
      selected={selected}
      filterBy={RootCauseTasksFilter}
      emptyLabel={emptyLabel}
      renderInput={renderInput}
      labelKey={option => option?.name || ''}
      renderMenu={renderMenu}
      clearButton
      onChange={([task]) => {
        if (typeof task === 'undefined') {
          setSelected([]);
        } else {
          setSelected([task]);
        }
        selectTask(task?.id);
      }}
    >
      <FontAwesomeIcon icon="magnifying-glass" hidden={selected.length > 0} />
    </Typeahead>
  );
};

export const renderMenu = (tasks, menuProps) => (
  <Menu {...menuProps} maxHeight="310px">
    {tasks.map((task, index) => (
      <StyledMenuItem disabled={task.orderLabel === 'current'} option={task} position={index} key={task.id}>
        <MenuItemLabel task={task} />
      </StyledMenuItem>
    ))}
  </Menu>
);

const StyledMenuItem = styled(MenuItem)`
  height: 60px !important;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${colors.gray100};

  &.disabled {
    color: #c1c6cf;
  }

  a.dropdown-item {
    height: 100%;
    display: flex;
    align-items: center;
  }
`;

const MenuItemLabel = ({ task }) => {
  const label = task.orderLabel === 'current' ? 'Current Task' : task.orderLabel;
  return (
    <div className="d-flex flex-column w-100">
      <div className="d-flex justify-content-between w-100">
        <span className="d-inline-block text-truncate w-75">{task.name}</span>
        <span className="font-size-12 mt-1 text-capitalize">{label}</span>
      </div>
    </div>
  );
};

const renderInput = ({ inputRef, referenceElementRef, ...inputProps }) => (
  <Hint shouldSelect={(shouldSelect, e) => e.key === 'Enter'}>
    <Input
      {...inputProps}
      className="form-control rbt-input rounded"
      ref={node => {
        inputRef(node);
        referenceElementRef(node);
      }}
    />
  </Hint>
);
