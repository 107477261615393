import React from 'react';
import styled from 'styled-components';
import { colors } from '../theme';
import cn from 'classnames';
import { MilestoneCircleInProgress } from './MilestoneCircleInProgress';
import { MilestoneCompleted } from './MilestoneCompleted';
import { MilestoneNotStarted } from './MilestoneNotStarted';
import { MilestoneRhombusInProgress } from './MilestoneRhombusInProgress';

export const MilestonType = ({
  milestone,
  width,
  addProgressBarSection,
  milestones,
  isNextMilestoneFromRef,
  statusMilestoneRef,
  widthFillBar,
  isHomeowner,
  isFirstMilestone,
  isLastMilestone,
}) => {
  const { status } = milestone.toObject();
  const isJobCompleted = isMilestonesCompleted(milestones);
  const isMobileSize = window.innerWidth <= 1199;

  const inProgressMilestone = isHomeowner ? (
    <MilestoneCircleInProgress
      milestone={milestone}
      isFirstMilestone={isFirstMilestone}
      isLastMilestone={isLastMilestone}
      isHomeowner={isHomeowner}
    />
  ) : (
    <MilestoneRhombusInProgress
      milestone={milestone}
      isFirstMilestone={isFirstMilestone}
      isLastMilestone={isLastMilestone}
      isHomeowner={isHomeowner}
    />
  );
  return (
    <MilestoneContainer data-testid="milestone-container">
      {status === 'completed' && (
        <MilestoneCompleted
          isJobCompleted={isJobCompleted}
          milestone={milestone}
          isHomeowner={isHomeowner}
          isFirstMilestone={isFirstMilestone}
          isLastMilestone={isLastMilestone}
          showMilestoneData={!isMobileSize}
        />
      )}
      {status === 'not-started' && (
        <MilestoneNotStarted
          isNextMilestoneFromRef={isNextMilestoneFromRef}
          statusMilestoneRef={statusMilestoneRef}
          milestone={milestone}
          isHomeowner={isHomeowner}
          isFirstMilestone={isFirstMilestone}
          isLastMilestone={isLastMilestone}
          showMilestoneData={!isMobileSize}
        />
      )}
      {status === 'in-progress' && inProgressMilestone}
      {addProgressBarSection && (
        <ProgressBarItem
          width={width}
          className={cn({ 'ml-5': status === 'in-progress' && isHomeowner })}
          status={status}
          isJobCompleted={isJobCompleted}
          widthFillBar={widthFillBar}
          isHomeowner={isHomeowner}
        />
      )}
    </MilestoneContainer>
  );
};

const MilestoneContainer = styled.div`
  display: flex;
  position: relative;
`;

const ProgressBarItem = styled.div`
  position: absolute;
  ${props => `
  background-color: ${props.isHomeowner ? colors.blue100 : colors.gray500};
  top: calc(50% - ${props.isHomeowner ? '4px' : '2px'});
  height: ${props.isHomeowner ? '8px' : '4px'};
  left:${props.status === 'in-progress' ? (props.isHomeowner ? '42px' : '15px') : props.isHomeowner ? '18px' : '12px'};
  width:${props.width + 3 + 'px'};
  &::after{
    content:' ';
    position:absolute;
    left:-1px;
    top: calc(50% - ${props.isHomeowner ? '4px' : '2px'});
    height: ${props.isHomeowner ? '8px' : '4px'};
    background-color: ${props.isJobCompleted ? colors.green300 : colors.blue300};
    border-top-right-radius: 8px;
    border-bottom-right-radius:8px;
    width:${props.widthFillBar};
  }
`};
`;

export const isMilestonesCompleted = milestones => {
  return milestones.every(milestone => milestone.get('status') === 'completed');
};
