import React from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import { colors } from '../../theme';
import { getAssigneeBadgeClassName } from '../../utils';
import { RecentActivityItem } from './Events';
import { LoadingPlaceHolder } from './LoadingPlaceholder';

export function RecentActivityList({ recentActivities, showCarousel, appState, containerRef, linkToTask, linkToJob }) {
  const getUserBadgeClassName = getAssigneeType(appState.get('user'));
  return (
    <div style={{ overflow: 'scroll' }}>
      <Container ref={containerRef} fluid="lg" className="mr-auto">
        <StyledList>
          {recentActivities.map((activityGroup, index) => (
            <div key={`group-${index}`}>
              <span className="text-muted">{activityGroup.get(0)}</span>
              {activityGroup.get(1).map((activity, index) => {
                if (activity.get('group') === 'loading') return <LoadingPlaceHolder key={`loading-${index}`} />;
                return (
                  <RecentActivityItem
                    key={`item-${index}`}
                    activity={activity}
                    linkToTask={linkToTask}
                    linkToJob={linkToJob}
                    assigneeClassName={getUserBadgeClassName(activity.get('createdBy'))}
                    showCarousel={showCarousel}
                    user={appState.get('user')}
                  />
                );
              })}
            </div>
          ))}
        </StyledList>
      </Container>
    </div>
  );
}

const getAssigneeType = loggedUser => assignee =>
  getAssigneeBadgeClassName(
    assignee.get('_id'),
    loggedUser.get('accountId'),
    loggedUser.get('_id'),
    assignee.get('accountId')
  );

const StyledList = styled.ul`
  overflow-y: visible;
  overflow-x: hidden;
  list-style-type: none;
  padding: 0 0 !important;
  display: flex;
  align-self: stretch;
  flex-direction: column;
  position: relative;
  color: ${colors.gray400};
  font-weight: bold;
  padding-left: 1.25rem;
  li {
    transition: opacity 0.6s linear;
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    align-self: stretch;
    letter-spacing: 0.2px;
    position: relative;
    padding-right: 1.25rem;
    // margin-bottom: 1.25rem;
    color: ${colors.gray400};
    font-weight: normal;

    .trx-time {
      font-size: 12px;
      line-height: 16px;
      color: ${colors.gray400};
      padding-bottom: 0.5rem;
    }

    &:not(:last-child) {
      border-bottom: solid 1px ${colors.gray100};
    }
  }
`;
