import React from 'react';
import { Tasks } from './Tasks';
import { ConfirmationsContext } from './ConfirmationsContext';
import { SearchGTL } from '@tradetrax/web-common/lib/Inputs/SearchGTL';

export function Confirmations() {
  const confirmationContext = ConfirmationsContext();

  const { state, controller } = confirmationContext;
  const selectedTaskTypes = state.get('selectedTaskTypes');
  const addTask = task => {
    controller.addTask(task);
    controller.expand(
      task
        .get('name')
        .toLowerCase()
        .replace(/\s/g, '-')
    );
  };

  return (
    <div className="d-flex flex-column" style={{ maxWidth: '700px' }}>
      <p className="font-size-14">
        Add Tasks you want Trades to confirm their Start Date. This setting will be applied as default when creating a
        new Template.
      </p>
      <SearchGTL selectedTasks={selectedTaskTypes} addTask={addTask} state={state} />
      <Tasks confirmationContext={confirmationContext} />
    </div>
  );
}
