import React, { useState } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import * as yup from 'yup';
import { Modal, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { PrimaryButton } from '@tradetrax/web-common';
import { plural } from '@tradetrax/web-common/lib/utils';
import { TasksGraph } from '@tradetrax/tasks-util';
import { JobStatus } from '@tradetrax/job-util';

const schema = yup.object().shape({
  targetCycleTime: yup
    .number()
    .typeError('#')
    .notRequired()
    .min(1, '1')
    .max(9999, '9999'),
});

const getTasksGraph = state => {
  const { job, tasks } = state.toObject();
  const tasksGraph = new TasksGraph(tasks.toJS(), job.toJS());
  return tasksGraph;
};

export function TargetCycleTimeModal({ job, status, state, onSubmit, cancel, accept }) {
  const TCT = job.getIn(['statusData', 'targetCycleTime']);
  const [targetCycleTimeValue, setTargetCycleTimeValue] = useState(TCT ? TCT : 1);
  const [inputTCT, setInputTCT] = useState(TCT ? TCT : 1);

  const tasksGraph = getTasksGraph(state);
  const startDate = tasksGraph.getStartDate();
  const endDate = tasksGraph.getEndDate();
  const tempJob = job
    .set('startDate', startDate)
    .set('expectedFinishDate', endDate)
    .setIn(['statusData', 'targetCycleTime'], targetCycleTimeValue);
  const { statusData } = JobStatus.changeStatus(tempJob.toJS(), status);
  const { originalExpectedCycleTime } = statusData;

  const [disableInputTCT, setDisableInputTCT] = useState(false);
  const { register, handleSubmit, errors, watch } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      targetCycleTime: TCT > 0 ? TCT : 1,
    },
  });
  const target = watch('target', inputTCT);

  const formSubmit = () => {
    accept({ targetCycleTimeValue });
  };
  const statusLabel = status === 'completed' ? 'Completed' : 'In-Progress';

  return (
    <ModalContainer show={true} onHide={cancel}>
      <Modal.Header closeButton>
        <Modal.Title>
          <FontAwesomeIcon icon="circle-exclamation" className="text-danger" />
          Set Target Cycle Time
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(formSubmit)}>
        <Modal.Body>
          <p>In order to move this Job to "{statusLabel}", first set the Target Cycle Time.</p>

          <div className="mt-4">
            <Form.Group>
              <Form.Check className="d-flex align-items-start  pl-2 mt-4">
                <input
                  type="radio"
                  name="modalOption"
                  ref={register}
                  id="optionECT"
                  onChange={() => {
                    setTargetCycleTimeValue(originalExpectedCycleTime);
                    setDisableInputTCT(true);
                  }}
                />
                <label className="d-flex flex-column mb-0 ml-2 font-size-14" htmlFor="optionECT">
                  <span style={{ lineHeight: '13px' }}>{`${originalExpectedCycleTime} ${plural(
                    originalExpectedCycleTime,
                    'day',
                    'days'
                  )}`}</span>
                  <span className="font-size-14 text-muted">(same as Original Expected Cycle Time)</span>
                </label>
              </Form.Check>

              <Form.Check className="d-flex align-items-start mt-4 pl-2">
                <input
                  className="mt-1"
                  id="optionTCT"
                  type="radio"
                  name="modalOption"
                  ref={register}
                  onChange={() => {
                    setTargetCycleTimeValue(inputTCT);
                    setDisableInputTCT(false);
                  }}
                  defaultChecked
                />
                <label className="text-capitalize ml-2 font-size-14" htmlFor="optionTCT">
                  <InputTargetCycleTime
                    errors={errors}
                    target={target}
                    register={register}
                    setInput={setInputTCT}
                    setTCTvalue={setTargetCycleTimeValue}
                    show={disableInputTCT}
                  />
                </label>
              </Form.Check>
            </Form.Group>
          </div>

          <Modal.Footer className=" d-flex p-0  mt-2 mb-4">
            <PrimaryButton variant="secondary" onClick={() => cancel()}>
              Cancel
            </PrimaryButton>
            <PrimaryButton type="submit">Confirm Target Cycle Time</PrimaryButton>
          </Modal.Footer>
        </Modal.Body>
      </Form>
    </ModalContainer>
  );
}

const ModalContainer = styled(Modal)`
  padding-top: calc((100vh / 2) - 300px);
`;

const TargetInput = styled(Form.Control)`
  width: 100px;
  background: none !important;
  padding-right: 0 !important;
`;

const InputTargetCycleTime = ({ register, errors, setInput, setTCTvalue, target, show }) => {
  const icon = errors.targetCycleTime && errors.targetCycleTime.type === 'min' ? faArrowUp : faArrowDown;
  return (
    <>
      <span className="d-block mb-1">Target Cycle Time</span>
      <div className="d-flex flex-row align-items-center">
        <TargetInput
          size="lg"
          name="targetCycleTime"
          type="number"
          ref={register}
          placeholder="1"
          isInvalid={errors.target}
          onChange={e => {
            const value = e.target.value;
            setInput(value);
            setTCTvalue(value);
          }}
          disabled={show}
        />
        <span className="font-size-14 ml-2">{plural(parseInt(target), 'Day', 'Days')}</span>
      </div>
      <Form.Control.Feedback type="invalid" className={cn({ 'd-inline': !!errors.targetCycleTime })}>
        <FontAwesomeIcon icon="circle-exclamation" />
        {` `}
        <FontAwesomeIcon icon={icon} />
        {errors.targetCycleTime && ` ${errors.targetCycleTime.message}`}
      </Form.Control.Feedback>
    </>
  );
};
//
