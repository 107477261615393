import moment from 'moment';
import { fromJS } from 'immutable';
import { markAsSync } from '@tradetrax/web-common/lib/useController';
import { buildersService } from 'services';

// My Tasks actions
markAsSync(updateNotificationEventsSettings);
export function updateNotificationEventsSettings(state, onlyChannels, customRole) {
  const channels = (onlyChannels || []).filter(channel => !!channel);
  const roleId = customRole.get('_id');
  const notifications = {
    notificationsChannels: channels,
  };

  buildersService.updateRole({ notifications }, { params: { roleId } }).catch(error => {
    this.controller.dispatch([
      state =>
        state.setIn(
          ['customRole', 'settings', 'notifications', 'notificationsChannels'],
          customRole.getIn(['settings', 'notifications', 'notificationsChannels'])
        ),
    ]);
  });

  return state.setIn(['customRole', 'settings', 'notifications', 'notificationsChannels'], fromJS(channels));
}

markAsSync(updateNotificationEvents);
export function updateNotificationEvents(state, customRole, event, { onlyChannels, notice_window, noticeTime }) {
  const roleId = customRole.get('_id');
  const eventState = customRole.getIn(['settings', 'notifications', event]);
  let payload = eventState;

  if (event === 'date-change') {
    const current = eventState.get('notice_window') || 7;
    payload = payload.set('notice_window', notice_window || current);
  } else if (event === 'task-missed-check-in' || event === 'task-missed-check-out') {
    const current = eventState.get('noticeTime') || 14;
    const selectedTime = noticeTime && moment(noticeTime, 'hha').hours();
    payload = payload.set('noticeTime', selectedTime || current);
  } else if (event === 'day-wrap-up') {
    const current = eventState.get('noticeTime') || 16;
    const selectedTime = noticeTime && moment(noticeTime, 'hha').hours();
    payload = payload.set('noticeTime', selectedTime || current);
  }
  if (onlyChannels) {
    payload = payload.set(
      'onlyChannels',
      onlyChannels.filter(channel => !!channel)
    );
  }
  buildersService.updateRole({ notifications: { [event]: payload.toJS() } }, { params: { roleId } }).catch(error => {
    this.controller.dispatch([state => state.setIn(['customRole', 'settings', 'notifications', event], eventState)]);
  });

  return state.setIn(['customRole', 'settings', 'notifications', event], payload);
}
