import React from 'react';
import { WeatherIndicator } from '../WeatherIndicator';
import { Item, Name, dateField, isBuilder, SystemItem } from './common';

export default ({ item, account, user, isInactive }) => {
  const isSystemItem = !item.get('createdByAccountId');
  const isLoggedBuilder = isBuilder(account);
  const Wrapper = isSystemItem ? SystemItem : Item;

  return (
    <Wrapper item={item} user={user}>
      {isSystemItem ? (
        <span className="text-dark">TradeTrax</span>
      ) : (
        <Name item={item} account={account} user={user} isInactive={isInactive} />
      )}
      {` ${isLoggedBuilder ? 'proposed' : 'sent'} exp. finish to ${dateField(item, 'newEndDate')}`}
      {!isLoggedBuilder && ` to ${item.getIn(['data', 'builderAccountName'])}`}
      <WeatherIndicator item={item} />
    </Wrapper>
  );
};
