import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Communities } from './Communities';
import { CommunityDetails } from './CommunityDetails/CommunityDetails';
import { Paths } from 'app/navigate';
import { NotFound } from '@tradetrax/web-common';

export function CommunityRoutes() {
  return (
    <Switch>
      <Route exact path={Paths.CommunityDetails} component={CommunityDetails} />
      <Route exact path={Paths.Community} component={Communities} />
      <Route component={NotFound} />
    </Switch>
  );
}
