import React from 'react';
import { plural } from '@tradetrax/web-common/lib/utils';

export const dayHeaderRender = (totalTasksOnDay, isUpcoming) => ({ text, date }) => {
  if (text.length === 3) return text; // length 3 is for  week and month views
  const currentDate = new Date();
  const dateLabel = isUpcoming && date > currentDate ? 'Upcoming' : !isUpcoming ? 'Open' : '';

  return (
    <div className="d-flex flex-column align-content-start">
      <span className="d-inline-flex mb-1">{text}</span>
      <span
        className="h6 font-weight-bold text-dark"
        style={{ textTransform: 'none' }}
      >{` ${totalTasksOnDay} ${dateLabel} ${plural(totalTasksOnDay, 'Task')} this day`}</span>
    </div>
  );
};

export const dayCellRender = ({ date, view, isToday }) => {
  if (view.type === 'dayGridDay') return { html: '' };
  let classNames = 'font-weight-bold font-size-12 ml-1 my-1';
  if (isToday) classNames += ' badge rounded-pill bg-primary text-light d-inline-block mb-n1';
  else classNames += ' text-dark';

  return <span className={classNames}>{date.getDate()}</span>;
};

export const moreLinkRender = ({ num }) => <span className="btn btn-link px-0">{`${num} more`}</span>;
