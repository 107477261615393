import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DropdownMenu } from '../Table/RowDropdownAction';
import { colors } from '../theme';
import { getActiveFilterset } from './Filter.shared.utils';

export const FilterSetSection = ({ filterContext }) => {
  const { filterState, filterController } = filterContext;
  const controller = filterController.filterSets;
  const activeFilterSet = getActiveFilterset(filterContext);
  const { isFiltering } = filterContext;
  // const hasActive = !!activeFilterSet && !activeFilterSet?.get('sharedBy');
  // TODO: I don't understan `hasActive` logic.
  const hasActive = !!activeFilterSet && !activeFilterSet?.get('sharedBy');
  const isShared = activeFilterSet?.get('isShared') || activeFilterSet?.get('sharedBy');

  const showEditedLabel = filterState.get('hasChanges');

  const onSelect = key => {
    if (key === 'save') {
      controller.saveFilterSet();
    } else if (key === 'save-new') {
      controller.saveNewFilterSet();
    }
  };

  return (
    <div className="d-flex flex-column mb-4">
      <div className="d-flex flex-row justify-content-between align-items-center ">
        <div className="d-flex flex-column" style={{ width: '75%' }}>
          <div className={cn('text-secondary ', { 'd-none': !activeFilterSet })}>Filter Set Name</div>
          <div className="font-size-16  d-flex align-items-center">
            <span className="d-inline-block text-truncate" style={{ maxWidth: showEditedLabel ? '60%' : '85%' }}>
              {activeFilterSet?.get('name') || ''}
            </span>
            {isShared && <FontAwesomeIcon icon="user-group" className="ml-2 font-size-12 text-gray-400" />}
            {showEditedLabel && <span className="ml-2 font-size-16 text-gray-400 font-italic">[Edited] *</span>}
          </div>
        </div>
        <Dropdown onSelect={onSelect}>
          <Dropdown.Toggle as={MoreFiltersToggleButton} isFiltering={isFiltering} />
          <DropdownMenu>
            {hasActive && showEditedLabel ? (
              <Dropdown.Item eventKey={'save'}>Save to this Filter Set</Dropdown.Item>
            ) : null}
            <Dropdown.Item eventKey={'save-new'}>Save as New Filter Set</Dropdown.Item>
          </DropdownMenu>
        </Dropdown>
      </div>
    </div>
  );
};

const MoreFiltersToggleButton = React.forwardRef(({ onClick, isFiltering }, ref) => (
  <ToggleButton
    className={cn('btn btn-lnk', { disabled: !isFiltering })}
    ref={ref}
    onClick={e => {
      e.preventDefault();
      if (isFiltering) onClick(e);
    }}
  >
    Filter Set
    <FontAwesomeIcon icon="chevron-down" className="ml-2" />
  </ToggleButton>
));

const ToggleButton = styled.span`
  cursor: pointer;
  padding: 5px 8px 5px 12px;
  margin-right: 2px;
  gap: 2px;

  //width: 111px;
  height: 40px;

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  flex-direction: row;
  align-items: center;
  letter-spacing: 0.2px;
  border: none;
  border-radius: 8px;
  white-space: nowrap !important;
  color: ${colors.gray400};
  background: ${colors.white};

  /* Active */
  &.active {
    background: ${colors.blue100};
    border-radius: 8px;
    color: ${colors.blue300};
  }

  &.disabled {
    opacity: 0.65;
    cursor: default;
    pointer-events: none;
  }
`;
