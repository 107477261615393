import { fromJS, List, Map } from 'immutable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { markAsSync, markAsSideEffect, CustomDialog, TaskBulkAssignmentModal } from '@tradetrax/web-common';
import { ALL_TASKS, TASK_TYPE } from '@tradetrax/web-common/lib/OuttakeIntake/TasksBySelect';
import { TaskTypeBulkAssignmentModal } from '@tradetrax/web-common/lib/OuttakeIntake/TaskTypeBulkAssignmentModal';
import { MINIMUM_BATCH_SIZE, RULE_MAP } from './OuttakeController';
import { buildersService } from 'services';

markAsSync(setTab);
export function setTab(state, tab) {
  setTimeout(() => this.loaderRef.current?.resetLoadMoreRowsCache(true), 1);
  return state.set('tab', tab).update('allTasks', allTasks =>
    allTasks
      .set('totalCount', 10)
      .set('maxCount', 10)
      .set('tasks', List())
  );
}

markAsSync(setTasksBy);
export function setTasksBy(state, tasksBy) {
  if (state.get('tasksBy') === tasksBy) return state;
  return state
    .set('tasksBy', tasksBy)
    .update('allTasks', allTasks =>
      allTasks
        .set('totalCount', 10)
        .set('maxCount', 10)
        .set('tasks', List())
    )
    .update('tasksByType', tasksByType =>
      tasksByType
        .set('totalCount', 10)
        .set('maxCount', 10)
        .set('tasks', List())
    );
}

markAsSync(toggleSelectAll);
export function toggleSelectAll(state, checked) {
  const tab = state.get('tab');
  const updatedState = state.setIn(['selectedTasks', tab, 'currentAssignees'], List());
  if (!checked) {
    return updatedState.updateIn(['selectedTasks', tab], selectedTasks => {
      return selectedTasks
        .set('selectAll', checked)
        .set('notIncludeTaskIds', List())
        .set('taskIds', List());
    });
  }
  return updatedState.updateIn(['selectedTasks', tab], selectedTasks =>
    selectedTasks.set('selectAll', checked).set('taskIds', List())
  );
}

markAsSync(onSelectCheckbox);
export function onSelectCheckbox(state, { checked, task, tab, tasksBy }) {
  const isTaskByType = tasksBy === TASK_TYPE;
  const isAccountTab = tab === 'account';
  const taskNameOrId = isTaskByType ? task.get('name') : task.get('id');
  const pathTaskIds = ['selectedTasks', tab, 'taskIds'];
  const path = isTaskByType ? ['selectedTasks', tab, 'taskNames'] : pathTaskIds;
  const assigneePath = isAccountTab ? ['task', 'assigneeAccountId'] : ['task', RULE_MAP[tab], '_id'];
  const taskAssigneeId = isTaskByType ? null : task.getIn(assigneePath) || null;
  const isSelectAll = state.getIn(['selectedTasks', tab, 'selectAll']);

  if (isSelectAll) return addRemoveFromExcluded(state, tab, taskNameOrId, checked);

  if (checked) {
    return state
      .updateIn(path, tasks => tasks.push(taskNameOrId))
      .updateIn(pathTaskIds, isTaskByType ? tasks => tasks.push(task.get('gtlTaskId')) : tasks => tasks)
      .updateIn(['selectedTasks', tab, 'currentAssignees'], selectedTaskAssignees => {
        if (isTaskByType) return selectedTaskAssignees;
        else return selectedTaskAssignees.push(taskAssigneeId);
      });
  } else {
    const index = state.getIn(path).indexOf(taskNameOrId);
    const indexTasksIds = state.getIn(pathTaskIds).indexOf(task.get('gtlTaskId'));
    if (index > -1) {
      return state
        .updateIn(path, tasks => tasks.remove(index))
        .updateIn(pathTaskIds, isTaskByType ? tasks => tasks.remove(indexTasksIds) : tasks => tasks)
        .updateIn(['selectedTasks', tab, 'currentAssignees'], selectedTaskAssignees => {
          if (isTaskByType) return selectedTaskAssignees;
          const index = selectedTaskAssignees.indexOf(taskAssigneeId);
          return selectedTaskAssignees.splice(index, 1);
        });
    }
  }
}

function addRemoveFromExcluded(state, tab, taskId, checked) {
  if (checked) {
    const index = state.getIn(['selectedTasks', tab, 'notIncludeTaskIds']).indexOf(taskId);
    return state.updateIn(['selectedTasks', tab, 'notIncludeTaskIds'], taskIds => taskIds.splice(index, 1));
  } else return state.updateIn(['selectedTasks', tab, 'notIncludeTaskIds'], taskIds => taskIds.push(taskId));
}

markAsSideEffect(openBulkAssignmentModal);
export async function openBulkAssignmentModal(tab, tasksBy) {
  const outtakeContext = this;
  const isTasksByType = tasksBy === TASK_TYPE;
  if (isTasksByType) return openTasksByTypeModal(tab, outtakeContext);
  else return openAllTasksModal(tab, outtakeContext);
}

async function openAllTasksModal(tab, outtakeContext) {
  const { state, modal, appState } = outtakeContext;
  const taskIds = state.getIn(['selectedTasks', tab, 'taskIds']).toJS();
  const community = state.get('community');
  const communityId = community.get('_id');
  const isAccount = tab === 'account';
  const isSelectAll = state.getIn(['selectedTasks', tab, 'selectAll']);

  const selectedTasksAssignees = state.getIn(['selectedTasks', tab, 'currentAssignees']);
  const isAnyNotAssigned = selectedTasksAssignees.includes(null);
  const taskAssignees = [...new Set(selectedTasksAssignees)].filter(item => item != null);
  const assigneeOptions = isAccount ? fromJS(appState.get('companies')) : appState.getIn(['account', 'usersActive']);
  const loggedAccountId = isAccount && appState.getIn(['account', '_id']);
  const { isAccept, assignee } = await modal.open(TaskBulkAssignmentModal, {
    context: outtakeContext,
    community,
    isAnyNotAssigned,
    taskAssignees,
    tab,
    assigneeOptions,
    loggedAccountId,
    isSelectAll,
  });

  if (!isAccept) return;

  const isSuperAssigned = tab === 'super';
  const assigneeType = isSuperAssigned ? 'userSuper' : 'userScheduler';
  if (isSelectAll) return assignTasksByQuery.call(outtakeContext, assignee, assigneeType);

  const hasAssignees = assigneeType =>
    state
      .getIn(['allTasks', 'tasks'])
      .filter(item => taskIds.includes(item.get('id')))
      .map(item => item.getIn(['task', assigneeType]))
      .some(assignee => assignee !== null);
  const tasks = taskIds.map(id => ({ taskId: parseInt(id.split('_')[1]), jobId: id.split('_')[0] }));

  if (isAccount) return assignAccountToTasks(outtakeContext, taskIds, tasks, assignee, communityId, hasAssignees, tab);
  else
    return assignSuperSchedulerToTasks(
      outtakeContext,
      taskIds,
      tasks,
      assignee,
      communityId,
      assigneeType,
      hasAssignees,
      tab
    );
}

markAsSideEffect(assignTasksByQuery);
export function assignTasksByQuery(assignee, userType) {
  const { tab } = this.state.toObject();
  const userId = assignee ? assignee.get('_id') : null;
  const accountId = assignee ? assignee.get('subAccountId') : null;
  const isAccountAssignee = tab === 'account';
  const notIncludeTaskIds = this.state.getIn(['selectedTasks', tab, 'notIncludeTaskIds']);
  const communityId = this.state.getIn(['community', '_id']);
  const body = {};
  const query = {};
  if (tab === 'super') body.userSuperId = userId;
  if (tab === 'scheduler') body.userSchedulerId = userId;
  if (notIncludeTaskIds.size) query.notIncludeTaskIds = notIncludeTaskIds.toJS();

  const filter = this.getFilter(tab).toJS();
  if (isAccountAssignee) {
    body.assigneeAccountId = accountId;
    query.assigneeAccountIds = filter.assigneeAccountIds;
  } else {
    query.assigneeUserType = tab;
    query.assigneeUserIds = filter.assigneeUserIds;
  }

  const updateAccountAssignee = item => {
    const accountName = assignee ? assignee.get('name') : null;
    return item.update('task', task =>
      task.set('assigneeAccountId', accountId).set('assigneeAccountName', accountName)
    );
  };
  const updateUserAssignee = task => {
    return task.updateIn(['task', userType], currentUser => {
      const [id, name] = assignee
        ? [assignee.get('_id'), `${assignee.get('firstName')} ${assignee.get('lastName')}`]
        : [null, null];
      if (!id) return null;
      const updatedUser = currentUser ?? Map();
      return updatedUser
        .set('_id', id)
        .set('name', name)
        .set('status', 'active');
    });
  };

  this.controller.dispatch([
    state =>
      state
        .updateIn(['selectedTasks', tab], selectedTasks =>
          selectedTasks.set('selectAll', false).set('notIncludeTaskIds', List())
        )
        .updateIn(['allTasks', 'tasks'], tasks =>
          tasks.map(task => {
            if (notIncludeTaskIds.includes(task.get('id'))) return task;
            else {
              if (isAccountAssignee) return updateAccountAssignee(task);
              else return updateUserAssignee(task);
            }
          })
        ),
  ]);

  return buildersService
    .assignUsersToTasksByQuery(body, { params: { communityId }, query })
    .then(() => {
      const assignType = isAccountAssignee ? 'Account' : `${userType.replace('user', '')}`;
      const assignUnassign = accountId || userId ? 'assigned to' : 'removed from';
      this.alert.success({ message: `${assignType} successfully ${assignUnassign} these Tasks.` });
    })
    .catch(error => {
      const assignType = isAccountAssignee ? 'an Account' : `a ${userType.replace('user', '')}`;
      const assignUnassign = accountId || userId ? 'assigning' : 'removing';
      this.alert.error({
        message: `There was a problem ${assignUnassign} ${assignType} to these Tasks. Please try again.`,
      });
    });
}

markAsSideEffect(openTasksByTypeModal);
export async function openTasksByTypeModal(tab, outtakeContext) {
  const { state, modal, appState } = outtakeContext;
  const taskNames = state.getIn(['selectedTasks', tab, 'taskNames']).toJS();
  const taskIds = state.getIn(['selectedTasks', tab, 'taskIds']).toJS();
  const community = state.get('community');
  const communityId = community.get('_id');
  const isAccountAssigned = tab === 'account';
  const isSuperAssigned = tab === 'super';

  const hasExistingRule = !!state
    .getIn(['tasksByType', 'tasks'])
    .find(task => taskNames.some(name => name === task.get('name')))
    .getIn(['rule', RULE_MAP[tab]]);
  const jobsPromise = buildersService.readJobs({}, { query: { communityId, taskNames } });
  const { isAccept, assignee, jobIds, createRule } = await modal.open(TaskTypeBulkAssignmentModal, {
    tab,
    community,
    jobsPromise,
    hasExistingRule,
    appState,
    assigneeOptions: appState.getIn(['account', 'usersActive']),
  });
  if (!isAccept) return;

  if (isAccountAssigned)
    return assignAccountToTaskByType(outtakeContext, taskNames, assignee, communityId, jobIds, createRule, tab);
  else {
    const assigneeType = isSuperAssigned ? 'userSuper' : 'userScheduler';
    return assignSuperSchedulerToTaskByType(
      outtakeContext,
      taskNames,
      assignee,
      communityId,
      assigneeType,
      jobIds,
      createRule,
      tab,
      taskIds
    );
  }
}

function assignAccountToTasks(self, taskIds, selectedTasks, account, communityId, hasAssignees, tab) {
  if (!account && !hasAssignees('assigneeAccountId')) return;
  const tasks = self.state.getIn(['allTasks', 'tasks']);
  const assigneeAccountId = account ? account.get('subAccountId') : null;
  const assigneeAccountName = account ? account.get('name') : null;
  self.controller.dispatch([
    state =>
      state
        .updateIn(['selectedTasks', tab], selectedTasks =>
          selectedTasks.set('taskIds', List()).set('currentAssignees', List())
        )
        .updateIn(['allTasks', 'tasks'], tasks =>
          tasks.map(item => {
            if (taskIds.includes(item.get('id'))) {
              return item.update('task', task =>
                task.set('assigneeAccountId', assigneeAccountId).set('assigneeAccountName', assigneeAccountName)
              );
            } else return item;
          })
        ),
  ]);
  const assignUnassign = assigneeAccountId ? 'assigned to' : 'removed from';
  const payload = { query: { communityId }, body: { assigneeAccountId }, tasks: selectedTasks };
  return buildersService
    .bulkUpdateTasks(payload)
    .then(() => self.addAlert(`Account successfully ${assignUnassign} these Tasks.`))
    .catch(() => {
      const assignUnassign = assigneeAccountId ? 'assigning' : 'removing';
      self.addAlert(`There was a problem ${assignUnassign} an Account to this Task. Please try again.`, 'danger');
      self.controller.dispatch([
        state => state.setIn(['allTasks', 'tasks'], tasks).setIn(['selectedTasks', tab, 'taskIds'], fromJS(taskIds)),
      ]);
    });
}

function assignSuperSchedulerToTasks(
  self,
  taskIds,
  selectedTasks,
  assigneeUser,
  communityId,
  userType,
  hasAssignees,
  tab
) {
  if (!assigneeUser && !hasAssignees(userType)) return;
  const tasks = self.state.getIn(['allTasks', 'tasks']);
  const assigneeUserId = assigneeUser ? assigneeUser.get('_id') : null;
  const assigneeUserName = assigneeUser ? `${assigneeUser.get('firstName')} ${assigneeUser.get('lastName')}` : null;
  self.controller.dispatch([
    state =>
      state
        .updateIn(['selectedTasks', tab], selectedTasks =>
          selectedTasks.set('taskIds', List()).set('currentAssignees', List())
        )
        .updateIn(['allTasks', 'tasks'], tasks =>
          tasks.map(item => {
            if (taskIds.includes(item.get('id'))) {
              return item.updateIn(['task', userType], currentUser => {
                const updatedUser = currentUser ? currentUser : Map();
                if (!assigneeUserId) return null;
                return updatedUser
                  .set('_id', assigneeUserId)
                  .set('name', assigneeUserName)
                  .set('status', 'active');
              });
            } else return item;
          })
        ),
  ]);

  const userTypeText = userType.replace('user', '');
  const assignUnassign = assigneeUserId ? 'assigned to' : 'removed from';
  const payload = { query: { communityId }, body: { [`${userType}Id`]: assigneeUserId }, tasks: selectedTasks };
  return buildersService
    .bulkUpdateTasks(payload)
    .then(() => self.addAlert(`${userTypeText} successfully ${assignUnassign} these Tasks.`))
    .catch(() => {
      const assignUnassign = assigneeUserId ? 'assigning' : 'removing';
      self.addAlert(
        `There was a problem ${assignUnassign} a ${userTypeText} to these Tasks. Please try again.`,
        'danger'
      );
      self.controller.dispatch([
        state => state.setIn(['allTasks', 'tasks'], tasks).setIn(['selectedTasks', tab, 'taskIds'], fromJS(taskIds)),
      ]);
    });
}

function assignAccountToTaskByType(self, taskNames, account, communityId, jobIds, createRule, tab) {
  const currentTasks = self.state.getIn(['tasksByType', 'tasks']);
  const assigneeAccountId = account ? account.get('subAccountId') : null;
  const tasks = taskNames.map(name => ({ name }));
  const payload = { query: { communityId }, body: { assigneeAccountId }, tasks };
  if (jobIds.length) payload.query.jobIds = jobIds;
  self.controller.dispatch([
    state =>
      state
        .updateIn(['selectedTasks', tab], selectedTasks =>
          selectedTasks
            .set('taskIds', List())
            .set('taskNames', List())
            .set('currentAssignees', List())
        )
        .updateIn(['tasksByType', 'tasks'], tasks => {
          if (!createRule) return tasks;
          return tasks.map(task => {
            if (taskNames.some(name => name === task.get('name'))) {
              const hasRule = !!task.get('rule');
              if (hasRule) return task.setIn(['rule', 'assigneeAccountName'], account.get('name'));
              else return task.set('rule', Map({ assigneeAccountName: account.get('name') }));
            }
            return task;
          });
        }),
  ]);

  const assignUnassign = assigneeAccountId ? 'assigned to' : 'removed from';
  const taskIds = self.state.getIn(['selectedTasks', tab, 'taskIds']).toJS();
  return buildersService
    .bulkUpdateTasks(payload)
    .then(() => {
      if (createRule && assigneeAccountId)
        buildersService.updateCommunityRules({ assigneeAccountId, taskIds }, { params: { communityId } }).then(() => {
          self.addAlert(
            `Account successfully assigned to these Tasks and Rule created for ${taskNames.length} Task type group${taskNames.length>1 ? 's':''}.`
          );
        });
      else self.addAlert(`Account successfully ${assignUnassign} these Tasks.`);
    })
    .catch(() => {
      const assignUnassign = assigneeAccountId ? 'assigning' : 'removing';
      self.controller.dispatch([
        state =>
          state
            .setIn(['tasksByType', 'tasks'], currentTasks)
            .setIn(['selectedTasks', tab, 'taskNames'], fromJS(taskNames)),
      ]);
      if (createRule && assigneeAccountId)
        self.addAlert(
          `There was a problem assigning an Account to these Tasks and creating the corresponding Rule for the Task type group(s). Please try again.`,
          'danger'
        );
      else self.addAlert(`There was a problem ${assignUnassign} an Account to this Task. Please try again.`, 'danger');
    });
}

function assignSuperSchedulerToTaskByType(
  self,
  taskNames,
  assigneeUser,
  communityId,
  assigneeType,
  jobIds,
  createRule,
  tab,
  taskIds
) {
  const assigneeUserId = assigneeUser ? assigneeUser.get('_id') : null;
  const tasks = taskNames.map(name => ({ name }));
  const payload = { query: { communityId }, body: { [`${assigneeType}Id`]: assigneeUserId }, tasks };
  if (jobIds.length) payload.query.jobIds = jobIds;
  self.controller.dispatch([
    state =>
      state
        .updateIn(['selectedTasks', tab], selectedTasks =>
          selectedTasks
            .set('taskIds', List())
            .set('taskNames', List())
            .set('currentAssignees', List())
        )
        .updateIn(['tasksByType', 'tasks'], tasks => {
          if (!createRule) return tasks;
          return tasks.map(task => {
            if (taskNames.some(name => name === task.get('name'))) {
              const hasRule = !!task.getIn(['rule', assigneeType]);
              const ruleName = `${assigneeUser.get('firstName')} ${assigneeUser.get('lastName')}`;
              if (hasRule) return task.setIn(['rule', assigneeType, 'name'], ruleName);
              else
                return task.set(
                  'rule',
                  Map({
                    [assigneeType]: {
                      name: ruleName,
                    },
                  })
                );
            }
            return task;
          });
        }),
  ]);

  const currentTasks = self.state.getIn(['tasksByType', 'tasks']);
  const userTypeText = assigneeType.replace('user', '');
  return buildersService
    .bulkUpdateTasks(payload)
    .then(() => {
      const assignUnassign = assigneeUserId ? 'assigned to' : 'removed from';
      if (createRule && assigneeUserId)
        buildersService
          .updateCommunityRules({ [`${assigneeType}Id`]: assigneeUserId, taskIds }, { params: { communityId } })
          .then(() => {
            self.addAlert(
              `${userTypeText} successfully assigned to these Tasks and Rule created for ${taskNames.length} Task type group(s).`
            );
          });
      else self.addAlert(`${userTypeText} successfully ${assignUnassign} these Tasks.`);
    })
    .catch(() => {
      self.controller.dispatch([
        state =>
          state
            .setIn(['tasksByType', 'tasks'], currentTasks)
            .setIn(['selectedTasks', tab, 'taskNames'], fromJS(taskNames)),
      ]);
      const assignUnassign = assigneeUserId ? 'assigning' : 'removing';
      if (createRule && assigneeUserId)
        self.addAlert(
          `There was a problem ${assignUnassign} a ${userTypeText}  to these Tasks and creating the corresponding Rule for the Task type group(s). Please try again.`,
          'danger'
        );
      else
        self.addAlert(
          `There was a problem ${assignUnassign} a ${userTypeText} to these Tasks. Please try again.`,
          'danger'
        );
    });
}

export function readCommunity(communityId) {
  return buildersService
    .readCommunity({}, { params: { communityId } })
    .then(community => state => state.set('community', fromJS(community)));
}

export function readCommunityTasks({ communityId, startIndex, stopIndex }) {
  const query = getQueryParam.call(this, { start_index: startIndex, stop_index: stopIndex });
  return buildersService.readCommunityTasks({}, { params: { communityId }, query }).then(data => {
    const totalCount = data.metadata.pagination ? data.metadata.pagination.totalCount : 0;
    return state =>
      state.update('allTasks', allTasks =>
        allTasks
          .set('totalCount', totalCount)
          .set('maxCount', stopIndex + MINIMUM_BATCH_SIZE + 1)
          .update('tasks', tasks => tasks.splice(startIndex, stopIndex - startIndex + 1, ...fromJS(data).toArray()))
      );
  });
}

export function readCommunityTasksByType({ communityId, startIndex, stopIndex }) {
  return buildersService
    .readCommunityTasksByType(
      {},
      { params: { communityId }, query: { start_index: startIndex, stop_index: stopIndex } }
    )
    .then(data => {
      const totalCount = data.metadata.pagination ? data.metadata.pagination.totalCount : 0;
      return state =>
        state.update('tasksByType', tasksByType =>
          tasksByType
            .update('tasks', tasks => tasks.splice(startIndex, stopIndex - startIndex + 1, ...fromJS(data).toArray()))
            .set('totalCount', totalCount)
            .set('maxCount', stopIndex + MINIMUM_BATCH_SIZE + 1)
        );
    });
}

markAsSync(assignTask);
export function assignTask(state, { company, task }) {
  const index = state.getIn(['allTasks', 'tasks']).indexOf(task);
  const assigneeAccountId = company ? company.get('subAccountId') : null;
  const assigneeAccountName = company ? company.get('name') : null;
  const jobId = task.getIn(['job', 'id']);
  const taskId = task.getIn(['task', 'id']);
  buildersService.updateTask({ assigneeAccountId }, { params: { jobId, taskId } }).catch(() => {
    this.controller.dispatch([state => state.setIn(['allTasks', 'tasks', index], task)]);
    this.addAlert('There was a problem assigning an Account to this Task. Please try again.', 'danger');
  });

  const tab = state.get('tab');
  const selectedTasksIds = state.getIn(['selectedTasks', tab, 'taskIds']);
  const assigneeIndex = selectedTasksIds.findIndex(taskId => taskId === task.get('id'));
  const isAllTasks = state.get('tasksBy') === ALL_TASKS;
  return state
    .updateIn(['allTasks', 'tasks', index, 'task'], task => {
      return task.set('assigneeAccountId', assigneeAccountId).set('assigneeAccountName', assigneeAccountName);
    })
    .updateIn(['selectedTasks', tab, 'currentAssignees', assigneeIndex], currentAssigneeId => {
      if (isAllTasks && assigneeIndex >= 0) return assigneeAccountId;
      else return currentAssigneeId;
    });
}

markAsSync(assignSuperSchedulerToTask);
export function assignSuperSchedulerToTask(state, { assignee, tab, task }) {
  const index = state.getIn(['allTasks', 'tasks']).indexOf(task);
  const jobId = task.getIn(['job', 'id']);
  const taskId = task.getIn(['task', 'id']);
  const [isSuper, isScheduler] = [tab === 'super', tab === 'scheduler'];
  const userType = isSuper ? 'userSuper' : 'userScheduler';
  const assigneeStatus = assignee ? assignee.get('status') : 'active';
  const assigneeId = assignee ? assignee.get('_id') : null;
  const assigneeName = assignee ? `${assignee.get('firstName')} ${assignee.get('lastName')}` : null;

  const payload = {};
  if (isSuper) {
    payload.userSuperId = assigneeId;
  } else if (isScheduler) {
    payload.userSchedulerId = assigneeId;
  }

  buildersService.updateTask(payload, { params: { jobId, taskId } }).catch(() => {
    this.controller.dispatch([state => state.setIn(['allTasks', 'tasks', index], task)]);
    const userType = isSuper ? 'Super' : 'Scheduler';
    this.addAlert(
      `There was a problem assigning a 
        ${userType} to this Task. Please try again.`,
      'danger'
    );
  });

  const isAllTasks = state.get('tasksBy') === ALL_TASKS;
  const selectedTasksIds = state.getIn(['selectedTasks', tab, 'taskIds']);
  const assigneeIndex = selectedTasksIds.findIndex(taskId => taskId === task.get('id'));
  return state
    .updateIn(['allTasks', 'tasks', index, 'task'], task =>
      task.update(userType, user => {
        const assignee = user || Map();
        return assignee
          .set('status', assigneeStatus)
          .set('name', assigneeName)
          .set('_id', assigneeId);
      })
    )
    .updateIn(['selectedTasks', tab, 'currentAssignees', assigneeIndex], currentAssigneeId => {
      if (isAllTasks && assigneeIndex >= 0) return assigneeId;
      else return currentAssigneeId;
    });
}

markAsSync(assignTaskByType);
export function assignTaskByType(company) {
  const communityId = this.state.getIn(['community', '_id']);
  const tab = this.state.get('tab');
  const taskNames = this.state.getIn(['selectedTasks', tab, 'taskNames']).toArray();
  const assigneeAccountId = company ? company.get('subAccountId') : null;

  return buildersService
    .assignAccountToTasks({ taskNames, assigneeAccountId }, { params: { communityId } })
    .then(() => state => state.setIn(['selectedTasks', tab, 'taskNames'], List()))
    .catch(() => {
      this.controller.dispatch([state => state.setIn(['selectedTasks', tab, 'taskNames'], taskNames)]);
      this.addAlert('There was a problem assigning an Account to this Task. Please try again.', 'danger');
    });
}

markAsSideEffect(removeRule);
export async function removeRule({ taskType, ruleType }) {
  const communityId = this.state.getIn(['community', '_id']);
  const index = this.state.getIn(['tasksByType', 'tasks']).indexOf(taskType);
  const taskIds = [taskType.get('gtlTaskId')];

  const { isAccept } = await this.modal.open(CustomDialog, {
    title: (
      <>
        <FontAwesomeIcon icon="circle-exclamation" className="text-danger" />
        Removal Alert
      </>
    ),
    message:
      'By removing this Rule, this Task Type will not be assigned automatically in future Jobs. Do you confirm the removal?',
    titleAccept: 'Yes, Remove Rule',
    titleCancel: 'Cancel',
  });

  if (!isAccept) return;
  const path = ['tasksByType', 'tasks', index, 'rule', ruleType];
  const payload = { taskIds };
  if (ruleType === 'assigneeAccountName') payload.assigneeAccountId = null;
  else payload[`${ruleType}Id`] = null;
  this.controller.dispatch([state => state.setIn(path, null)]);
  return buildersService
    .updateCommunityRules(payload, { params: { communityId } })
    .then(() => this.addAlert('Task type Rule successfully removed.', 'success'))
    .catch(() => {
      this.addAlert('There was a problem removing this Task type Rule. Please try again.', 'danger');
      const currentRule = this.state.getIn(path);
      this.controller.dispatch([state => state.setIn(path, currentRule)]);
    });
}

markAsSync(invalidateFilter);
export function invalidateFilter(state) {
  setTimeout(() => this.loaderRef.current?.resetLoadMoreRowsCache(true), 1);
  return state.update('allTasks', allTasks =>
    allTasks
      .set('totalCount', 10)
      .set('maxCount', 10)
      .set('tasks', List())
  );
}

function getQueryParam(query) {
  const tab = this.state.get('tab');
  const filter = this.getFilter(tab).toJS();

  if (!filter) return query;

  const queryPropName = tab === 'account' ? 'assigneeAccountIds' : 'assigneeUserIds';

  if (filter[queryPropName]?.length) {
    if (tab !== 'account') query.assigneeUserType = tab;
    query[queryPropName] = filter[queryPropName];
  }

  return query;
}
