import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from '@tradetrax/web-common';
import { PopoverStickOnHover } from '@tradetrax/web-common/lib/Popover';
import { plural } from '@tradetrax/web-common/lib/utils';
import navigate from 'app/navigate';

export const OverlayIncompleteStages = ({ stages }) => {
  let numberIncompleteStages = stages.filter(stage => !(stage.get('endTaskId') || stage.get('startTaskId'))).size;
  if (stages.size === numberIncompleteStages) numberIncompleteStages -= 1;
  const label = 'Incomplete Stages';
  const incompleteStagesText = (
    <>
      {plural(numberIncompleteStages, 'is', 'are')}
      <strong>{` ${numberIncompleteStages} ${plural(numberIncompleteStages, 'Stage')}`}</strong>
    </>
  );

  const popoverText = () => (
    <div className="d-flex pl-2 pt-2 pb-2">
      <FontAwesomeIcon icon="triangle-exclamation" className="text-yellow-100 font-size-14 mr-2 mt-1" />
      <span>
        There {incompleteStagesText} that {plural(numberIncompleteStages, 'has', 'have')} not been set, you can set{' '}
        {plural(numberIncompleteStages, 'it', 'them')} later.
      </span>
    </div>
  );

  return (
    <PopoverStickOnHover popoverId="warning-popover" popover={popoverText()} delay={600} placement="bottom-end">
      <Label>{label}</Label>
    </PopoverStickOnHover>
  );
};

export const OverlayIncompleteStartDateConfirmation = ({ tasks }) => {
  const numberMissingTasks =
    tasks.get('isLoading') || tasks.get('isEmpty') ? 0 : tasks.filter(task => task.get('isRelatedTaskMissing')).size;
  const label = 'Missing Tasks';
  const incompleteTasksText = (
    <>
      {plural(numberMissingTasks, 'is', 'are')}
      <strong>{` ${numberMissingTasks} ${plural(numberMissingTasks, 'Task')}`}</strong>
    </>
  );
  const popoverText = () => (
    <div className="d-flex pl-2 pt-2 pb-2">
      <FontAwesomeIcon icon="triangle-exclamation" className="text-yellow-100 font-size-14 mr-2 mt-1" />

      <span>
        There {incompleteTasksText} that {plural(numberMissingTasks, 'is', 'are')} missing.
        <div>Make sure to add {plural(numberMissingTasks, 'it', 'them')} to the Template.</div>
      </span>
    </div>
  );

  return (
    <PopoverStickOnHover popoverId="warning-popover" popover={popoverText()} delay={600} placement="bottom-end">
      <Label>{label}</Label>
    </PopoverStickOnHover>
  );
};

export const OverlayIncompleteSettings = ({ templateId }) => {
  const label = 'Incomplete Template Settings';
  const popoverText = () => {
    return (
      <div className="d-flex p-3">
        <FontAwesomeIcon icon="triangle-exclamation" className="text-yellow-100 font-size-14 mr-2 mt-1" />
        <span>
          Go to{' '}
          <Link to={navigate.from.TemplateDetails.to.TemplateSettings({ templateId }, false)}>Template Settings</Link>{' '}
          and complete the missing fields.
        </span>
      </div>
    );
  };

  return (
    <PopoverStickOnHover
      className="mr-3"
      popoverId="warning-popover"
      popover={popoverText()}
      delay={600}
      placement="bottom-end"
    >
      <Label>{label}</Label>
    </PopoverStickOnHover>
  );
};

const Label = styled.span`
  background-color: ${colors.yellow};
  padding: 4px 8px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 6px;
`;
