import React from 'react';
import { Item, Name } from './common';
import { formatTrx, plural } from '../../utils';
import { WeatherIndicator } from '../WeatherIndicator';

export default ({ item, account, user, isInactive }) => {
  const date = field => formatTrx(item.getIn(['data', field]));
  const behind = item.getIn(['data', 'daysBehind']);
  const accepted = item.getIn(['data', 'accepted']);

  return (
    <Item item={item} user={user}>
      <Name item={item} account={account} user={user} isInactive={isInactive} />
      {accepted ? (
        <>
          {` accepted exp. start update request to ${date('newStartDate')}`}
          {!!behind && ' and had an impact of '}
          {behind > 0 && <span className="text-danger">{plural.day(behind)} behind</span>}
          {behind < 0 && <span className="text-success">{plural.day(Math.abs(behind))} ahead</span>}
          <WeatherIndicator item={item} />
        </>
      ) : (
        ` declined exp. start update request  to ${date('newStartDate')}`
      )}
    </Item>
  );
};
