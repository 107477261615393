import React from 'react';
import { useAppContext } from '../../globalAppContext';

export const useTasks = task => {
  const [state, setState] = React.useState([]);
  const { services } = useAppContext();

  React.useEffect(() => {
    const taskId = task.get('id');
    const jobId = task.getIn(['job', 'id']);
    const params = { jobId, taskId };

    services.buildersService.getRootCauseTasks({}, { params }).then(allTasks => {
      const currentIndex = allTasks.findIndex(t => t.orderLabel === 'current');

      const tasks = allTasks.map((t, i) => {
        if (i >= currentIndex - 3 && i <= currentIndex + 3) {
          t.showAsDefault = i <= currentIndex || t.orderLabel === 'subsequent';
        } else {
          t.showAsDefault = false;
        }
        return t;
      });

      setState(tasks);
    });
  }, [setState, task, services]);

  return state;
};
