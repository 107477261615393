import React from 'react';
import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import { PrimaryButton, MiniProfile } from '@tradetrax/web-common';

export function ResendInviteModal({ accept, cancel, homeowner }) {
  return (
    <Modal show={true} onHide={cancel}>
      <Modal.Header closeButton>
        <Modal.Title>Resend Invite</Modal.Title>
      </Modal.Header>
      <Modal.Body className="py-0">
        <span>You can resend the invite through Email.</span>
        <ProfileWrapper>
          <MiniProfile
            className="my-3"
            initials={homeowner.get('initials')}
            name={`${homeowner.get('firstName')} ${homeowner.get('lastName')}`}
            email={homeowner.get('email')}
          />
        </ProfileWrapper>
      </Modal.Body>
      <Modal.Footer>
        <PrimaryButton variant="secondary" className="mr-3" onClick={() => cancel()}>
          Cancel
        </PrimaryButton>
        <PrimaryButton onClick={() => accept()}>Resend Invite</PrimaryButton>
      </Modal.Footer>
    </Modal>
  );
}

const ProfileWrapper = styled.div`
  div {
    margin-left: 0px !important;

    &:first-child {
      margin-top: 1.5rem;
      margin-bottom: 1rem;
    }
  }
  h4 {
    font-size: 16px;
  }
  span.form-label {
    font-size: 14px;
  }
`;
