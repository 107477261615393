import React from 'react';
import cn from 'classnames';
import { Map } from 'immutable';
import { ReportEmptyState, ReportContainer, AppliedFilters } from '@tradetrax/web-common/lib/Reports';
import { AreaManager } from '../Common';

export const TaskOverdueReportTotalAmountByMonth = ({ context }) => {
  const { state } = context;
  const [collapsed, setCollapsed] = React.useState(Map({}));
  const data = state.get('data');

  React.useEffect(() => {
    setCollapsed(Map());
  }, [data]);

  if (data.size === 0) return <ReportEmptyState />;

  return (
    <ReportContainer>
      <h4 className="font-weight-bold text-center mx-3 mt-4 pt-3">Total Overdue Task Time by Month</h4>
      <AppliedFilters filters={state.get('appliedFilters')} />
      <div className="report-body">
        {data
          .map(community => (
            <Community key={community.get('communityId')} community={community}>
              <div className="group-inner-header">
                <div className="cell-w250">Trade Assignee</div>
                <div className="cell-w250"># Jobs</div>
                <div className="cell-w150">Total Overdue</div>
                <div className="cell-w150">Missed Starts</div>
                <div className="cell-w150">Missed Finishes</div>
                <div className="cell-w250">Progress</div>
              </div>
              {community
                .get('trades')
                .map(trade => (
                  <Trade
                    key={trade.get('tradeAccountId')}
                    communityId={community.get('communityId')}
                    setCollapsed={setCollapsed}
                    collapsed={collapsed}
                    trade={trade}
                  >
                    {trade
                      .get('jobs')
                      .map(job => <Job key={job.get('id')} job={job} />)
                      .toArray()}
                  </Trade>
                ))
                .toList()}
            </Community>
          ))
          .toArray()}
      </div>
    </ReportContainer>
  );
};

const Community = ({ community, children }) => {
  return (
    <div className="group-outter">
      <div className="group-outter-header d-flex flex-row justify-content-between mb-2">
        <span>
          Community:&nbsp;
          <span className="h5 font-weight-bold">{community.get('communityName')}</span>
        </span>
        <span>
          Area Manager: <AreaManager manager={community.getIn(['areaManagers', 0, 'name'])} />
        </span>
      </div>
      {children}
    </div>
  );
};

const Job = ({ job }) => {
  return (
    <div className="d-flex flex-row mb-1">
      <div className="cell-w250"></div>
      <div className="cell-w250">{job.get('name') || ''}</div>
      <div className="cell-w150">{job.get('overdueTasks') ?? ''}</div>
      <div className="cell-w150">{job.get('missedStarts') ?? ''}</div>
      <div className="cell-w150">{job.get('missedFinishes') ?? ''}</div>
      <div className={'cell-w150'}>{job.get('progress')}%</div>
    </div>
  );
};

const Trade = ({ setCollapsed, collapsed, children, trade, communityId }) => {
  const key = `${communityId}_${trade.get('tradeAccountId')}`;
  const isCollapsed = !!collapsed.get(key);

  return (
    <div className="group-inner d-flex flex-column">
      <div className="group-inner-summary">
        <div className="cell-w250">
          <button
            onClick={() => setCollapsed(collapsed => collapsed.set(key, !isCollapsed))}
            className="btn btn-link p-0 font-weight-bold text-dark"
          >
            {trade.get('tradeAccountName')}
          </button>
        </div>
        <div className="cell-w250">{trade.get('jobs').size}</div>
        <div className="cell-w150">{trade.get('totalOverdue')}</div>
        <div className="cell-w150">{trade.get('missedStarts')}</div>
        <div className="cell-w150">{trade.get('missedFinishes')}</div>
        <div className="cell-w150">{trade.get('progress')}%</div>
      </div>
      <div className={cn('group-inner-body', { 'd-none': isCollapsed })}>{children}</div>
    </div>
  );
};
