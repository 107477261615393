import React from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { COMPLETED, IN_PROGRESS, plural } from '../../../utils';
import { PrimaryButton } from '../../../Button';
import { ChipStatus, STATUS_MAP } from '../../../ChipStatus';
import { UpdateStatusModalContext, MISSED_START, MISSED_FINISH, NO_PERMISSION } from './UpdateStatusModal.context';
import { TaskList } from './TaskList';
import { WarningView } from './WarningView';

export function UpdateStatusModal({ accept, cancel, tasks, isTaskAssigned }) {
  const { state, controller } = UpdateStatusModalContext(tasks, isTaskAssigned);
  const { view } = state.toObject();
  const isNotPermission = view === NO_PERMISSION;
  const totalSize = state.getIn([MISSED_START, 'tasks']).size + state.getIn([MISSED_FINISH, 'tasks']).size;

  const props = { state, controller, totalSize, accept, cancel };
  const ModalView = isNotPermission ? WarningView : StatusSelection;
  return (
    <StyledModal show={true} onHide={() => cancel()} size="md">
      <ModalView {...props} />
    </StyledModal>
  );
}

const StatusSelection = ({ state, controller, totalSize, accept, cancel }) => {
  const { view, isTwoSteps } = state.toObject();
  const size = state.getIn([view, 'tasks']).size;
  const isMissedStart = view === MISSED_START;
  const buttonText = isTwoSteps ? (isMissedStart ? 'Next' : `Change Status (${totalSize} Tasks)`) : 'Change Status';
  const isInProgressChecked = isMissedStart && state.getIn([MISSED_START, 'status']) === IN_PROGRESS;
  const isCompletedChecked = !isMissedStart || state.getIn([MISSED_START, 'status']) === COMPLETED;

  const onClick = () => {
    if (isTwoSteps && isMissedStart) controller.setFinishView();
    else accept({ form: state });
  };

  return (
    <>
      <Modal.Header closeButton>
        <div className="d-flex flex-column align-items-start">
          <button
            onClick={() => controller.setStartView()}
            className={cn('btn text-muted position-relative', { 'd-none': !isTwoSteps || isMissedStart })}
            style={{ bottom: '0.5rem', right: '0.5rem' }}
          >
            <FontAwesomeIcon icon="arrow-left" />
          </button>
          <Modal.Title>
            {`Resolve Missed ${view}`}
            {isTwoSteps ? (
              <h6 className="font-weight-bold">{`${size} out of ${totalSize} Tasks`} </h6>
            ) : (
              <h6 className="font-weight-bold">{`${size} ${plural(size, 'Task')}`}</h6>
            )}
          </Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-row align-items-center mb-5">
          <span>Change status to:</span>
          <div className={cn('flex-row ml-3', { 'd-none': !isMissedStart, 'd-flex': isMissedStart })}>
            <input
              type="radio"
              name="status"
              checked={isInProgressChecked}
              onChange={() => controller.updateStatus(IN_PROGRESS)}
            />
            <ChipStatus {...STATUS_MAP[IN_PROGRESS]} className="mx-2" />
          </div>
          <div className="d-flex flex-row ml-3">
            <input
              type="radio"
              name="status"
              checked={isCompletedChecked}
              disabled={!isMissedStart}
              onChange={() => controller.updateStatus(COMPLETED)}
            />
            <ChipStatus {...STATUS_MAP[COMPLETED]} className="ml-2" />
          </div>
        </div>
        <TaskList tasks={state.getIn([view, 'tasks'])} />
      </Modal.Body>
      <Modal.Footer>
        <PrimaryButton variant="secondary" className="mr-3" onClick={() => cancel()}>
          Cancel
        </PrimaryButton>
        <PrimaryButton onClick={() => onClick()}>{buttonText}</PrimaryButton>
      </Modal.Footer>
    </>
  );
};

const StyledModal = styled(Modal)`
  .modal-dialog.modal-md {
    max-width: 600px;
  }
`;
