import React from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import { colors } from '@tradetrax/web-common';
import { Modal, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MiniProfileModal } from '../Tags';
import { PopoverStickOnHover } from '../Popover';
import { PrimaryButton } from '../Button';

const popover = () => (
  <div className="px-3 pb-3">This user opted out from receiving text message to recover access to the platform. </div>
);

export function SendPasswordReset({ accept, cancel, user }) {
  const hasPhone = !!user.get('phone');
  const hasEmail = !!user.get('email');
  const allowSMS = user.getIn(['settings', 'allowSMS']);
  const [state, setState] = React.useState({ viaEmail: hasEmail, viaSMS: hasPhone && !hasEmail });
  const btnDisabled = (!state.viaEmail && !state.viaSMS) || (!hasEmail && !allowSMS);
  const name = `${user.get('firstName')} ${user.get('lastName')}`;
  const send = option => accept({ option, ...state });
  const onChange = e => {
    const { name, checked } = e.target;
    setState({
      ...state,
      [name]: checked,
    });
  };

  return (
    <Modal show={true} onHide={cancel}>
      <Modal.Header closeButton>
        <Modal.Title>Send Password Reset</Modal.Title>
      </Modal.Header>
      <Modal.Body className="py-0">
        <p>You can send user instructions to either reset their password or log in immediately with a Magic Link.</p>
        <MiniProfileModal initials={user.get('initials')} name={name} email={user.get('email')} />
        <div className="d-flex flex-row mt-3 ml-4 text-secondary">
          <span>Via:</span>
          <Form.Group controlId="viaSMS" className={cn('ml-2', { 'd-none': !hasPhone })}>
            <div className="d-flex flex-row">
              <StyledCheckBox
                type="checkbox"
                label="SMS"
                name="viaSMS"
                disabled={!allowSMS}
                checked={state.viaSMS}
                onChange={onChange}
              />
              <PopoverStickOnHover
                placement="right-end"
                popover={popover()}
                className={cn('pt-0', { 'd-none': allowSMS })}
              >
                <span className="btn btn-link mt-n1 pl-2 font-size-12">
                  <FontAwesomeIcon icon="square-info" className="mr-1 text-primary" />
                </span>
              </PopoverStickOnHover>
            </div>
          </Form.Group>
          <Form.Group controlId="viaEmail" className={cn('ml-4', { 'd-none': !hasEmail })}>
            <StyledCheckBox
              type="checkbox"
              label="Email"
              name="viaEmail"
              disabled={!hasEmail}
              checked={state.viaEmail}
              onChange={onChange}
            />
          </Form.Group>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <PrimaryButton
          variant="secondary"
          className="ml-n2"
          onClick={() => send('resetPasswordUser')}
          disabled={btnDisabled}
        >
          Send Password Reset
        </PrimaryButton>
        <PrimaryButton className="mr-n2" onClick={() => send('magicLinkUser')} disabled={btnDisabled}>
          Magic Link
        </PrimaryButton>
      </Modal.Footer>
    </Modal>
  );
}

const StyledCheckBox = styled(Form.Check)`
  .form-check-input:not(:checked) ~ .form-check-label,
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: ${colors.gray200} !important;
  }
`;
