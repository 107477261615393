import styled from 'styled-components';
import { colors } from '../theme';

export const STATUS_COLOR = {
  'ready-to-start': colors.green300,
  'almost-ready-to-start': colors.gray100,
  'not-ready-to-start': colors.red,
  'not-ctr': colors.gray400,
  undefined: colors.gray400,
};

export const CalendarWrapper = styled.div`
  ${props => `
  position: relative;
  margin-bottom: 10rem;

// CALENDAR HEADER
.fc-header-toolbar {
  margin-bottom: ${props.isDayView ? 'unset !important' : '1.5em'};
  
  .fc-toolbar-chunk:first-of-type {
    font-weight: bold;
    div {
      display: flex;
      align-items: center;
      min-width: 280px;
    }
    h2.fc-toolbar-title {
      flex: 2;
      color: ${colors.blue300};
      font-size: 24px;
      font-weight: bold;
      padding: 5px;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background-color: ${colors.pearl};
      }
      &:active {
        color: ${colors.blue400} !important;
      }
    }
    .fc-button {
      font-size: 18px;
      color: ${colors.blue300};
      padding: 5px;
      &:focus {
        box-shadow: none;
      }
      &:active {
        color: ${colors.blue400};
      }
      &:hover {
        background-color: ${colors.pearl};
      }
      .fc-icon {
        transform: ${props.isMonthView ? 'rotate(90deg)' : 'none'};
      }
    }
  }
  .fc-button-group .fc-button {
    color: ${colors.gray400};
    text-transform: capitalize;
    border-radius: 5px;

    &.fc-button-active {
      color: ${colors.blue300};
      background-color: ${colors.blue100} !important;

      &:hover {
        color: ${colors.blue400};
      }
    }
    &:hover {
      text-decoration: underline;
    }
    &:active,
    &:focus {
      box-shadow: none;
      background-color: transparent;
    }
  }
  .fc-button {
    background-color: transparent;
    border: none;
  }
}

// DAYS HEADER
hr.solid {
  display: ${props.isDayView ? 'auto' : 'none'};;
  position: absolute;
  top: 3rem;
  left: -25px;
  right: -25px;
}
.fc-col-header-cell.fc-day {
  height: ${props.isDayView ? '75px !important' : 'auto'};
  .fc-scrollgrid-sync-inner {
    display: flex;
    height: 100%;
    a {
      color: ${colors.gray400};
      font-weight: normal;
      text-transform: uppercase;
      font-size: 12px;
      cursor: text;

      &:hover {
        text-decoration: none;
      }

      div {
        height: 100%;
        justify-content: space-between;

        span:first-child {
          font-size: 14px;
        }
      }
    }
  }
}

// WEEKENDS COLUMNS
tr .fc-day-sun:not(.fc-col-header-cell),
tr .fc-day-sat:not(.fc-col-header-cell) {
  background-color: ${colors.pearl};
  .fc-daygrid-day-number {
    color: ${colors.gray400};
  }
}

// TABLE BORDERS
.fc-theme-standard {
  td {
    border: 1px solid ${colors.gray100};
  }
  th,
  .fc-scrollgrid {
    border: 0px;
  }
  .fc-dayGridDay-view td {
    border: 0px;
  }
}

// DAY CELL HEADER
.fc .fc-daygrid-day-top {
  flex-direction: row !important;
}

// DAY CELL
td.fc-day-today {
  background-color: ${colors.white} !important;
}
td.fc-daygrid-day {
  height: 152px !important;
}

// EVENTS
.fc-event-main {
  display: block;
  height: 32px;
}
a.fc-daygrid-event {
  border-radius: 8px;
  cursor: pointer;
}
a.event-ready-to-start:hover {
  background-color: ${colors.green400} !important;
  border-color: ${colors.green400} !important;
}
a.event-almost-ready-to-start:hover {
  background-color: ${colors.blue100} !important;
  border-color: ${colors.blue100} !important;
}
a.event-not-ready-to-start:hover {
  background-color: ${colors.red400} !important;
  border-color: ${colors.red400} !important;
}
a.event-not-ctr:hover {
  background-color: ${colors.gray800} !important;
  border-color: ${colors.gray800} !important;
}
a.event-default {
  background-color: ${colors.gray100} !important;
  border-color: ${colors.gray100} !important;
  .fc-event-main span {
    color: ${colors.gray400} !important;
  }
}

// ======== Day Grid =========
.fc-daygrid.fc-dayGridDay-view {
  .fc-daygrid-day-frame.fc-scrollgrid-sync-inner {
    width: 700px !important;

    .fc-daygrid-event {
      border-radius: 8px;
      margin-left: 0px;
    }
  }
}
`}
`;

export const PopoverWrapper = styled.div`
  ${props => `
    border-left: 6px solid ${props.color};
    padding: 1rem;
    padding-bottom:0;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
  `}
`;
