import { fromJS } from 'immutable';
import { markAsSideEffect } from '@tradetrax/web-common/lib/useController';
import { buildersService } from 'services';
export { deleteLeadTimeTask } from '../../../../Users/MyProfile/MyProfileActions';

const formatLeadTimeTask = gtlTask => {
  const task = gtlTask;
  if (!task.onlyChannels) task.onlyChannels = [];
  if (!task.leadTimeDays) task.leadTimeDays = 0;
  if (!task.isChildTask) task.isChildTask = false;
  if (!task.gtlTaskId) task.gtlTaskId = task._id;
  delete task._id;
  delete task.__v;
  delete task.children;
  delete task.parentTaskId;
  delete task.parentTaskName;
  delete task.trade;
  return task;
};

export function createLeadTimeTask({ task }) {
  const { customRole } = this.state.toObject();
  const roleId = customRole.get('_id');
  const leadTimeTasks = customRole.getIn(['settings', 'notifications', 'leadTimeTasks']).toJS();
  const notifications = { leadTimeTasks: leadTimeTasks.concat(task.toJS()).map(formatLeadTimeTask) };
  return buildersService
    .updateRole({ notifications }, { params: { roleId } })
    .then(() => state =>
      state.updateIn(['customRole', 'settings', 'notifications', 'leadTimeTasks'], leadTimeTasks =>
        leadTimeTasks.unshift(task)
      )
    )
    .catch(error => {
      this.alert.error({ message: 'There was a problem adding this Task. Please try again.' });
      this.controller.dispatch([
        state => state.setIn(['customRole', 'settings', 'notifications', 'leadTimeTasks'], fromJS(leadTimeTasks)),
      ]);
      throw error;
    });
}

markAsSideEffect(doDeleteLeadTimeTask);
export function doDeleteLeadTimeTask(task) {
  const { customRole } = this.state.toObject();
  const roleId = customRole.get('_id');
  const leadTimeTasks = customRole.getIn(['settings', 'notifications', 'leadTimeTasks']);
  const index = leadTimeTasks.indexOf(task);
  const updated = index > -1 ? leadTimeTasks.splice(index, 1).toJS() : leadTimeTasks.toJS();
  const notifications = {
    leadTimeTasks: updated.map(formatLeadTimeTask),
  };

  this.controller.dispatch([
    state =>
      state.updateIn(['customRole', 'settings', 'notifications', 'leadTimeTasks'], leadTimeTasks =>
        leadTimeTasks.splice(index, 1)
      ),
  ]);

  buildersService
    .updateRole({ notifications }, { params: { roleId } })
    .then(() =>
      this.alert.success({
        message: 'Task successfully removed from the Lead Time notifications list.',
      })
    )
    .catch(error => {
      this.alert.error({ message: 'There was a problem removing this Task from the list. Please try again.' });
      this.controller.dispatch([
        state => state.setIn(['customRole', 'settings', 'notifications', 'leadTimeTasks'], fromJS(leadTimeTasks)),
      ]);
    });
}

export function updateLeadTimeTask(task, channels, leadTimeDays) {
  const { customRole } = this.state.toObject();
  const roleId = customRole.get('_id');
  const leadTimeTasksImmuted = customRole.getIn(['settings', 'notifications', 'leadTimeTasks']);
  const leadTimeTasks = leadTimeTasksImmuted.toJS();
  const index = leadTimeTasksImmuted.indexOf(task);
  const update = task =>
    task
      .setIn(
        ['onlyChannels'],
        channels.filter(x => !!x)
      )
      .setIn(['leadTimeDays'], leadTimeDays);
  const updated = update(task);
  const payload = {
    leadTimeDays: updated.get('leadTimeDays'),
    onlyChannels: updated.get('onlyChannels'),
  };

  const updatedLeadTimeTasks = leadTimeTasks.map((item, i) => (i === index ? { ...item, ...payload } : item));
  const notifications = {
    leadTimeTasks: updatedLeadTimeTasks.map(formatLeadTimeTask),
  };

  return buildersService
    .updateRole({ notifications }, { params: { roleId } })
    .then(() => state => state.updateIn(['customRole', 'settings', 'notifications', 'leadTimeTasks', index], update))
    .catch(() => {
      this.alert.error({ message: 'There was an error updating the Lead Time Task.' });
      this.controller.dispatch([
        state => state.setIn(['customRole', 'settings', 'notifications', 'leadTimeTasks'], leadTimeTasksImmuted),
      ]);
    });
}
