import React from 'react';
import styled from 'styled-components';
import { useTimeAgo } from '../useTimeAgo';
import { UserProfileForm } from './UserProfileForm';
import { UserStatusCard } from '../UserStatusCard';

export const UserProfileDetails = ({ user, controller, alert, loggedInUserRole }) => {
  const name = `${user.get('firstName')} ${user.get('lastName')}`;

  return (
    <div className="d-flex flex-row justify-content-between">
      <UserProfileForm
        user={user}
        name={name}
        controller={controller}
        alert={alert}
        loggedInUserRole={loggedInUserRole}
      />
      <UserStatusCardContainer>
        <UserStatusCard user={user} className="ml-5 mt-4 pt-2">
          <CardContentMessage user={user} controller={controller} />
        </UserStatusCard>
      </UserStatusCardContainer>
    </div>
  );
};

export const CardContentMessage = ({ user, controller }) => {
  const status = user.get('status');
  const fullName = `${user.get('firstName')} ${user.get('lastName')}`;
  const firstLogin = useTimeAgo(user.get('firstLogin'));

  if (status === 'profile-created') {
    return (
      <>
        <span className="pl-1">
          Pending on {` `}
          <span className="text-dark">{fullName}</span>
          {` `}to log in.
        </span>
        <div className="pl-4">
          Forgot credentials?{` `}
          <button className="btn btn-link font-size-14 p-0 pb-1" onClick={() => controller.resetPassword(user)}>
            Reset Password
          </button>
        </div>
      </>
    );
  }
  if (status === 'invitation-sent') {
    const isSending = user.get('sendingInvite');

    return (
      <>
        Pending on {` `}
        <span className="text-dark">{fullName}</span>
        {` `}to accept.{` `}
        <button className="btn btn-link font-size-14 p-0 pb-1" onClick={() => controller.resendInvite(user)}>
          {isSending && (
            <span className="spinner-border spinner-border-sm mb-1 mr-1" role="status" aria-hidden="true" />
          )}
          {isSending ? 'Sending...' : 'Resend Invite'}
        </button>
      </>
    );
  }
  if (status === 'invitation-accepted') {
    return (
      <>
        Pending on {` `}
        <span className="text-dark">{fullName}</span>
        {` `}to log in.
      </>
    );
  }
  if (status === 'active') {
    return (
      <>
        First login: {` `}
        <span className="text-dark">{firstLogin}</span>
      </>
    );
  }
  if (status === 'inactive') {
    const userPrefix = user.get('domain') !== 'builder' ? 'User' : 'Builder';
    return (
      <>
        {`${userPrefix}  ${fullName} has been deactivated.`}
        <button
          className="d-block btn btn-link font-size-14 p-0 pb-1 ml-3"
          onClick={() => controller.toggleActive(user)}
        >
          Reactivate User
        </button>
      </>
    );
  }
  return (
    <>
      User <span className="text-dark">{fullName}</span> hasn’t signed in.
    </>
  );
};

const UserStatusCardContainer = styled.div`
  display: none;
  @media (min-width: 1200px) {
    display: block;
  }
`;
