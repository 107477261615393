import React from 'react';
import styled from 'styled-components';
import { colors } from '@tradetrax/web-common';

export const LoadingStages = ({ howMany = 3 }) => {
  return (
    <div className="pt-1">
      {Array.from({ length: howMany }, (v, k) => (
        <Card key={k} status="not-started">
          <PlaceHolder className="mt-2 mb-4" style={{ width: 160 }} />
          <PlaceHolder className="mt-3 mb-3" style={{ width: 420 }} />
          <PlaceHolder className="mt-3 mb-3" style={{ width: 510 }} />
        </Card>
      ))}
    </div>
  );
};

const PlaceHolder = styled.span`
  height: 12px;
  background-color: #e0e2e7;
  border-radius: 5px;
  display: inline-block;
`;

const Card = styled.div`
  ${props => `
    background: ${colors.pearl};
    // border: 1px solid ${colors.gray100};
    box-sizing: border-box;
    // box-shadow: 0px 1px 2px rgba(51, 51, 51, 0.2);
    border-radius: 0.375rem;
    margin-bottom: 0.5rem;
    padding: 1rem 1rem 1rem 1.5rem;
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 178px;
    cursor: pointer;
    width: 660px;
`}
`;
