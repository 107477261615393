import React from 'react';
import { TrxTypeahead, renderMenu } from './index';

export const TasksTypeahead = ({ ...props }) => <TrxTypeahead {...props} renderMenu={customRenderMenu} />;

const customRenderMenu = props => (results, menuProps) => renderMenu(props, menuProps, results, menuItemLabel);

const menuItemLabel = option => {
  if (option.paginationOption) return <div className="text-secondary text-center">{option.label || 'See more...'}</div>;
  const isSubTask = option.get('isChildTask');
  const parentTaskName = option.get('parentTaskName');
  const subTaskLabel = parentTaskName ? `From ${parentTaskName}` : "This Sub-Task doesn't belong to any Task";
  return (
    <div className="d-flex flex-column" style={{ width: '100%' }}>
      <div className="d-flex flex-row justify-content-between align-items-center">
        <div className="w-75 text-truncate">{`${option.get('name')} `}</div>
        {isSubTask && <span className="text-muted font-size-14">Sub-Task</span>}
      </div>
      {isSubTask && <div className="text-muted font-size-14 w-75 text-truncate">{subTaskLabel}</div>}
    </div>
  );
};
