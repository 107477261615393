import React from 'react';
import { Link, Route } from 'react-router-dom';
import { Breadcrumb } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToggleButtons, ToggleButton } from '@tradetrax/web-common';
import { InlineInputEdit } from '@tradetrax/web-common/lib/EditableField/InlineInputEdit';
import { Notifications } from './Notifications';
import { FilterSets } from './FilterSets';
import { Context, nameSchema, CustomRoleDetailsContext } from './CustomRoleDetails.context';
import { checkValidName, NOTIFICATIONS, FILTER_SETS, PERMISSIONS } from './CustomRoleDetails.common';
import { UserPermissions } from '@tradetrax/web-common/lib/UserProfile/UserPermissions';

import { Paths } from 'app/navigate';
import navigate from 'app/navigate';

export function CustomRoleDetails({ match, customRoles, globalTasks }) {
  const { customRoleId, customRoleTab: tab = 'notifications' } = match.params;
  const context = Context(customRoleId);
  const { state, controller, loggedUser } = context;
  const { customRole, filterSets } = state.toObject();

  const onTabClick = tab => {
    navigate.from.SettingsCustomRoleDetails.to.SettingsCustomRoleDetails({ customRoleTab: tab });
  };

  return (
    <CustomRoleDetailsContext.Provider value={context}>
      <Breadcrumb className="mx-4 mt-4" tag="nav">
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: Paths.SettingsCustomRoles }}>
          Custom Roles
        </Breadcrumb.Item>
        <Breadcrumb.Item active tag="span">
          {customRole.get('name')}
        </Breadcrumb.Item>
      </Breadcrumb>
      <RoleNameInputEdit customRole={customRole} controller={controller} customRoles={customRoles} />
      <div className="d-flex flex-row justify-content-between mt-4 mb-4">
        <ToggleButtons onChange={onTabClick} type="radio" name="options" value={tab}>
          <ToggleButton value={NOTIFICATIONS}>Notifications</ToggleButton>
          <ToggleButton value={FILTER_SETS}>Filter Sets</ToggleButton>
          <ToggleButton value={PERMISSIONS}>Permissions</ToggleButton>
        </ToggleButtons>
      </div>
      <Route
        path={Paths.SettingsCustomRoleFilterSets}
        render={() => <FilterSets controller={controller} filterSets={filterSets} loggedUser={loggedUser} />}
      />
      <Route path={Paths.SettingsCustomRoleNotifications} render={() => <Notifications {...context} />} />
      <Route
        path={Paths.SettingsCustomRolePermissions}
        render={() => <UserPermissions controller={controller} user={customRole} isCustomRoleBuilder />}
      />
    </CustomRoleDetailsContext.Provider>
  );
}

function RoleNameInputEdit({ customRole, customRoles, controller }) {
  const { active, name } = customRole.toObject();
  const isNameValid = (name, setError) => checkValidName(name, customRoles, setError);

  if (active)
    return (
      <InlineInputEdit
        labelClassName="h4 mx-3 mt-2 mb-4 font-weight-bold"
        inputClassName="ml-4"
        inputSize="fit-content"
        value={name}
        field="name"
        schema={nameSchema}
        update={controller.updateName}
        onBlur={isNameValid}
      />
    );

  return (
    <>
      <span className="font-weight-bold h4 ml-2">{name}</span>
      <button className="btn btn-link text-green-300 mr-5 pr-5" onClick={() => controller.toggleRoleActive(customRole)}>
        <FontAwesomeIcon icon="circle-plus" className="mr-2" />
        Reactivate Custom Role
      </button>
    </>
  );
}
