import { useEffect } from 'react';
import { fromJS, Map, List } from 'immutable';
import { useController, markAsSideEffect } from '@tradetrax/web-common';
import { useAppContext } from 'app/App.context';
import { buildersService } from 'services';
import JobNew from './JobNew';

const emptyState = Map({
  jobs: List(),
  totalCount: 10,
  maxCount: 10,
  communities: [], // as array to be used on typeahead component
  templates: [], // as array to be used on typeahead component
});

const actions = { loadMoreRows, openNewJobModal, createJob, readCommunities };

export const MINIMUM_BATCH_SIZE = 25;

export const JobPageController = () => {
  const appContext = useAppContext();
  const [state, controller] = useController(actions, emptyState, appContext);
  const { modal } = appContext;

  useEffect(() => {
    controller.readCommunities();
  }, [controller]);

  return { state, controller, modal };
};

function loadMoreRows({ startIndex, stopIndex }) {
  return buildersService.readJobs({}, { query: { start_index: startIndex, stop_index: stopIndex } }).then(data => {
    const totalCount = data.metadata.pagination ? data.metadata.pagination.totalCount : 0;
    return state =>
      state
        .set('totalCount', totalCount)
        .set('maxCount', stopIndex + MINIMUM_BATCH_SIZE + 1)
        .update('jobs', jobs => jobs.splice(startIndex, stopIndex - startIndex + 1, ...fromJS(data).toArray()));
  });
}

// // TODO: have an endpoint which return all the templates with basic info + NO pagination
// function readTemplates() {
//   return buildersService
//     .getTemplates()
//     .then(templates => templates.map(({ _id, name }) => ({ _id, name })))
//     .then(templates => state => state.set('templates', templates));
// }

// // TODO: have an endpoint which return all the communities with basic info + NO pagination
// function readCommunities() {
//   return buildersService
//     .readCommunities({}, { query: { start_index: 0, stop_index: 99 } })
//     .then(communities => state => state.set('communities', communities));
// }

markAsSideEffect(openNewJobModal);
function openNewJobModal() {
  const { modal, controller, alert, state } = this; //appContext
  const jobs = state.get('jobs');
  const communities = state
    .get('communities')
    .toJS()
    .map(({ _id, name }) => ({ _id, name }));

  modal.open(JobNew, { controller, communities, alert, jobs });
}

markAsSideEffect(createJob);
function createJob({ name, templateId, communityId, lotNumber, squareFootage }) {
  const job = { name, templateId, communityId };

  if (lotNumber) job.lotNumber = lotNumber;
  if (squareFootage) job.squareFootage = squareFootage;

  return buildersService.createJob(job).then(({ _id }) => _id);
}

function readCommunities() {
  return buildersService.readCommunities().then(communities => state => state.set('communities', fromJS(communities)));
}
