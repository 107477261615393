import React from 'react';
import cn from 'classnames';
import { Item, Name, status, isBuilder } from './common';
import { formatTrx, isUserInactive } from '../../utils';
import { UserNameLabel } from '../../Tags';

const Card = ({ item, account, user, text, isInactive }) => {
  const date = field => formatTrx(item.getIn(['data', field]));
  const assigneeAccountName = item.getIn(['data', 'assigneeAccountName']);
  const assigneeAccountId = item.getIn(['data', 'assigneeAccountId']);
  const isSameAccount = assigneeAccountId === account.get('_id');
  const assigned = (label, field) => {
    const value = item.getIn(['data', field, 'name']) || 'Needs Assignment';
    const id = item.getIn(['data', field, '_id']);
    const isInactive = item.getIn(['data', field, 'status']) === 'inactive';
    return (
      <div className="text-truncate">
        {label} assigned:{' '}
        <UserNameLabel
          className={cn('text-dark', {
            highlight: isBuilder(account),
            'font-weight-bold': id === user.get('_id'),
          })}
          isInactive={isInactive}
        >
          {value}
        </UserNameLabel>
      </div>
    );
  };

  return (
    <Item item={item} user={user}>
      <Name item={item} account={account} user={user} isInactive={isInactive} /> {text}
      <div className="d-flex flex-column" style={{ width: '380px' }}>
        <div>
          Exp. Start: {date('expectedStartDate')} - Exp. Finish: {date('expectedEndDate')}
        </div>
        {assigneeAccountName && (
          <div className="text-truncate w-100 d-flex align-items-center">
            Account assigned:{' '}
            <span
              className={cn('text-dark d-inline-block text-truncate ml-1', {
                highlight: isSameAccount,
                'font-weight-bold': isSameAccount,
              })}
            >
              {assigneeAccountName}
            </span>
          </div>
        )}
        <div>Task Status: {status(item)}</div>
        {assigned('Super', 'builderUserSuper')}
        {assigned('Scheduler', 'builderUserScheduler')}
      </div>
    </Item>
  );
};

export const JobRTC = ({ item, account, user, isInactive }) => (
  <Card item={item} account={account} user={user} isInactive={isInactive} text="released to construction" />
);

export const TaskAddedToRTC = ({ item, account, user, isInactive }) => (
  <Card item={item} account={account} user={user} isInactive={isInactive} text="added to Job" />
);
