import { fromJS, Repeat } from 'immutable';
import { markAsSideEffect, markAsSync } from '../../useController';
import { FILTER_NAME } from '../../Filters/Implementation/filter.name';

const BATCH_SIZE = 10;

markAsSync(getRecentActivities);
export function getRecentActivities(state, start_index = 0, stop_index = BATCH_SIZE - 1) {
  const filter = this.filtersState.getIn([FILTER_NAME.RECENT_ACTIVITY, 'values']).toJS();
  const query = getQueryParams({ start_index, stop_index }, filter, this.isTrade);
  this.dashboardService
    .getRecentActivities({}, { query })
    .then(data => {
      const totalCount = data.metadata.pagination ? data.metadata.pagination.totalCount : 0;
      const items = fromJS(data).toArray();
      this.controller.dispatch([
        state =>
          state.update('recentActivities', recentActivities =>
            recentActivities
              .set('isLoading', false)
              .set('totalCount', totalCount)
              .update('activities', activities => {
                let updated = activities;
                if (start_index === 0) updated = activities.slice(start_index, stop_index - start_index + 1);
                return updated.splice(start_index, stop_index - start_index + 1, ...items);
              })
          ),
      ]);
    })
    .catch(error => {
      console.log(error);
    });

  const data = Repeat({ group: 'loading' }, stop_index - start_index);
  const result = fromJS(data).toArray();
  return state.updateIn(['recentActivities', 'activities'], activities =>
    activities.splice(start_index, stop_index - start_index + 1, ...fromJS(result))
  );
}

markAsSideEffect(loadMoreRowsRecentActivity);
export function loadMoreRowsRecentActivity() {
  const { totalCount, activities } = this.state.get('recentActivities').toObject();

  if (activities.size < totalCount) {
    this.controller.getRecentActivities(activities.size, activities.size + BATCH_SIZE - 1);
  }
}

function getQueryParams(query = {}, filter, isTrade) {
  if (!filter) return query;

  const events = [];
  if (filter['task-assigned']) events.push('task-assigned');
  if (filter['attachments']) events.push('attachment-added-to-task');
  if (filter['update-request']) events.push('sub-creates-change-request-to-task');
  if (isTrade && filter['update-request']) events.push('installer-creates-change-request-to-task');
  if (filter['notes']) events.push('note-added-to-task', 'tagged-in-note');
  if (filter['filter-sets']) events.push('filter-shared');

  query.events = events;
  return query;
}
