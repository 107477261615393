import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { ToggleButtons, ToggleButton } from '@tradetrax/web-common';
import { Breadcrumb } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Paths } from 'app/navigate';
import { StagesSettings } from './Stages/StagesSettings';
import { StagesSettingsContext } from './TemplateSettingsContext';
import { StartDateConfirmationSettings } from './StartDateConfirmations/StartDateConfirmationsSettings';

const START_DATE_CONFIRMATIONS = 'start-date-confirmations';
const STAGES = 'stages';

export const TemplateSettings = ({ location, match }) => {
  const { templateId } = match.params;
  const defaultTab = location.state?.tab || START_DATE_CONFIRMATIONS;
  const context = StagesSettingsContext(templateId, defaultTab);
  const { state, controller } = context;
  const { template, tab } = state.toObject();

  React.useEffect(() => {
    controller.setTab(defaultTab);
  }, [controller, defaultTab]);

  return (
    <>
      <div className="my-4 mx-4 mr-auto">
        <div className="d-flex flex-row align-items-center justify-content-between">
          <Breadcrumb tag="nav">
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: Paths.Templates }}>
              Templates
            </Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: './' }}>
              {template.get('name')}
            </Breadcrumb.Item>
            <Breadcrumb.Item active tag="span">
              Settings
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="h4 mt-1 mb-4 pb-1 font-weight-bold d-block">Settings for {template.get('name')}</div>
      </div>
      <ToggleButtons className="mb-2 mt-4 mr-auto" type="radio" name="options" value={tab} onChange={controller.setTab}>
        <ToggleButton value={START_DATE_CONFIRMATIONS}>
          Start Date Confirmations
          <FontAwesomeIcon
            className={cn('text-danger ml-2', { 'd-none': template.get('hasCompleteAccountabilities') })}
            icon="circle-exclamation"
          />
        </ToggleButton>
        <ToggleButton value={STAGES}>
          Stages Configuration
          <FontAwesomeIcon
            className={cn('text-danger ml-2', { 'd-none': template.get('hasCompleteStages') })}
            icon="circle-exclamation"
          />
        </ToggleButton>
      </ToggleButtons>
      <div className="pl-3 pr-3" style={{ maxWidth: '800px' }}>
        {tab === STAGES ? <StagesSettings context={context} /> : <StartDateConfirmationSettings context={context} />}
      </div>
    </>
  );
};
