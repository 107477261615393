import React from 'react';
import { Name } from './common';
import { mongoToHistory, getUserDomain } from '../../utils';

export const useGeoLocationProps = (item, account, user, isInactive, modal, eventName) => {
  return React.useMemo(() => {
    const coordinates = item.getIn(['data', 'location', 'locationGeoJSON', 'coordinates']);
    const { isBuilder } = getUserDomain(user);
    const assigneeAccountId = item.get('assigneeAccountId');
    const accountId = account.get('_id');
    const isAccountAssigned = accountId === assigneeAccountId;
    const isLocationEnabled = isBuilder
      ? account
          .get('tradesLocationSettings')
          .find(setting => setting.get('subAccountId') === assigneeAccountId)
          ?.get('locationTrackingEnabled')
      : isAccountAssigned && account.get('locationTrackingEnabled');
    if (!coordinates) return null;
    if (!isLocationEnabled) return null;

    const [lng, lat] = coordinates.toJS();
    const geoLocation = { lat, lng };
    const title = item.get('taskName');
    const subTitle = (
      <>
        <span className="text-secondary">Check-{eventName} by </span>
        <Name item={item} accountId={accountId} user={user} isInactive={isInactive} />
        <span className="trx-time text-secondary"> {mongoToHistory(item.get('createdOn'))}</span>
      </>
    );

    return { geoLocation, modal, title, subTitle };
  }, [item, user, account, isInactive, modal, eventName]);
};
