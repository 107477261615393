export const NOTIFICATIONS = 'notifications';
export const FILTER_SETS = 'filter-sets';
export const PERMISSIONS = 'permisions';

export const MY_TASKS = 'my-tasks';
export const RELEVANT_TASKS = 'relevant-tasks';
export const LEAD_TIME = 'lead-time';

//TODO: deprecate in favor of the same in ../CustomRoles.common.js
export function checkValidName(name, customRoles, setError) {
  const nameExisis = customRoles?.filter(role => role.get('name').toLowerCase() === name.toLowerCase());
  if (nameExisis?.size > 0 && setError) setError('name', { type: 'notMatch', message: 'Name already exists' });
  return !nameExisis?.size;
}
