import React from 'react';
import styled from 'styled-components';
import { Button, InputGroup } from 'react-bootstrap';
import { TasksTypeahead } from '../Typeahead/TasksTypeahead';
import { colors } from '../theme';

const removeDuplicated = taskIds => task => !taskIds.includes(task.get('_id'));
const removeSubTasks = task => !task.get('isChildTask');
const isEmpty = collection => collection.get('isEmpty');
const isLoading = collection => collection.get('isLoading');

export const SearchGTL = ({ selectedTasks, expand, addTask, state }) => {
  const [selected, setSelected] = React.useState([]);
  const globalTasks = state.get('globalTasks');

  const tasks = React.useMemo(() => {
    if (!selectedTasks.size || isEmpty(selectedTasks) || isLoading(selectedTasks))
      return globalTasks.filter(removeSubTasks).toArray();
    const taskIds = selectedTasks.map(task => task.get('gtlTaskId') || task.get('_id'));
    return globalTasks
      .filter(removeDuplicated(taskIds))
      .filter(removeSubTasks)
      .toArray();
  }, [globalTasks, selectedTasks]);

  const addTaskSearchGTL = e => {
    e.preventDefault();
    const [task] = selected;
    const taskId = task.get('_id');
    addTask(task);
    expand && expand(taskId);
    setSelected([]);
  };

  return (
    <InputGroup className="mb-5">
      <TasksTypeaheadComp
        id="gtl"
        name="taks"
        bsSize="lg"
        placeholder="Search from global task library..."
        labelKey={option => option.get('name')}
        options={tasks}
        selected={selected}
        onChange={setSelected}
      />
      <Append>
        <Button className="badge-pill px-4" onClick={addTaskSearchGTL} disabled={selected.length === 0}>
          Add Task
        </Button>
      </Append>
    </InputGroup>
  );
};

const TasksTypeaheadComp = styled(TasksTypeahead)`
  .close.rbt-close {
    display: none;
  }
`;

export const Append = styled(InputGroup.Append)`
  .btn {
    border-radius: 10rem !important;
    position: relative;
    right: 3rem;
    z-index: 100;
    position: relative;

    &:hover {
      background-color: ${colors.blue400};
    }

    &.btn-primary:disabled {
      background-color: ${colors.gray200};
      border-color: ${colors.gray200};
      opacity: 1;
    }
  }
`;
