import React from 'react';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToggleOption } from '../Components/Filter.advanced.toggle.option';
import { DurationOption } from './Filter.advanced.duration';
import { DateOption } from './Filter.date.option';
import { Counter } from './Filter.counter';
import { jobEmptyFilterValues } from '../Implementation/Filter.empty';

const ADVANCED_FIELDS = ['dateConfirmation', 'criticalTasks', 'startDate', 'finishDate', 'duration'];

export function AdvancedFilter({ filterContext }) {
  const { filterController, isCollapsed, filterState } = filterContext;
  const isSectionCollapsed = isCollapsed(`advanced-filter-section`, false);
  const toggleCollapsed = () => {
    filterController.defer.setCollapsedSection(`advanced-filter-section`, !isSectionCollapsed);
  };

  return (
    <>
      <div className="d-flex justify-content-between py-4 border-top">
        <span className="font-weight-bold">Advanced Filters</span>
        <span>
          <Counter values={filterState.get('values')} fields={ADVANCED_FIELDS} emptyValues={jobEmptyFilterValues} />
          <FontAwesomeIcon
            className="mx-3 cursor-pointer"
            icon={`chevron-${isSectionCollapsed ? 'down' : 'up'}`}
            onClick={toggleCollapsed}
          />
        </span>
      </div>
      <div className={cn({ 'd-none': isSectionCollapsed })}>
        <ToggleOption.DateConfirmation filterContext={filterContext} />
        <ToggleOption.CriticalTasks filterContext={filterContext} className="mt-3" />
        <DateOption filterContext={filterContext} fieldName="startDate" />
        <DateOption filterContext={filterContext} fieldName="finishDate" />
        <DurationOption filterContext={filterContext} />
      </div>
    </>
  );
}
