import { fromJS, Map } from 'immutable';

export const emptyTaskList = fromJS([]);
export const emptyExpandedState = Map({ stages: Map(), list: Map() });
export const emptyFeedCount = fromJS([{ count: 3 }, { count: 3 }]);

export const emptyJob = fromJS({
  _id: 'aaaaaabbbbbbccccccdddddd',
  name: '',
  status: '',
  tasks: [],
  communityId: 'aaaaaabbbbbbccccccdddddd',
  statusData: {},
  templateId: 'aaaaaabbbbbbccccccdddddd',
});

export const emptyTask = fromJS({
  name: '',
  duration: 0,
  startDate: '',
  status: '',
  assigneeAccountId: '',
});
