import moment from 'moment';
import { fromJS, List } from 'immutable';
import { buildersService } from 'services';

const DATE_FORMAT_ISO = 'YYYY-MM-DD';

export function viewCommitments() {
  const date = moment().format(DATE_FORMAT_ISO);

  return buildersService
    .viewCommitments({}, { query: { date, status: 'denied', viewType: 'view-task-type' } })
    .then(fromJS)
    .then(deniedRequests =>
      deniedRequests
        .reduce((result, task) => {
          const { gtlTaskId, gtlTaskName, communitiesByTask } = task.toObject();
          const currentDate = moment();
          const formatYearIfNeeded = date => {
            return date.year() !== currentDate.year() ? date.format(', Y') : '';
          };

          communitiesByTask.forEach(community => {
            const from = moment(community.get('from'), DATE_FORMAT_ISO);
            const to = moment(community.get('to'), DATE_FORMAT_ISO);
            const isSameMonth = from.month() === to.month();
            const isSameYear = from.year() === to.year();
            let weekLabel;
            if (isSameYear) {
              weekLabel = `${from.format('MMMM D')} - ${to.format(isSameMonth ? 'D' : 'MMMM D')}${formatYearIfNeeded(
                from
              )}`;
            } else {
              weekLabel = `${from.format('MMMM D')}${formatYearIfNeeded(from)} - ${to.format(
                'MMMM D'
              )}${formatYearIfNeeded(to)}`;
            }

            result = result.push(community.merge({ gtlTaskId, gtlTaskName, weekLabel }));
          });

          return result;
        }, List())
        .groupBy(request => request.get('weekLabel'))
        .mapEntries(([week, tasks]) => [week, tasks.groupBy(task => task.get('gtlTaskId'))])
    )
    .then(deniedRequests => state => state.set('deniedRequests', deniedRequests));
}
