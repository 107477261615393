import styled from 'styled-components';
import { colors } from '@tradetrax/web-common';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  overflow: hidden;
  height: 100%;
  margin-left: -1.5rem;
  .main-link {
    &:hover {
      color: ${colors.blue300};
      text-decoration: underline;
    }
    color: ${colors.gray800};
  }
  span {
    padding-left: 2rem;
  }
  .list-area {
    overflow: scroll;
    height: 100%;
  }
  li.is-loading {
    border-top: none;
    span {
      margin-left: 2rem;
    }
  }
`;
