import React from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import { Dropdown } from 'react-bootstrap';
import { colors } from '../../theme';
import Datepicker from '../../Datepicker';
import * as yup from 'yup';
import { DropdownToggle } from './Filter.advanced.toggle.button';
import moment from 'moment';
import { ValidationFeedback } from './Filter.feedback';

const DATE_LABELS = {
  startDate: 'Exp. Start Date',
  finishDate: 'Exp. Finish Date',
};

const DROPDOWN_OPTIONS = {
  any: 'Any',
  equal: 'Equal To',
  from: 'From',
  missed: 'Missed',
};

const MISSED_LABEL = {
  startDate: 'Missed Starts',
  finishDate: 'Missed Finishes',
};

export function DateOption({ filterContext, fieldName, className = 'mt-3 mb-4', ...rest }) {
  const { filterState, filterController } = filterContext;
  const errors = filterState.get('errors');
  const dateFilter = filterState.getIn(['values', fieldName]);
  const dateFilterType = dateFilter.get('type');
  const isEqual = dateFilterType === 'equal';
  const isRange = dateFilterType === 'from';
  const name = fieldName;
  const missedLabel = MISSED_LABEL[fieldName];

  const dropDownOnChange = type => {
    filterController.defer.updateFieldValue(fieldName, dateFilter.merge({ type }));
  };

  const onDateChange = (type, date) => {
    // const dateUpdated = dateObject.set(type, date).toJS();
    // const isValid = validate(dateUpdated, type);
    // const isDateValid = date && compareDates(date, fieldName, type);
    // const hasError = !isValid || !isDateValid;
    // events.emit('filter:hasError', hasError);
    // updateCounter(fieldName, !hasError);

    filterController.defer.updateFieldValue(fieldName, dateFilter.merge({ [type]: date }));
  };

  // const handleOnChangeType = type => {
  //   if (type === 'any' || type === 'missed') events.emit('filter:hasError', false);
  //   rest.updateCounter(fieldName, type === 'missed');
  //   rest.clearField(fieldName);
  //   validation.clearErrors(fieldName);
  //   onChange(type);
  // };

  const props = {
    // events,
    fieldName,
    dateFilter,
    onDateChange,
    errors,
    calendarClassName: 'do-not-close-popup',
    // control,
    // validation,
  };

  const dropDownLabel = dateFilterType === 'missed' ? missedLabel : DROPDOWN_OPTIONS[dateFilterType];

  return (
    <div className={`d-flex flex-row align-items-center ${className}`}>
      <span className="text-capitalize w-25 text-gray-400">{DATE_LABELS[name]}</span>
      <Dropdown onSelect={dropDownOnChange}>
        <Dropdown.Toggle as={DropdownToggle} label={dropDownLabel} />
        <Dropdown.Menu>
          <DropDownOption eventKey="any" active={dateFilterType === 'any'}>
            Any
          </DropDownOption>
          <DropDownOption eventKey="equal" active={dateFilterType === 'equal'}>
            Equal To
          </DropDownOption>
          <DropDownOption eventKey="from" active={dateFilterType === 'from'}>
            From
          </DropDownOption>
          <DropDownOption eventKey="missed" active={dateFilterType === 'missed'}>
            {missedLabel}
          </DropDownOption>
        </Dropdown.Menu>
      </Dropdown>
      <Wrapper>
        <DateField type="dateEqual" show={isEqual} {...props} />
        <DateField type="dateFrom" show={isRange} {...props} />
        {/* <DateField type="dateFrom" show={isRange} maxDate={maxEarlest} {...props} /> */}
        <span className={cn('mx-2', { 'd-none': !isRange })}>to</span>
        <DateField type="dateTo" show={isRange} autofocus={false} {...props} />
        {/* <DateField type="dateTo" show={isRange} minDate={minLatest} autofocus={false} {...props} /> */}
      </Wrapper>
      {/* <DateSelect {...props} {...rest} /> */}
    </div>
  );
}

const DateField = ({
  dateFilter,
  fieldName,
  type,
  // control,
  show,
  onDateChange,
  errors,
  // Feedback,
  autofocus = true,
  ...rest
}) => {
  const name = `${fieldName}.${type}`;
  const isInvalid = !!errors?.has(name);
  const datepickerRef = React.useRef();
  // const { current } = datepickerRef;
  const value = dateFilter.get(type);
  const dateValue = value
    ? moment(value)
        .startOf('day')
        .toDate()
    : null;
  // moment()
  //     .startOf('day')
  //     .toDate();

  if (!show) return null;
  // React.useEffect(() => {
  //   const isAutofocus = autofocus && current && show;
  //   if (isAutofocus) datepickerRef.current.setFocus(true);
  // }, [current, show]);

  // onBlur={() => handleOnChange(value, fieldName, type)}
  return (
    <DatepickerWrapper className={cn({ 'd-none': !show })}>
      <Datepicker
        {...rest}
        name={name}
        placeholderText="MM/DD/YY"
        popperPlacement="top-end"
        selected={dateValue}
        isInvalid={isInvalid}
        datePickerRef={datepickerRef}
        onChange={date => {
          onDateChange(type, date);
        }}
      />

      {/* {isInvalid && <Feedback field={type} />} */}
      <ValidationFeedback field={name} errors={errors} />
    </DatepickerWrapper>
  );
};

// const DateToggleButton = React.forwardRef(({ onClick, eventKey, missedLabel }, ref) => {
//   const label = eventKey === 'missed' ? missedLabel : DROPDOWN_OPTIONS[eventKey];
//   return (
//     <ToggleButton
//       className="btn btn-lnk d-flex justify-content-between text-capitalize"
//       ref={ref}
//       onClick={e => {
//         e.preventDefault();
//         onClick(e);
//       }}
//     >
//       {label}
//       <FontAwesomeIcon icon="chevron-down" className="ml-2" />
//     </ToggleButton>
//   );
// });

// const DateSelect = ({
//   dateObject,
//   fieldName,
//   compareDates,
//   control,
//   validation,
//   restrictions,
//   updateCounter,
//   events,
// }) => {
//   const type = dateObject.get('type');
//   const isEqual = type === 'equal';
//   const isRange = type === 'from';
//   const { validate, errors, Feedback } = validation;
//   const { minLatest, maxEarlest } = restrictions[fieldName];

//   const handleOnChange = (date, fieldName, type) => {
//     const dateUpdated = dateObject.set(type, date).toJS();
//     const isValid = validate(dateUpdated, type);
//     const isDateValid = date && compareDates(date, fieldName, type);
//     const hasError = !isValid || !isDateValid;
//     events.emit('filter:hasError', hasError);
//     updateCounter(fieldName, !hasError);
//   };

//   const props = {
//     fieldName,
//     control,
//     updateCounter,
//     dateObject,
//     handleOnChange,
//     errors,
//     Feedback,
//   };
//   return (
//     <Wrapper>
//       <DateField type="dateEqual" show={isEqual} {...props} />
//       <DateField type="dateFrom" show={isRange} maxDate={maxEarlest} {...props} />
//       <span className={cn('mx-2', { 'd-none': !isRange })}>to</span>
//       <DateField type="dateTo" show={isRange} minDate={minLatest} autofocus={false} {...props} />
//     </Wrapper>
//   );
// };

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  .react-datepicker__input-container {
    width: 135px;
  }

  .invalid-feedback {
    max-width: 135px;
  }
`;

const DatepickerWrapper = styled.div`
  .react-datepicker-wrapper {
    display: block;
  }
`;

const DropDownOption = styled(Dropdown.Item)`
  &.dropdown-item.active {
    background-color: ${colors.blue100};
    color: ${colors.blue300};
  }
`;

const dateSchema = yup.object().shape({
  type: yup.string(),
  dateEqual: yup.date().when('type', {
    is: type => type === 'equal',
    then: schema => schema.typeError('Select a date').required('Select a date'),
    otherwise: schema => schema.nullable(),
  }),
  dateFrom: yup.date().when('type', {
    is: type => type === 'from',
    then: schema => schema.typeError('Select a date').required('Select a date'),
    otherwise: schema => schema.nullable(),
  }),
  dateTo: yup.date().when('type', {
    is: type => type === 'from',
    then: schema => schema.typeError('Select a date').required('Select a date'),
    otherwise: schema => schema.nullable(),
  }),
});
