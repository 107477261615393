import React from 'react';
import { Button, InputGroup } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Append } from '@tradetrax/web-common/lib/Inputs/SearchGTL';

const isEmpty = collection => collection.get('isEmpty') === true;
const isLoading = collection => collection.get('isLoading') === true;

export function AddTaskInput({ context, tasks }) {
  const { controller, state } = context;
  const accountabilityTasks = state.getIn(['accountability', 'tasks']);
  const recommendedTasks = state.getIn(['accountability', 'recommended']);
  const [selected, setSelected] = React.useState([]);
  const options = React.useMemo(() => {
    if (isEmpty(accountabilityTasks) || isLoading(accountabilityTasks)) return [];
    if (isEmpty(recommendedTasks) || isLoading(recommendedTasks)) return [];
    const taskIds = recommendedTasks.concat(accountabilityTasks).map(task => task.get('taskId'));
    return tasks.filter(task => !taskIds.includes(task.get('_id')));
  }, [tasks, accountabilityTasks, recommendedTasks]);
  const addTask = e => {
    e.preventDefault();
    const [task] = selected;
    controller.addTask(task);
    setSelected([]);
    controller.expand(
      task
        .get('name')
        .toLowerCase()
        .replace(/\s/g, '-')
    );
  };
  return (
    <InputGroup className="mb-lg-3 pb-3">
      <Typeahead
        id="gtl"
        name="taks"
        bsSize="lg"
        placeholder="Search a Task from this Template"
        labelKey={option => option.get('name')}
        options={options}
        selected={selected}
        onChange={setSelected}
      />
      <Append>
        <Button className="badge-pill px-4" onClick={addTask} disabled={selected.length === 0}>
          Add Task
        </Button>
      </Append>
    </InputGroup>
  );
}
