import { markAsSync } from '@tradetrax/web-common/lib/useController';
import { fromJS, Map } from 'immutable';
import { buildersService } from 'services';

markAsSync(loadTaskOverdueReport);
export function loadTaskOverdueReport(state) {
  const body = this.getFilters(state);

  buildersService
    .getTasksOverdueByAssigneeAccount(body)
    .then(fromJS)
    .then(data => {
      const model = data
        .groupBy(d => d.get('communityId'))
        .toList()
        .map(group => {
          const first = group.first();
          const trades = group
            .groupBy(job => job.getIn(['assigneeAccount', 'companyId']))
            .toList()
            .map(jbt => {
              const totals = jbt.reduce(
                (total, job) => {
                  total.totalTasks += job.getIn(['job', 'totalTasks']);
                  total.totalOverdue += job.getIn(['job', 'overdueTasks']);
                  total.missedStarts += job.getIn(['job', 'missedStarts']);
                  total.missedFinishes += job.getIn(['job', 'missedFinishes']);
                  total.progress += job.getIn(['job', 'progress']);
                  return total;
                },
                {
                  totalTasks: 0,
                  totalOverdue: 0,
                  missedStarts: 0,
                  missedFinishes: 0,
                  progress: 0,
                  tradeAccountId: jbt.getIn([0, 'assigneeAccount', 'companyId']),
                  tradeAccountName: jbt.getIn([0, 'assigneeAccount', 'companyName']),
                }
              );

              totals.onScheduleRate = Math.round(((totals.totalTasks - totals.totalOverdue) * 100) / totals.totalTasks);
              if (isNaN(totals.onScheduleRate)) totals.onScheduleRate = 0;

              totals.progress = Math.round(totals.progress / jbt.size);
              if (isNaN(totals.progress)) totals.progress = 0;

              return Map({
                ...totals,
                jobs: jbt.map(job => job.get('job')),
              });
            });

          return Map({
            trades,
            communityId: first.get('communityId'),
            communityName: first.get('communityName'),
            areaManagers: first.get('areaManagers'),
          });
        });

      this.controller.dispatch([state => state.set('data', model)]);
    })
    .catch(() => {
      this.alert.error({
        message: (
          <>
            There was a problem trying to generate this Report.
            <br />
            Please try again.
          </>
        ),
      });
    });

  return state.set('appliedFilters', state.get('filters'));
}
