import React from 'react';
import cn from 'classnames';
import { Map } from 'immutable';
import { ReportContainer, ReportEmptyState, AppliedFilters } from '@tradetrax/web-common/lib/Reports';
import { AreaManager } from '../Common';

export const AverageCycleTimeOfJobsBySuperAssigneeReport = ({ context }) => {
  const { state } = context;
  const [collapsed, setCollapsed] = React.useState(Map({}));
  const data = state.get('data');

  React.useEffect(() => {
    setCollapsed(Map());
  }, [data]);

  if (data.size === 0) return <ReportEmptyState />;

  return (
    <ReportContainer>
      <h4 className="font-weight-bold text-center mx-3 mt-4 pt-3">Average Cycle Time by Builder Assignee</h4>
      <AppliedFilters filters={state.get('appliedFilters')} />
      <div className="report-body">
        {data.toList().map(community => (
          <Community key={community.get('communityId')} community={community}>
            <div className="group-inner-header">
              <div className="cell-w250">Builder Super Assignee</div>
              <div className="cell-w250"># Jobs</div>
              <div className="cell-w250">Average Days</div>
              <div className="cell-w250"># Jobs Behind</div>
              <div className="cell-w250"># Date Changes</div>
            </div>
            {community
              .get('jobsBySuper')
              .toList()
              .map((jobsBySuper, userId) => (
                <GroupSuperAssignee
                  key={userId}
                  community={community}
                  communityId={community.get('communityId')}
                  setCollapsed={setCollapsed}
                  collapsed={collapsed}
                  jobsBySuper={jobsBySuper}
                >
                  {jobsBySuper
                    .get('jobs')
                    .map(job => <Job key={job.get('_id')} job={job} />)
                    .toArray()}
                </GroupSuperAssignee>
              ))}
          </Community>
        ))}
      </div>
    </ReportContainer>
  );
};

const Community = ({ community, children }) => {
  return (
    <div className="group-outter">
      <div className="group-outter-header d-flex flex-row justify-content-between mb-2">
        <span>
          Community:&nbsp;
          <span className="h5 font-weight-bold">{community.get('communityName')}</span>
        </span>
        <span>
          Area Manager: <AreaManager manager={community.getIn(['areaManagers', 0, 'name'])} />
        </span>
      </div>
      {children}
    </div>
  );
};

function computeDaysBehind(behind) {
  const daysBehind = behind ?? 0;
  const daysBehindLabel =
    behind < 0 ? `${Math.abs(behind)} Days Behind` : behind > 0 ? `${behind} Days Ahead` : 'On Schedule';
  const daysBehindClass = daysBehind < 0 ? 'danger' : daysBehind > 0 ? 'success' : 'dark';

  return { daysBehind, daysBehindLabel, daysBehindClass };
}

const Job = ({ job }) => {
  const { daysBehindClass, daysBehindLabel } = computeDaysBehind(job.get('daysBehind'));

  return (
    <div className="d-flex flex-row mb-1">
      <div className="cell-w250"></div>
      <div className="cell-w250">{job.get('name') || 'job name'}</div>
      <div className="cell-w250">{job.get('totalDays') ?? ''}</div>
      <div className={`cell-w250 text-${daysBehindClass}`}>{daysBehindLabel}</div>
      <div className="cell-w250">{job.get('dateChanges') ?? ''}</div>
    </div>
  );
};

const GroupSuperAssignee = ({ setCollapsed, collapsed, children, jobsBySuper, communityId }) => {
  const { averageDays, jobsBehind, dateChanges, userSuper, jobs } = jobsBySuper.toObject();
  const { daysBehindClass } = computeDaysBehind(jobsBehind);
  const key = `${communityId}_${userSuper.get('_id')}`;
  const isCollapsed = !!collapsed.get(key);

  return (
    <div className="group-inner">
      <div className="group-inner-summary">
        <div className="cell-w250">
          <button
            onClick={() => setCollapsed(collapsed => collapsed.set(key, !isCollapsed))}
            className="btn btn-link p-0 font-weight-bold text-dark"
          >
            {userSuper.get('name')}
          </button>
        </div>
        <div className="cell-w250">{jobs.size}</div>
        <div className="cell-w250">{averageDays}</div>
        <div className={`cell-w250 text-${daysBehindClass}`}>{Math.abs(jobsBehind)}</div>
        <div className="cell-w250">{dateChanges}</div>
      </div>

      <div className={cn('group-group-inner-body', { 'd-none': isCollapsed })}>{children}</div>
    </div>
  );
};
