import React from 'react';
import cn from 'classnames';
import { SystemItem } from './common';
import { formatTrx } from '../../utils';
import { UserNameLabel } from '../../Tags';

//start-date-confirmation-request
export default ({ item, account }) => {
  const assigneeAccountName = item.get('assigneeAccountName');
  const assigneeAccountId = item.get('assigneeAccountId');
  const isSameAccount = assigneeAccountId === account.get('_id');

  return (
    <SystemItem item={item}>
      Start date confirmation sent to{' '}
      <UserNameLabel className={cn('text-dark cursor-default', { 'font-weight-bold': isSameAccount })}>
        {assigneeAccountName}
      </UserNameLabel>{' '}
      for {formatTrx(item.getIn(['data', 'expectedEndDate']))}
    </SystemItem>
  );
};
