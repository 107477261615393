import React from 'react';
import cn from 'classnames';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { plural, getMinEndDate, datepickerFormat, mongoToTrx, formatISO, isDifferentYear } from '../../utils';
import { PrimaryButton, SecondaryButton } from '../../Button';
import { Impact } from '../../Task/Impact';
import { PopoverStickOnHover } from '../../Popover';
import Datepicker from '../../Datepicker';
import WarningCriticalMsg, {
  CriticalDay,
  criticalProposedDateClass,
  showWarningCriticalMsg,
} from '../../CriticalPath/WarningCriticalMsg';
import { CycleTimeImpact } from '../../CycleTimeImpact/RootCause/CycleTime.impact';
import { UpdateRequestState } from '../../CycleTimeImpact/UpdateRequest.state';

export function ChangeDateModal({ state, controller, accept, close }) {
  const { task, jobDelay, tasksAffected, tasksAffectedSize, proposedDate } = state.toObject();
  const urState = UpdateRequestState({ task, jobDelay, isDueWeather: false });
  const hasPredecessors = !!task.get('predecessors').size;
  const isEndDate = task.get('overdue') === 'finish';
  const isJobDelayed = jobDelay > 0;
  const impactText = jobDelay === 0 ? 'Cycle Time Impact' : isJobDelayed ? 'Increase Cycle Time' : 'Reduce Cycle Time';
  let date = isEndDate ? proposedDate.newEndDate : proposedDate.newStartDate;
  date = datepickerFormat(mongoToTrx(date));
  const numberTasksHeld = tasksAffected.filter(task => task.get('isHeld') && task.get('isDirectSuccesor')).size;
  const isClickable = tasksAffectedSize || numberTasksHeld;

  const onSubmit = () => {
    const { payload } = urState;
    const tasksHeld = tasksAffected
      .filter(task => task.get('isHeld') && task.get('isDirectSuccesor'))
      .map(task => task.get('id'));
    accept({ proposedDate, tasksHeld, ...payload });
  };

  return (
    <>
      <Modal.Header className="pb-0" closeButton>
        <Modal.Title>Change Date</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <h6 className="font-weight-bold">{`Task: ${task.get('name')}`}</h6>
        <div className="my-4">
          <span className="small d-block ">{`Expected ${isEndDate ? 'Finish' : 'Start'}`}</span>
          <ProposedDateSelect
            task={task}
            isEndDate={isEndDate}
            proposedDate={date}
            controller={controller}
            areTasksHeldEdited={numberTasksHeld > 0}
          />
        </div>
        <Impact>
          <li className={cn({ 'd-none': !numberTasksHeld })}>
            <span>Tasks Held</span>
            <span className="text-gray-400">{numberTasksHeld}</span>
          </li>
          <li
            onClick={() => isClickable && controller.showTasksAffectedView()}
            className={cn({
              clickable: isClickable,
              'text-danger': tasksAffectedSize,
            })}
          >
            <label className={cn('mb-0 mr-3', { 'font-weight-bold': tasksAffectedSize === 0 })}>Tasks Affected</label>
            <span className={cn('d-flex align-items-center', { 'text-gray-400': !tasksAffectedSize })}>
              {tasksAffectedSize}{' '}
              <FontAwesomeIcon
                icon="chevron-right"
                className={cn('font-size-12 ml-1 text-gray-400', { 'd-none': !isClickable })}
              />
            </span>
          </li>
          <CycleTimeImpact updateRequestState={urState} />
          <li className="border-top">
            <span className="font-weight-bold">Due to Weather</span>
            <input
              type="checkbox"
              checked={urState.isDueWeather}
              onChange={() => urState.setValue('isDueWeather', !urState.isDueWeather)}
            />
          </li>
        </Impact>
        <div>
          {!isEndDate && hasPredecessors && !isJobDelayed && (
            <p className="pt-3 font-size-14 text-gray-400">
              Manually changing the Exp. Start will also result in{' '}
              <strong>all Predecessor relationships being removed.</strong> Please double-check before confirming.
            </p>
          )}
          {isJobDelayed && (!hasPredecessors || isEndDate) && (
            <p className="pt-3 font-size-14 text-gray-400">
              By confirming this update will <strong>delay the finish date for the Job</strong>. Please double-check
              before confirming.
            </p>
          )}
          {isJobDelayed && hasPredecessors && !isEndDate && (
            <p className="pt-3 font-size-14 text-gray-400">
              By confirming this update will result in <strong>all Predecessor relationships being removed</strong>. It
              will also <strong>delay the finish date for the Job</strong>. Please double-check before confirming.
            </p>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex flex-row mt-4">
        <PrimaryButton variant="secondary" onClick={() => close()} className="ml-auto mr-3">
          Cancel
        </PrimaryButton>
        <PrimaryButton disabled={urState.hasErrors} onClick={onSubmit}>
          Confirm Date Change
        </PrimaryButton>
      </Modal.Footer>
    </>
  );
}

const ProposedDateSelect = ({ task, controller, isEndDate, proposedDate, areTasksHeldEdited }) => {
  const selectedDate = datepickerFormat(mongoToTrx(proposedDate));
  const minEndDate = isEndDate ? getMinEndDate(task.get('startDate')) : null;
  const [isYearDisplayed, setIsYearDisplayed] = React.useState(isDifferentYear(selectedDate));
  const [newProposedDate, setNewProposedDate] = React.useState(null);

  const [date1, date2] = isEndDate ? ['expectedFinishDate', 'lateEndDate'] : ['startDate', 'lateStartDate'];
  const expectedDate = task.get(date1) ? datepickerFormat(mongoToTrx(task.get(date1))) : '';
  const lateDate = task.get(date2) || undefined;
  const criticalDate = new Date(lateDate);
  const showPopover = areTasksHeldEdited && !!newProposedDate;

  const updateProposedDate = date => {
    const proposedDate = date || newProposedDate;
    controller.updateOverdueProposedDate(formatISO(proposedDate));
    setNewProposedDate(null);
  };

  const popover = (
    <div className="pt-4 pl-3 pb-3 pr-1">
      <p>By selecting a new date, the Tasks you've held will be reset.XXX</p>
      <div className="d-flex flex-row justify-content-end">
        <PrimaryButton
          variant="link"
          className="text-secondary font-weight-bold mr-3"
          onClick={() => setNewProposedDate(null)}
        >
          Cancel
        </PrimaryButton>
        <SecondaryButton variant="outline-primary" className="px-3" onClick={() => updateProposedDate()}>
          Select and Reset
        </SecondaryButton>
      </div>
    </div>
  );

  return (
    <div style={{ width: '130px' }}>
      <PopoverStickOnHover placement="bottom-start" popover={popover} show={showPopover} width={340}>
        <Datepicker
          name="propFinishDate"
          selected={selectedDate}
          minDate={minEndDate}
          readOnly={showPopover}
          dateFormat={`MMM dd${isYearDisplayed ? ', yyyy' : ''}`}
          onChange={date => {
            if (date) {
              setIsYearDisplayed(isDifferentYear(date));
              if (areTasksHeldEdited) setNewProposedDate(date);
              else updateProposedDate(date);
            }
          }}
          onFocus={() => setIsYearDisplayed(true)}
          onBlur={({ target }) => setIsYearDisplayed(isDifferentYear(Date.parse(target.value)))}
          dayClassName={date => criticalProposedDateClass(date, selectedDate, expectedDate, criticalDate)}
          renderDayContents={(day, date) => CriticalDay(day, date, criticalDate, selectedDate)}
          calendarClassName="react-datepicker__critical"
        >
          <WarningCriticalMsg showMsg={showWarningCriticalMsg(selectedDate, criticalDate)} />
        </Datepicker>
      </PopoverStickOnHover>
    </div>
  );
};
