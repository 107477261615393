import React from 'react';
import { RadioBox } from '../Components/Filter.radio.box';

export const RadioCommonFilter = ({ title, children, ...rest }) => {
  return (
    <div className="d-flex settings-container flex-column justify-content-start">
      <div className="d-flex justify-content-between">
        <h5 className="mb-4 font-weight-bold" style={{ width: '250px' }}>
          {title}
        </h5>
      </div>
      <div className="d-flex flex-column border-top pt-2">
        <RadioBox {...rest}>{children}</RadioBox>
      </div>
    </div>
  );
};
