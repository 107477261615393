import React from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Counter } from './Filter.counter';
import { taskEmptyFilterValues } from '../Implementation/Filter.empty';

const FormCheckBox = styled(Form.Check)`
  display: flex;
  align-items: center;
  .form-check-label {
    margin-top: 4px;
  }
`;

const fieldNames = ['inProgressStatus', 'completedStatus', 'notStartedStatus', 'notReadyStatus'];

export const FilterTaskStatus = ({ filterContext, text }) => {
  const { filterState, filterController, isCollapsed } = filterContext;
  const values = filterState.get('values');
  const isStatusSectionCollapsed = isCollapsed('status-section', true);
  const toggleCollapsed = () => {
    filterController.setCollapsedSection('status-section', !isStatusSectionCollapsed);
  };

  const onCheckboxChange = e => {
    const { name, checked } = e.target;
    e.preventDefault();
    e.stopPropagation();
    filterController.defer.updateFieldValue(name, checked);
  };

  const Checkbox = ({ name, label }) => (
    <FormCheckBox
      type="checkbox"
      id={name}
      name={name}
      label={label}
      onChange={onCheckboxChange}
      checked={values.get(name)}
    />
  );

  return (
    <>
      <div className="d-flex justify-content-between py-4 border-top">
        <span className="font-weight-bold">{text}</span>
        <span>
          <Counter isStatus values={values} fields={fieldNames} emptyValues={taskEmptyFilterValues} />
          <FontAwesomeIcon
            className="mx-3 cursor-pointer"
            icon={`chevron-${isStatusSectionCollapsed ? 'down' : 'up'}`}
            onClick={toggleCollapsed}
          />
        </span>
      </div>

      <div className={cn({ 'invisible h-0': isStatusSectionCollapsed })}>
        <div className="d-flex status-section">
          <Checkbox name="inProgressStatus" label="In Progress" />
          <Checkbox name="completedStatus" label="Completed" />
          <Checkbox name="notStartedStatus" label="Not Started" />
          <Checkbox name="notReadyStatus" label="Not Ready" />
        </div>
      </div>
    </>
  );
};
