import React from 'react';
import { Wrapper } from '.';
import { TradeTrax, Logo } from '../Logo';

const options = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0,
};

let timeoutId = null;

export const DemoLanding = ({ onValidate, usersService }) => {
  React.useEffect(() => {
    function getGeo() {
      global.navigator.geolocation.getCurrentPosition(success, error, options);
    }

    async function success(pos) {
      const { latitude, longitude, accuracy } = pos.coords;
      const location = { latitude, longitude, accuracy };
      const fingerPrint = await getFingerPrint();

      usersService
        .validateDemoUser({ fingerPrint, location })
        .then(onValidate)
        .catch(() => {
          console.error('Error validating demo user...');
        });
    }

    function error(err) {
      console.warn(`ERROR(${err.code}): ${err.message}`);
      global.history.pushState('', 'Location Required', '/location-required');
      timeoutId = setTimeout(getGeo, 500);
    }

    getGeo();
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
        timeoutId = null;
      }
    };
  }, [onValidate, usersService]);

  return (
    <Wrapper className="bg-tile">
      <div className="d-flex flex-column text-white align-items-center">
        <div className="d-flex flex-row justify-content-center justify-content-lg-start align-items-center mb-5 pb-4">
          <Logo className="mr-3" />
          <TradeTrax />
        </div>
        <h3 className="font-weight-bold">Almost there!</h3>
        <h6 style={{ fontSize: '18px' }}>Make sure to allow location permission to continue.</h6>
        <p className="text-center" style={{ maxWidth: 706, fontSize: '18px' }}>
          If you block this permission, go to your browser settings and allow location; otherwise, contact the TradeTrax
          team.
        </p>
      </div>
    </Wrapper>
  );
};

async function fingerprint_ip() {
  try {
    const getIp = 'https://api.ipify.org/?format=json';
    return await fetch(getIp)
      .then(result => result.json())
      .then(data => data.ip);
  } catch (error) {
    return 'unknown';
  }
}

function fingerprint_OS() {
  try {
    let OS;
    const userAgent = navigator.userAgent;
    switch (true) {
      case userAgent.indexOf('Mac') !== -1:
        OS = 'MacOS';
        break;
      case userAgent.indexOf('Win') !== -1:
        OS = 'Windows';
        break;
      case userAgent.indexOf('Linux') !== -1:
        OS = 'Linux';
        break;
      case userAgent.indexOf('X11') !== -1:
        OS = 'UNIX';
        break;
      default:
        OS = 'unknown';
    }
    return OS;
  } catch (error) {
    return 'unknown';
  }
}

function fingerprint_browser() {
  try {
    let browser;
    const userAgent = navigator.userAgent;
    switch (true) {
      case userAgent.indexOf('Chrome') !== -1:
        browser = 'Chrome';
        break;
      case userAgent.indexOf('Safari') !== -1:
        browser = 'Safari';
        break;
      case userAgent.indexOf('Firefox') !== -1:
        browser = 'Firefox';
        break;
      case userAgent.indexOf('MSIE') !== -1:
        browser = 'InternetExplorer';
        break;
      case userAgent.indexOf('Opera') !== -1:
        browser = 'Opera';
        break;
      default:
        browser = 'unknown';
    }
    return browser;
  } catch (error) {
    return 'unknown';
  }
}

function fingerprint_cookie() {
  let cookieAllowed = null;
  cookieAllowed = navigator.cookieEnabled ? true : false;
  if (typeof navigator.cookieEnabled === 'undefined' && !cookieAllowed) {
    document.cookie = 'testcookie';
    cookieAllowed = document.cookie.indexOf('testcookie') !== -1 ? true : false;
  }
  return cookieAllowed;
}

function fingerprint_touch() {
  try {
    if (document.createEvent('TouchEvent')) return true;
  } catch (ignore) {
    return false;
  }
}

async function getFingerPrint() {
  const userFingerPrint = [];
  userFingerPrint.push(await fingerprint_ip());
  userFingerPrint.push(fingerprint_OS());
  userFingerPrint.push(fingerprint_browser());
  userFingerPrint.push(navigator.language);
  userFingerPrint.push(fingerprint_cookie());
  userFingerPrint.push(fingerprint_touch());
  userFingerPrint.push(navigator.userAgent);
  const base64 = window.btoa(userFingerPrint.join('/'));
  return base64;
}
