import React from 'react';
import cn from 'classnames';
import { List } from 'immutable';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TaskList } from './TaskList';
import { PrimaryButton } from '../../../Button';

const confirmationRequestText =
  'The following Tasks have a pending Start Date Confirmation Request and are not eligible to send a proposed finish:';

export function WarningView({ cancel, state, controller, isUR }) {
  const { startTasks, withoutPermission, isAllWithoutPermission } = state.toObject();
  let sdcrTasks = List();
  if (isUR) {
    const sdcrFiltered = startTasks.filter(
      task => task.get('startDateConfirmationRequest') && task.get('status') !== 'not-started'
    );
    sdcrTasks = state.get('sdcrTasks').concat(sdcrFiltered);
  }

  const warningMsg = isUR ? 'send an Update Request for' : 'change the status of';
  const withoutPermissionText = isAllWithoutPermission
    ? `You don’t have permission to ${warningMsg} any of these Tasks. Make sure to select at least one Task with permission.`
    : `You don’t have permission to ${warningMsg} the following Tasks and will be omitted:`;

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>
          <div className="d-flex align-items-center">
            <FontAwesomeIcon
              icon={isAllWithoutPermission ? 'exclamation-circle' : 'warning'}
              className={cn('mr-2 mb-2 font-size-18', {
                'text-yellow-100': !isAllWithoutPermission,
                'text-danger': isAllWithoutPermission,
              })}
            />{' '}
            <h4 className="font-weight-bold">Important</h4>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="py-0">
        {!!withoutPermission?.size && (
          <Content
            tasks={withoutPermission}
            message={withoutPermissionText}
            className={cn({ 'my-4': sdcrTasks?.size })}
          />
        )}
        {!!sdcrTasks?.size && <Content tasks={sdcrTasks} message={confirmationRequestText} />}
      </Modal.Body>
      <Modal.Footer>
        {isAllWithoutPermission ? (
          <PrimaryButton onClick={() => cancel()}>OK</PrimaryButton>
        ) : (
          <>
            <PrimaryButton variant="secondary" className="mr-3" onClick={() => cancel()}>
              Cancel
            </PrimaryButton>
            <PrimaryButton onClick={() => controller.warningNext()}>Next</PrimaryButton>
          </>
        )}
      </Modal.Footer>
    </>
  );
}

const Content = ({ tasks, message, className }) => {
  return (
    <div className={className}>
      <div>{message}</div>
      <TaskList tasks={tasks} showJob />
    </div>
  );
};
