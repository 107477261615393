import React, { useState, useEffect } from 'react';
import { Popover, Button, FormCheck, OverlayTrigger } from 'react-bootstrap';

export const WeatherOverlay = ({ children, showOverlay, setShowOverlay, card, onSaveAndAccept, isTrade }) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(card?.getIn(['changeRequest', 'reasons'])?.includes('weather') || false);
  }, [card, showOverlay]);

  const handleSaveAndAccept = () => {
    onSaveAndAccept(checked);
    setShowOverlay(false);
  };

  return (
    <OverlayTrigger
      placement="bottom"
      overlay={renderPopover(checked, setChecked, setShowOverlay, handleSaveAndAccept, isTrade)}
      show={showOverlay}
      trigger="click"
      rootClose
      onToggle={setShowOverlay}
    >
      {children}
    </OverlayTrigger>
  );
};

const renderPopover = (checked, setChecked, setShowOverlay, handleSaveAndAccept, isTrade) => (
  <Popover id="popover-weather" style={{ maxWidth: 355, borderRadius: 8 }} className="p-3">
    <Popover.Content className="pb-0">
      <div className="d-flex flex-row">
        <FormCheck
          type="checkbox"
          className="mb-3"
          checked={checked}
          onChange={() => setChecked(!checked)}
          id="weather-checkbox"
        />
        <label
          htmlFor="weather-checkbox"
          onClick={e => {
            e.preventDefault();
            setChecked(!checked);
          }}
        >
          Due to Weather
        </label>
      </div>
      <div className="d-flex flex-row justify-content-end">
        <Button variant="link" className="text-secondary font-weight-bold mr-3" onClick={() => setShowOverlay(false)}>
          Cancel
        </Button>
        <Button variant="primary" className="font-weight-bold rounded-pill px-3" onClick={handleSaveAndAccept}>
          {`Save and ${isTrade ? 'Send' : 'Accept'} `}
        </Button>
      </div>
    </Popover.Content>
  </Popover>
);
