import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'react-bootstrap';
import { ToggleButtons, ToggleButton } from '@tradetrax/web-common';
import { MembersTab } from './MembersTab';
import { BuildersJobsTab } from './BuildersJobsTab';
import { useAppContext } from 'app/App.context';
import navigate, { Paths } from 'app/navigate';

export function HomeownerDetails({ context }) {
  const { state, controller } = context;
  const { tab, homeownerDetails } = state.toObject();
  const { firstName, lastName, status } = homeownerDetails.toObject();
  const isInactive = status === 'deactivated';
  const { account } = useAppContext();

  return (
    <>
      <div className="mx-4">
        <Breadcrumb className="mt-4" tag="nav">
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: Paths.Homeowners }}>
            Homeowners
          </Breadcrumb.Item>
          <Breadcrumb.Item
            linkAs={Link}
            linkProps={{ to: navigate.from.HomeownerDetails.to.HomeownerProfile({}, false) }}
          >
            {`${firstName} ${lastName}`}
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Homeowner Details</Breadcrumb.Item>
        </Breadcrumb>
        <h4 className="font-weight-bold">Homeowner Details</h4>
      </div>
      <div className="d-flex flex-row justify-content-between mt-4 mr-4 mb-2">
        <ToggleButtons onChange={controller.toggleTab} type="radio" name="options" value={tab}>
          <ToggleButton value="members">Members</ToggleButton>
          <ToggleButton value="builders-jobs" disabled={isInactive}>
            Builders & Jobs
          </ToggleButton>
        </ToggleButtons>
      </div>
      {tab === 'members' && <MembersTab homeownerDetails={homeownerDetails} controller={controller} />}
      {tab === 'builders-jobs' && (
        <BuildersJobsTab homeownerDetails={homeownerDetails} controller={controller} account={account} />
      )}
    </>
  );
}
