import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const DraggableArea = ({ task, controller, rowIndex, className = '' }) => {
  const isStage = task.get('isStage');

  const handleInputEvent = e => {
    if (!task.get('isStage')) {
      e.preventDefault();
      controller.onMouseDown(e, task);
    }
  };

  return (
    <span className={className} onMouseDown={handleInputEvent} onTouchStart={handleInputEvent}>
      {isStage ? null : <FontAwesomeIcon icon="grip-lines-vertical" />}
      {rowIndex ? rowIndex : task.get(isStage ? 'stageIndex' : 'rowIndex')}
    </span>
  );
};
