import React from 'react';
import styled from 'styled-components';
import { colors } from '@tradetrax/web-common';
import { EmptyState, EmptyStateCenterContainer } from '@tradetrax/web-common/lib/EmptyState';
import { TaskCard } from './TaskCard';

export function Tasks({ confirmationContext }) {
  const { controller, state } = confirmationContext;
  const tasks = state.get('selectedTaskTypes');
  const toggle = task => controller.expand(task.get('key') === state.get('expanded') ? null : task.get('key'));

  if (tasks.get('isLoading') === true) {
    return (
      <div data-testid="tasks-loading" className="spinner-border text-secondary align-self-center mt-5" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    );
  }

  if (tasks.get('isEmpty') === true) {
    return (
      <EmptyStateCenterContainer top="-70px">
        <EmptyState icon="wrench" title="No Tasks Type Added" body="When a Task Type is added, it will appear here." />
      </EmptyStateCenterContainer>
    );
  }

  return (
    <UL>
      {tasks
        .map((task, index) => (
          <TaskCard
            expanded={task.get('key') === state.get('expanded')}
            toggle={toggle}
            task={task}
            key={`${index}_${task.get('key')}`}
            controller={controller}
            globalTasks={state.get('globalTasks')}
          />
        ))
        .toArray()}
    </UL>
  );
}

const UL = styled.ul`
  margin-top: 0.25rem;
  list-style: none;
  padding: 0;
  margin-right: 20px;

  li {
    border-top: solid 1px ${colors.gray100};
    &.task-card {
      padding: 1rem 1rem 1.5rem 1rem;

      .gtl-typeahead input {
        min-width: 325px;
      }
    }

    div {
      display: flex;
      flex-direction: row;
    }

    &.expanded {
      background-color: ${colors.activeState};
      flex-direction: column;
      margin-bottom: 1px;
      border-radius: 6px;
    }
  }
`;
