import React from 'react';
import { Col, Row } from 'react-bootstrap';

export const CompanyInfo = ({ settings }) => {
  return (
    <>
      <h5 className="mb-2 font-weight-bold">Builder Profile</h5>
      <Row>
        <Col>
          <label className="text-secondary font-size-14 mb-1">Company Name</label>
          <div data-testid="company-name">{settings.get('name') || 'Not Set'}</div>
        </Col>
      </Row>
      <h6 className="mb-2 font-weight-bold mt-4">Account Manager</h6>
      <Row>
        <Col>
          <label className="text-secondary font-size-14 mb-1">Name</label>
          <div data-testid="manager-name">{settings.getIn(['manager', 'name']) || 'Not Set'}</div>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <label className="text-secondary font-size-14 mb-1">Email</label>
          <div data-testid="manager-email">{settings.getIn(['manager', 'email']) || 'Not Set'}</div>
        </Col>
      </Row>
    </>
  );
};
