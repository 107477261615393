import React from 'react';
import { Map, List } from 'immutable';
import { useController } from '@tradetrax/web-common';
import { useAppContext } from 'app/App.context';
import * as actions from './MyProfileActions';

const relevantTasks = List();
const leadTimeTasks = List();
const globalTasks = List();

export const MyProfileController = () => {
  const appContext = useAppContext();
  const { appState, appController, alert } = appContext;
  const { logout } = appController;
  const user = appState.get('user');

  const [state, controller] = useController(
    actions,
    Map({ user, relevantTasks, leadTimeTasks, globalTasks, isLoading: true }),
    appContext
  );

  React.useEffect(() => {
    controller.readNotificationSettings();
    controller.readRelevantTasks();
    controller.readLeadTimeTasks();
    controller.loadGTL();
  }, [controller]);

  return { state, controller, alert, logout };
};
