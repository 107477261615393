import React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { ToggleButtons, ToggleButton, NotPermission } from '@tradetrax/web-common';
import { useSettingsContext } from './SettingsController';
import { EmptyStateCenterContainer } from '@tradetrax/web-common/lib/EmptyState';
import navigate from 'app/navigate';

export const SettingsPage = () => {
  const settingsContext = useSettingsContext();
  const { tab = 'company' } = useParams();
  const { hasPermission } = settingsContext.state.toObject();
  // const { setTab } = settingsContext.controller;
  const setTab = tab => {
    navigate.to.SettingsTab({ tab });
  };

  if (!hasPermission)
    return (
      <EmptyStateCenterContainer>
        <NotPermission />
      </EmptyStateCenterContainer>
    );

  return (
    <>
      <div className="d-flex flex-row justify-content-between mx-4 my-4">
        <h4 className="font-weight-bold">Settings</h4>
      </div>
      <div className="d-flex justify-content-between mr-4 mt-3">
        <ToggleButtons
          data-testid="settings-toggle-buttons"
          onChange={setTab}
          className="mb-2 mr-auto"
          type="radio"
          name="options"
          value={tab}
        >
          <ToggleButtonContainer value="company">Company</ToggleButtonContainer>
          <ToggleButtonContainer value="jobs">Jobs</ToggleButtonContainer>
          <ToggleButtonContainer value="confirmations">Start Date Confirmations</ToggleButtonContainer>
          <ToggleButtonContainer value="homeowner">Homeowner Job Progress</ToggleButtonContainer>
          <ToggleButtonContainer value="custom-roles">Custom Roles</ToggleButtonContainer>
        </ToggleButtons>
      </div>
    </>
  );
};

const ToggleButtonContainer = styled(ToggleButton)`
  min-width: 111px !important;
  max-width: 111px;

  @media (min-width: 950px) {
    max-width: 196px;
  }
  @media (min-width: 1200px) {
    max-width: 250px;
  }
`;
