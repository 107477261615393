import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { STATUS_MAP, ChipStatusDropdown, OnTime } from '@tradetrax/web-common';
import navigate from 'app/navigate';
import { NOT_STARTED, IN_PROGRESS, COMPLETED } from '@tradetrax/web-common/lib/utils';
import { PreConstructionIcon } from '@tradetrax/web-common/lib/Stages/PreConstructionIcon';
import { CriticalPathLabel } from '@tradetrax/web-common/lib/CriticalPath';

export const TaskHeader = ({ controller, task, job }) => {
  const handleChangeStatus = status => controller.updateStatus({ task, status });
  const taskStatus = !!task.get('checkedIn') ? 'checked-in' : task.get('status');
  const showDaysAhead = false;
  const isCritical = task.get('isCritical');
  return (
    <>
      <TopContainer>
        <div className="d-flex flex-column">
          <span className="h4 mb-0 font-weight-bold">{task.get('name')}</span>
          <div className="d-flex flex-row align-items-center">
            {isCritical && <CriticalPathLabel className="mb-2" />}
            <PreConstructionIcon task={task} />
          </div>
        </div>
        <StatusContainer>
          <ChipStatusDropdown
            {...STATUS_MAP[taskStatus]}
            notStarted={taskStatus !== NOT_STARTED}
            progress={taskStatus !== IN_PROGRESS}
            completed={taskStatus !== COMPLETED}
            onSelect={handleChangeStatus}
          />
          {showDaysAhead && <OnTime onTime={task.get('daysBehind')} />}
        </StatusContainer>
      </TopContainer>

      <div className="d-flex flex-row mt-4 pt-3">
        <div className="d-flex flex-column mr-5">
          <small className="text-muted">Community</small>
          <Link to={navigate.to.CommunityDetails({ communityId: job.get('communityId') }, false)}>
            {job.get('communityName')}
          </Link>
        </div>
        <div className="d-flex flex-column">
          <small className="text-muted">Job</small>
          <Link to={navigate.to.JobDetails({ jobId: job.get('_id') }, false)}>{job.get('name')}</Link>
        </div>
      </div>
    </>
  );
};

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  @media (min-width: 1200px) {
    flex-direction: row;
  }
`;

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  @media (min-width: 1200px) {
    margin-top: 0px;

    margin-left: 15px;
  }
`;
