import React from 'react';
import { fromJS, List, Map } from 'immutable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { markAsSideEffect, markAsSync, CustomDialog } from '@tradetrax/web-common';
import { buildersService } from 'services';

const dashKey = task =>
  task
    .get('taskName')
    .toLowerCase()
    .replace(/\s/g, '-');

markAsSideEffect(readTemplateAccountabilities);
export function readTemplateAccountabilities(templateId) {
  buildersService
    .getTemplateAccountabilities({}, { params: { templateId } })
    .then(fromJS)
    .then(tasks => tasks.map(task => task.set('key', dashKey(task))))
    .then(tasks => this.controller.dispatch([state => state.setIn(['accountability', 'tasks'], tasks)]));
}

markAsSideEffect(readRecommendedAccountabilities);
export function readRecommendedAccountabilities(templateId) {
  buildersService
    .getRecommendedAccountabilities({}, { params: { templateId } })
    .then(fromJS)
    .then(recommended => recommended.map(task => task.set('key', dashKey(task))))
    .then(recommended =>
      this.controller.dispatch([state => state.setIn(['accountability', 'recommended'], recommended)])
    );
}

markAsSync(dismissRecommendedAccountability);
export function dismissRecommendedAccountability(state, templateId, accountability) {
  const path = ['accountability', 'recommended'];
  const recommended = state.getIn(path);
  const accountabilityId = accountability.get('_id');
  const index = recommended.indexOf(accountability);

  buildersService
    .dismissRecommendedAccountability({}, { params: { templateId, accountabilityId } })
    .then(() =>
      this.addAlert(
        'Task configuration successfully dismissed. This can not be undone. You may configure this Task by adding it to the list.',
        'success'
      )
    )
    .catch(() => {
      this.addAlert('There was a problem dismissing this Task configuration. Please try again.', 'danger');
      this.controller.dispatch([state => state.setIn(path, recommended)]);
    });

  return state.setIn(path, recommended.remove(index));
}

markAsSync(useRecommendedAccountability);
export function useRecommendedAccountability(state, templateId, accountability) {
  const path = ['accountability', 'recommended'];
  const recommended = state.getIn(path);
  const index = recommended.indexOf(accountability);
  const tasks = state.getIn(['template', 'tasks']);
  const containsRelatedTask = !!tasks.find(task => task.get('name') === accountability.get('relatedTaskName'));

  const payload = accountability
    .remove('_id')
    .remove('isRelatedTaskMissing')
    .remove('key')
    .merge({ templateId })
    .toObject();

  buildersService
    .createAccountability(payload)
    .then(fromJS)
    .then(task => task.set('key', dashKey(task)))
    .then(task => {
      this.controller.dispatch([
        state =>
          state
            .setIn(['template', 'hasCompleteAccountabilities'], containsRelatedTask)
            .updateIn(['accountability', 'tasks'], tasks => {
              const isEmpty = tasks.get('isEmpty');
              if (isEmpty) return List([task]);
              return tasks.unshift(task);
            }),
      ]);
      this.addAlert('Task configuration successfully saved.', 'success');
    })
    .catch(() => {
      this.controller.dispatch([state => state.setIn(path, recommended)]);
      this.addAlert('There was a problem saving this Task configuration. Please try again.', 'danger');
    });

  return state.setIn(path, recommended.remove(index));
}

markAsSync(expand);
export function expand(state, key) {
  return state.setIn(['accountability', 'expanded'], key);
}

markAsSync(addTask);
export function addTask(state, task) {
  const taskType = fromJS({
    taskId: task.get('_id'),
    taskName: task.get('name'),
    daysTimeRange: 0,
  });
  const taskKey = dashKey(taskType);

  return state
    .updateIn(['accountability', 'tasks'], tasks => {
      const isEmpty = !tasks.size;
      if (isEmpty) return List([taskType.set('key', taskKey)]);
      return tasks.unshift(taskType.set('key', taskKey));
    })
    .setIn(['accountability', 'expanded'], taskKey);
}

markAsSideEffect(saveTaskCard);
export function saveTaskCard(task, form) {
  const templateId = this.state.getIn(['template', '_id']);

  if (task.get('_id')) {
    this.controller.updateStartDateConfirmation(task, form);
  } else {
    this.controller.createStartDateConfirmation(templateId, task, form);
  }
}

markAsSideEffect(createStartDateConfirmation);
export function createStartDateConfirmation(templateId, task, form) {
  const { timeConditions, daysTimeRange, relatedTask, timeCompletion } = form;
  const index = this.state.getIn(['accountability', 'tasks']).indexOf(task);
  const numberOfDays = daysTimeRange && daysTimeRange === 'Same' ? 0 : parseInt(daysTimeRange);
  const payload = {
    templateId,
    taskId: task.get('taskId'),
    taskName: task.get('taskName'),
    relatedTaskId: relatedTask.get('_id'),
    relatedTaskName: relatedTask.get('name'),
    timeCompletion: timeCompletion === 'Starts' ? 'start' : 'finish',
    daysTimeRange: numberOfDays,
  };
  if (timeConditions) {
    payload.timeConditions = timeConditions.toLowerCase();
  }

  return buildersService
    .createAccountability(payload)
    .then(response => {
      this.controller.dispatch([
        state => state.updateIn(['accountability', 'tasks', index], task => task.merge(response)),
      ]);
      this.addAlert(`${task.get('taskName')} Start Date Confirmation request successfully set.`, 'success');
    })
    .catch(() => {
      this.addAlert('There was a problem saving this setting. Please try again.', 'danger');
    });
}

markAsSideEffect(updateStartDateConfirmation);
export function updateStartDateConfirmation(task, form) {
  const { timeConditions, daysTimeRange, relatedTask, timeCompletion } = form;
  const path = ['accountability', 'tasks'];
  const index = this.state.getIn(path).indexOf(task);
  const numberOfDays = daysTimeRange && daysTimeRange === 'Same' ? 0 : parseInt(daysTimeRange);
  const payload = {
    relatedTaskId: relatedTask.get('_id'),
    relatedTaskName: relatedTask.get('name'),
    timeCompletion: timeCompletion === 'Starts' ? 'start' : 'finish',
    daysTimeRange: numberOfDays,
  };
  if (timeConditions) {
    payload.timeConditions = timeConditions.toLowerCase();
  }
  this.controller.dispatch([state => state.updateIn([...path, index], task => task.merge(payload))]);

  return buildersService
    .updateAccountability(payload, { params: { accountabilityId: task.get('_id') } })
    .then(() => {
      const templateId = this.state.getIn(['template', '_id']);
      return this.controller.readTemplate(templateId);
    })
    .then(response => {
      this.controller.dispatch([state => state.set('template', response.get('template'))]);
      this.addAlert(`${task.get('taskName')} Start Date Confirmation request successfully set.`, 'success');
    })
    .catch(error => {
      this.addAlert('There was a problem saving this setting. Please try again.', 'danger');
    });
}

//TODO: would it be better to name this method: `deleteAccountability`?
markAsSideEffect(deleteTaskType);
export async function deleteTaskType(task) {
  const accountabilityId = task.get('_id');
  const { isAccept } = await this.modal.open(CustomDialog, {
    maxWidth: '511px',
    title: (
      <>
        <FontAwesomeIcon icon="circle-exclamation" className="text-danger" />
        Remove Task Type
      </>
    ),
    message:
      'By removing it, you will no longer request Trades to confirm the Start Date for this Task Type. You can add it again later from the Global Task Library.',
    titleAccept: 'Yes, Remove Task Type',
    titleCancel: 'Cancel',
  });
  if (!isAccept) return;

  const path = ['accountability', 'tasks'];
  const currentTaskTypes = this.state.getIn(path);
  const index = currentTaskTypes.indexOf(task);
  const numberMissingTasks = currentTaskTypes.remove(index).filter(task => task.get('isRelatedTaskMissing')).size;
  this.controller.dispatch([
    state =>
      state.setIn(['template', 'hasCompleteAccountabilities'], !numberMissingTasks).updateIn(path, taskTypes => {
        const updatedTaskTypes = taskTypes.remove(index);
        const isEmpty = !updatedTaskTypes.size;
        if (isEmpty) return Map({ isEmpty });
        else return updatedTaskTypes;
      }),
  ]);

  const displaySuccessMessage = () => this.addAlert('Task Type successfully removed.', 'success');

  if (accountabilityId) {
    buildersService
      .removeAccountability({}, { params: { accountabilityId } })
      .then(displaySuccessMessage)
      .then(() => this.controller.readRecommendedAccountabilities(this.state.getIn(['template', '_id'])))
      .catch(() => {
        this.controller.dispatch([state => state.setIn(path, currentTaskTypes)]);
        this.addAlert('There was a problem removing this Task Type and its setting. Please try again.', 'danger');
      });
  } else {
    displaySuccessMessage();
  }
}
