import styled from 'styled-components';
import { colors } from '@tradetrax/web-common';

export const UL = styled.ul`
  list-style: none;
  padding: 0;
  overflow: visible;
  max-height: ${props => props.maxHeight || '587px'};
  margin-bottom: 0px;

  li {
    display: flex;
    justify-content: space-between;
    border-top: solid 1px ${colors.gray100};
    padding: 1.5rem 2rem 1.5rem 0rem;

    &.selected {
      background-color: ${colors.blue50};
    }

    &:first-of-type {
      margin-top: 1rem;
    }

    &:hover {
      background-color: ${colors.blue100};
    }

    .dropdown-item {
      color: ${colors.red};
    }
  }

  li.inactive .dropdown-item {
    color: ${colors.green300};
  }
`;
