const ServiceClient = require('@tradetrax/service-client');
const UsersServiceInterface = require('@tradetrax/users-service-interface');
const GlobalTaskLibraryInterface = require('@tradetrax/gtl-service-interface');
const BuildersServiceInterface = require('@tradetrax/builders-service-interface');
const JobsServiceInterface = require('@tradetrax/jobs-service-interface');
const { serviceUrl } = require('@tradetrax/web-common');

export const usersService = ServiceClient(serviceUrl('users'), UsersServiceInterface);
export const gtlService = ServiceClient(serviceUrl('gtl'), GlobalTaskLibraryInterface);
export const buildersService = ServiceClient(serviceUrl('builders'), BuildersServiceInterface);
export const jobsService = ServiceClient(serviceUrl('jobs'), JobsServiceInterface);
