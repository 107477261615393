import React from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import { Form } from 'react-bootstrap';
import { colors } from '../theme';
import { getUserDomain } from '../utils';
import PERMISSIONS from './permissions.json';

export const UserPermissions = ({ user, controller, isCustomRole, isCustomRoleBuilder }) => {
  const {
    task_user_assignment = 'no',
    task_update_status = 'yes',
    task_update_installer = 'yes',
    manage_update_request = 'yes',
    // view_tasks = 'yes',
    user_management = 'no',
    commitment_request_management = 'yes',
    job_status_and_release = 'yes',
    job_update_task_dependencies_and_dates = 'yes',
  } = user.get('permissions')?.toObject() || user.getIn(['settings', 'permissions'])?.toObject() || {};
  const isManager = user.get('role') === 'manager';
  const { isBuilder, isTrade } = getUserDomain(user);

  const update = (id, value) => {
    const permission = id.replace('builder_', '');
    controller.updateUserPermissions(user, permission, value);
  };

  return (
    <div className="d-flex flex-column ml-4 mt-4">
      {isCustomRole || isCustomRoleBuilder ? (
        <p className="mb-5">
          These Permissions will be applied when creating new users with this Custom Role. Changes won't impact existing
          users.{' '}
        </p>
      ) : (
        <h5 className="font-weight-bold mb-3">Permissions</h5>
      )}
      <div className="d-flex flex-column flex-xl-row">
        <UL className="mr-4">
          <Checkbox
            id={`${isBuilder || isCustomRoleBuilder ? 'builder_' : ''}user_management`}
            value={user_management}
            update={update}
            disabled={isManager}
          />
          <Checkbox
            id="job_status_and_release"
            className={cn({ 'd-none': isTrade || isCustomRole })}
            value={job_status_and_release}
            update={update}
            disabled={isManager}
          />
          <Checkbox
            id="job_update_task_dependencies_and_dates"
            className={cn({ 'd-none': isTrade || isCustomRole })}
            value={job_update_task_dependencies_and_dates}
            update={update}
            disabled={isManager}
          />
          <Checkbox
            id={`${isBuilder || isCustomRoleBuilder ? 'builder_' : ''}task_user_assignment`}
            value={task_user_assignment}
            update={update}
            disabled={isManager}
          />
          <Checkbox
            id="commitment_request_management"
            className={cn({ 'd-none': isBuilder || isCustomRoleBuilder })}
            value={commitment_request_management}
            update={update}
            disabled={isManager}
          />
          {/* <RadioGroup id="view_tasks" label="View Tasks" value={view_tasks} update={update} /> */}
        </UL>
        <UL className="ml-0 ml-xl-4">
          <RadioGroup
            id={`${isBuilder || isCustomRoleBuilder ? 'builder_' : ''}manage_update_request`}
            label="Manage Update Requests"
            value={manage_update_request}
            update={update}
            disabled={isManager}
          />
          <RadioGroup
            id="task_update_installer"
            label="Installer Assignment"
            className={cn({ 'd-none': isBuilder || isCustomRoleBuilder })}
            value={task_update_installer}
            update={update}
            disabled={isManager}
          />
          <RadioGroup
            id="task_update_status"
            label="Manage Task Status"
            className={cn({ 'd-none': isBuilder || isCustomRoleBuilder })}
            value={task_update_status}
            update={update}
            disabled={isManager}
          />
        </UL>
      </div>
    </div>
  );
};

const Title = ({ id }) => <span className="mr-auto">{PERMISSIONS[id].title}</span>;

const Description = ({ id }) => {
  const { description } = PERMISSIONS[id];
  if (!description) return null;
  return <p className="mt-0">{description}</p>;
};

const Checkbox = ({ id, value, update, disabled = false, className = '' }) => {
  return (
    <li className={className}>
      <div className="mb-2">
        <Title id={id} />
        <Form.Check
          className="mr-1"
          id={`${id}_chk`}
          checked={value === 'yes'}
          type="switch"
          label=""
          disabled={disabled}
          onChange={({ target }) => !disabled && update(id, target.checked ? 'yes' : 'no')}
        />
      </div>
      <Description id={id} />
    </li>
  );
};

const RadioGroup = ({ id, value, update, disabled = false, className = '' }) => {
  return (
    <li className={className}>
      <div className="mb-2">
        <Title id={id} />
        <Form.Check
          checked={value === 'yes'}
          className="mr-2 text-secondary"
          custom
          id={`${id}_all`}
          type="radio"
          disabled={disabled}
          label="All"
          onChange={({ target }) => target.checked && update(id, 'yes')}
        />
        <Form.Check
          checked={value === 'assigned'}
          className="mr-2 text-secondary"
          custom
          id={`${id}_assigned`}
          type="radio"
          disabled={disabled}
          label="Assigned"
          onChange={({ target }) => target.checked && update(id, 'assigned')}
        />
        <Form.Check
          checked={value === 'no'}
          className="mr-2 text-secondary"
          custom
          id={`${id}_none`}
          type="radio"
          disabled={disabled}
          label="None"
          onChange={({ target }) => target.checked && update(id, 'no')}
        />
      </div>
      <Description id={id} />
    </li>
  );
};

const UL = styled.ul`
  list-style: none;
  padding: 0;
  width: 470px;

  li {
    display: flex;
    // justify-content: space-between;
    flex-direction: column;
    border-top: solid 1px ${colors.gray100};
    padding: 1rem 1rem 1.5rem 0.5rem;

    > div {
      display: flex;
      flex-direction: row;
    }

    p {
      font-size: 14px;
      margin-top: 0.25rem;
      color: ${colors.gray400};
    }
  }
`;
