import React from 'react';
import { Modal, Form, Col } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import navigate from 'app/navigate';
import { PrimaryButton, Typeahead } from '@tradetrax/web-common';
import { preventCharsInNumbers, plural, trimSpaces } from '@tradetrax/web-common/lib/utils';
import { useTemplates } from './useTemplates';
import { emptyStringToNull } from '@tradetrax/web-common/lib/EditableField';

const schema = yup.object().shape({
  name: yup
    .string()
    .required('Job Name is required.')
    .matches(/^[a-zA-Z0-9.!#$%&'*+-/=?^_`{|}~\s]+$/, 'Character not allowed')
    .transform(trimSpaces)
    .min(2, 'Min. 2 characters')
    .max(99, 'Max. 99 characters'),
  community: yup
    .array()
    .of(
      yup.object().shape({
        _id: yup.string().required(),
        name: yup.string().required(),
      })
    )
    .nullable()
    .required('Community is required'),
  template: yup
    .array()
    .of(
      yup.object().shape({
        _id: yup.string().required(),
        name: yup.string().required(),
      })
    )
    .nullable()
    .required('Template is required'),
  lotNumber: yup
    .string()
    .matches(/^[a-zA-Z0-9\s-]+$/, 'Character not allowed')
    .max('50', 'Max 50 characters')
    .notRequired()
    .transform(emptyStringToNull)
    .nullable(),
  squareFootage: yup
    .number()
    .min('0', '0')
    .max(9999999999, '9999999999')
    .transform(emptyStringToNull)
    .nullable(),
});

function createJob({ form, controller, setError, alert, close }) {
  const { community, template, ...job } = form;
  const communityId = community[0]._id;
  const templateId = template[0]._id;

  return controller
    .createJob({ communityId, templateId, ...job })
    .then(jobId => {
      alert.success({
        message: 'Job successfully created. Set all dates and assign all tasks in order to ‘Release to Construction’',
      });
      navigate.to.JobDetails({ jobId });
      close();
    })
    .catch(response => {
      if (response.type === 'job-name-already-exists-for-community')
        setError('name', { type: 'notMatch', message: 'Job already exist in this Community' });
      else {
        //TODO: display the error on the popup to allow the user modify the fields and try again.
        alert.error({ message: 'There was a problem creating this Job. Please try again.' });
        close();
      }
    });
}

function validateIfJobNameExist(jobs, watch, setError, clearErrors) {
  const { name, community } = watch();

  const communityIdSelected = community ? community[0]?._id : '';
  const jobNameExist = jobs.filter(
    job => job.get('name').toLowerCase() === name.toLowerCase() && job.get('communityId') === communityIdSelected
  );

  if (jobNameExist.size > 0) setError('name', { type: 'notMatch', message: 'Job already exist in this Community' });
  else clearErrors('name');
}

export default function JobNew({ controller, communities, community, alert, close, jobs }) {
  const templates = useTemplates();
  const sqrFtRef = React.useRef();
  const [stagesNumber, setStagesNumber] = React.useState(false);

  const {
    register,
    handleSubmit,
    errors,
    setError,
    control,
    watch,
    clearErrors,
    formState: { dirtyFields },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      community,
      name: '',
      lotNumber: '',
      squareFootage: '',
      template: null,
    },
  });

  React.useEffect(() => {
    const squareFtInput = sqrFtRef.current;
    const onKeyPress = e => preventCharsInNumbers(e);

    squareFtInput.addEventListener('keydown', onKeyPress);

    return () => {
      squareFtInput.removeEventListener('keydown', onKeyPress);
    };
  }, [sqrFtRef]);

  const onSubmit = form => createJob({ form, controller, setError, alert, close });
  const handleShowMessage = template => {
    setStagesNumber(template?.stages?.length || false);
    return template ? [template] : [];
  };

  return (
    <Modal show={true} onHide={close} data-testid="modal-job-new">
      <Modal.Header closeButton>
        <Modal.Title>Create New Job</Modal.Title>
      </Modal.Header>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <Form.Group controlId="formName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              isInvalid={!!errors.name}
              isValid={dirtyFields.name && !errors.name}
              ref={register}
              autoComplete="off"
              placeholder="Ex. 5224 S Saguaro Trail"
              onBlur={() => validateIfJobNameExist(jobs, watch, setError, clearErrors)}
            />
            <Form.Control.Feedback type="invalid">
              <FontAwesomeIcon icon="circle-exclamation" />
              {` `}
              {errors.name?.message}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid">
              <FontAwesomeIcon icon="circle-check" />
              {` Job name is valid`}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formCommunity">
            <Form.Label>Community</Form.Label>
            <Controller
              control={control}
              name="community"
              render={({ onChange, onBlur, value }) => (
                <Typeahead
                  id="new-job-community"
                  name="community"
                  placeholder="Choose Community"
                  options={communities}
                  labelKey={option => option.name}
                  filterSelected={(option, selected) => selected._id !== option._id}
                  selected={value}
                  onBlur={onBlur}
                  onChange={community => {
                    onChange(community);
                    validateIfJobNameExist(jobs, watch, setError, clearErrors);
                  }}
                  isInvalid={!!errors.community}
                  icon="magnifying-glass"
                  className={cn({
                    'is-invalid': errors.community,
                  })}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              <FontAwesomeIcon icon="circle-exclamation" />
              {` `}
              {errors.community?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Row>
            <Col>
              <Form.Group controlId="formLot">
                <Form.Label>LOT #</Form.Label>
                <Form.Control
                  type="text"
                  name="lotNumber"
                  isInvalid={!!errors.lotNumber}
                  isValid={dirtyFields.lotNumber && !errors.lotNumber}
                  ref={register}
                  placeholder="00000"
                />
                <small className="text-muted">* Optional</small>
                <Form.Control.Feedback type="invalid">
                  <FontAwesomeIcon icon="circle-exclamation" />
                  {` `}
                  {errors.lotNumber?.message}
                </Form.Control.Feedback>
                <Form.Control.Feedback type="valid">
                  <FontAwesomeIcon icon="circle-check" />
                  {` Lot # is valid`}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="squareFootage">
                <Form.Label>Square Ft.</Form.Label>
                <Form.Control
                  type="number"
                  name="squareFootage"
                  isInvalid={!!errors.squareFootage}
                  isValid={dirtyFields.squareFootage && !errors.squareFootage}
                  ref={e => {
                    register(e);
                    sqrFtRef.current = e;
                  }}
                  placeholder="00000"
                />
                <small className="text-muted">* Optional</small>
                <Form.Control.Feedback type="invalid">
                  <FontAwesomeIcon icon="circle-exclamation" />
                  {` `}
                  {errors.squareFootage?.type === 'min' && <FontAwesomeIcon icon="arrow-up" />}
                  {errors.squareFootage?.type === 'max' && <FontAwesomeIcon icon="arrow-down" />}
                  {` `}
                  {errors.squareFootage?.message}
                </Form.Control.Feedback>
                <Form.Control.Feedback type="valid">
                  <FontAwesomeIcon icon="circle-check" />
                  {` Square Ft. is valid`}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Group controlId="formTemplate">
            <Form.Label>From template</Form.Label>
            <Controller
              control={control}
              name="template"
              render={({ onChange, onBlur, value }) => (
                <Typeahead
                  id="new-job-template"
                  name="template"
                  placeholder="Choose from Template Library"
                  labelKey={option => option.name}
                  filterSelected={(option, selected) => selected._id !== option._id}
                  selected={value}
                  options={templates}
                  onBlur={onBlur}
                  onChange={([template]) => onChange(handleShowMessage(template))}
                  isInvalid={!!errors.template}
                  icon="magnifying-glass"
                  className={cn({
                    'is-invalid': errors.template,
                    'is-valid': !errors.template,
                  })}
                />
              )}
            />
            {stagesNumber && (
              <span className="font-size-14 text-muted">{`*This Template contains ${stagesNumber} ${plural(
                stagesNumber,
                'Stage',
                'Stages'
              )}`}</span>
            )}
            <Form.Control.Feedback type="invalid">
              <FontAwesomeIcon icon="circle-exclamation" />
              {` `}
              {errors.template?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Modal.Footer className="px-0">
            <PrimaryButton type="submit">Create Job</PrimaryButton>
          </Modal.Footer>
        </Modal.Body>
      </Form>
    </Modal>
  );
}
