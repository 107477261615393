import React from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import { colors } from '../theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { debounce } from 'lodash';
import { mongoToTrx, formatTrx } from '../utils';

const WarningCriticalContainer = styled.div`
  background-color: #fff;
  border-top: 1px solid ${colors.gray};
  color: ${colors.gray400};
  font-size: 12px;
  margin: 10px 0 0 10px;
  padding-top: 10px;
  width: 220px;
`;

export const criticalClass = (date, criticalDate, startDate) => {
  if (startDate && date.getTime() > criticalDate.getTime() && date.getTime() >= startDate.getTime()) {
    return date.getTime() === startDate.getTime()
      ? 'react-datepicker__critical-start-day'
      : 'react-datepicker__critical-day rounded-0';
  }
  return '';
};

export const criticalProposedDateClass = (date, dateSelected, taskDate, criticalDate) => {
  if (formatTrx(date) === mongoToTrx(taskDate)) return 'pointer-events-none text-muted';
  if (taskDate && date.getTime() > criticalDate.getTime() && date.getTime() >= dateSelected?.getTime()) {
    return date.getTime() === dateSelected.getTime()
      ? 'react-datepicker__critical-start-day'
      : 'react-datepicker__critical-day rounded-0';
  }
  return '';
};

export const showWarningCriticalMsg = (date, criticalDate) => (date ? date.getTime() > criticalDate.getTime() : false);

const hideWarningMsg = debounce(() => {
  const warningMsgContainer = document.querySelector('.react-datepicker__critical-warning-msg');
  if (warningMsgContainer) warningMsgContainer.classList.remove('show-warning-critical-msg');
}, 500);

export const CriticalDay = (day, date, criticalDate, calendarDate) => {
  if (date.getTime() > criticalDate.getTime() && calendarDate) {
    return (
      <div
        onMouseEnter={() => {
          hideWarningMsg.cancel();
          document.querySelector('.react-datepicker__critical-warning-msg').classList.add('show-warning-critical-msg');
        }}
        onMouseLeave={() => {
          if (calendarDate.getTime() < criticalDate.getTime() || date.getTime() < calendarDate.getTime())
            hideWarningMsg();
        }}
      >
        {day}
      </div>
    );
  }
  return (
    <div
      onMouseEnter={() => {
        hideWarningMsg();
      }}
      onMouseLeave={() => {
        if (calendarDate && calendarDate.getTime() > criticalDate.getTime()) {
          hideWarningMsg.cancel();
          document.querySelector('.react-datepicker__critical-warning-msg').classList.add('show-warning-critical-msg');
        }
      }}
    >
      {day}
    </div>
  );
};

const WarningCriticalMsg = ({ showMsg }) => {
  return (
    <WarningCriticalContainer
      className={cn('react-datepicker__critical-warning-msg', { 'show-warning-critical-msg': showMsg })}
    >
      <FontAwesomeIcon icon="circle-exclamation" className="text-danger mr-1 mt-1" />
      <p className="ml-1 pr-3">
        Selecting this date might <b>delay the entire Job</b> finish date.
      </p>
    </WarningCriticalContainer>
  );
};

export default WarningCriticalMsg;
