import React from 'react';
import * as yup from 'yup';
import styled from 'styled-components';
import { Link, Redirect } from 'react-router-dom';
import { Breadcrumb } from 'react-bootstrap';
import navigate, { Paths } from 'app/navigate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TableRowDropDown, IconButton } from '@tradetrax/web-common';
import { IconButtonWrap } from '@tradetrax/web-common/lib/Button';
import { plural } from '@tradetrax/web-common/lib/utils';
import { TemplateDetailsContext } from './TemplateDetailsContext';
import { TemplateTasks } from './TemplateTasks';
import { TemplateStages } from './TemplateStages/TemplateStages';
import { OverlayIncompleteSettings } from '../Settings/OverlayIncompleteTemplate';
import { EditableField, emptyStringToNull } from '@tradetrax/web-common/lib/EditableField';

const targetCycleTimeSchema = yup.object().shape({
  targetCycleTime: yup
    .number()
    .integer()
    .min('0', '0')
    .max(9999, '9999')
    .transform(emptyStringToNull)
    .nullable(),
});

export function TemplateDetails({ match }) {
  const { templateId } = match.params;
  const templateDetailsContext = TemplateDetailsContext(templateId);
  const { state, controller } = templateDetailsContext;
  const template = state.get('template');
  const isListView = state.get('templateView') === 'list';

  if (!state.get('hasPermission')) return <Redirect to={Paths.NotPermission} />;

  return (
    <>
      <Header template={template} controller={controller} isListView={isListView} />
      {isListView && <TemplateTasks context={templateDetailsContext} />}
      {!isListView && <TemplateStages context={templateDetailsContext} />}
    </>
  );
}

const Header = ({ template, controller, isListView }) => {
  const templateId = template.get('_id');
  const linkToSettings = () => navigate.from.TemplateDetails.to.TemplateSettings({ templateId });
  const onSelect = action => {
    if (action === 'add-task') {
      controller.openAddTaskModal();
    } else if (action === 'add-stage') {
      controller.openAddStageModal();
    }
  };

  const update = (field, value, title) => controller.updateTemplate(templateId, field, value, title);

  return (
    <div className="mx-4 mt-4">
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: Paths.Templates }}>
          Templates
        </Breadcrumb.Item>
        <Breadcrumb.Item active>{template.get('name')}</Breadcrumb.Item>
      </Breadcrumb>
      <div className="d-flex flex-row align-items-center justify-content-between mb-3">
        <span data-testid="template-name" className="h4 mb-0 font-weight-bold">
          {template.get('name')}
        </span>
        <div data-testid="template-action-buttons" className="d-flex flex-row align-items-center position-relative">
          {!template.get('isComplete') && (
            <OverlayContainer>
              {' '}
              <OverlayIncompleteSettings templateId={template.get('_id')} />
            </OverlayContainer>
          )}
          <TableRowDropDown toggle={TogglePlusButton} onSelect={onSelect} addTask addStage />
          <IconButton
            className="active-on-hover cursor-pointer ml-2"
            icon={isListView ? 'bars-staggered' : 'list'}
            onClick={controller.toggleTemplateView}
          />
          <IconButton className="active-on-hover cursor-pointer ml-2" icon="gear" onClick={linkToSettings} />
        </div>
      </div>

      <div className="mb-3">
        <EditableField
          title="Target Cycle Time"
          editableProps={{
            schema: targetCycleTimeSchema,
            value: template.get('targetCycleTime') || null,
            update: update,
            field: 'targetCycleTime',
            type: 'number',
            preventChars: true,
            showDaysLabel: true,
          }}
          inputSize="90px"
        />
      </div>

      <span data-testid="template-tasks-count" className="font-weight-bold">{`${template.get('tasks').size} ${plural(
        template.get('tasks').size,
        'task'
      )}`}</span>
    </div>
  );
};

const TogglePlusButton = React.forwardRef(({ onClick }, ref) => (
  <IconButtonWrap
    primary
    className="btn btn-primary"
    ref={ref}
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <FontAwesomeIcon icon="plus" />
  </IconButtonWrap>
));

const OverlayContainer = styled.div`
  position: absolute;
  right: -20px;
  top: 60px;
  width: 250px;
  @media (min-width: 992px) {
    position: relative;
    width: auto;
    right: 0;
    top: 0;
  }
`;
