import React from 'react';
import navigate from 'app/navigate';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from 'react-bootstrap';
import { PrimaryButton } from '@tradetrax/web-common';

const RemoveKeyTaskModalUnstyled = ({ templateId, close, accept, className }) => {
  const onConfigClick = () => {
    accept();
    navigate.to.TemplateSettings({ templateId });
  };

  return (
    <Modal centered show={true} onHide={close} dialogClassName={className}>
      <Modal.Header closeButton>
        <Modal.Title>
          <FontAwesomeIcon icon="circle-exclamation" className="text-danger" />
          Remove Task
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        This Task is used as a reference for the Stage status. To remove this Task, go to the Stage Configuration and
        select another Task.
      </Modal.Body>
      <Modal.Footer className="d-flex flex-row justify-content-end">
        <PrimaryButton variant="secondary" onClick={() => close()}>
          Cancel
        </PrimaryButton>
        <PrimaryButton onClick={onConfigClick} className="ml-3">
          Open Stage Configuration
        </PrimaryButton>
      </Modal.Footer>
    </Modal>
  );
};

export const RemoveKeyTaskModal = styled(RemoveKeyTaskModalUnstyled)`
  max-width: 540px;
`;
