import React from 'react';
import cn from 'classnames';
import { useAppContext } from 'app/App.context';
import { SideBar, SideBarLink, SideBarSeparator } from '@tradetrax/web-common';
import { SideBarLinkOverlay, HelpCenterLink } from '@tradetrax/web-common/lib/SideBar';
import { Paths } from 'app/navigate';

const BuilderSideBar = () => {
  const { appState, hasPermission } = useAppContext();
  const { options, account, user, counters } = appState.toObject();
  const isShowingSettings = user.getIn(['permissions', 'user_management']) === 'yes';

  return (
    <SideBar expanded={options.expanded} companyName={account.get('name')}>
      <SideBarLinkOverlay to={Paths.DashboardV2} icon="table-columns" counters={counters}>
        Dashboard
      </SideBarLinkOverlay>
      <SideBarLink to={Paths.ToDo} icon="circle-check" badge={counters} exact>
        To-Dos
      </SideBarLink>
      <SideBarLink to={Paths.UpcomingTasks} icon="clipboard-list">
        Upcoming
      </SideBarLink>
      <SideBarLink to={Paths.Community} icon="building">
        Communities
      </SideBarLink>
      <SideBarLink to={Paths.Job} icon="house">
        Jobs
      </SideBarLink>
      <SideBarLink to={Paths.Task} icon="wrench">
        Tasks
      </SideBarLink>
      <SideBarLink className="d-none" to="/completed-tasks" icon="circle-check">
        Completed Tasks
      </SideBarLink>
      <SideBarLink to={Paths.Templates} icon="map">
        Templates
      </SideBarLink>
      {hasPermission('custom_reports') ? (
        <SideBarLink to={Paths.CustomReport} icon="chart-column">
          Custom Report
        </SideBarLink>
      ) : null}
      <SideBarSeparator />
      <SideBarLink to={Paths.MyProfile} icon="circle-user">
        My Profile
      </SideBarLink>
      {hasPermission('user_management') && (
        <SideBarLink to="/users" icon="users">
          Users
        </SideBarLink>
      )}
      <SideBarLink to="/homeowners" icon="person-shelter">
        Homeowners
      </SideBarLink>
      <SideBarLink className="d-none" to="/subs" icon="compress">
        Subs
      </SideBarLink>
      <SideBarLink className="d-none" to="/builder" icon="star">
        Builder Account
      </SideBarLink>
      <SideBarLink to="/settings" icon="gear" className={cn({ 'd-none': !isShowingSettings })}>
        Settings
      </SideBarLink>
      <HelpCenterLink />
    </SideBar>
  );
};

export default BuilderSideBar;
