import React from 'react';
import { SystemItem } from './common';
import { formatTrx, plural } from '../../utils';

export default ({ item, user }) => {
  const date = field => formatTrx(item.getIn(['data', field]));
  const behind = item.getIn(['data', 'daysBehind']);

  return (
    <SystemItem item={item} user={user}>
      Predecessor logic updated exp. start and exp. finish dates to {date('newStartDate')}, {date('newEndDate')}
      {!!behind && ' and had an impact of '}
      {behind > 0 && <span className="text-danger">{plural.day(behind)} behind</span>}
      {behind < 0 && <span className="text-success">{plural.day(Math.abs(behind))} ahead</span>}
    </SystemItem>
  );
};
