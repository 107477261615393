import moment from 'moment';
import { fromJS } from 'immutable';
import { markAsSideEffect } from '../../index';
import { setCheckedInStatus } from '../../utils';
import { PrecedingStatusModal } from './PrecedingStatusModal';
import navigate from 'app/navigate';
import { getUpcomingQuery, getQueryParam } from '../getQueryParams';
import { FILTER_NAME } from '../../Filters/Implementation/filter.name';

const BATCH_SIZE = 4;

const taskKey = task => task.set('key', `${task.getIn(['job', 'id'])}_${task.get('id')}`);

const expectedStartLabel = task => {
  const startDate = moment(task.get('startDate'), 'YYYY-MM-DD');
  return task
    .set('expectedStartDate', startDate.format('YYYY-MM-DD'))
    .set('expectedStartLabel', `Exp. Start ${startDate.format('MMM D')}`);
};

markAsSideEffect(showPrecedingTasks);
export async function showPrecedingTasks(task) {
  const precedingTasks = task.getIn(['ctr', 'precedingTasksWithStatus']);
  const canEdit = this.appState.getIn(['user', 'role']) === 'manager';
  const jobId = task.getIn(['job', 'id']);
  const taskId = task.get('id');
  const loadTasksOfInterest = () => this.controller.loadTasksOfInterest(jobId, taskId);

  const { isAccept } = await this.modal.open(PrecedingStatusModal, {
    task,
    precedingTasks,
    loadTasksOfInterest,
    canEdit,
  });
  if (canEdit && isAccept && precedingTasks.size === 0) {
    navigate.to.PrecedingTasks({});
  }
}

markAsSideEffect(readUpcomingTasks);
export function readUpcomingTasks(start_index = 0, stop_index = BATCH_SIZE - 1) {
  const filter = this.filtersState.getIn([FILTER_NAME.UPCOMING, 'values'])?.toJS();
  const upcommingQuery = getUpcomingQuery({ start_index, stop_index, sort: 'upcoming' }, this.isTrade);
  const query = getQueryParam(upcommingQuery, filter);

  this.dashboardService.readTasks({}, { query }).then(data => {
    const upcomingTasks = fromJS(data)
      .map(taskKey)
      .map(expectedStartLabel)
      .map(setCheckedInStatus)
      .toArray();

    this.controller.dispatch([
      state =>
        state.update('upcomingTasks', tasks =>
          tasks.splice(start_index, stop_index - start_index + 1, ...upcomingTasks)
        ),
    ]);
  });
}
