import { fromJS } from 'immutable';
import { useController } from '@tradetrax/web-common';
import { useAppContext } from 'app/App.context';
import * as actions from './CommunitiesActions';

const emptyState = fromJS({
  totalCount: 10,
  communities: [],
});

export function CommunitiesContext() {
  const appContext = useAppContext();
  const [state, controller] = useController(actions, emptyState, { ...appContext });
  return { state, controller };
}
