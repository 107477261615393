import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import * as yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from '../../theme';
import { FilterNameEditor } from './FilterNameEditor';

// TODO: rename this component and file to FilterSetItem.

const filterNameSchema = yup.object().shape({
  name: yup
    .string()
    .required('Filter Name is required.')
    .matches(/^[a-zA-Z0-9~!@#$%^&*_\-+=`|\\(){}[\]:;'<>,.?/\s]+$/, 'Character not allowed')
    .min(2, 'Min. 2 characters')
    .max(99, 'Max. 99 characters'),
});

const UnstyledFilter = ({
  className,
  filter,
  isActive,
  isManager,
  controller,
  disabled = false,
  // currectFilterApplied,
  showEditedLabel = false,
}) => {
  const [isEditing, setIsEditing] = React.useState(!!filter.get('isNewFilter'));
  const icon = filter.get('sharedBy') || (isManager && filter.get('isShared')) ? 'user-group' : 'filter';
  const onSelectFilter = e => {
    if (e.target.tagName === 'BUTTON' || e.target.parentElement.tagName === 'BUTTON') return;
    if (disabled) return;
    controller.selectFilter(isActive ? null : filter);
  };

  const props = {
    value: filter.get('name'),
    field: 'name',
    schema: filterNameSchema,
    update: (field, value) => controller.updateFilterName(filter, value),
    filterSets: [],
  };
  //TODO get filterSets

  return (
    <li data-testid="filter-set-item" onClick={onSelectFilter} className={cn(className, { active: isActive })}>
      <FontAwesomeIcon icon={icon} />
      {isEditing ? (
        <FilterNameEditor setIsEditing={setIsEditing} {...props} />
      ) : (
        <span className="ml-2">{`${filter.get('name')}${showEditedLabel ? ' *' : ''}`}</span>
      )}
    </li>
  );
};

/* <TableRowDropDown remove rename duplicate onSelect={onAction} /> */

export const Filter = styled(UnstyledFilter)`
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  padding: 5px 12px 5px 12px;
  margin-right: 2px;
  gap: 2px;

  //width: 111px;
  height: 40px;

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  flex-direction: row;
  align-items: center;
  letter-spacing: 0.2px;
  border: none;
  white-space: nowrap !important;
  color: ${props => (props.disabled ? colors.gray200 : colors.gray400)};
  background: ${colors.white};

  /* Active */
  &:hover {
    background: ${props => (props.disabled ? colors.white : colors.blue100)};
    border-radius: 8px;
  }

  &.active {
    background: ${props => (props.disabled ? colors.blue_50 : colors.blue100)};
    color: ${props => (props.disabled ? colors.blue_30 : colors.blue300)};
    border-radius: 8px;
  }

  > .dropdown {
    visibility: hidden;
  }

  &:hover > .dropdown {
    visibility: visible;
  }
`;
