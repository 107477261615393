import React from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from '../../index';
import { plural } from '../../utils';
import { CarouselCard } from './CarouselCard';
import { CarouselContainer, CarouselContainerCards } from '../index';

export const Carousel = ({
  isOverdue,
  items,
  className,
  Render,
  controller,
  appContext,
  linkToTaskDetails,
  linkToRequestAndOverdueDetails,
  ...props
}) => {
  const { user } = appContext.appState.toObject();
  const themeColor = isOverdue ? colors.red : colors.blue400;
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const overdueTitle = `Overdue ${plural(items.size, 'Task')}`;
  const updateRequestTitle = `Update ${plural(items.size, 'Request')}`;
  const isLoading = items.getIn([0, 'isLoading']);
  const linkToTask = card => {
    const jobId = card.getIn(['job', 'id']);
    const taskId = card.get('id');

    return {
      to: {
        pathname: linkToTaskDetails(jobId, taskId),
        state: { referrer: 'dashboard' },
      },
      onClick: () =>
        isOverdue
          ? controller.markTaskAsSeen({ jobId, taskId })
          : controller.markChangeRequestAsSeen({ jobId, taskId }),
    };
  };

  const moveCardsOnCarousel = position => {
    switch (position) {
      case 1:
        return setCurrentIndex(currentIndex + 1);
      case 2:
        return setCurrentIndex(currentIndex + 2);
      case -1:
        return setCurrentIndex(currentIndex - 1);
      case -2:
        return setCurrentIndex(currentIndex - 2);
      default:
        break;
    }
  };

  return (
    <CarouselContainer themeColor={themeColor} className={className} {...props}>
      <div className="title d-flex align-items-center justify-content-center mb-2">
        <span className="count-label mr-2 ">{isLoading ? '-' : items.size}</span>
        <span> {isOverdue ? overdueTitle : updateRequestTitle}</span>
      </div>
      {items.size === 0 ? (
        <div className="text-center text-secondary">You're up to date!</div>
      ) : (
        <>
          <CarouselContainerCards className="d-flex flex-row align-items-center">
            <ArrowLeftContainer
              style={{ color: `${colors.gray400}` }}
              className={cn('btn', { invisible: currentIndex === 0 })}
              onClick={() => setCurrentIndex(currentIndex - 1)}
            >
              <FontAwesomeIcon icon="chevron-left" style={{ color: `${colors.gray400}` }} />
            </ArrowLeftContainer>
            <div className="cards-container">
              {items.map((card, i) => {
                if (!items.get(currentIndex)) setCurrentIndex(currentIndex - 1);
                return (
                  <CarouselCard
                    key={i}
                    position={i - currentIndex}
                    onClick={() => moveCardsOnCarousel(i - currentIndex)}
                  >
                    <Render
                      linkToTask={linkToTask(card)}
                      controller={controller}
                      key={card.get('id')}
                      card={card}
                      user={user}
                      hasPermission={appContext.hasPermission}
                    />
                  </CarouselCard>
                );
              })}
            </div>
            <ArrowRightContainer
              style={{ color: `${colors.gray400}` }}
              className={cn('btn ', { invisible: currentIndex === items.size - 1 })}
              onClick={() => setCurrentIndex(currentIndex + 1)}
            >
              <FontAwesomeIcon icon="chevron-right" style={{ color: `${colors.gray400}` }} />
            </ArrowRightContainer>
          </CarouselContainerCards>
          <div className={cn('text-center', { invisible: items.size < 6 })}>
            <button className="btn btn-link mt-2 mb-1 shadow-none" onClick={linkToRequestAndOverdueDetails}>
              View all
            </button>
          </div>
        </>
      )}
    </CarouselContainer>
  );
};

const ArrowLeftContainer = styled.button`
  margin-right: 40px;
  @media (min-width: 992px) {
    margin-right: 0;
  }
`;

const ArrowRightContainer = styled.button`
  margin-left: 40px;
  @media (min-width: 992px) {
    margin-left: 0;
  }
`;
