import moment from 'moment';
import { formatISO } from '../utils';

// const sort = 'upcoming';
const status = ['not-started', 'not-ready-to-start'];

// TODO: refactor this file/method name/etc
export function getUpcomingQuery(initQuery, isTrade = false) {
  const currentDate = formatISO(new Date());
  const latestDate = formatISO(moment(currentDate).add(30, 'days'));
  const startDateQuery = `gte:${currentDate}&lte:${latestDate}`;
  const query = { ...initQuery, startDateQuery, status };
  if (isTrade) query.includeCtrStatus = true;

  return query;
}

export function getQueryParam(query = {}, filter) {
  if (!filter) return query;

  if (filter.myTasks) query.myTasks = true;
  if (filter.taskNames?.length) query.taskNames = filter.taskNames;
  if (filter.communityIds?.length) query.communityIds = filter.communityIds;
  if (filter.assigneeAccounts?.length) query.assigneeAccounts = filter.assigneeAccounts;

  return query;
}
