import { useState } from 'react';
import { fromJS } from 'immutable';
import moment from 'moment';
import { buildersService } from '../../services';

export const emptyState = fromJS([]);

const JobsStore = ([jobs, setState]) => {
  const initialize = () => readJobs().then(setState);
  const [jobsTotalCount, setTotalCount] = useState(10);
  const [communityJobsTotalCount, setcommunityJobsTotalCount] = useState(10);

  return {
    initialize,
    jobs,
    jobsTotalCount,
    communityJobsTotalCount,
    readJobs,
    createJob,
    deleteJob,
    getJob,
    readJobTasks,
    updateJobStatus,
    assignTask,
    updateTaskDuration,
    updateDependencies,
    updateDate,
    updateEndDate,
    updateStatus,
    updateTaskScheduler,
    updateTaskSuper,
    acceptExpFinishDateRequest,
    cancelExpFinishDateRequest,
    acceptStartDateRequest,
    cancelStartDateRequest,
    releaseToConstruction,
    addTask,
    deleteTask,
    reorderTaks,
    readJobsByCommunity,
    readJobFeed,
    readJobFeedCount,
    invalidate: initialize,
  };

  function readJobs(startIndex = 0, stopIndex = 14) {
    return buildersService.readJobs({}, { query: { start_index: startIndex, stop_index: stopIndex } }).then(data => {
      const totalCount = data.metadata.pagination ? data.metadata.pagination.totalCount : 0;
      setTotalCount(totalCount);
      return fromJS(data);
    });
  }

  async function createJob({ name, templateId, communityId, lotNumber, squareFootage }) {
    const job = {
      name,
      templateId,
      communityId,
    };
    if (lotNumber) {
      job.lotNumber = lotNumber;
    }
    if (squareFootage) {
      job.squareFootage = squareFootage;
    }
    const newJob = fromJS(await buildersService.createJob(job));
    setState(jobs.push(newJob));
    return newJob.get('_id');
  }

  async function deleteJob(job) {
    // TODO await buildersService.removeJob()
  }

  function getJob(jobId) {
    return buildersService.readJob({}, { params: { jobId } }).then(fromJS);
  }

  function readJobTasks(jobId) {
    return buildersService.readJobTasks({}, { params: { jobId } }).then(fromJS);
  }

  function updateJobStatus(jobId, status) {
    return buildersService.updateJob({ status }, { params: { jobId } });
  }

  function assignTask({ jobId, taskId, assigneeAccountId }) {
    return buildersService.updateTask({ assigneeAccountId }, { params: { jobId, taskId } });
  }

  function updateTaskScheduler({ jobId, taskId, userSchedulerId }) {
    return buildersService.updateTask({ userSchedulerId }, { params: { jobId, taskId } });
  }

  function updateTaskSuper({ jobId, taskId, userSuperId }) {
    return buildersService.updateTask({ userSuperId }, { params: { jobId, taskId } });
  }

  function updateTaskDuration({ jobId, taskId, duration }) {
    return buildersService.updateTask({ duration }, { params: { jobId, taskId } });
  }

  function updateDependencies(jobId, taskId, predecessors) {
    const predecesorList = predecessors.map(p => {
      return {
        taskId: parseInt(p.get('taskId'), 10),
        dependencyType: p.get('dependencyType'),
        lagTime: parseInt(p.get('lagTime'), 10),
      };
    });
    return buildersService.updateTask(
      { predecessors: predecesorList.toJS(), missingReference: false },
      { params: { jobId, taskId } }
    );
  }

  function updateDate(dateString, taskId, jobId) {
    return buildersService.updateTask({ startDate: dateString }, { params: { jobId, taskId } });
  }

  function updateEndDate(jobId, taskId, date) {
    return buildersService.updateTask({ endDate: date }, { params: { jobId, taskId } });
  }

  function updateStatus(task, status, job) {
    const taskId = task.get('id');
    const jobId = job.get('_id');
    return buildersService.updateTask({ status }, { params: { jobId, taskId } });
  }

  function acceptExpFinishDateRequest(jobId, taskId, newEndDate) {
    return buildersService.acceptFinishDateRequest({ newEndDate }, { params: { jobId, taskId } });
  }

  function cancelExpFinishDateRequest(jobId, taskId) {
    return buildersService.rejectFinishDateRequest({}, { params: { jobId, taskId } });
  }

  function acceptStartDateRequest(jobId, taskId, newStartDate) {
    return buildersService.acceptStartDateRequest({ newStartDate }, { params: { jobId, taskId } });
  }

  function cancelStartDateRequest(jobId, taskId) {
    return buildersService.rejectStartDateRequest({}, { params: { jobId, taskId } });
  }

  function releaseToConstruction(job) {
    const jobId = job.get('_id');
    return buildersService.releaseJob({}, { params: { jobId } });
  }

  function addTask(job, param) {
    const jobId = job.get('_id');
    const { task, duration } = param;
    const newTask = {
      ...param,
      name: task.name,
      trade: task.trade,
      duration: parseInt(duration, 10),
    };
    delete newTask.task;
    return buildersService.addTask(newTask, { params: { jobId } });
  }

  function deleteTask(job, task) {
    const jobId = job.get('_id');
    const taskId = task.get('id');
    return buildersService.deleteTask({}, { params: { jobId, taskId } });
  }

  function reorderTaks(jobId, taskId, newOrder) {
    return buildersService.reorderTask({ newOrder }, { params: { jobId, taskId } });
  }

  async function readJobsByCommunity(communityId, startIndex = 0, stopIndex = 15) {
    const data = await buildersService.readJobs(
      {},
      { query: { communityId, start_index: startIndex, stop_index: stopIndex } }
    );
    const totalCount = data.metadata.pagination ? data.metadata.pagination.totalCount : 0;
    setcommunityJobsTotalCount(totalCount);
    return fromJS(data);
  }

  async function readJobFeed(jobId, startIndex = 0, stopIndex = 10) {
    const utcOffset = moment().format('Z');
    return buildersService
      .readJobFeed({}, { params: { jobId }, query: { utcOffset, start_index: startIndex, stop_index: stopIndex } })
      .then(fromJS);
  }

  async function readJobFeedCount(jobId) {
    const utcOffset = moment().format('Z');
    return buildersService.readJobFeedCount({}, { params: { jobId }, query: { utcOffset } }).then(fromJS);
  }
};

JobsStore.emptyState = emptyState;

export default JobsStore;
