import React from 'react';
import { Link } from 'react-router-dom';
import { InfiniteLoader, AutoSizer, Table, Column } from 'react-virtualized';
import { CommunityNew } from './CommunityNew';
import navigate from 'app/navigate';
import { EMPTY_ROW, renderCell } from '@tradetrax/web-common';
import { EmptyState, EmptyStateCenterContainer } from '@tradetrax/web-common/lib/EmptyState';
import { CommunitiesContext } from './CommunitiesContext';

const COL_WIDTHS = {
  span: 32,
  activeJobs: 128,
  jobsBehind: 128,
  // city: 128,
  // expStart: 128,
  // expFinish: 128,
  // status: 160, //not yet available
  // action: 32,  //not yet available
};

const WIDTHS = Object.values(COL_WIDTHS).reduce((sum, width) => sum + width, 0);

export const Communities = () => {
  const { state, controller } = CommunitiesContext();
  const { totalCount, communities } = state.toObject();

  return (
    <>
      <div className="d-flex flex-row align-items-center justify-content-between my-4 pb-3 mx-4">
        <h4 className="font-weight-bold">Communities</h4>
        <CommunityNew communities={communities} controller={controller} />
      </div>
      <InfiniteLoader
        isRowLoaded={({ index }) => !!communities.get(index)}
        loadMoreRows={controller.loadMoreRows}
        rowCount={totalCount}
      >
        {({ onRowsRendered, registerChild }) => (
          <div style={{ flex: '1 1 auto', overflowY: 'hidden' }}>
            <AutoSizer>
              {({ width, height }) => {
                return (
                  <Table
                    ref={registerChild}
                    onRowsRendered={onRowsRendered}
                    className="trx-table stickyColumn2"
                    headerHeight={40}
                    width={width}
                    height={height}
                    onRowClick={() => {}}
                    overscanRowCount={2}
                    rowHeight={80}
                    rowCount={totalCount}
                    rowClassName={({ index }) => (communities.get(index) ? '' : 'loading')}
                    rowGetter={({ index }) => communities.get(index) || EMPTY_ROW}
                    noRowsRenderer={() => (
                      <EmptyStateCenterContainer top="220px">
                        <EmptyState
                          icon="building"
                          title="No Communities Created"
                          body="When you create a Community, it will appear here."
                        />
                      </EmptyStateCenterContainer>
                    )}
                  >
                    <Column width={COL_WIDTHS.span} label="" dataKey="" />
                    <Column
                      label="Name"
                      dataKey="name"
                      minWidth={230}
                      width={width - WIDTHS}
                      cellRenderer={renderCell(({ cellData, rowIndex }) => (
                        <Link
                          className="main-link w-100 d-inline-block text-truncate"
                          to={navigate.to.CommunityDetails(
                            { communityId: communities.getIn([rowIndex, '_id']) },
                            false
                          )}
                        >
                          {cellData}
                        </Link>
                      ))}
                    />
                    {/* <Column label="City" width={COL_WIDTHS.city} cellRenderer={() => 'Not defined'} /> */}
                    {/* <Column
                      label="Exp. Start"
                      width={COL_WIDTHS.expStart}
                      dataKey="startDate"
                      cellRenderer={({ cellData }) => mongoToTrx(cellData) || 'MM/DD/YY'}
                    />
                    <Column
                      label="Exp. Finish"
                      width={COL_WIDTHS.expFinish}
                      dataKey="expectedFinishDate"
                      cellRenderer={({ cellData }) => mongoToTrx(cellData) || 'MM/DD/YY'}
                    /> */}
                    <Column
                      label="Active Jobs"
                      dataKey="activeJobs"
                      minWidth={150}
                      width={COL_WIDTHS.activeJobs}
                      cellRenderer={renderCell(({ cellData }) => cellData)}
                      style={{ paddingLeft: '28px' }}
                    />
                    <Column
                      className="text-danger "
                      label="Jobs Behind"
                      dataKey="jobsBehindSchedule"
                      width={COL_WIDTHS.jobsBehind}
                      cellRenderer={renderCell(({ cellData }) => cellData)}
                      style={{ paddingLeft: '32px' }}
                    />
                    {/* <Column
                      label="Status"
                      width={COL_WIDTHS.status}
                      cellRenderer={({ cellData }) => <ChipStatus {...STATUS_MAP[cellData]} />}
                    /> */}
                    {/* <Column width={COL_WIDTHS.action} cellRenderer={() => <TableRowDropDown remove />} /> */}
                  </Table>
                );
              }}
            </AutoSizer>
          </div>
        )}
      </InfiniteLoader>
    </>
  );
};
