import React from 'react';
import { Tasks } from './Tasks';
import { SearchGTL } from '@tradetrax/web-common/lib/Inputs/SearchGTL';

export const RelevantTasks = ({ ...props }) => {
  const [expanded, expand] = React.useState(null);

  const { controller, state } = props;
  const relevantTasks = state.get('relevantTasks');
  const addTask = task => {
    controller.addRelevantTask(task);
  };

  return (
    <div>
      <h5 className="font-weight-bold mb-4">Relevant Tasks</h5>
      <p>
        Select tasks that are relevant to you in order to receive notifications about them. The notifications will apply
        for the selected tasks on Jobs that are assigned to you.
      </p>
      <SearchGTL selectedTasks={relevantTasks} expand={expand} addTask={addTask} state={state} />
      <Tasks {...props} expanded={expanded} expand={expand} />
    </div>
  );
};
