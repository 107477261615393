import React from 'react';
import { useAppContext } from 'app/App.context';
import CommunityForm from './CommunityForm';
import { PrimaryButton } from '@tradetrax/web-common';

const title = 'Create New Community';

export const CommunityNew = ({ communities, controller }) => {
  const { appState, modal } = useAppContext();
  const users = appState.getIn(['account', 'usersActive']);

  const onSubmit = form => {
    form.areaManagersIds = form.areaManagersIds[0]?._id ? [form.areaManagersIds[0]?._id] : null;

    const formArray = Object.entries(form);
    const filteredForm = formArray.filter(([key, value]) => value !== null);

    controller.createNewCommunity(Object.fromEntries(filteredForm));
  };
  const onClick = e => {
    modal.open(CommunityForm, { title, onSubmit, communities, users });
  };

  return <PrimaryButton onClick={onClick}>Create Community</PrimaryButton>;
};
