import React from 'react';
import styled from 'styled-components';
import { Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers';
import { colors } from '@tradetrax/web-common';
import { preventConsecutiveSpaceChar } from '@tradetrax/web-common/lib/utils';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required')
    .matches(/^[a-zA-Z0-9\s]+$/, 'Character not allowed')
    .min(2, 'Min. 2 characters')
    .max(30, 'Max. 30 characters'),
});
export const AddNewMilestone = ({ controller, state }) => {
  const { register, errors, setError, formState, handleSubmit, watch, reset } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: { name: '' },
  });

  const { name } = watch();

  const onSubmit = async ({ name }) => {
    try {
      await controller.createMilestone(name);
      reset({ name: '' });
      var elmRefScroll = document.getElementById('scrollNewMilestoneRef');
      elmRefScroll.scrollIntoView({ behavior: 'smooth' });
    } catch (error) {
      if (error.type === 'entity-conflict') {
        setError('name', { type: 'notMatch', message: 'Name already exists in this Job' });
      }
    }
  };

  return (
    <AddNewMilestoneContainer>
      <Form validated={formState.isValid} onSubmit={handleSubmit(onSubmit)}>
        <InputMilestoneName
          type="text"
          name="name"
          ref={register}
          isInvalid={!!errors.name}
          placeholder="Ex. Foundation"
          onKeyDown={preventConsecutiveSpaceChar}
        />
        <Form.Control.Feedback type="invalid">
          <FontAwesomeIcon icon="circle-exclamation" /> {errors.name?.message}
        </Form.Control.Feedback>
        <Form.Control.Feedback type="valid">
          <FontAwesomeIcon icon="circle-check" /> Milestone name is valid
        </Form.Control.Feedback>
        <SubmitButton type="submit" className="rounded-pill" disabled={name.length === 0 || errors.name}>
          Create Milestone
        </SubmitButton>
      </Form>
    </AddNewMilestoneContainer>
  );
};

const AddNewMilestoneContainer = styled.div`
  position: relative;
  width: calc(100% - 200px);
  @media (min-width: 992px) {
    width: 300px;
  }
`;

const SubmitButton = styled(Button)`
  position: absolute;
  right: -190px;
  top: 0;
  width: 217px;
  display: inline-block;
  height: 42px;
  padding: 0;
  opacity: 1 !important;

  &:disabled {
    background-color: ${colors.gray200};
    outline: none;
    border: 0;
  }
`;

const InputMilestoneName = styled(Form.Control)`
  height: 42px;
  z-index: 1;
`;
