import React from 'react';
import styled from 'styled-components';
import { TextPlaceholder } from '../../LoadingPlaceholder';

export const UpcomingCardPlaceholder = () => {
  const n = 4;
  const LoadingCard = () => (
    <UpcomingCardLoading position={0} className="mt-3">
      <div className="d-flex flex-row justify-content-between ">
        <div className="d-flex flex-column mr-1" style={{}}>
          <TextPlaceholder style={{ width: '265px' }} />
          <TextPlaceholder style={{ width: '159px' }} />
        </div>
        <div className="d-flex flex-column ml-1 flex-fill" style={{ alignItems: 'flex-end' }}>
          <TextPlaceholder style={{ width: '107px' }} />
          <TextPlaceholder style={{ width: '150px' }} />
        </div>
      </div>
    </UpcomingCardLoading>
  );
  return [...Array(n)].map((e, i) => <LoadingCard key={i} />);
};

const UpcomingCardLoading = styled.div`
  width: 100%;
  height: 80px;
  background: #ffffff;

  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(51, 51, 51, 0.2);
  border-radius: 8px;
  padding: 20px;
`;

export const LoadingTaskCard = ({ isOverdue }) => {
  return (
    <CardLoading>
      <div className="d-flex flex-row justify-content-between">
        <div className="d-flex flex-column mr-1" style={{ marginTop: '18px' }}>
          <TextPlaceholder style={{ width: '141px' }} />
          <TextPlaceholder style={{ width: '175px', marginTop: '10px' }} />
          <TextPlaceholder style={{ width: '141px' }} />
        </div>
        <div className="d-flex flex-column ml-1 flex-fill" style={{ alignItems: 'flex-end', marginTop: '18px' }}>
          <TextPlaceholder style={{ width: '150px' }} />
          <TextPlaceholder style={{ width: '110px', marginTop: '10px' }} />
        </div>
      </div>
      <hr className="m-2 p-0" />
      <div className="d-flex flex-row justify-content-center ">
        {isOverdue ? (
          <div className=" mt-2">
            <TextPlaceholder width="144px" />
          </div>
        ) : (
          <>
            <div className=" d-flex justify-content-center mt-2" style={{ width: '50%' }}>
              <TextPlaceholder width="80px" />
            </div>
            <div className=" mt-2" style={{ width: '50%' }}>
              <TextPlaceholder width="144px" />
            </div>
          </>
        )}
      </div>
    </CardLoading>
  );
};

const CardLoading = styled.div`
  width: 420px;
  bottom: 0;
  height: 190px;
  /* White */
  background: #ffffff;
  /* Gray 50 */
  border: 1px solid #e0e2e7;
  box-sizing: border-box;
  box-shadow: 0px 2px 10px rgba(51, 51, 51, 0.25);
  border-radius: 8px;
  padding: 20px;
`;

const LoadingState = styled.div`
  background-color: #f9f9fa;
  height: 112px;
  padding: 1.25rem 1.25rem 1.25rem 2rem;
  margin-bottom: 0.75rem;
`;

export const DashboardLoadingState = () => {
  const n = 5;
  const LoadingCard = () => (
    <LoadingState className="d-flex flex-row justify-content-between">
      <div className="d-flex flex-column mr-1" style={{ flex: '2 1 auto' }}>
        <TextPlaceholder width="80%" />
        <TextPlaceholder width="50%" />
        <TextPlaceholder width="100%" />
      </div>
      <div className="d-flex flex-column ml-1 flex-fill" style={{ alignItems: 'flex-end' }}>
        <TextPlaceholder width="100%" />
        <TextPlaceholder width="80%" />
      </div>
    </LoadingState>
  );
  return [...Array(n)].map((e, i) => <LoadingCard key={i} />);
};
