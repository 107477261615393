import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { InfiniteLoader, AutoSizer, Table, Column } from 'react-virtualized';
import cn from 'classnames';
import { PrimaryButton, ChipStatus, STATUS_MAP, EMPTY_ROW, renderCell } from '@tradetrax/web-common';
import { EmptyState, EmptyStateCenterContainer } from '@tradetrax/web-common/lib/EmptyState';
import { JobPageController, MINIMUM_BATCH_SIZE } from './JobPageController';
import navigate from 'app/navigate';
import { mongoToTrx, handleTableScrollStickyColumn2, scrollbarStyles } from '@tradetrax/web-common/lib/utils';

const COL_WIDTHS = {
  span: 16 * 2,
  community: 16 * 14,
  // tasks: 16 * 6,
  // createdBy: 16 * 10,
  expStart: 16 * 7,
  expFinish: 16 * 7,
  lotNumber: 16 * 10,
  status: 160,
  // action: 16 * 2,
};

const WIDTHS = Object.values(COL_WIDTHS).reduce((sum, width) => sum + width, 0);

export const JobsPage = () => {
  const { state, controller } = JobPageController();
  const { jobs, totalCount, maxCount } = state.toObject();
  const scrollDivRef = useRef(null);
  const rowClassName = rowData => (rowData === null || rowData === EMPTY_ROW ? 'loading' : '');

  useEffect(() => {
    const element = scrollDivRef.current;
    if (element) element.addEventListener('scroll', handleTableScrollStickyColumn2);

    return () => {
      if (element) element.removeEventListener('scroll', handleTableScrollStickyColumn2);
    };
  }, []);

  return (
    <>
      <div className="mx-4 my-4 d-flex flex-row justify-content-between  align-items-center">
        <h4 className="font-weight-bold">Jobs</h4>
        <PrimaryButton data-testid="btn-create-job" onClick={() => controller.openNewJobModal()}>
          Create Job
        </PrimaryButton>
      </div>
      <InfiniteLoader
        isRowLoaded={({ index }) => !!jobs.get(index)}
        loadMoreRows={controller.loadMoreRows}
        rowCount={Math.min(maxCount, totalCount)}
        threshold={0}
        minimumBatchSize={MINIMUM_BATCH_SIZE}
        style={{ position: 'relative' }}
      >
        {({ onRowsRendered, registerChild }) => (
          <ScrollDiv ref={scrollDivRef}>
            <AutoSizer>
              {({ width, height }) => {
                const minWidth = width > 1200 ? width : 1200;
                return (
                  <Table
                    ref={registerChild}
                    onRowsRendered={onRowsRendered}
                    className="trx-table stickyColumn2"
                    headerHeight={40}
                    width={minWidth}
                    height={height}
                    overscanRowCount={2}
                    rowHeight={80}
                    rowCount={Math.min(maxCount, totalCount)}
                    rowClassName={({ index }) => rowClassName(jobs.get(index))}
                    rowGetter={({ index }) => jobs.get(index) || EMPTY_ROW}
                    noRowsRenderer={() => (
                      <EmptyStateCenterContainer top={'220px'}>
                        <EmptyState
                          icon="house"
                          title="No Jobs Created"
                          body="When you create a Job it will appear here."
                        />
                      </EmptyStateCenterContainer>
                    )}
                    onScroll={event => handleTableScrollStickyColumn2(event, scrollDivRef)}
                  >
                    <Column label="" dataKey="" width={COL_WIDTHS.span} />
                    <Column
                      label="Job Name"
                      dataKey="name"
                      style={{ marginTop: '20px' }}
                      minWidth={250}
                      width={minWidth - WIDTHS - COL_WIDTHS.span}
                      cellRenderer={renderCell(({ cellData, rowData }) => (
                        <Link
                          className="main-link"
                          to={{
                            pathname: navigate.to.JobDetails({ jobId: rowData.get('_id') }, false),
                            state: {
                              referrer: 'jobs',
                              defaultTab: 'schedule',
                            },
                          }}
                        >
                          <div className="text-truncate">{cellData}</div>
                        </Link>
                      ))}
                    />
                    <Column
                      label="LOT #"
                      dataKey="lotNumber"
                      width={COL_WIDTHS.lotNumber}
                      className="overflow-hidden"
                      cellRenderer={renderCell(({ cellData }) => (
                        <span
                          className={cn('d-inline-block text-truncate w-100', { 'text-gray-200': cellData === null })}
                        >
                          {cellData === null ? 'Not Set' : cellData}
                        </span>
                      ))}
                    />
                    <Column
                      label="Community"
                      dataKey="communityName"
                      width={COL_WIDTHS.community}
                      cellRenderer={renderCell(({ rowData, cellData }) => (
                        <Link
                          className="secondary-link text-truncate d-block"
                          to={navigate.to.CommunityDetails({ communityId: rowData.get('communityId') }, false)}
                        >
                          {cellData}
                        </Link>
                      ))}
                    />
                    {/* <Column
                      label="Tasks"
                      dataKey="numberOfTasks"
                      width={COL_WIDTHS.tasks}
                      cellRenderer={renderCell()}
                    />
                    <Column
                      label="Created By"
                      dataKey="createdByUserName"
                      width={COL_WIDTHS.createdBy}
                      cellRenderer={renderCell()}
                    /> */}
                    <Column
                      label="Exp. Start"
                      width={COL_WIDTHS.expStart}
                      dataKey="statusData"
                      cellRenderer={renderCell(
                        ({ cellData, rowData }) =>
                          mongoToTrx(cellData.get('targetStart') || rowData.get('startDate')) || 'MM/DD/YY'
                      )}
                    />
                    <Column
                      label="Exp. Finish"
                      width={COL_WIDTHS.expFinish}
                      dataKey="statusData"
                      cellRenderer={renderCell(({ cellData, rowData }) => (
                        <span className="with-sublabel">
                          {mongoToTrx(cellData.get('targetFinish') || rowData.get('expectedFinishDate')) || 'MM/DD/YY'}
                          {/* {cellData.get('cycleTime') && <span>{cellData.get('cycleTime')} days Cycle</span>} */}
                        </span>
                      ))}
                    />
                    <Column
                      label="Status"
                      dataKey="status"
                      width={COL_WIDTHS.status}
                      cellRenderer={renderCell(({ cellData }) => (
                        <ChipStatus {...STATUS_MAP[cellData]} />
                      ))}
                    />

                    <Column label="" dataKey="" width={COL_WIDTHS.span} />
                    {/* <Column
                      label=""
                      dataKey=""
                      width={COL_WIDTHS.action}
                      hidden
                      className="d-none"
                      cellRenderer={renderCell(({ rowIndex }) => (
                        <TableRowDropDown
                          remove
                          onSelect={() => modal.open(JobDelete, { job: jobs.get(rowIndex), deleteJob })}
                        />
                      ))}
                    /> */}
                  </Table>
                );
              }}
            </AutoSizer>
          </ScrollDiv>
        )}
      </InfiniteLoader>
    </>
  );
};

const ScrollDiv = styled.div`
  flex: 1 1 auto;
  ${scrollbarStyles}
`;
