import React, { useContext } from 'react';
import { fromJS } from 'immutable';
import { useForm } from 'react-hook-form';
import { useController } from '@tradetrax/web-common/lib/useController';
import { isoToTrxFull } from '@tradetrax/web-common/lib/utils';
import { useAppContext } from 'app/App.context';
import * as actions from './Settings.actions';

const getHolidays = settings =>
  settings
    .get('holidays')
    .toArray()
    .map(isoToTrxFull)
    .join(', ');

const defaultValues = settings => ({
  workingDays: settings.get('workingDays').toJS(),
  holidays: getHolidays(settings),
});

const emptyState = fromJS({
  tab: 'company',
  globalTasks: [],
});

export const SettingsContext = React.createContext(null);
export const useSettingsContext = () => useContext(SettingsContext);

export const Context = () => {
  const appContext = useAppContext();
  const { appState } = appContext;
  const settings = appState.get('account');
  const formContext = useForm({
    ...defaultValues,
  });
  const hasPermission = appState.getIn(['user', 'permissions', 'user_management']) === 'yes';
  const initialState = emptyState.set('settings', settings).set('hasPermission', hasPermission);
  const [state, controller] = useController(actions, initialState, {
    formContext,
    ...appContext,
  });
  const { reset } = formContext;

  React.useEffect(() => {
    reset({ ...defaultValues(settings) });
    controller.updateSettings(settings);
    controller.loadGTL();
  }, [settings, reset, controller]);

  return {
    state,
    controller,
    formContext,
  };
};
