import React from 'react';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrimaryButton } from '@tradetrax/web-common';

export const ReleasedStatus = ({ job }) => {
  if (!job.get('released')) return null;

  return (
    <div className="ml-auto font-size-16 mt-4 mr-4 mb-4">
      <FontAwesomeIcon icon="circle-check" className=" text-success" />{' '}
      <span className="text-success ml-1 font-weight-bold">Job Released</span>
    </div>
  );
};

export const ReleaseToConstructionButton = ({ job, tasks, hasPermissionForStatusAndRelease, onReleaseClick }) => {
  const { allTasksAssigned, allTaskDatesSet, notAllTasksArePreConstruction, isReadyForRelease } = React.useMemo(() => {
    const allTasksAssigned = tasks.every(
      task => task.get('isStageTask') || !!task.getIn(['assigneeAccount', 'companyId'])
    );
    const allTaskDatesSet = tasks.every(task => !!task.get('hasDatesSet'));
    const notAllTasksArePreConstruction = tasks.some(
      task => !task.get('isPreConstruction') && !task.get('isStageTask')
    );
    const isReadyForRelease =
      tasks.size > 0 &&
      allTasksAssigned &&
      allTaskDatesSet &&
      notAllTasksArePreConstruction &&
      hasPermissionForStatusAndRelease;
    return { allTasksAssigned, allTaskDatesSet, notAllTasksArePreConstruction, isReadyForRelease };
  }, [tasks, hasPermissionForStatusAndRelease]);

  if (job.get('released')) return null;

  return (
    <div className="d-flex flex-column align-items-end ml-auto">
      <PrimaryButton
        disabled={!isReadyForRelease}
        variant="primary"
        className="rounded-pill ml-auto"
        onClick={onReleaseClick}
        style={{ marginTop: '-2rem' }}
        data-testid="btn-release-to-construction"
      >
        Release to Construction
      </PrimaryButton>
      <NotReady hidden={isReadyForRelease}>
        <p className="mb-2">To release this Job, it must meet the following requirements:</p>
        <ul className="list-unstyled m-0 p-0">
          <IconCheckListItem success={allTaskDatesSet}>All dates must be set</IconCheckListItem>
          <IconCheckListItem success={allTasksAssigned}>All Tasks must be assigned</IconCheckListItem>
          <IconCheckListItem success={notAllTasksArePreConstruction}>
            There must be at least one Non-Pre-Construction Task
          </IconCheckListItem>
        </ul>
      </NotReady>
    </div>
  );
};

const NotReady = ({ hidden, children }) => {
  if (hidden) return null;

  const popover = (
    <Popover className="trx-popover">
      <Popover.Content className="text-dark">{children}</Popover.Content>
    </Popover>
  );

  return (
    <div className="d-flex flex-row small align-items-center m-2 text-secondary">
      Not ready to Release
      <OverlayTrigger placement="bottom" overlay={popover}>
        <FontAwesomeIcon icon="triangle-exclamation" className="text-yellow-100 ml-2 cursor-pointer" />
      </OverlayTrigger>
    </div>
  );
};

const IconCheckListItem = ({ success, children }) => {
  const icon = success ? 'check-circle' : 'triangle-exclamation';
  const className = success ? 'text-success mr-2 mt-1' : 'text-yellow-100 mr-2 mt-1';

  return (
    <li className="d-flex flex-row py-1">
      <FontAwesomeIcon icon={icon} className={className} />
      {children}
    </li>
  );
};
