import React from 'react';
import { Item, Name, dateField, isBuilder, SystemItem } from './common';
import { WeatherIndicator } from '../WeatherIndicator';

export default ({ item, account, user, isInactive }) => {
  const isSystemItem = !item.get('createdByAccountId');
  const isLoggedBuilder = isBuilder(account);

  const Wrapper = isSystemItem ? SystemItem : Item;
  return (
    <Wrapper item={item} user={user}>
      <Name item={item} account={account} user={user} isInactive={isInactive} />
      {` ${isLoggedBuilder ? 'proposed' : 'sent'} exp. start to ${dateField(item, 'newStartDate')}`}
      {!isLoggedBuilder && ` to ${item.getIn(['data', 'builderAccountName'])}`}
      <WeatherIndicator item={item} />
    </Wrapper>
  );
};
