import React from 'react';
import { List } from 'immutable';
import { buildersService } from 'services';

const emptyState = List([{ _id: 'aaaaabbbbbcccccddddd', name: '' }]);

export const useTemplates = () => {
  const [templates, setTemplates] = React.useState(emptyState);

  React.useEffect(() => {
    buildersService
      .getTemplates({}, { query: { complete: true } })
      .then(templates => templates.map(({ _id, name, stages }) => ({ _id, name, stages })))
      .then(setTemplates);
  }, []);

  return templates;
};
