import React from 'react';
import { useAppContext } from 'app/App.context';
import { EmptyStateCenterContainer } from '@tradetrax/web-common/lib/EmptyState';
import { NotPermission } from '@tradetrax/web-common';
import { Context, SettingsContext } from './SettingsController';
import { Route, Switch } from 'react-router-dom';
import { Paths } from 'app/navigate';
import { SettingsPage } from './SettingsPage';
import { JobSettings } from './JobSettings';
import { CompanyInfo } from './CompanyInfo';
import { Confirmations } from './StartDateConfirmations';
import { HomeownerJobProgress } from './HomeownerJobProgress';
import { CustomRoles } from './CustomRoles/CustomRoles.page';
import { CustomRoleDetails } from './CustomRoles/CustomRoleDetails/CustomRoleDetails.page';

export const SettingsRoutes = () => {
  const appContext = useAppContext();
  const { appState } = appContext;
  const hasPermission = appState.getIn(['user', 'permissions', 'user_management']) === 'yes';

  if (!hasPermission)
    return (
      <EmptyStateCenterContainer>
        <NotPermission />
      </EmptyStateCenterContainer>
    );

  return <Routes appContext={appContext} />;
};

const Routes = ({ appContext }) => {
  const context = Context();
  const settings = context.state.get('settings');

  return (
    <SettingsContext.Provider value={context}>
      <Switch>
        <Route path={Paths.SettingsCustomRoleDetails} component={CustomRoleDetails} />
        <Route>
          <SettingsPage />
          <div className="mt-2 pt-2 ml-4">
            <Switch>
              <Route path={Paths.SettingsJobs} component={JobSettings} />
              <Route path={Paths.SettingsHomeOwner} component={HomeownerJobProgress} />
              <Route path={Paths.SettingsCustomRoles} component={CustomRoles} />
              <Route path={Paths.SettingsStartDateConfirmations} component={Confirmations} />
              <Route path={Paths.Settings} render={props => <CompanyInfo {...props} settings={settings} />} />
            </Switch>
          </div>
        </Route>
      </Switch>
    </SettingsContext.Provider>
  );
};
