import React from 'react';
import { Paths } from 'app/navigate';
import { UserProfileContext } from './UserProfileContext';
import { UserProfileContainer } from '@tradetrax/web-common/lib/UserProfile/UserProfileContainer';

export const UserProfile = ({ match }) => {
  const { userId } = match.params;
  const { state, controller, alert, loggedInUserRole } = UserProfileContext(userId);

  return (
    <UserProfileContainer
      state={state}
      controller={controller}
      alert={alert}
      paths={Paths}
      loggedInUserRole={loggedInUserRole}
    />
  );
};
