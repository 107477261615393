import React from 'react';
import { ChipStatusDropdown, STATUS_MAP, StatusDataOnTime } from '@tradetrax/web-common';
import { InlineInputEdit } from '@tradetrax/web-common/lib/EditableField/InlineInputEdit';
import { JobDatesHeader } from './JobDatesHeader';
import * as yup from 'yup';
import { JobHeaderLoading } from '@tradetrax/web-common/lib/LoadingPlaceholder';
import { ReleaseToConstructionButton } from '../ReleaseToConstructionButton';

const jobNameSchema = yup.object().shape({
  name: yup
    .string()
    .required('Job Name is required.')
    .matches(/^[a-zA-Z0-9~!@#$%^&*_\-+=`|\\(){}[\]:;'<>,.?/\s]+$/, 'Character not allowed')
    .min(2, 'Min. 2 characters')
    .max(99, 'Max. 99 characters'),
});

export const JobHeader = ({ className, job, tasks, updateJob, controller, isLoading, ...props }) => {
  const jobId = job.get('_id');
  const update = (field, value, title) => updateJob(jobId, field, value, title);

  if (isLoading) return <JobHeaderLoading />;
  return (
    <div className={className}>
      <div className="d-flex flex-row align-items-start mr-auto">
        {/* <span className="h4 mb-0 font-weight-bold">{job.get('name')}</span> */}
        <InlineInputEdit
          labelClassName="h4 mb-0 font-weight-bold"
          value={job.get('name')}
          field="name"
          schema={jobNameSchema}
          update={update}
          data-testid="header-job-name"
        />
        <ChipStatusDropdown
          {...STATUS_MAP[job.get('status')]}
          progress={job.get('status') !== 'in-progress'}
          completed={job.get('status') !== 'completed'}
          className="mx-2"
          onSelect={status => controller.openModalTCT({ status, job })}
          disabled={!props.canChangeJobStatus}
          data-testid="job-chip-status-dropdown"
        />
        <StatusDataOnTime data={job.get('statusData')} />
        <ReleaseToConstructionButton job={job} tasks={tasks} {...props} />
        <br />
      </div>
      <JobDatesHeader job={job} update={update} {...props} />
    </div>
  );
};

JobHeader.defaultProps = {
  onReleaseClick: e => e.stopPropagation(),
  onSelectStatus: () => {},
};
