import React from 'react';
import styled from 'styled-components';
import { colors } from '../../theme';

const IconPlaceholder = styled.span`
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 1rem;
  background-color: ${colors.gray500};
`;

const TextPlaceholder = styled.span`
  ${props => `
    display: inline-block;
    width: ${props.width};
    height: 12px;
    background-color: ${colors.gray500};
    border-radius: 5px;
  `}
`;

const LoadingElement = () => (
  <div className="d-flex flex-row mt-4 mb-3">
    <div>
      <IconPlaceholder />
    </div>
    <div className="d-flex flex-column">
      <TextPlaceholder width="650px" className="mb-3" />
      <TextPlaceholder width="250px" />
    </div>
  </div>
);

export const LoadingPlaceHolder = () => <LoadingElement />;
