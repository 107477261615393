import React from 'react';
import { fromJS } from 'immutable';
import { useController } from '../useController';
import * as actions from './TaskHistory.actions';
import { useChannel, CHANNELS } from '../useRealTime';

const emptyState = fromJS({
  isCollapsed: true,
  isLoading: true,
  history: [],
});

export const TaskHistoryContext = (jobId, taskId, historyService) => {
  const [state, controller] = useController(actions, emptyState, { historyService });

  React.useEffect(() => {
    controller.loadTaskHistory(jobId, taskId);
  }, [controller, jobId, taskId]);

  useChannel(CHANNELS.TASK, `${jobId}_${taskId}`, controller.addTaskHistory);

  return { state, controller };
};
