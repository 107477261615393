import React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { CalendarDatepicker } from '../Datepicker';
import { isUserInactive } from '../utils';
import { CalendarWrapper } from './CalendarWrapper';
import { CalendarContext } from './CalendarContext';
import { getEventContent } from './eventContent';
import { dayHeaderRender, dayCellRender, moreLinkRender } from './CalendarRenders';

const headerToolbar = {
  left: 'title,prev,next',
  right: 'dayGridDay,dayGridWeek,dayGridMonth',
};

const getAssigneeInactive = accountUsers => task =>
  isUserInactive(task.getIn(['assigneeAccount', 'installerId']), accountUsers);

export function CalendarView({ isUpcoming, navigate, ...rest }) {
  const { calendarRef, datePickerRef, controller, dayGridConfig, state, linkToTaskDetails } = CalendarContext({
    ...rest,
    navigate,
    isUpcoming,
  });
  const { appContext } = rest;
  const loggedUser = appContext.appState.get('user');
  const accountUsers = appContext.appState.getIn(['account', 'assignees']);
  const isDayView = state.get('calendarView') === 'dayGridDay';
  const isMonthView = state.get('calendarView') === 'dayGridMonth';
  const getIsInactive = getAssigneeInactive(accountUsers);
  const eventContent = getEventContent(getIsInactive, loggedUser, navigate, linkToTaskDetails);
  return (
    <CalendarWrapper className="mx-4" isDayView={isDayView} isMonthView={isMonthView}>
      <CalendarDatepicker
        name="calendar-datepicker"
        datePickerRef={datePickerRef}
        onChange={controller.datePickerClick}
        state={state}
      />
      <hr className="solid" />
      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin]}
        initialView={state.get('calendarView')}
        fixedWeekCount={false}
        headerToolbar={headerToolbar}
        views={dayGridConfig}
        events={controller.getCalendarTasks}
        dayHeaderFormat={{ weekday: 'short' }}
        dayHeaderContent={dayHeaderRender(state.get('totalTasksOnDay'), isUpcoming)}
        dayCellContent={dayCellRender}
        eventContent={eventContent}
        eventClassNames={eventClassNames}
        moreLinkContent={moreLinkRender}
        eventClick={onEventClick(linkToTaskDetails)}
        initialDate={state.get('date')}
        eventsSet={controller.eventsSet}
      />
    </CalendarWrapper>
  );
}

const eventClassNames = ({ event }) => {
  const { task } = event.extendedProps;
  const eventType = task.getIn(['ctr', 'status']) || 'default';
  return [`event-${eventType}`];
};

const onEventClick = linkToTaskDetails => ({ event }) => {
  const { task } = event.extendedProps;
  const jobId = task.getIn(['job', 'id']);
  const taskId = task.get('id');
  const taskStatus = task.get('status');
  linkToTaskDetails({ jobId, taskId, status: taskStatus });
};
