import React from 'react';
import { Item, Name, dateField } from './common';
import { plural } from '../../utils';
import { WeatherIndicator } from '../WeatherIndicator';

export default ({ item, account, user, isInactive }) => {
  const behind = item.getIn(['data', 'daysBehind']);

  return (
    <Item item={item} user={user}>
      <Name item={item} account={account} user={user} isInactive={isInactive} /> updated exp. finish date to{' '}
      {dateField(item, 'newEndDate')}
      {!!behind && ' and had an impact of '}
      {behind > 0 && <span className="text-danger">{plural.day(behind)} behind</span>}
      {behind < 0 && <span className="text-success">{plural.day(Math.abs(behind))} ahead</span>}
      <WeatherIndicator item={item} />
    </Item>
  );
};
