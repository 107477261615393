import React from 'react';
import { fromJS } from 'immutable';
import { useController } from '@tradetrax/web-common';
import { useAppContext } from 'app/App.context';
import * as templatesActions from './TemplatesActions';
import { useFilterContext, FILTER_NAME } from '@tradetrax/web-common/lib/Filters/Filters.context';

const emptyState = fromJS({
  templates: [],
  totalCount: 10,
});

const actions = { ...templatesActions };

export function TemplatesController() {
  const appContext = useAppContext();
  const filterContext = useFilterContext(FILTER_NAME.TEMPLATES);
  const { filterState } = filterContext;
  const [state, controller] = useController(actions, emptyState, { ...appContext, filterState });
  const { modal } = appContext;
  const filter = filterState.get('values');

  React.useEffect(() => {
    controller.loadMoreRows(() => {});
  }, [controller, filter]);

  return {
    state,
    controller,
    modal,
    filterContext,
  };
}
