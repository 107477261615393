import moment from 'moment';
import { fromJS } from 'immutable';
import { markAsSync, markAsSideEffect } from '@tradetrax/web-common';
import { NOT_STARTED, NOT_READY_TO_START, setCheckedInStatus } from '@tradetrax/web-common/lib/utils';
import { getUpcomingQuery, getQueryParam } from '@tradetrax/web-common/lib/Dashboard/getQueryParams';
import { buildersService } from 'services';

const BATCH_SIZE = 15;

const taskKey = task => task.set('key', `${task.getIn(['job', 'id'])}_${task.get('id')}`);

const expectedStartLabel = task => {
  const startDate = moment(task.get('startDate'), 'YYYY-MM-DD');
  return task
    .set('expectedStartDate', startDate.format('YYYY-MM-DD'))
    .set('expectedStartLabel', `Exp. Start ${startDate.format('MMMM D')}`);
};

markAsSync(loadUpcomingTasks);
export function loadUpcomingTasks(state, start_index = 0, stop_index = BATCH_SIZE - 1) {
  const filter = this.filterState.get('values').toJS();
  const upcommingQuery = getUpcomingQuery({ start_index, stop_index, sort: 'upcoming' });
  const query = getQueryParam(upcommingQuery, filter);

  buildersService.readTasks({}, { query }).then(data => {
    const totalCount = data.metadata.pagination ? data.metadata.pagination.totalCount : 0;
    const upcomingTasks = fromJS(data)
      .map(taskKey)
      .map(expectedStartLabel)
      .map(setCheckedInStatus)
      .toArray();

    this.controller.dispatch([
      state => {
        const updatedState = state
          .set('isLoading', false)
          .set('totalCount', totalCount)
          .update('tasks', tasks => tasks.splice(start_index, stop_index - start_index + 1, ...upcomingTasks));
        const tasks = updatedState.get('tasks');
        return updatedState.set('groups', getTasksByDate(tasks));
      },
    ]);
  });

  return start_index === 0 ? state.set('isLoading', true) : state;
}

markAsSideEffect(loadMoreRows);
export function loadMoreRows() {
  const totalCount = this.state.get('totalCount');
  const tasks = this.state.get('tasks');

  if (tasks.size < totalCount) {
    this.controller.loadUpcomingTasks(tasks.size, tasks.size + BATCH_SIZE - 1);
  }
}

markAsSync(invalidateFilter);
export function invalidateFilter(state) {
  // TODO: this method
  setTimeout(() => this.loaderRef.current?.resetLoadMoreRowsCache(true), 1);
  this.controller.defer.loadUpcomingTasks();
  return state.merge(fromJS({ tasks: [], totalCount: 10, maxCount: 10 }));
}

function updateJobName({ job, jobId, tasks }) {
  const { name: jobName } = job;
  this.controller.dispatch([
    state => {
      const updatedState = state.update('tasks', stateTasks => {
        return stateTasks.map(stateTask => {
          const found = tasks.find(task => {
            const key = `${jobId}_${task.id}`;
            return key === stateTask.get('key');
          });
          if (found) return stateTask.setIn(['job', 'name'], jobName);
          return stateTask;
        });
      });
      const updatedTasks = updatedState.get('tasks');
      return updatedState.set('groups', getTasksByDate(updatedTasks));
    },
  ]);
}

markAsSideEffect(updateUpcomingRealTime);
export function updateUpcomingRealTime({ action, task, taskId, ...props }) {
  if (action === 'update-job') return updateJobName.call(this, props);
  if (action !== 'update') return;
  const jobId = props.jobId;
  const index = this.state
    .getIn(['tasks'])
    .findIndex(task => task.getIn(['job', 'id']) === jobId && task.get('id') === taskId);
  if (index < 0) return;

  const { status } = task;
  if (status !== NOT_STARTED && status !== NOT_READY_TO_START) {
    this.controller.dispatch([
      state => {
        const updatedState = state
          .update('tasks', tasks => tasks.splice(index, 1))
          .update('totalCount', totalCount => --totalCount);
        const tasks = updatedState.get('tasks');
        return updatedState.set('groups', getTasksByDate(tasks));
      },
    ]);
  } else
    this.controller.dispatch([
      state => {
        const updatedState = state.updateIn(['tasks', index], oldTask => updateTaskRealTime(oldTask, task));
        const tasks = updatedState.get('tasks');
        return updatedState.set('groups', getTasksByDate(tasks));
      },
    ]);
}

const updateTaskRealTime = (task, updated) => {
  const { status, startDate, startDateConfirmed, isCritical, checkedIn } = updated;
  return setCheckedInStatus(
    expectedStartLabel(
      task.merge({
        status,
        startDate,
        startDateConfirmed,
        isCritical,
        checkedIn,
      })
    )
  );
};

const getTasksByDate = tasks => {
  return tasks
    .sort((a, b) => {
      if (a.get('expectedStartDate') < b.get('expectedStartDate')) return -1;
      if (b.get('expectedStartDate') < a.get('expectedStartDate')) return 1;
      return 0;
    })
    .groupBy(task => task.get('expectedStartDate'));
};

markAsSync(toggleManageTasks);
export function toggleManageTasks(state) {
  return state.update('isManageTasks', isManageTasks => !isManageTasks);
}
