import React from 'react';
import { LeadTimeTasks } from '@tradetrax/web-common/lib/Profile/Notifications/LeadTimeTasks';
import { emptyList } from '@tradetrax/web-common/lib/entities';
import { useAppContext } from '@tradetrax/web-common/lib/globalAppContext';

const filterGTLTasks = (gtl, relevantTasks) => {
  return gtl.filter(task => !relevantTasks.find(rTask => rTask.get('name') === task.get('name')));
};

export function LeadTime({ state, controller }) {
  const { appState } = useAppContext();
  const globalTasks = appState.get('gtl');
  const customRole = state.get('customRole');
  const leadTimeTasks = customRole.getIn(['settings', 'notifications', 'leadTimeTasks']) || emptyList;

  const gtlTasks = React.useMemo(() => filterGTLTasks(globalTasks, leadTimeTasks), [leadTimeTasks, globalTasks]);

  return (
    <div className="mr-2 pb-5 mt-2">
      <LeadTimeTasks
        isMyProfile={false}
        leadTimeTasks={leadTimeTasks}
        controller={controller}
        state={state.set('globalTasks', gtlTasks)}
      />
    </div>
  );
}
