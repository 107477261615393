import React from 'react';
import cn from 'classnames';
import { Breadcrumb } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';
import { UserProfileLoading } from '../LoadingState';
import { Initials } from '../Tags';
import { ToggleButtons, ToggleButton } from '../ToggleButtons';
import { UserProfileDetails } from './UserProfileDetails';
import { UserPermissions } from './UserPermissions';

export const UserProfileContainer = ({ state, controller, alert, paths, loggedInUserRole }) => {
  const { tab, user } = state.toObject();

  if (!state.get('hasPermission')) return <Redirect to={paths.NotPermission} />;
  if (user.get('isEmpty')) return <UserProfileLoading />;

  const name = `${user.get('firstName')} ${user.get('lastName')}`;
  const email = user.get('email');
  const isInstaller = user.get('domain') === 'installer';
  const isInactive = user.get('status') === 'inactive';
  return (
    <>
      <Breadcrumb className="mx-4 mt-4" tag="nav">
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: paths.Users }}>
          Users
        </Breadcrumb.Item>
        <Breadcrumb.Item active tag="span">
          {name}
        </Breadcrumb.Item>
      </Breadcrumb>
      <MiniProfile initials={user.get('initials')} name={name} email={email} />
      <ToggleButtons className="mb-2 mt-4 mr-auto" type="radio" name="options" value={tab} onChange={controller.setTab}>
        <ToggleButton value="profile">Profile</ToggleButton>
        {!isInstaller && (
          <ToggleButton value="permissions" disabled={isInactive}>
            Permissions
          </ToggleButton>
        )}
      </ToggleButtons>
      {tab === 'profile' && (
        <UserProfileDetails user={user} controller={controller} alert={alert} loggedInUserRole={loggedInUserRole} />
      )}
      {tab === 'permissions' && <UserPermissions user={user} controller={controller} />}
    </>
  );
};

export const NullMuted = ({ value, className = '' }) => (
  <span className={cn(className, { 'text-muted': !value })}>{value || 'Not Set'}</span>
);

export const MiniProfile = ({ name, initials, email, size = 80 }) => (
  <div className="d-flex flex-row ml-4">
    <Initials name={initials || name} size={size} />
    <div className="d-flex flex-column ml-4 justify-content-center">
      <h4 className="mb-0 font-weight-bold">{name}</h4>
      <NullMuted value={email} />
    </div>
  </div>
);
