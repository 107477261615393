import React, { useState } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { colors } from '../theme';
import { HistoryItem } from './Events/HistoryEvents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, IconButtonWrap } from '../Button';
import { TableRowDropDown } from '../Table/RowDropdownAction';
import { useTimeAgo } from '../useTimeAgo';
import { TaskHistoryContext } from './TaskHistory.context';

export const TaskHistory = ({ onDropdownMenuSelect, account, user, showActions, accountUsers, modal, ...props }) => {
  const { jobId, taskId, historyService } = props;
  const [isHidden, setIsHidden] = useState(window.innerWidth < 1200);
  const { state, controller } = TaskHistoryContext(jobId, taskId, historyService);
  const { isCollapsed, history, isLoading } = state.toObject();
  const hasMoreThanOneItem = history.size > 1;
  const firstItem = history.first();
  const lastUpdate = useTimeAgo(firstItem ? firstItem.get('createdOn') : '');
  const isEmpty = isLoading === false && history.size === 0;
  const renderFirstOnly = index => {
    // TODO fix here if is a builder account but first item is from sub action, nothing is rendered
    if (isCollapsed) return index === 0;
    return true;
  };
  const toggleVisibility = () => setIsHidden(!isHidden);

  return (
    <StyledTaskHistory className={cn({ hidden: isEmpty })} isCollapsed={isCollapsed}>
      <div className="header">
        <div className="d-flex flex-column mr-1">
          <span className="text-dark">Task History</span>
          {firstItem && <span className="text-secondary font-size-14">Updated {lastUpdate}</span>}
          {!firstItem && <span className="text-secondary font-size-14">No Activity Yet</span>}
        </div>
        <IconButton
          icon="clock"
          className={cn('ml-2 mr-2 ', { active: !isHidden || isEmpty })}
          onClick={toggleVisibility}
        />
        {showActions && <TableRowDropDown toggle={EllipsisV} onSelect={onDropdownMenuSelect} removeTask />}
      </div>
      <div
        className={cn('history-container flex-column justify-content-between', {
          hidden: isHidden || isEmpty,
          'd-flex': !isHidden && !isEmpty,
        })}
      >
        {/* <div className="search">
          <input className="form-control size-lg" />
          <FontAwesomeIcon icon="magnifying-glass" />
        </div> */}
        {isLoading ? (
          <div className="d-flex flex-column align-items-center mt-5">
            <div className="spinner-border text-secondary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : null}
        <HistoryList>
          {history
            .map((item, index) =>
              renderFirstOnly(index) ? (
                <HistoryItem
                  item={item}
                  key={item.get('_id')}
                  account={account}
                  user={user}
                  accountUsers={accountUsers}
                  modal={modal}
                />
              ) : null
            )
            .toArray()}
        </HistoryList>
        {hasMoreThanOneItem && (
          <button
            className="btn btn-link align-self-end"
            style={{ boxShadow: 'none' }}
            onClick={controller.toggleCollapsed}
          >
            {isCollapsed && `See All Task History`}
            {!isCollapsed && `See Less Task History`}
          </button>
        )}
      </div>
    </StyledTaskHistory>
  );
};

const StyledTaskHistory = styled.div`
  /* ${props => `
    ${props.isCollapsed ? `` : `bottom: 32px;`}
  `} */
  transition: right 600ms ease;
  align-items: start;
  mix-blend-mode: normal;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  width: 450px;
  position: relative;
  @media (min-width: 992px) {
    position: fixed;
    right: 32px;
    top: 8.5rem;
    z-index: 1000;
  }

  &.hidden {
    pointer-events: none;
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-self: stretch;
    align-items: flex-start;
    margin-bottom: 0.75rem;
    pointer-events: all;
  }

  .history-container {
    background: ${colors.pearl};
    border-radius: 10px;
    padding-top: 1.25rem;
    /* align-self: stretch; */
    /* flex: 1; */
    /* overflow: auto; */
    position: absolute;
    top: 50px;
    z-index: 2;
    width: 450px;
    right: 0px;
    max-height:calc(100vh - 150px);
     @media (min-width: 1200px) {
      max-height:calc(100vh - 250px);
      }
    

    &.hidden {
      display: none;
    }
  }

  .search {
    width: 100%;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    margin-bottom: 0.5rem;
    position: relative;

    svg {
      position: absolute;
      right: 1.75rem;
      top: 0.6rem;
    }
  }

  &.hide {
    right: -452px;
  }
`;

const HistoryList = styled.ul`
  overflow-y: visible;
  overflow-x: hidden;
  list-style-type: none;
  padding: 0 0;
  display: flex;
  align-self: stretch;
  flex-direction: column;
  position: relative;

  li {
    transition: opacity 0.6s linear;
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    align-self: stretch;
    letter-spacing: 0.2px;
    position: relative;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    color: ${colors.gray400};

    .trx-time {
      font-size: 12px;
      line-height: 16px;
      color: ${colors.gray400};
      padding-bottom: 0.5rem;
    }

    &:not(:last-child) {
      border-bottom: solid 1px ${colors.gray100};
    }
  }
`;

const EllipsisV = React.forwardRef(({ onClick }, ref) => (
  <IconButtonWrap
    icon="ellipsis-vertical"
    className="btn"
    ref={ref}
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <FontAwesomeIcon icon="ellipsis-vertical" />
  </IconButtonWrap>
));
