import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import * as yup from 'yup';
import { Form } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useForm, Controller } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers';
import { capitalize } from '@tradetrax/web-common/lib/utils';
import { PrimaryButton, TransparentButton } from '@tradetrax/web-common/lib/Button';
import { ENUM_TIME_COMPLETION } from '../TemplateSettingsContext';
import { fromJS } from 'immutable';
import { colors } from '@tradetrax/web-common';

const WHEN_OPTIONS = ['After', 'Before'];
const arrayNumbers = [...Array(30).keys()].map(i => (++i).toString());
const DAYS_OPTIONS = ['Same', ...arrayNumbers];
const COMPLETION_OPTIONS = ['Finishes', 'Starts'];

const schema = yup.object().shape({
  daysTimeRange: yup
    .string()
    .oneOf(DAYS_OPTIONS)
    .required(),
  timeConditions: yup.string().when('daysTimeRange', {
    is: val => val !== 'Same',
    then: yup
      .string()
      .required()
      .oneOf(WHEN_OPTIONS),
    otherwise: yup
      .string()
      .notRequired()
      .nullable(),
  }),
  timeCompletion: yup
    .string()
    .oneOf(COMPLETION_OPTIONS)
    .required(),
});

export function TaskCard({ expanded, controller, task, toggle, globalTasks }) {
  const defaultValues = React.useMemo(() => getFormDefaultValues(task, globalTasks), [task, globalTasks]);

  const formContext = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const { reset } = formContext;

  const onClickCancel = event => {
    event.preventDefault();
    reset(defaultValues);
  };

  const onToggle = () => {
    reset(defaultValues);
    toggle(task);
  };

  const { watch } = formContext;
  const { relatedTask } = watch();
  const containsRelatedTask = relatedTask
    ? !!globalTasks.find(task => task.get('name') === relatedTask.get('name'))
    : true;
  const showIcon = !containsRelatedTask && !expanded;

  return (
    <li className={`task-card ${expanded ? 'expanded' : ''}`}>
      <div>
        <button className="btn btn-link p-0 text-secondary" onClick={() => controller.deleteTaskType(task)}>
          <FontAwesomeIcon icon="trash" />
        </button>
        <strong className="ml-3 mr-auto align-self-center">{task.get('taskName')}</strong>
        <div className="mt-2">
          {showIcon && <FontAwesomeIcon icon="circle-exclamation" className="text-danger mr-2 mt-1" />}
          <button className="btn btn-link p-0 text-secondary" onClick={onToggle}>
            <FontAwesomeIcon icon={`chevron-${expanded ? 'up' : 'down'}`} />
          </button>
        </div>
      </div>
      <TaskCardDetails
        defaultValues={defaultValues}
        expanded={expanded}
        task={task}
        controller={controller}
        globalTasks={globalTasks}
        formContext={formContext}
        onClickCancel={onClickCancel}
        containsRelatedTask={containsRelatedTask}
      />
    </li>
  );
}

const TaskCardDetails = ({
  task,
  expanded,
  controller,
  globalTasks,
  formContext,
  onClickCancel,
  defaultValues,
  containsRelatedTask,
}) => {
  const { handleSubmit, control, watch, errors, setValue, formState, trigger, clearErrors, reset } = formContext;
  const { daysTimeRange, relatedTask, timeConditions } = watch();
  const timeConditionsValue = timeConditions || 'After';

  React.useEffect(() => {
    if (daysTimeRange === 'Same') {
      setValue('timeConditions', null);
    } else if (!isNaN(daysTimeRange)) {
      setValue('timeConditions', timeConditionsValue);
    }
  }, [daysTimeRange, setValue, timeConditionsValue]);

  const onSubmit = form => {
    reset(form);
    controller.saveTaskCard(task, form);
  };

  const onBlur = async (name, { target }) => {
    const isValid = await trigger(name);
    if (isValid) return;
    if ((!isValid && isNaN(target.value)) || !target.value) {
      !daysTimeRange && clearErrors(name);
      const value = name === 'timeConditions' ? 'After' : defaultValues[name];
      setValue(name, value);
    } else if (!daysTimeRange) {
      clearErrors(name);
      setValue(name, target.value);
    }
  };

  const options = React.useMemo(() => globalTasks.filter(gt => task.get('taskId') !== gt.get('_id')), [
    globalTasks,
    task,
  ]);

  const { isDirty } = formState;
  const isButtonDisabled = !isDirty || !relatedTask;

  return (
    <div className={cn('font-size-14 mt-3 flex-column', { 'd-none': !expanded })}>
      <span className="mr-auto">TradeTrax will send automatic requests to the Trade:</span>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex flex-column flex-lg-row my-3 align-items-bottom ">
          <div className="mb-2 mb-lg-2">
            <CardTypeahead
              id="daysTimeRange"
              checkIfValid={onBlur}
              className=""
              control={control}
              defaultSelected={[DAYS_OPTIONS[0]]}
              name="daysTimeRange"
              options={DAYS_OPTIONS}
            />
            <span className="ml-2 mt-2">day(s)</span>
            <CardTypeahead
              id="timeConditions"
              checkIfValid={onBlur}
              control={control}
              disabled={daysTimeRange === 'Same' && !errors.daysTimeRange}
              name="timeConditions"
              options={WHEN_OPTIONS}
              placeholder="When"
            />
          </div>
          <div>
            <div className="d-flex flex-column">
              <Controller
                control={control}
                name="relatedTask"
                render={({ onChange, value }) => (
                  <Typeahead
                    id="gtl"
                    className="ml-2 gtl-typeahead"
                    options={options}
                    filterBy={filterBy}
                    labelKey={option => option?.get('name')}
                    onChange={([selected]) => onChange(selected)}
                    placeholder="Choose Task"
                    selected={value ? [value] : []}
                  >
                    <FontAwesomeIcon icon="magnifying-glass" />
                  </Typeahead>
                )}
              />
              <span className={cn('font-size-14 text-muted ml-1 mt-2 text-nowrap', { 'd-none': containsRelatedTask })}>
                <FontAwesomeIcon icon="circle-exclamation" className="text-danger mr-1" />
                Task missing from this Template
              </span>
            </div>
            <CardTypeahead
              id="timeCompletion"
              checkIfValid={onBlur}
              control={control}
              defaultSelected={[COMPLETION_OPTIONS[0]]}
              name="timeCompletion"
              options={COMPLETION_OPTIONS}
            />
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <TransparentButton disabled={isButtonDisabled} onClick={onClickCancel}>
            Cancel
          </TransparentButton>
          <PrimaryButton className="ml-2" disabled={isButtonDisabled} type="submit">
            Save
          </PrimaryButton>
        </div>
      </Form>
    </div>
  );
};

const SmallTypeahead = styled(Typeahead)`
  max-width: 120px;

  .rbt-input-main {
    padding-right: 2rem;
  }
`;

const TextInput = styled.input`
  ${props => `
    max-width: ${props.maxWidth || 'auto'};
  `}
  width: 100%;
  height: 38px;
  margin-left: 0.5rem;
  padding-left: 0.5rem;
  border-radius: 0.25rem;
  font-size: 16px;

  .rbt-input-main {
    padding-right: 2rem;
  }

  &:disabled {
    color: ${colors.gray200};
  }
`;

const filterBy = (option, props) => {
  if (props.selected.length) return props.labelKey(option) !== props.labelKey(props.selected[0]);
  return (
    props
      .labelKey(option)
      .toLowerCase()
      .indexOf(props.text.toLowerCase()) !== -1
  );
};

const CardTypeahead = ({
  icon = 'chevron-down',
  placeholder,
  name,
  control,
  checkIfValid,
  className = '',
  ...props
}) => (
  <Controller
    control={control}
    name={name}
    render={({ onBlur, onChange, value }) => (
      <SmallTypeahead
        {...props}
        className={`ml-2 ${className}`}
        filterBy={filterBy}
        labelKey={option => option}
        onBlur={event => onBlur(checkIfValid(name, event))}
        onChange={([selected]) => onChange(selected)}
        placeholder={placeholder || null}
        selected={value ? [value] : []}
      >
        <FontAwesomeIcon icon={icon} />
      </SmallTypeahead>
    )}
  />
);

const getFormDefaultValues = (task, globalTasks) => {
  const isSameDay = task.get('daysTimeRange') === 0;
  const daysTimeRange = isSameDay ? 'Same' : task.get('daysTimeRange').toString();
  const timeCompletion = ENUM_TIME_COMPLETION[task.get('timeCompletion')] || 'Finishes';
  const timeConditions = daysTimeRange === 'Same' ? null : capitalize(task.get('timeConditions'));
  const relatedTask =
    globalTasks.find(gt => gt.get('_id') === task.get('relatedTaskId')) || task.get('relatedTaskId')
      ? fromJS({
          _id: task.get('relatedTaskId'),
          name: task.get('relatedTaskName'),
        })
      : null;

  return {
    daysTimeRange,
    timeCompletion,
    timeConditions,
    relatedTask,
  };
};

export function TaskCardReadOnly({ context, accountability }) {
  const { state, controller, templateId } = context;
  const { daysTimeRange, timeConditions, relatedTaskName } = accountability.toObject();
  const timeCompletion = accountability.get('timeCompletion') === 'finish' ? 'Finishes' : 'Starts';
  const taskName = accountability.get('taskName');
  const tasks = state.getIn(['template', 'tasks']);
  const containsRelatedTask = !!tasks.find(task => task.get('name') === relatedTaskName);
  return (
    <li className="task-card bg-white mt-3 w-100" style={{ borderRadius: '6px', height: '234px' }}>
      <div className="flex-column">
        <strong className="mr-auto align-self-center font-size-16">{taskName}</strong>
        <span className="mt-3 mr-auto">TradeTrax will send automatic requests to the Trade:</span>
        <div className="d-flex flex-row my-3 align-items-bottom" style={{ maxWidth: '100%' }}>
          <TextInput type="text" value={DAYS_OPTIONS[daysTimeRange]} maxWidth="100px" disabled />
          <span className="ml-2 mt-2">day(s)</span>
          <TextInput type="text" value={daysTimeRange === 0 ? 'When' : timeConditions} maxWidth="100px" disabled />
          <div className="d-flex flex-column flex-grow-1 mr-2">
            <TextInput type="text" value={relatedTaskName} style={{ maxWidth: '100%' }} disabled />
            <span className={cn('font-size-14 text-muted ml-1 mt-2 text-nowrap', { 'd-none': containsRelatedTask })}>
              <FontAwesomeIcon icon="circle-exclamation" className="text-danger mr-1" />
              Task missing from this Template
            </span>
          </div>
          <TextInput type="text" value={timeCompletion} maxWidth="100px" disabled />
        </div>
        <div className="d-flex justify-content-end mr-3 pr-1">
          <TransparentButton onClick={() => controller.dismissRecommendedAccountability(templateId, accountability)}>
            Dismiss
          </TransparentButton>
          <PrimaryButton
            className="ml-2"
            onClick={() => controller.useRecommendedAccountability(templateId, accountability)}
          >
            Use
          </PrimaryButton>
        </div>
      </div>
    </li>
  );
}
