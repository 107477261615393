import React from 'react';
import { EmptyState } from '../EmptyState';
import { ToDoCard } from './ToDoCard';
import { LoadingCards } from './LoadingCards';
import { useConfirmPopover } from './useConfirmPopover';

export const ConfirmationRequests = ({ state, ...todoContext }) => {
  const kind = 'confirmationRequests';
  const tasks = state.getIn([kind, 'tasks']);
  const totalTasks = state.getIn([kind, 'totalCount']);
  const isEmpty = totalTasks === 0;
  const containerRef = React.useRef();
  const { ConfirmPopover, openConfirmTask } = useConfirmPopover(todoContext.controller, containerRef);

  if (state.getIn([kind, 'isLoading'])) return <LoadingCards howMany={3} />;

  return (
    <div ref={containerRef}>
      <h6 className="font-weight-bold ml-2 mb-1">{totalTasks} Start Date Confirmation Requests</h6>
      {!isEmpty && (
        <span className="font-size-12 text-secondary ml-2 mb-4 d-block">Confirm or Send an Update Request</span>
      )}
      <EmptyState
        visible={isEmpty}
        icon="circle-check"
        title="No Tasks to Confirm"
        body="When you have a Task to Confirm its Start Date, it will appear here."
      />
      {tasks.map(task => (
        <ToDoCard
          confirm
          task={task}
          key={task.get('key')}
          kind={kind}
          openConfirmTask={openConfirmTask}
          {...todoContext}
        />
      ))}
      {ConfirmPopover}
    </div>
  );
};
