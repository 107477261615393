import React from 'react';
import { ToggleButtons, ToggleButton, useCommunities, useTaskTypes } from '@tradetrax/web-common';
import { FilterSets } from '@tradetrax/web-common/lib/Filters/FilterSets';
import { IconButton } from '@tradetrax/web-common/lib/Button';
import { CalendarView } from '@tradetrax/web-common/lib/CalendarView';
import { CALENDAR_VIEW } from '@tradetrax/web-common/lib/CalendarView/CalendarContext';
import { buildersService } from 'services';
import { OpenTasksTable } from './OpenTasks';
import { CompletedTasksTable } from './CompletedTasks';
import { TasksPageContext } from './TasksPageContext';
import { useAppContext } from 'app/App.context';
import navigate from 'app/navigate';
import { FilterImplementation } from '@tradetrax/web-common/lib/Filters/Implementation/Filter.implementation';

export function TasksPage({ location }) {
  const appContext = useAppContext();
  const communities = useCommunities(buildersService);
  const taskTypes = useTaskTypes(buildersService);
  const defaultTab = location.state ? location.state.defaultTab : 'open';
  const taskContext = TasksPageContext(defaultTab, appContext);
  const { state, controller, filterContext } = taskContext;
  const { tab, view } = state.toObject();
  const { appState } = appContext;
  const assignees = appState.get('assignees');
  const showCalendar = view === CALENDAR_VIEW;
  const lookups = { communities, taskTypes, assignees };
  const filter = filterContext.filterState.get('values');

  return (
    <>
      <div className="d-flex flex-row justify-content-between mx-4 mt-4">
        <h4 className="font-weight-bold">Tasks</h4>
      </div>
      <div className="d-flex flex-row justify-content-between mt-4 mr-4 mb-2">
        <ToggleButtons onChange={controller.setTab} className="mb-2 mr-auto" type="radio" name="options" value={tab}>
          <ToggleButton value="open">Open</ToggleButton>
          <ToggleButton value="completed">Completed</ToggleButton>
        </ToggleButtons>
      </div>
      <FilterSets.Panel visible={tab === 'open'} filterContext={filterContext}>
        {/* <div className="d-flex justify-content-between mr-4" data-testid="tasks-page-filter"> */}
        <IconButton
          icon={showCalendar ? 'list' : 'calendar-days'}
          onClick={() => controller.toggleView()}
          className="mr-3 active-on-hover"
        />
        <FilterImplementation filterContext={filterContext} lookups={lookups} title="Open Tasks Filters">
          <FilterSets.Section filterContext={filterContext} />
        </FilterImplementation>
        {/* <TasksFilter top="11.5rem" /> */}
      </FilterSets.Panel>
      {showCalendar ? (
        <div className="pt-2">
          <CalendarView filter={filter} appContext={appContext} calendarService={buildersService} navigate={navigate} />
        </div>
      ) : (
        <>
          {tab === 'open' && <OpenTasksTable {...taskContext} />}
          {tab === 'completed' && <CompletedTasksTable {...taskContext} />}
        </>
      )}
    </>
  );
}
