import React from 'react';
import { Item, Name, dateField } from './common';
import { plural } from '../../utils';
import { WeatherIndicator } from '../WeatherIndicator';

export default ({ item, account, user, isInactive }) => {
  const behind = item.getIn(['data', 'daysBehind']);
  const accepted = item.getIn(['data', 'accepted']);

  return (
    <Item item={item} user={user}>
      <Name item={item} account={account} user={user} isInactive={isInactive} />
      {accepted ? (
        <>
          {` accepted exp. finish update request to ${dateField(item, 'newEndDate')} `}
          {!!behind && ' and had an impact of '}
          {behind > 0 && <span className="text-danger">{plural.day(behind)} behind</span>}
          {behind < 0 && <span className="text-success">{plural.day(Math.abs(behind))} ahead</span>}
          <WeatherIndicator item={item} />
        </>
      ) : (
        `  declined exp. finish update request to ${dateField(item, 'newEndDate')} `
      )}
    </Item>
  );
};

// module.exports.default.visibility = ({ account, user }) => {
//   console.log(user.get('accountId'), account.get('_id'));
//   return user.get('createdById') === account.get('_id');
// };
