import React from 'react';
import { Item, Name } from './common';
import cn from 'classnames';

export default ({ item, account, user, isInactive }) => {
  const { assigneeAccountName, assigneeAccountId, firstTime } = item.get('data').toObject();
  const isSameAccount = assigneeAccountId === account.get('_id');

  const message = (
    <>
      {firstTime ? 'set' : 'updated'} Account assignee to{' '}
      <span
        className={cn('text-dark d-inline-block text-truncate', {
          'font-weight-bold': isSameAccount,
          highlight: isSameAccount,
        })}
        style={{ maxWidth: '180px' }}
      >
        {assigneeAccountName}
      </span>
    </>
  );

  const removedAssigneeMessage = 'removed Account assignee';

  return (
    <Item item={item} user={user}>
      <Name item={item} account={account} user={user} isInactive={isInactive} />{' '}
      {assigneeAccountId ? message : removedAssigneeMessage}
    </Item>
  );
};
