import React, { useState, useRef, useLayoutEffect } from 'react';
import styled from 'styled-components';
import { colors } from '../theme';
import { InProgressData } from './InProgressData';

export const MilestoneRhombusInProgress = ({ milestone, isFirstMilestone, isLastMilestone, isHomeowner }) => {
  const [show, setShow] = useState(false);
  return (
    <RhombusProgressContainer onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
      <InProgressData
        milestone={milestone}
        show={isHomeowner ? show : true}
        isFirstMilestone={isFirstMilestone}
        isHomeowner={isHomeowner}
        isLastMilestone={isLastMilestone}
      />
    </RhombusProgressContainer>
  );
};

const RhombusProgressContainer = styled.div`
  height: 15px;
  width: 15px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &::after {
    content: '';
    width: 15px;
    height: 15px;
    background-color: ${colors.blue300};
    transform: rotate(45deg);
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 2px;
    border: 2px solid ${colors.white};
    z-index: 1;
  }
`;
