export function rowOrderMemo(controller) {
  let prevTemplate = null;
  let prevViewModel = null;

  return function(template, viewModel) {
    if (template === prevTemplate) return;
    prevTemplate = template;

    if (prevViewModel === null) {
      prevViewModel = viewModel;
      return;
    }

    const changed = [];
    const getId = item => (item ? String(item.get('_id') || item.get('id')) : null);

    viewModel.forEach((row, index) => {
      const isStage = row.get('isStage');
      const id = getId(row);
      const prevId = getId(prevViewModel.get(index));

      if (id !== prevId) {
        changed.push(index);
      } else if (isStage) {
        const prevStage = prevViewModel.find(item => item.get('_id') === id);
        if (prevStage && prevStage.get('tasks').size !== row.get('tasks').size) {
          changed.push(index);
        }
      }
    });

    prevViewModel = viewModel;
    if (changed.length) controller.updateTable(changed);
  };
}
