import React from 'react';
import { RelevantTasks as RelevantTasksDetails } from '@tradetrax/web-common/lib/Profile/Notifications/RelevantTasks';
import { emptyList } from '@tradetrax/web-common/lib/entities';
import { useAppContext } from '@tradetrax/web-common/lib/globalAppContext';
const filterGTLTasks = (gtl, relevantTasks) => {
  return gtl.filter(task => !relevantTasks.find(rTask => rTask.get('name') === task.get('name')));
};

export function RelevantTasks({ state, controller }) {
  const { appState } = useAppContext();
  const globalTasks = appState.get('gtl');
  const { customRole } = state.toObject();
  const relevantTasks = customRole.getIn(['settings', 'notifications', 'relevantTasks']) || emptyList;
  const gtlTasks = React.useMemo(() => filterGTLTasks(globalTasks, relevantTasks), [relevantTasks, globalTasks]);

  return (
    <div className="mt-2 pb-5" style={{ maxWidth: '620px' }}>
      <RelevantTasksDetails
        isMyProfile={false}
        controller={controller}
        state={state.set('relevantTasks', relevantTasks).set('globalTasks', gtlTasks)}
      />
    </div>
  );
}
