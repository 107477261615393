import React from 'react';
import { debounce } from 'lodash';
import { Portal } from 'react-overlays';
import { Datepicker } from '@tradetrax/web-common';
import cn from 'classnames';

export function DatepickerWithPortal({
  name,
  date,
  isOverdue,
  dateText,
  isEditing,
  portalRoot,
  onClick,
  onChangeRaw,
  isDefaultOpen = true,
  ...props
}) {
  if (isEditing) {
    const calendarContainer = ({ children }) => {
      return <Portal container={portalRoot.current}>{children}</Portal>;
    };

    return (
      <Datepicker
        {...props}
        className={`edit-${name}`}
        placeholderText=""
        name={name}
        selected={date}
        autoFocus={isDefaultOpen}
        popperContainer={calendarContainer}
        onChangeRaw={event => debounce(() => onChangeRaw(event), 600)}
      />
    );
  }

  const _onClick = props.canEdit ? onClick : readOnlyClick;

  return (
    <span onClick={_onClick} className={cn('with-sublabel', { 'text-danger': isOverdue, editable: props.canEdit })}>
      {dateText}
      {isOverdue && <span>Missed {name === 'startDate' ? 'Start' : 'Finish'}</span>}
    </span>
  );
}

export const readOnlyClick = e => {
  e.preventDefault();
};
