import React from 'react';
import moment from 'moment';
import { buildersService } from 'services';

const DATE_FORMAT_ISO = 'YYYY-MM-DD';

export function useCommitmentRequestsCount() {
  const [count, setCount] = React.useState(0);

  React.useEffect(() => {
    const date = moment().format(DATE_FORMAT_ISO);

    buildersService
      .countCommitments({}, { query: { date, status: 'denied', viewType: 'view-task-type' } })
      .then(({ commitments }) => setCount(commitments));
  }, [setCount]);

  return count;
}
