import React from 'react';
import styled from 'styled-components';
import { Overlay, Popover, Button } from 'react-bootstrap';
import { SecondaryButton } from '../Button';
import { colors } from '../theme';

export const useConfirmPopover = (controller, containerRef) => {
  const [task, setTask] = React.useState(null);
  const [target, setTarget] = React.useState(null);

  const openConfirmTask = (target, task) => {
    setTask(task);
    setTarget(target);
  };

  const onConfirm = () => {
    controller.confirmDate(task);
    setTask(null);
  };
  const ConfirmPopover = (
    <Overlay
      show={!!task}
      target={target}
      placement="left-start"
      container={containerRef.current}
      containerPadding={20}
    >
      <StyledPopover id="confirm-request-popover">
        <Popover.Content>
          <p>By confirming this request, you agree that you will begin work on the Task on that date.</p>
          <div className="d-flex flex-row  justify-content-end">
            <Button variant="link" className="text-secondary font-weight-bold mr-3" onClick={() => setTask(null)}>
              Cancel
            </Button>
            <SecondaryButton variant="outline-primary" onClick={onConfirm}>
              Confirm
            </SecondaryButton>
          </div>
        </Popover.Content>
      </StyledPopover>
    </Overlay>
  );

  React.useEffect(() => {
    const isInsidePoper = node => {
      if (!node) return false;
      if (node.hasAttribute('data-popup-confirm')) return true;
      if (node.id === 'confirm-request-popover') return true;
      return isInsidePoper(node.parentElement);
    };

    const onClick = e => {
      if (!!task) {
        if (isInsidePoper(e.target)) return;
        e.preventDefault();
        e.stopPropagation();
        setTask(null);
      }
    };

    global.document.addEventListener('click', onClick);
    return () => {
      global.document.removeEventListener('click', onClick);
    };
  }, [task, setTask]);

  return { ConfirmPopover, openConfirmTask };
};

const StyledPopover = styled(Popover)`
  background-color: ${colors.pearl};
  box-shadow: 0px 1px 2px rgba(51, 51, 51, 0.2);
  border-radius: 8px;
  border: none;
  max-width: 324px;
  .arrow {
    display: none;
  }

  .popover-body {
    padding: 1.5rem 1.75rem;
  }
`;
