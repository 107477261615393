import { markAsSideEffect } from '@tradetrax/web-common/lib/useController';
import { fromJS } from 'immutable';
import { buildersService } from 'services';

export function readFilterSets(roleId) {
  return buildersService
    .readRoleJobFilters({}, { params: { roleId } })
    .then(filterSets => filterSets.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)))
    .then(fromJS)
    .then(filterSets => state => state.merge({ filterSets }));
}

markAsSideEffect(removeFilterSet);
export function removeFilterSet(filterSet) {
  const index = this.state.get('filterSets').indexOf(filterSet);
  const customRoleId = this.state.getIn(['customRole', '_id']);
  const filterId = filterSet.get('_id');

  buildersService
    .unshareJobFilter({ ids: [customRoleId] }, { params: { filterId } })
    .then(() => {
      this.alert.success({ message: 'Filter Set successfully removed from Custom Role.' });
      this.controller.dispatch([state => state.update('filterSets', filterSets => filterSets.splice(index, 1))]);
    })
    .catch(() => {
      this.alert.error({
        message: 'There was a problem removing this Filter Set from the Custom Role. Please try again',
      });
    });
}
