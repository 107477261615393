import React from 'react';
import cn from 'classnames';
import { Modal, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrimaryButton } from '@tradetrax/web-common';
import { checkValidName, nameSchema } from './CustomRoles.common';

export function NewCustomRoleModal({ accept, close, customRoles }) {
  const {
    register,
    handleSubmit,
    setError,
    errors,
    formState: { isDirty },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: { name: '' },
    resolver: yupResolver(nameSchema),
  });
  const isValid = isDirty && !errors.name;
  const isNameValid = name => checkValidName(name, customRoles, setError);
  const onSubmit = form => {
    if (isNameValid(form.name)) accept(form);
  };
  return (
    <Modal show={true} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>Create New Custom Role</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <Form.Group controlId="role-name">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              size="lg"
              placeholder="Ex. Builder Schedulers"
              ref={register}
              isInvalid={!!errors.name}
              isValid={isValid}
            />
            <Form.Control.Feedback type="invalid" className={cn({ 'd-inline': !!errors.name })}>
              <FontAwesomeIcon icon="circle-exclamation" />
              {errors.name && ` ${errors.name.message}`}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid" className={cn({ 'd-inline': isValid })}>
              <FontAwesomeIcon icon="circle-exclamation" className="mr-2" />
              Custom Role name is valid
            </Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <PrimaryButton type="submit" className="ml-4" disabled={!!errors.name}>
            Create Custom Role
          </PrimaryButton>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
