import React from 'react';
import { Item, Name } from './common';
import { formatTrx, plural } from '../../utils';
import { WeatherIndicator } from '../WeatherIndicator';

export default ({ item, account, user, isInactive }) => {
  const date = field => formatTrx(item.getIn(['data', field]));
  const accepted = item.getIn(['data', 'accepted']);

  return (
    <Item item={item} user={user}>
      <Name item={item} account={account} user={user} isInactive={isInactive} />
      {accepted
        ? ` accepted the proposed Start Date Confirmation for ${date('newStartDate')}`
        : ` declined the proposed Start Date Confirmation for ${date('newStartDate')}`}
      <WeatherIndicator item={item} />
    </Item>
  );
};
