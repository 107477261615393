import React from 'react';
import { EmptyState } from '@tradetrax/web-common/lib/EmptyState';
import { TaskCard } from './TaskCard';
import { UL } from './UL';

export function Tasks({ context, globalTasks }) {
  const { controller, state } = context;
  const accountability = state.get('accountability');
  const tasks = accountability.get('tasks');
  const toggle = task => controller.expand(task.get('key') === accountability.get('expanded') ? null : task.get('key'));

  if (tasks.get('isLoading') === true) {
    return (
      <div className="spinner-border text-secondary align-self-center mt-5" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    );
  }

  if (tasks.get('isEmpty') || tasks.size === 0) {
    const leftPosition = getCenteredLeftPosition(600);
    const containerWidth = 415;

    return (
      <div style={{ width: `${containerWidth}px`, marginLeft: `${leftPosition}px` }}>
        <EmptyState
          className="mb-5 "
          icon="wrench"
          title="No Tasks Type Added"
          body="When a Task Type is added, it will appear here."
        />
      </div>
    );
  }

  return (
    <UL className="w-50">
      {tasks
        .map((task, index) => (
          <TaskCard
            expanded={task.get('key') === accountability.get('expanded')}
            toggle={toggle}
            task={task}
            key={`${index}_${task.get('key')}`}
            controller={controller}
            globalTasks={globalTasks}
          />
        ))
        .toArray()}
    </UL>
  );
}

const getCenteredLeftPosition = elementWidth => {
  const windowWidth = window.innerWidth;
  return (windowWidth - elementWidth) / 2;
};
