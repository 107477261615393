import React from 'react';
import styled from 'styled-components';
import { EmptyState, colors } from '@tradetrax/web-common';
import { RelevantTask } from './RelevantTask';

export const Tasks = ({ controller, state, expanded, expand }) => {
  const tasks = state.get('relevantTasks');
  const toggle = task => expand(task.get('_id') === expanded ? null : task.get('_id'));
  if (!tasks.size) {
    return (
      <EmptyState
        icon="wrench"
        title="No Relevant Tasks Added"
        body="When you add a Relevant Task, it will appear here"
      />
    );
  }

  return (
    <UL>
      {tasks
        .map(task => (
          <RelevantTask expanded={task.get('_id') === expanded} toggle={toggle} task={task} controller={controller} />
        ))
        .toArray()}
    </UL>
  );
};

const UL = styled.ul`
  margin-top: 0.25rem;
  list-style: none;
  padding: 0;

  li {
    border-top: solid 1px ${colors.gray100};
    padding: 1rem 1rem 1.5rem 1rem;

    div {
      display: flex;
      flex-direction: row;
    }

    &.expanded {
      background-color: ${colors.activeState};
      flex-direction: column;
      margin-bottom: 1px;
      border-radius: 6px;
    }
  }
`;
