import React, { useState } from 'react';
import styled from 'styled-components';
import { colors } from '../theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InProgressData } from './InProgressData';

export const MilestoneCircleInProgress = ({ milestone, isFirstMilestone, isLastMilestone, isHomeowner }) => {
  const [show, setShow] = useState(false);
  return (
    <CircleInProgressContainer onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
      <GearsAnimated />
      <InProgressData
        milestone={milestone}
        show={show}
        isFirstMilestone={isFirstMilestone}
        isHomeowner={isHomeowner}
        isLastMilestone={isLastMilestone}
      />
    </CircleInProgressContainer>
  );
};

const CircleInProgressContainer = styled.div`
  background-color: ${colors.pearl};
  border-radius: 50%;
  color: ${colors.yellow100};
  font-size: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 6px solid ${colors.blue300};
  height: 90px;
  width: 90px;
  position: relative;
  cursor: pointer;
  &:hover {
    #gear1 {
      animation: gearsMove1 7s infinite reverse forwards linear;
    }
    #gear2 {
      animation: gearsMove2 7s infinite normal forwards linear;
    }
  }
`;

const GearsAnimated = () => (
  <GearsAnimation>
    <FontAwesomeIcon icon="gear" id="gear1" />
    <FontAwesomeIcon icon="gear" id="gear2" />
  </GearsAnimation>
);

const GearsAnimation = styled.div`
  width: 55%;
  height: 51%;
  position: relative;

  #gear1 {
    position: absolute;
    top: 0;
    left: 0;
  }
  #gear2 {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: rotate(-13deg);
  }

  @keyframes gearsMove1 {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes gearsMove2 {
    from {
      transform: rotate(-13deg);
    }
    to {
      transform: rotate(347deg);
    }
  }
`;
