import React from 'react';
import moment from 'moment';
import { debounce } from 'lodash';
import { fromJS } from 'immutable';
import { useController } from '../useController';
import { getUserDomain } from '../utils';
import * as actions from './CalendarActions';

export const LIST_VIEW = 'LIST_VIEW';
export const CALENDAR_VIEW = 'CALENDAR_VIEW';

export const TIME_PERIOD = {
  dayGridMonth: 'M',
  dayGridWeek: 'w',
  dayGridDay: 'd',
};

const VIEW_MAP = {
  dayGridMonth: 'month',
  dayGridWeek: 'week',
  dayGridDay: 'day',
};

const calendarConfig = {
  dayGridWeek: {
    titleFormat: { year: 'numeric', month: 'long' },
  },
  dayGridDay: {
    dayHeaderFormat: { weekday: 'long' },
  },
};

const emptyState = {
  calendarView: 'dayGridMonth',
  date: new Date(),
  totalTasksOnDay: 0,
  isLoading: true,
};
emptyState.date.setHours(0, 0, 0, 0);

export function CalendarContext({ filter, calendarService, appContext, isUpcoming, jobId, navigate }) {
  const calendarRef = React.useRef();
  const datePickerRef = React.useRef();
  const initState = setUrlParamsToState();
  const { user } = appContext.appState.toObject();
  const { isTrade } = getUserDomain(user);
  const linkToTaskDetails = getTaskUrl(navigate, isTrade);
  const [state, controller] = useController(actions, initState, {
    ...appContext,
    calendarRef,
    filter,
    calendarService,
    isUpcoming,
    jobId,
    isTrade,
    getCurrentUrlParams,
  });
  const { date, calendarView } = state.toObject();

  const dayGridConfig = React.useMemo(
    () => ({
      ...calendarConfig,
      dayGridMonth: {
        dayMaxEvents: 3,
        moreLinkClick: ({ date, allSegs }) => {
          controller.showMoreClick(date, allSegs);
          return 'day';
        },
      },
    }),
    [controller]
  );

  React.useEffect(() => {
    const calendarApi = calendarRef.current?.getApi();
    const events = calendarApi?.getEvents();
    if (events) {
      const [event] = events;
      if (!events.length || !event.extendedProps?.isPlaceholder) calendarApi?.refetchEvents();
    }
  }, [filter, calendarRef]);

  React.useEffect(() => {
    const [params, currentURL] = getCurrentUrlParams();
    params.set('view', VIEW_MAP[calendarView]);
    params.set('date', moment(date).format('YYYYMMDD'));

    currentURL.search = params.toString();
    window.history.replaceState({}, 'TradeTrax', currentURL.href);
  }, [calendarView, date]);

  React.useEffect(() => {
    const [dayButton] = document.getElementsByClassName('fc-dayGridDay-button');
    const [weekButton] = document.getElementsByClassName('fc-dayGridWeek-button');
    const [monthButton] = document.getElementsByClassName('fc-dayGridMonth-button');
    const [prevButton] = document.getElementsByClassName('fc-prev-button');
    const [nextButton] = document.getElementsByClassName('fc-next-button');
    const [calendarDate] = document.getElementsByClassName('fc-toolbar-title');

    const onSelectView = view => controller.onClickSelectView(view);

    const changeMonthView = () => onSelectView('dayGridMonth');
    const changeWeekView = () => onSelectView('dayGridWeek');
    const changeDayView = () => onSelectView('dayGridDay');
    const onClickPrev = debounce(() => controller.onClickPrevNext(), 600);
    const onClickNext = debounce(() => controller.onClickPrevNext(), 600);
    const onOpenDatepicker = () => datePickerRef.current?.setOpen(true);

    monthButton.addEventListener('click', changeMonthView);
    weekButton.addEventListener('click', changeWeekView);
    dayButton.addEventListener('click', changeDayView);
    nextButton.addEventListener('click', onClickNext);
    prevButton.addEventListener('click', onClickPrev);
    calendarDate.addEventListener('click', onOpenDatepicker);

    return () => {
      monthButton.removeEventListener('click', changeMonthView);
      weekButton.removeEventListener('click', changeWeekView);
      dayButton.removeEventListener('click', changeDayView);
      nextButton.removeEventListener('click', onClickNext);
      prevButton.removeEventListener('click', onClickPrev);
      calendarDate.removeEventListener('click', onOpenDatepicker);
    };
  }, [calendarRef, controller]);

  return {
    state,
    controller,
    calendarRef,
    datePickerRef,
    dayGridConfig,
    linkToTaskDetails,
  };
}

export const momentIncrease = (date, period) =>
  moment(date)
    .add(1, period)
    .toDate();

export const momentDecrease = (date, period) =>
  moment(date)
    .subtract(1, period)
    .toDate();

const getCurrentUrlParams = () => {
  const currentURL = new URL(document.URL);
  const params = new URLSearchParams(currentURL.search);
  return [params, currentURL];
};

const setUrlParamsToState = () => {
  const initState = fromJS({ ...emptyState });
  const [params] = getCurrentUrlParams();
  if (params.toString()) {
    const view = Object.keys(VIEW_MAP).find(key => VIEW_MAP[key] === params.get('view'));
    const dateString = params.get('date');
    const date = moment(dateString, 'YYYYMMDD').toDate();
    if (view !== emptyState.calendarView || dateString !== moment(emptyState.date).format('YYYYMMDD')) {
      return initState.set('calendarView', view).set('date', date);
    }
  }
  return initState;
};

const getTaskUrl = (navigate, isTrade) => {
  if (isTrade) return navigate.to.TaskDetails;
  return navigate.to.JobTaskDetails;
};
