import styled from 'styled-components';
import { colors } from '../../theme';

const cardScale = ({ position }) => {
  switch (Math.abs(position)) {
    case 0:
      return 1;
    case 1:
      return 0.93;
    case 2:
      return 0.82;
    default:
      return 0;
  }
};

const translateCardX = ({ position }, largeSize) => {
  switch (position) {
    case 0:
      return 0;
    case -1:
      return largeSize ? -150 : -62;
    case -2:
      return largeSize ? -270 : -134;
    case 1:
      return largeSize ? 150 : 62;
    case 2:
      return largeSize ? 270 : 134;
    default:
      return 300;
  }
};

const translateCardY = ({ position }) => {
  switch (position) {
    case 0:
      return 0;
    case -1:
      return 6;
    case -2:
      return 15;
    case 1:
      return 6;
    case 2:
      return 15;
    default:
      return 20;
  }
};

export const CarouselCard = styled.div`
  transition: display 600ms fade;
  position: absolute;
  bottom: 0;
  background: ${colors.white};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  transition: transform 0.3s;
  ${props => `
    box-shadow:${props.position === 0 ? ' 0px 2px 10px 0px rgba(51,51,51,0.25)' : '0px'};
    transform: translateX(${translateCardX(props)}px) translateY(${translateCardY(props)}px) scale(${cardScale(props)});
    @media (min-width: 1920px) {
      transform: translateX(${translateCardX(props, true)}px) translateY(${translateCardY(props)}px) scale(${cardScale(
    props
  )});
    }
    z-index: ${100 - Math.abs(props.position)};
    ${
      Math.abs(props.position) === 1 || Math.abs(props.position) === 2
        ? `
        &::after{
          opacity:${Math.abs(props.position) === 1 ? '40%' : '75%'};
            position:absolute;
            right:0;
            top:-10px;
            height:100%;
            width:100%;
            background-color:white;
            content:'';
            border-radius: 8px;
        }
        `
        : ''
    }
  `};

  @media (max-width: 991px) {
    ${props => (Math.abs(props.position) > 0 ? 'display: none;' : '')}
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    ${props => (Math.abs(props.position) > 1 ? 'display: none;' : '')}
  }

  @media (min-width: 1200px) {
    ${props => (Math.abs(props.position) > 2 ? 'display: none;' : '')}
  }
`;
