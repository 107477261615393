import React from 'react';
import * as yup from 'yup';
import cn from 'classnames';
import { Modal, Form } from 'react-bootstrap';
import { Typeahead } from '@tradetrax/web-common';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { PrimaryButton, DurationInput } from '@tradetrax/web-common';
import { plural } from '@tradetrax/web-common/lib/utils';
import { useTaskAccountability } from './useTaskAccountability';

const schema = yup.object().shape({
  task: yup
    .object()
    .nullable()
    .required('Task required'),
  stage: yup.object().nullable(),
  isPreConstruction: yup.boolean(),
  duration: yup
    .number()
    .typeError('#')
    .required()
    .min(1, '1')
    .max(999, '999'),
  addAnother: yup.boolean(),
});

const emptyState = {
  task: null,
  isPreConstruction: false,
  duration: null,
  addAnother: false,
};

export function AddTaskModal({ title, addTask, globalTasks, stage = null, stages, templateId, close }) {
  const { register, handleSubmit, errors, control, watch, reset } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: { ...emptyState, stage },
  });
  const tasks = React.useMemo(() => {
    return globalTasks.toArray();
  }, [globalTasks]);
  const duration = watch('duration', 0);
  const [subTasksNumber, setSubTasksNumber] = React.useState(false);
  const stagesOptions = React.useMemo(() => (stages ? stages.toArray() : []), [stages]);

  const { task } = watch();
  const [accountability] = useTaskAccountability(task, templateId);

  const onSubmit = async form => {
    const { task, duration, stage: formStage, addAnother, startDateConfirmation, isPreConstruction } = form;
    const stageId = formStage?.get('_id');

    try {
      await addTask(task, duration, stageId, startDateConfirmation, isPreConstruction, accountability);

      if (!addAnother) return close();
      setSubTasksNumber(false);
      reset({ addAnother, task: null, stage, duration: 0, isPreConstruction });
    } catch (err) {
      console.log(err);
    }
  };

  const getAccountabilityMessage = () => {
    if (!accountability) return '';
    const message =
      accountability.get('daysTimeRange') === 0
        ? 'Same day when'
        : `${plural.day(accountability.get('daysTimeRange'))} ${accountability.get('timeConditions')}`;
    const timeCompletion = accountability.get('timeCompletion') === 'start' ? 'starts' : 'finishes';
    return `${message} ${accountability.get('relatedTaskName')} ${timeCompletion}`;
  };

  const onChangeTask = task => {
    setSubTasksNumber(task?.get('children')?.size || false);
    return task;
  };

  return (
    <Modal show={true} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>
          {title}
          {stage && (
            <>
              <br />
              <h6 className="font-weight-bold">{`Stage: ${stage.get('name')}`}</h6>
            </>
          )}
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onSubmit)} data-testid="template-add-task-form">
        <Modal.Body>
          <Form.Group controlId="formTask">
            <Controller
              name="task"
              control={control}
              render={({ onChange, onBlur, value }) => (
                <Typeahead
                  id="new-task-name"
                  placeholder="Choose from global library"
                  labelKey={task => task.get('name')}
                  isInvalid={!!errors.task}
                  options={tasks}
                  onChange={([task]) => onChange(onChangeTask(task))}
                  onBlur={onBlur}
                  selected={value ? [value] : []}
                  filterSelected={(option, selected) => selected._id !== option._id}
                  multiple={false}
                  clearButton
                >
                  {!value?.length && !errors.task && <FontAwesomeIcon icon="magnifying-glass" />}
                </Typeahead>
              )}
            />
            {subTasksNumber && (
              <span className="font-size-14 text-muted">{`*This Task contains ${subTasksNumber} ${plural(
                subTasksNumber,
                'Sub-Task',
                'Sub-Tasks'
              )}`}</span>
            )}
            <Form.Control.Feedback type="invalid" className={cn({ 'd-inline': !!errors.task })}>
              <FontAwesomeIcon icon="circle-exclamation" />
              {` `}
              {errors.task && ` ${errors.task.message}`}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formIsPreConstruction">
            <div className="d-flex flex-row align-items-center">
              <Form.Check
                type="checkbox"
                id="is-pre-construction"
                name="isPreConstruction"
                ref={register}
                label="Pre-Construction Task"
              />
            </div>
            <small className="text-secondary">This task won't affect the Job Exp. Start and Job Exp. Cycle Time</small>
          </Form.Group>
          <Form.Group controlId="formDuration">
            <Form.Label>Duration</Form.Label>
            <div className="d-flex flex-row align-items-center">
              <DurationInput duration={duration} register={register} errors={errors} />
            </div>
            <Form.Control.Feedback type="invalid" className={cn({ 'd-inline': !!errors.duration })}>
              <FontAwesomeIcon icon="circle-exclamation" />
              {` `}
              {errors.duration && errors.duration.type === 'min' && <FontAwesomeIcon icon={faArrowUp} />}
              {errors.duration && errors.duration.type === 'max' && <FontAwesomeIcon icon={faArrowDown} />}
              {errors.duration && ` ${errors.duration.message}`}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formStage" className={cn({ 'd-none': !!stage || stagesOptions.length === 0 })}>
            <Form.Label>Stage (optional)</Form.Label>
            <Controller
              name="stage"
              control={control}
              render={({ onChange, onBlur, value }) => (
                <Typeahead
                  id="choose-stage"
                  placeholder="Choose stage"
                  labelKey={stage => stage.get('name')}
                  isInvalid={!!errors.stage}
                  options={stagesOptions}
                  onChange={([value]) => onChange(value)}
                  onBlur={onBlur}
                  selected={value ? [value] : []}
                  multiple={false}
                  clearButton
                >
                  {!value?.length && !errors.stage && <FontAwesomeIcon icon="magnifying-glass" />}
                </Typeahead>
              )}
            />
            <Form.Control.Feedback type="invalid" className={cn({ 'd-inline': !!errors.stage })}>
              <FontAwesomeIcon icon="circle-exclamation" />
              {` `}
              {errors.stage && ` ${errors.stage.message}`}
            </Form.Control.Feedback>
          </Form.Group>
          {accountability && (
            <Form.Group controlId="formAccountability">
              <Form.Label>This task has a Start Date Configuration. Do you want to use it?</Form.Label>
              <div className="font-size-14 my-3 bg-pearl p-4 rounded-6">
                Send Request: <span className="font-weight-bold">{getAccountabilityMessage()}</span>
              </div>
              <div className="d-flex flex-row mr-5 mt-3 justify-content-between">
                <RatioOption text="Yes, I want to use it" defaultChecked value="yes" register={register} />
                <RatioOption text="No, I will use it later" value="no" register={register} />
              </div>
            </Form.Group>
          )}
        </Modal.Body>
        <Modal.Footer className="d-flex flex-row justify-content-between mt-5">
          <Form.Check type="checkbox" id="add-another" name="addAnother" ref={register} label="Add Another Task" />
          <PrimaryButton type="submit">{title}</PrimaryButton>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

const RatioOption = ({ text, defaultChecked = false, register, value }) => (
  <div className="d-flex align-items-center font-size-14">
    <input
      className="mr-1"
      defaultChecked={defaultChecked}
      id={`rule-${value}`}
      name="startDateConfirmation"
      type="radio"
      value={value}
      ref={register}
    />
    <label className="mb-0">{text}</label>
  </div>
);
