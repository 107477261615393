import React from 'react';
import { fromJS, List, Map } from 'immutable';
import { useController } from '@tradetrax/web-common';
import { useAppContext } from 'app/App.context';
import * as actions from './TemplateDetailsActions';

export const TEMPLATE_VIEW = 'TEMPLATE_VIEW';
export const TEMPLATE_STAGES_EXPANDED_STATE = 'TEMPLATE_STAGES_EXPANDED_STATE';

export const emptyExpandedState = Map({ stages: Map(), list: Map() });
export const emptyState = fromJS({
  template: {
    tasks: List(),
    stages: List(),
    tasksTotalCount: 10,
    name: '',
    stagesViewModel: List(),
    isComplete: true,
  },
  hasPermission: true,
  templateView: 'stages',
  expandedRows: emptyExpandedState,
  expandedInnerRow: null,
});

export function TemplateDetailsContext(templateId) {
  const appContext = useAppContext();
  const tableRef = React.useRef();
  const { modal } = appContext;
  const [state, controller] = useController(actions, emptyState, { tableRef, ...appContext });

  React.useEffect(() => {
    controller.readTemplate(templateId);
    controller.loadDefaultView();
    controller.loadLastStagesExpandedState(templateId);
  }, [controller, templateId]);

  return {
    state,
    controller,
    modal,
    tableRef,
  };
}

const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');
function getStageIndex(index) {
  if (alphabet[index]) {
    return alphabet[index].toUpperCase();
  }
  // only works for a template with less than 53 stages
  return `A${getStageIndex(index - alphabet.length)}`;
}

export function mapStagesViewModel(template) {
  const stages = template.get('stages');
  const tasks = template.get('tasks');
  const orphans = tasks.filter(task => task.get('stageId') === null);
  const above = orphans.filter(task => task.get('orderAboveStages'));
  const below = orphans.filter(task => !task.get('orderAboveStages'));

  const stagesViewModel = stages.reduce(
    (model, stage, index) =>
      model.push(
        stage
          .set(
            'tasks',
            tasks.filter(task => task.get('stageId') === stage.get('_id'))
          )
          .set('isStage', true)
          .set('children', List())
          .set('predecessors', stage.get('predecessors') || List())
          .set('stageIndex', getStageIndex(index))
      ),
    List()
  );

  return above.push(...stagesViewModel.toArray()).push(...below.toArray());
}

export function getPredecessorsString(predecessors, tasks) {
  const result = predecessors
    .map(p => {
      const taskAux = tasks.find(t => t.get('id') === p.get('taskId'));
      const depType = taskAux && p.get('dependencyType') !== 'FS' ? p.get('dependencyType') : '';
      return taskAux ? depType + taskAux.get('rowIndex') : '';
    })
    .toJS()
    .join();

  return result;
}
