import React from 'react';

const content = props => {
    const { body } = props;
    return (
        <>
            <div>{body}</div>
        </>
);}

export default content;