import React from 'react';
import styled from 'styled-components';
import { Form } from 'react-bootstrap';
import { colors } from '../../theme';

export const RecentActivityFilter = ({ filterContext }) => {
  const { filterState, filterController, isManager } = filterContext;
  const values = filterState.get('values');
  const disabledField = React.useMemo(() => {
    // exclude `filter-sets` field when current user is manager.
    const checked = values.filter((value, field) => !(isManager && field === 'filter-sets') && !!value);
    const max = isManager ? values.size - 1 : values.size;
    if (checked.size === max) return 'all';
    if (checked.size === 1) return checked.keySeq().first();
    return '';
  }, [values]);
  const onCheckChange = e => {
    const { name, checked } = e.target;
    e.preventDefault();
    e.stopPropagation();
    if (name === 'all') {
      filterController.defer.reset();
    } else {
      filterController.defer.updateFieldValue(name, checked);
    }
  };

  const CheckBox = ({ name, checked, ...props }) => (
    <StyledCheckBox
      id={`option-${name}`}
      disabled={disabledField === name}
      name={name}
      onChange={onCheckChange}
      checked={checked || values.get(name)}
      {...props}
    />
  );

  return (
    <div className="d-flex settings-container flex-column justify-content-start">
      <div className="d-flex justify-content-between">
        <h5 className="mb-4 font-weight-bold" style={{ width: '250px' }}>
          Recent Activity Filters
        </h5>
      </div>
      <div className="d-flex flex-column border-top pt-2">
        <CheckBox label="All" name="all" checked={disabledField === 'all'} />
        <div className="d-flex flex-row">
          <div className="pl-4 mt-3 d-flex flex-column">
            <CheckBox label="Assigned to me" name="task-assigned" />
            <CheckBox label="Notes" name="notes" />
            <CheckBox label="Attachments" name="attachments" />
            <CheckBox label="Update Requests" name="update-request" />
          </div>
          <div className="pl-4 mt-3 d-flex flex-column">
            {isManager ? null : <CheckBox label="Shared Filter Sets" name="filter-sets" />}
          </div>
        </div>
      </div>
    </div>
  );
};

const StyledCheckBox = styled(Form.Check)`
  display: flex;
  align-items: center;

  input[disabled] {
    background: ${colors.blue300};
  }

  .form-check-label {
    margin-top: 4px;
    margin-left: 0.5rem;
  }
  .form-check-input {
    width: 15px;
    height: 15px;
  }
`;
