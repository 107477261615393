import styled from 'styled-components';
import { colors } from '../theme';

export { OverdueTaskCard, UpdateRequestCard } from './Requests/RequestCard';
export { RequestsOverdueSection } from './Requests/RequestsOverdueSection';
export { ToDoSection } from './ToDo/ToDoSection';
export { UpcomingSection } from './Upcoming/UpcomingSection';
export { RecentActivitySection } from './RecentActivity/RecentActivitySection';
export { CommitmentRequests } from './V2/CommitmentRequests';

export const Section = styled.div`
  ${props => `
    background: ${props.blue ? colors.activeState : colors.white};
    /* Gray */
    border: 1px solid ${colors.gray100};
    box-sizing: border-box;
    /* Dropdown-shadow */
    box-shadow: 0px 1px 2px rgba(51, 51, 51, 0.2);
    border-radius: 10px;
    padding: 1.5rem;

    .section-title {
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      color: ${colors.gray800};
    }

    .toggle-filter {
      position: absolute;
      right: 24px;
    }
`}
`;
export const LineDivider = styled.hr`
  ${({ width }) => `
  width: ${width || '600px'};
  @media (min-width: 1920px) {
    width: ${width || '880px'};
  }
`}
`;
export const DashboardContainer = styled.div`
  padding: 5px;
  @media (min-width: 992px) {
    padding: 27px;
  }
  background-color: ${colors.gray300};
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
  width: 100%;
  .dashboard-container {
    max-width: 1720px;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    @media (min-width: 992px) {
      display: grid;
      gap: 0 20px;
      grid-template-columns: 1fr 1fr;

      grid-template-areas:
        'ReqAndOverdue ReqAndOverdue'
        'ToDo Commitment'
        'Upcoming Upcoming'
        'RecentAct RecentAct';
    }

    @media (min-width: 1400px) {
      display: grid;
      gap: 20px 20px;
      grid-template-columns: 62% 38%;
      grid-template-areas:
        'ReqAndOverdue ToDo'
        'ReqAndOverdue Commitment'
        'Upcoming Commitment'
        'RecentAct ...';
      ${({ emptyRequest }) =>
        emptyRequest &&
        `
          grid-template-areas:
          'ReqAndOverdue ToDo'
          'Upcoming ToDo'
          'Upcoming Commitment'
          'Upcoming .'
          'RecentAct .'; 
      `}
    }

    & > div {
      margin-bottom: 20px;
      @media (min-width: 1400px) {
        margin-bottom: 0;
      }
    }
    & > div:nth-child(1) {
      grid-area: ReqAndOverdue;
      @media (min-width: 1400px) {
        align-self: start;
      }
    }
    & > div:nth-child(2) {
      grid-area: ToDo;
      @media (min-width: 1400px) {
        align-self: start;
      }
    }
    & > div:nth-child(3) {
      grid-area: Commitment;
      @media (min-width: 992px) {
        align-self: start;
      }
    }
    & > div:nth-child(4) {
      grid-area: Upcoming;
      @media (min-width: 1400px) {
        align-self: start;
      }
    }
    & > div:nth-child(5) {
      grid-area: RecentAct;
    }
  }
`;

export const CarouselContainer = styled.div`
  ${props => `
  display: flex;
  flex-direction: column;
  margin-bottom:0;
  .cards-container {
    display: flex;
    position: relative;
    height: 190px;
    justify-content: center;
  }

  .title {
    font-weight: bold;
    font-size: 18px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .count-label {
      color: ${props.themeColor};
      font-size: 40px;
    }
  }
`}
`;

export const CarouselContainerCards = styled.div`
  justify-content: space-around;
  width: 700px;
  @media (min-width: 992px) {
    justify-content: space-between;
  }
  @media (min-width: 1920px) {
    width: 1000px;
  }
`;
