import React from 'react';
import cn from 'classnames';
import { useCommunities, useTaskTypes } from '../../index';
import { Section, LineDivider } from '../index';
import { UpdateRequestCard, OverdueTaskCard } from './RequestCard';
import { Carousel } from './Carousel';
import { FilterImplementation } from '../../Filters/Implementation/Filter.implementation';
import { FILTER_NAME, useFilterContext } from '../../Filters/Filters.context';
import { FilterSets } from '../../Filters/FilterSets';

export const RequestsOverdueSection = ({
  context,
  appContext,
  linkToTaskDetails,
  linkToRequestAndOverdueDetails,
  service,
}) => {
  const { assignees } = appContext.appState.toObject();
  const { state, controller } = context;
  const { tasksUpdateRequest, tasksOverdue } = state.toObject();

  const bothEmpty = tasksUpdateRequest?.size === 0 && tasksOverdue?.size === 0;
  const communities = useCommunities(service);
  const taskTypes = useTaskTypes(service);
  const filterContext = useFilterContext(FILTER_NAME.UR_OVERDUE);
  const lookups = { communities, taskTypes, assignees };

  return (
    <Section
      blue
      className={cn(' position-relative pb-2', { 'd-flex flex-row-reverse justify-content-center': bothEmpty })}
    >
      <div className="d-flex justify-content-end  position-absolute" style={{ right: '30px', top: '20px' }}>
        <FilterImplementation
          top="10rem"
          filterContext={filterContext}
          lookups={lookups}
          title="Update Requests & Overdue Tasks Filters"
          backGroundWhite
        >
          <FilterSets.SectionDropdown filterContext={filterContext} />
        </FilterImplementation>
      </div>
      <div className={cn({ 'd-flex flex-row justify-content-center p-4 w-100': bothEmpty })}>
        <Carousel
          items={tasksUpdateRequest}
          Render={UpdateRequestCard}
          className="d-flex align-items-center"
          controller={controller}
          appContext={appContext}
          linkToTaskDetails={linkToTaskDetails}
          linkToRequestAndOverdueDetails={linkToRequestAndOverdueDetails}
          data-testid="dashboard-update-requests"
        />
        <LineDivider className={cn(' my-0', { 'd-none': bothEmpty })} />
        <Carousel
          isOverdue
          items={tasksOverdue}
          Render={OverdueTaskCard}
          className={cn('d-flex align-items-center', { 'w-50': bothEmpty })}
          controller={controller}
          appContext={appContext}
          linkToTaskDetails={linkToTaskDetails}
          linkToRequestAndOverdueDetails={linkToRequestAndOverdueDetails}
          data-testid="dashboard-overdue-tasks"
        />
      </div>
    </Section>
  );
};
