import React from 'react';
import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import { ProposeDateView } from './ProposeDateView';
import { UpdateRequestModalContext, WARNING_VIEW } from './UpdateRequestModal.context';
import { WarningView } from './WarningView';

export function UpdateRequestModal({ accept, cancel, tasks, isBuilder, isTaskAssigned }) {
  const context = UpdateRequestModalContext(tasks, isTaskAssigned);
  const { state } = context;
  const showWarning = state.get('view') === WARNING_VIEW;
  const props = {
    accept,
    cancel,
    tasks,
    isBuilder,
    ...context,
  };

  const ModalView = showWarning ? WarningView : ProposeDateView;

  return (
    <StyledModal show={true} onHide={() => cancel()} size="md">
      <ModalView {...props} isUR />
    </StyledModal>
  );
}

const StyledModal = styled(Modal)`
  .modal-dialog.modal-md {
    max-width: 600px;
  }
`;
