import React from 'react';
import { EmptyState, EmptyStateCenterContainer } from '@tradetrax/web-common/lib/EmptyState';
import { LoadingStages } from './LoadingStages';
import { StageCards } from './StageCards';
import { OverlayIncompleteStages } from '../OverlayIncompleteTemplate';

export const StagesSettings = ({ context }) => {
  const template = context.state.get('template');
  //TODO: pending to define which role/permission restricts this area.
  const canEdit = true; //appContext.appState.getIn(['user', 'role']) === 'manager';
  const isLoading = template.get('isLoading');
  const stages = template.get('stages');

  // Configure the Stages from this Template by setting their order, start, and finish.
  return (
    <>
      <div className={canEdit ? 'mb-4 pb-2' : 'mb-5'}>
        <div className="d-flex flex-row justify-content-between align-items-start">
          <span className="font-size-12 text-gray-400 mt-4">
            {canEdit
              ? 'Configure the Stages from this Template by setting their order, start, and finish.'
              : 'Only the Account Manager can ... ???'}
          </span>
          {!template.get('hasCompleteStages') && (
            <div className="mt-4" style={{ width: '205px' }}>
              <OverlayIncompleteStages stages={stages} />
            </div>
          )}
        </div>
      </div>
      {isLoading ? <LoadingStages /> : <StageCards {...context} />}
      <EmptyStateCenterContainer top="-70px">
        <EmptyState
          pt={5}
          visible={!isLoading && stages.size === 0}
          icon="bars-staggered"
          title="No Stages Added"
          body="When a Stage is added, it will appear here."
        />
      </EmptyStateCenterContainer>
    </>
  );
};
