import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { Col, Form } from 'react-bootstrap';
import { Datepicker, PrimaryButton } from '@tradetrax/web-common';
import { TaskChangeAssignee } from './TaskChangeAssignee';
import { datepickerFormat, mongoToTrx, plural, getMinEndDate, COMPLETED } from '@tradetrax/web-common/lib/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDuration } from '../TaskDurationInput';
import { TaskPreConstructionCheckbox } from './Task.PreConstruction.checkbox';

import WarningCriticalMsg, {
  CriticalDay,
  criticalClass,
  showWarningCriticalMsg,
} from '@tradetrax/web-common/lib/CriticalPath/WarningCriticalMsg';

const DurationInput = styled(Form.Control)`
  background: none !important;
  padding-right: 0 !important;
`;

export function TaskDefaultContent({ task, controller, jobContext }) {
  const { hasPermission, isJobReleased } = jobContext;
  const startDate = task.get('startDate') ? datepickerFormat(mongoToTrx(task.get('startDate'))) : '';
  const endDate = task.get('expectedFinishDate') ? datepickerFormat(mongoToTrx(task.get('expectedFinishDate'))) : '';
  const { duration, errors, Feedback, ...handlers } = useDuration(controller, task);
  const isStartMissed = task.get('overdue') === 'start';
  const isFinishMissed = task.get('overdue') === 'finish';
  const minEndDate = getMinEndDate(task.get('startDate'));
  const canEdit = hasPermission('job_update_task_dependencies_and_dates');

  const lateStartDate = task.get('lateStartDate') || undefined;
  const lateEndDate = task.get('lateEndDate') || undefined;
  const criticalStartDate = new Date(lateStartDate);
  const criticalEndDate = new Date(lateEndDate);
  const canSendUR = task.get('status') !== COMPLETED && !canEdit;
  const isSendURDisabled = !startDate || !endDate;

  return (
    <>
      <div className="d-flex flex-column mt-4 pt-3" style={{ width: '32rem' }}>
        <Form.Row className="flex-nowrap">
          <Form.Group as={Col} md="6" controlId="formStartDate" className="d-flex flex-column">
            <Form.Label>Expected Start</Form.Label>
            <Datepicker
              name="startDate"
              selected={startDate}
              onChange={date => controller.updateTaskStartDate({ date, task })}
              placeholderText="MM/DD/YY"
              isInvalid={isStartMissed}
              isReadOnly={!canEdit}
              dayClassName={date => criticalClass(date, criticalStartDate, startDate)}
              renderDayContents={(day, date) => CriticalDay(day, date, criticalStartDate, startDate)}
              calendarClassName="react-datepicker__critical"
            >
              <WarningCriticalMsg showMsg={showWarningCriticalMsg(startDate, criticalStartDate)} />
            </Datepicker>
            {isStartMissed && (
              <small className="text-danger">
                <FontAwesomeIcon icon="circle-exclamation" className="mr-1" /> Missed Start
              </small>
            )}
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formEndDate" className="d-flex flex-column ">
            <Form.Label>Expected Finish</Form.Label>
            <Datepicker
              name="endDate"
              selected={endDate}
              onChange={date => controller.updateEndDateAndDuration({ date, task })}
              placeholderText="MM/DD/YY"
              isInvalid={isFinishMissed}
              minDate={minEndDate}
              isReadOnly={!canEdit}
              dayClassName={date => criticalClass(date, criticalEndDate, endDate)}
              renderDayContents={(day, date) => CriticalDay(day, date, criticalEndDate, endDate)}
              calendarClassName="react-datepicker__critical"
            >
              <WarningCriticalMsg showMsg={showWarningCriticalMsg(endDate, criticalEndDate)} />
            </Datepicker>
            {isFinishMissed && (
              <small className="text-danger">
                <FontAwesomeIcon icon="circle-exclamation" className="mr-1" /> Missed Finish
              </small>
            )}
          </Form.Group>
          {canSendUR && (
            <PrimaryButton
              className="text-nowrap"
              disabled={isSendURDisabled}
              onClick={() => controller.openUpdateRequestModal(task)}
            >
              Send Update Request
            </PrimaryButton>
          )}
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} md="6" controlId="formDuration">
            <Form.Label>Duration</Form.Label>
            <div className="d-flex flex-row align-items-center">
              {canEdit && (
                <DurationInput
                  className="w-25 mr-1"
                  value={duration}
                  type="number"
                  isInvalid={errors.duration}
                  {...handlers}
                />
              )}
              {!canEdit && <span className="text-gray-200 mr-1">{duration}</span>}
              <span className={cn('font-size-14', { 'text-gray-200': !canEdit })}>
                {plural(parseInt(duration), 'day', 'days')}
              </span>
            </div>
            <Feedback />
          </Form.Group>
        </Form.Row>
        <TaskPreConstructionCheckbox controller={controller} task={task} canEdit={canEdit && !isJobReleased} />
        <TaskChangeAssignee task={task} controller={controller} jobContext={jobContext} />
      </div>
    </>
  );
}
