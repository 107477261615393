import { fromJS, Map, List } from 'immutable';
import { markAsSync, markAsSideEffect } from '@tradetrax/web-common';
import { redirectToLogin, redirectToHomeWebsite } from '@tradetrax/web-common/lib/redirectToLogin';
import { DEVICE_TOKEN } from '@tradetrax/web-common/lib/Notifications/NotificationsController';
import { readCookies } from '@tradetrax/web-common/lib/utils';
import { LegalAgreement } from '@tradetrax/web-common/lib/Modal';
import { gtlService, buildersService, usersService } from 'services';

export { updateFiltersOnDataBase } from '@tradetrax/web-common/lib/Dashboard/DashboardActions';

export async function readAccount() {
  const userId = this.state.getIn(['user', '_id']);
  const [account, settings, mySelf] = await Promise.all([
    buildersService.self().then(fromJS),
    buildersService.viewSubsLibrarySettings().then(fromJS),
    buildersService.getUser({}, { params: { userId } }).then(fromJS),
  ]);
  const assignees = account.get('subs').unshift(
    Map({
      name: account.get('name'),
      subAccountId: account.get('_id'),
    })
  );
  const companies = assignees.toArray();
  const accountUsers = account
    .get('users')
    .map(user => user.set('name', `${user.get('firstName')} ${user.get('lastName')}`).set('userId', user.get('_id')));
  const usersActive = accountUsers.filter(user => user.get('status') === 'active');

  const stateAccount = account
    .set('usersActive', usersActive)
    .set('tradesLocationSettings', settings)
    .set('legalAgreements', mySelf.get('legalAgreements'));

  return state =>
    state
      .set('account', stateAccount)
      .set('subs', account.get('subs'))
      .set('installers', account.get('installers'))
      .set('assignees', assignees)
      .set('companies', companies);
}

export function updateSettings(settings) {
  return buildersService
    .updateSettings(settings)
    .then(() =>
      this.alert.add({
        message: 'Account settings successfully updated',
        timeout: 5,
      })
    )
    .then(() => state => state.updateIn(['account'], account => account.merge(fromJS(settings))));
}

markAsSync(selectScheduleView);
export function selectScheduleView(state, jobCurrentView) {
  const settings = { jobCurrentView };
  buildersService.updateSelf({ settings });

  return state.setIn(['user', 'settings', 'jobCurrentView'], jobCurrentView);
}

export function readGTL() {
  return gtlService
    .readTasks()
    .then(fromJS)
    .then(tasks => state => {
      const trades = tasks
        .map(t => t.get('trade'))
        .toOrderedSet()
        .toArray();
      return state.set('gtl', tasks).set('trades', trades);
    });
}

export async function logout() {
  const { isDemo, tokenDevices } = this.state.get('user').toObject();
  const token = localStorage.getItem(DEVICE_TOKEN);
  const body = {};
  if (token && tokenDevices.includes(token)) {
    body.deviceToken = token;
  }
  localStorage.clear();
  return usersService.logout(body).then(() => (isDemo ? redirectToHomeWebsite() : redirectToLogin(false)));
}

markAsSync(updateUserSettings);
export async function updateUserSettings(state, settings) {
  return state.updateIn(['user', 'settings'], current => current.merge(settings));
}
// export async function updateUserSettings(settings) {
//   return usersService
//     .updateUser({ settings })
//     .then(() => state => state.updateIn(['user', 'settings'], current => current.merge(settings)));
// }

markAsSync(toggleOption);
export function toggleOption(state, option) {
  return state.update('options', options => ({
    ...options,
    [option]: !options[option],
  }));
}

// TODO: have an endpoint which return all the templates with basic info + NO pagination
// TODO: move this to modules where needed and load on demand
// function readTemplates() {
//   return buildersService
//     .getTemplates()
//     .then(templates => templates.map(({ _id, name }) => ({ _id, name })))
//     .then(templates => state => state.set('templates', templates));
// }

// TODO: have an endpoint which return all the communities with basic info + NO pagination
// TODO: move this to modules where needed and load on demand
export function readCommunities() {
  return buildersService
    .readCommunities({}, { query: { start_index: 0, stop_index: 99 } })
    .then(communities => state => state.set('communities', fromJS(communities)));
}

export function acceptCookies() {
  return usersService.acceptCookieBanner().then(() => {
    return state => state.set('cookieBannerAccepted', true);
  });
}

markAsSync(getCookies);
export function getCookies(state) {
  const cookies = readCookies();
  return state.set('cookieBannerAccepted', !!cookies['cookie-banner-accepted']);
}

markAsSync(addNewTemplate);
export function addNewTemplate(state, template) {
  return state.update('templates', templates => templates.push(fromJS(template)));
}

markAsSync(updateTemplatesList);
export function updateTemplatesList(state, template, status) {
  const isActive = status === 'active';
  if (!isActive) {
    const index = state.get('templates').indexOf(template);
    return state.update('templates', templates => templates.splice(index, 1));
  }
  return state.update('templates', templates => templates.splice(0, 0, template.set('status', 'active')));
}

markAsSideEffect(getSearchResults);
export function getSearchResults(text) {
  const query = text.replace('#', '');
  const currentResults = this.state.get('filteredResults');
  if (currentResults.size && query.length < 2) {
    this.controller.dispatch([state => state.set('globalSearchResults', List()).set('filteredResults', List())]);
  } else if (query.length >= 2) {
    buildersService
      .search({}, { query: { query } })
      .then(fromJS)
      .then(response => {
        this.controller.dispatch([
          state => state.set('globalSearchResults', response).set('filteredResults', response),
        ]);
      });
  }
}

markAsSideEffect(openLegalAgreementModal);
export async function openLegalAgreementModal() {
  const legalAgreements = this.state.getIn(['account', 'legalAgreements']);
  const { isAccept } = await this.modal.open(LegalAgreement, { legalAgreements });
  if (!isAccept) return;

  const payload = {
    eulaAccepted: true,
    privacyPolicyAccepted: true,
    termsOfUseAccepted: true,
  };
  this.controller.dispatch([state => state.setIn(['account', 'isAcceptLegalAgreements'], true)]);

  buildersService.updateSelf(payload).catch(err => {
    this.alert.error({ message: 'There was a problem accepting the Legal Agreement. Please try again.' });
  });
}
