import { fromJS } from 'immutable';
import { markAsSync, markAsSideEffect } from '../useController';

markAsSync(toggleCollapsed);
export function toggleCollapsed(state) {
  return state.update('isCollapsed', isCollapsed => !isCollapsed);
}

// will try to fetch the latest entries on history
export function readTaskHistory(jobId, taskId) {
  const from = this.state.getIn(['history', 0, 'createdOn']);

  return this.historyService
    .readTaskHistory({}, { params: { jobId, taskId }, query: { from } })
    .then(fromJS)
    .then(history => state => state.update('history', current => history.concat(current)).set('isLoading', false));
}

// will load the whole task history.
export function loadTaskHistory(jobId, taskId) {
  return this.historyService
    .readTaskHistory({}, { params: { jobId, taskId } })
    .then(fromJS)
    .then(history => state => state.merge({ history, isLoading: false }));
}

markAsSideEffect(addTaskHistory);
export function addTaskHistory({ action, taskHistory }) {
  if (action === 'update-history') {
    this.controller.dispatch([state => state.update('history', history => history.unshift(fromJS(taskHistory)))]);
  }
}
