import React from 'react';
import { fromJS } from 'immutable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { markAsSideEffect, CustomDialog } from '@tradetrax/web-common';
import navigate from 'app/navigate';
import { buildersService } from 'services';
import { TemplateForm } from './TemplateNew';

export function loadMoreRows({ startIndex, stopIndex }) {
  const filter = this.filterState.get('values').toJS();
  const query = getQueryParam(startIndex, stopIndex, filter);

  return buildersService.getTemplates({}, { query }).then(data => {
    const totalCount = data.metadata.pagination ? data.metadata.pagination.totalCount : 0;
    return state =>
      state
        .set('totalCount', totalCount)
        .update('templates', templates => templates.splice(startIndex, stopIndex - startIndex + 1, ...fromJS(data)));
  });
}

markAsSideEffect(createTemplate);
export function createTemplate(form) {
  form.tasks = [];
  return new Promise((resolve, reject) => {
    buildersService
      .createTemplate(form)
      .then(response => {
        this.appController.addNewTemplate(response);
        this.alert.success({ message: 'Template successfully created.' });
        const { _id } = response;
        navigate.to.TemplateDetails({ templateId: _id });
        resolve();
      })
      .catch(err => {
        if (err.type !== 'entity-conflict') {
          this.alert.error({ message: 'There was a problem creating this template. Please try again.' });
        }
        reject(err);
      });
  });
}

markAsSideEffect(handleActions);
export async function handleActions(option, template) {
  if (option === 'rename') {
    const submitForm = form => this.controller.renameTemplate({ template, templateName: form.name });
    this.modal.open(TemplateForm, {
      title: 'Rename Template',
      message: 'New Template Name',
      buttonText: 'Rename Template',
      name: template.get('name'),
      submitForm,
      isRename: true,
    });
  }
  if (option === 'duplicate') {
    const submitForm = form => this.controller.duplicateTemplate({ template, templateName: form.name });
    this.modal.open(TemplateForm, {
      title: 'Duplicate Template',
      message: 'Template Name',
      name: `${template.get('name')} copy`,
      buttonText: 'Create Template',
      submitForm,
    });
  }
  if (option === 'deactivate') {
    const { isAccept } = await this.modal.open(CustomDialog, {
      title: (
        <>
          <FontAwesomeIcon icon="ban" className="text-danger" />
          Deactivate Template
        </>
      ),
      message: 'Template will no longer be available to use when creating jobs. You can reactivate it anytime. ',
      titleAccept: 'Yes, Deactivate Template',
      titleCancel: 'Cancel',
      maxWidth: '33.4rem',
    });
    if (!isAccept) return;
    this.controller.toggleTemplateStatus({ template, option });
  }
  if (option === 'reactivate') {
    this.controller.toggleTemplateStatus({ template, option });
  }
}

markAsSideEffect(renameTemplate);
export function renameTemplate({ template, templateName }) {
  const templates = this.state.get('templates');
  const index = templates.indexOf(templates.find(t => t.get('_id') === template.get('_id')));

  this.controller.dispatch([state => state.setIn(['templates', index, 'name'], templateName)]);

  return new Promise((resolve, reject) => {
    buildersService
      .updateTemplate({ name: templateName }, { params: { templateId: template.get('_id') } })
      .then(() => {
        this.alert.success({ message: 'Template successfully renamed.' });
        resolve();
      })
      .catch(err => {
        if (err.type !== 'entity-conflict') {
          this.alert.error({ message: 'There was a problem renaming this template. Please try again.' });
        }
        this.controller.dispatch([state => state.setIn(['templates', index, 'name'], template.get('name'))]);
        reject(err);
      });
  });
}

markAsSideEffect(duplicateTemplate);
export function duplicateTemplate({ template, templateName }) {
  return new Promise((resolve, reject) => {
    buildersService
      .createTemplate({
        templateId: template.get('_id'),
        name: templateName,
      })
      .then(response => {
        const { _id } = response;
        this.alert.success({ message: 'Template successfully created.' });
        navigate.to.TemplateDetails({ templateId: _id });
        resolve();
      })
      .catch(err => {
        if (err.type !== 'entity-conflict') {
          this.alert.error({ message: 'There was a problem creating this template. Please try again.' });
        }
        reject(err);
      });
  });
}

export function toggleTemplateStatus({ template, option }) {
  const templates = this.state.get('templates');
  const index = templates.indexOf(templates.find(t => t.get('_id') === template.get('_id')));
  const isReactivated = option === 'reactivate';
  const status = isReactivated ? 'active' : 'deactivated';

  return buildersService
    .updateTemplate({ status }, { params: { templateId: template.get('_id') } })
    .then(() => {
      const message = isReactivated
        ? 'Template has been successfully reactivated. Access has been restored.'
        : 'Template successfully deactivated. You can reactivate it anytime.';
      this.alert.success({ message });
      this.appController.updateTemplatesList(template, status);
      return state => state.updateIn(['templates', index], template => template.set('status', status));
    })
    .catch(err => {
      this.alert.error({
        message: `There was a problem ${isReactivated ? 'r' : 'd'}eactivating this template. Please try again.`,
      });

      return state => state.updateIn(['templates', index], template => template.set('status', template.get('status')));
    });
}

function getQueryParam(startIndex, stopIndex, filter) {
  const query = { start_index: startIndex, stop_index: stopIndex, status: 'all' };
  if (!filter) return query;
  if (filter.status === 'active') query.complete = true;
  if (filter.status === 'incomplete') {
    query.complete = false;
    query.status = 'active';
    return query;
  }
  if (filter.status) query.status = filter.status;

  return query;
}
