import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import { colors } from '../theme';
import { mongoToText } from '../utils';
import cn from 'classnames';

export const PopoverApprovalStatus = ({ task, isBuilder, isTrade, onlyIcon = false, onlyDate = false }) => {
  const {
    changeRequestType,
    changeRequestDate,
    isPending,
    isRejected,
    accountName,
    createdByType,
  } = getChangeRequestData(task, isBuilder, isTrade);

  // Nothing to display.
  if (!isPending && !isRejected) return null;
  // trades should not view URs created by builders.
  if (isTrade && createdByType === 'builder') return null;

  let message = null;
  if (!onlyDate) {
    if (isPending) {
      message = (
        <div className="PendingTooltip_msg d-flex justify-content-start align-items-center">
          <FontAwesomeIcon icon="clock" className="mr-2" />
          <span>Pending approval on </span>
        </div>
      );
    } else if (isRejected) {
      message = (
        <div className="PendingTooltip_msg d-flex justify-content-start align-items-center">
          <FontAwesomeIcon icon="exclamation-circle" className="mr-2 text-danger" />
          <span>Declined by </span>
        </div>
      );
    }
  }

  const popover = (
    <Popover>
      <Popover.Content>
        <PendingTooltip>
          <strong className="PendingTooltip_date mb-2 ">{`Proposed ${changeRequestType} ${mongoToText(
            changeRequestDate
          )} `}</strong>
          {message}
          {onlyDate ? null : <span className="PendingTooltip_builder">{accountName}</span>}
        </PendingTooltip>
      </Popover.Content>
    </Popover>
  );

  const label = onlyIcon ? null : <span>{isPending ? 'Pending approval' : 'Request declined'}</span>;

  return (
    <OverlayTrigger placement="bottom" overlay={popover} open>
      <PendingStatusContainer className={cn({ 'with-background': !onlyIcon })}>
        <FontAwesomeIcon
          className={cn({ 'text-danger': !isPending, 'mr-2': !onlyIcon })}
          icon={isPending ? 'clock' : 'exclamation-circle'}
        />
        {label}
      </PendingStatusContainer>
    </OverlayTrigger>
  );
};

function getChangeRequestData(task, isBuilder, isTrade) {
  const type = task.getIn(['changeRequestOnReviewByBuilder', 'type']) || task.getIn(['changeRequest', 'type']);
  const createdByType =
    task.getIn(['changeRequestOnReviewByBuilder', 'createdByType']) || task.getIn(['changeRequest', 'createdByType']);

  let isPending = false;
  let isRejected = false;
  let changeRequestType = 'Finish';
  let accountName = task.getIn(['job', 'builderName']);
  let changeRequestDate =
    task.getIn(['changeRequestOnReviewByBuilder', 'proposedFinishDate']) ||
    task.getIn(['changeRequest', 'proposedFinishDate']);

  if (type === 'new-start-date-request') {
    changeRequestType = 'Start';
    changeRequestDate =
      task.getIn(['changeRequestOnReviewByBuilder', 'proposedStartDate']) ||
      task.getIn(['changeRequest', 'proposedStartDate']) ||
      task.getIn(['changeRequestForStartDateConfirmation', 'newStartDate']);
  }

  if (isTrade) {
    const reviewByBuilderState = task.getIn(['changeRequestOnReviewByBuilder', 'state']);
    const rejectedByBuilderState =
      task.getIn(['changeRequestForStartDateConfirmation', 'state']) === 'rejected-by-builder';
    const isChangeRequestOnReviewByTrade =
      task.getIn(['changeRequest', 'state']) === 'review-by-sub' &&
      task.getIn(['changeRequest', 'activeForCurrentUser']) &&
      !task.get('startDateConfirmationRequest');

    if (isChangeRequestOnReviewByTrade) accountName = task.getIn(['assigneeAccount', 'companyName']);

    isPending = isChangeRequestOnReviewByTrade || reviewByBuilderState === 'review-by-builder';
    isRejected = rejectedByBuilderState || reviewByBuilderState === 'rejected-by-builder';
  } else if (isBuilder) {
    isPending = task.getIn(['changeRequest', 'state']) === 'review-by-builder';
  }

  return { changeRequestDate, changeRequestType, isPending, isRejected, accountName, createdByType };
}

const PendingStatusContainer = styled.div`
  border-radius: 20px;
  color: ${colors.gray400};
  font-size: 12px;
  padding: 5px 8px;
  max-width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.with-background {
    background-color: ${colors.gray100};
  }

  &:hover {
    cursor: pointer;
  }
`;

const PendingTooltip = styled.div`
  color: ${colors.gray800};
  font-size: 12px;
  padding: 10px;
  .PendingTooltip_msg {
    color: ${colors.gray400};
  }
`;
