import React from 'react';
import { fromJS } from 'immutable';
import { markAsSync, useController } from '../../../useController';
import {
  NOT_STARTED,
  NOT_READY_TO_START,
  IN_PROGRESS,
  COMPLETED,
  datepickerFormatIncremented,
  formatTrx,
} from '../../../utils';

export const START_VIEW = 'Start';
export const FINISH_VIEW = 'Finish';
export const WARNING_VIEW = 'WARNING_VIEW';

const emptyState = fromJS({
  startTasks: [],
  finishTasks: [],
  proposedStart: null,
  proposedFinish: null,
  withoutPermission: [],
  sdcrTasks: [],
});

const actions = { setFinishView, setStartView, setProposedStart, setProposedFinish, warningNext };

const getLatestDate = (tasks, dateField) => {
  const maxDate = new Date(Math.max(...tasks.map(task => new Date(task.get(dateField)))));
  return datepickerFormatIncremented(formatTrx(maxDate), 2);
};

export function UpdateRequestModalContext(tasks, isTaskAssigned) {
  const initState = mapTasks(tasks, isTaskAssigned);
  const { initView, isTwoSteps, isAllWithoutPermission } = React.useMemo(() => getInitView(initState), []);
  const [state, controller] = useController(
    actions,
    initState
      .set('view', initView)
      .set('isTwoStep', isTwoSteps)
      .set('isAllWithoutPermission', isAllWithoutPermission)
  );

  const { startTasks, finishTasks } = state.toObject();
  React.useEffect(() => {
    const proposedStart = startTasks.size && getLatestDate(startTasks, 'startDate');
    const proposedFinish = finishTasks.size && getLatestDate(finishTasks, 'expectedFinishDate');
    if (proposedStart) controller.setProposedStart(proposedStart);
    if (proposedFinish) controller.setProposedFinish(proposedFinish);
  }, [startTasks, finishTasks]);

  return { state, controller };
}

const TASKS_KIND_MAP = {
  [NOT_STARTED]: 'startTasks',
  [NOT_READY_TO_START]: 'startTasks',
  [IN_PROGRESS]: 'finishTasks',
  [COMPLETED]: 'finishTasks',
};

const mapTasks = (tasks, isTaskAssigned) => {
  return tasks.reduce((acc, task) => {
    const isCheckedIn = !!task.get('checkedIn');
    const status = isCheckedIn ? task.get('originalStatus') : task.get('status');
    const hasSDCR = task.get('startDateConfirmationRequest') && status === IN_PROGRESS;
    const isNotAssigned = !isTaskAssigned(task);
    const taskKind = isNotAssigned ? 'withoutPermission' : hasSDCR ? 'sdcrTasks' : TASKS_KIND_MAP[status];
    return acc.update(taskKind, taskKind => taskKind.push(task));
  }, emptyState);
};

const getInitView = state => {
  const { startTasks, finishTasks, sdcrTasks } = state.toObject();
  const showWarningView = !!sdcrTasks.size || state.get('withoutPermission').size;
  const hasOnlyFinish = finishTasks.size && !startTasks.size;
  const isTwoSteps = !!startTasks.size && !!finishTasks.size;
  const initView = showWarningView ? WARNING_VIEW : hasOnlyFinish ? FINISH_VIEW : START_VIEW;
  const isAllWithoutPermission = showWarningView && !startTasks.size && !finishTasks.size;

  return { initView, isTwoSteps, isAllWithoutPermission };
};

markAsSync(setFinishView);
function setFinishView(state) {
  return state.set('view', FINISH_VIEW);
}

markAsSync(setStartView);
function setStartView(state) {
  return state.set('view', START_VIEW);
}

markAsSync(setProposedStart);
function setProposedStart(state, date) {
  return state.set('proposedStart', date);
}

markAsSync(setProposedFinish);
function setProposedFinish(state, date) {
  return state.set('proposedFinish', date);
}

markAsSync(warningNext);
function warningNext(state) {
  const hasProposedStart = !!state.get('startTasks').size;
  if (hasProposedStart) return state.set('view', START_VIEW);
  return state.set('view', FINISH_VIEW);
}
