import { buildersService } from 'services';
import { fromJS } from 'immutable';
import { markAsSideEffect, markAsSync } from '@tradetrax/web-common/lib/useController';
import { NewCustomRoleModal } from './CustomRole.new.modal';

export function readCustomRoles() {
  return buildersService
    .listRoles({}, { query: { active: 'all' } })
    .then(fromJS)
    .then(response => state => viewModel(state.set('customRoles', response)));
}

markAsSideEffect(openCreateRoleModal);
export async function openCreateRoleModal() {
  const customRoles = this.state.get('customRoles');
  const { isAccept, name } = await this.modal.open(NewCustomRoleModal, { customRoles });
  if (!isAccept) return;

  buildersService
    .createRole({ name })
    .then(fromJS)
    .then(response => {
      this.alert.success({ message: 'Custom Role successfully created.' });
      this.controller.dispatch([
        state =>
          viewModel(
            state.update('customRoles', customRoles => customRoles.push(response)).set('roleId', response.get('_id'))
          ),
      ]);
    })
    .catch(() => this.alert.error({ message: 'There was a problem creating this Custom Role. Please try again.' }));
}

markAsSync(toggleRoleActive);
export function toggleRoleActive(state, customRole) {
  const roleId = customRole.get('_id');
  const index = state.get('customRoles').findIndex(cr => cr.get('_id') === roleId);
  const { active } = customRole.toObject();

  buildersService
    .updateRole({ active: !active }, { params: { roleId } })
    .then(() => {
      this.alert.success({ message: `Custom Role successfully ${active ? 'd' : 'r'}eactivated.` });
    })
    .catch(() => {
      this.alert.error({
        message: `There was a problem ${active ? 'd' : 'r'}eactivating this Custom Role. Please try again.`,
      });
      this.controller.dispatch([state => viewModel(state.setIn(['customRoles', index, 'active'], active))]);
    });

  return viewModel(
    state.updateIn(['customRoles', index], customRole => customRole.set('active', !active).set('tab', 'my-tasks'))
  );
}

const viewModel = state => {
  const customRoles = state.get('customRoles');
  const active = customRoles.filter(role => role.get('active'));
  const inactive = customRoles.filter(role => !role.get('active'));
  return state.set('active', active.sort(byDescOrder)).set('inactive', inactive.sort(byDescOrder));
};

const byDescOrder = (a, b) => {
  if (a.get('name') > b.get('name')) return 1;
  if (a.get('name') < b.get('name')) return -1;
  return 0;
};
