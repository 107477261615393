// import moment from 'moment';
import { fromJS } from 'immutable';
import { markAsSync, markAsSideEffect } from '@tradetrax/web-common';
import { checkValidName } from './CustomRoleDetails.common';
import { buildersService } from 'services';

// export { deleteRelevantTask, deleteLeadTimeTask } from '../../Users/MyProfile/MyProfileActions';

export function readCustomRole(roleId) {
  return buildersService
    .getRole({}, { params: { roleId } })
    .then(fromJS)
    .then(response => state => state.set('customRole', response));
}

export * from './FilterSets.actions';
export * from './Notifications/MyTasks.actions';
export * from './Notifications/RelevantTasks.actions';
export * from './Notifications/LeadTime.actions';

markAsSync(setTab);
export function setTab(state, tab) {
  return state.set('tab', tab);
}

markAsSync(setSelectedSection);
export function setSelectedSection(state, section) {
  return state.set('selectedSection', section);
}

markAsSideEffect(updateName);
export function updateName(_, name) {
  const isNameValid = checkValidName(name, this.customRoles);
  if (!isNameValid) throw new Error('name-already-exists');

  const { customRole } = this.state.toObject();
  const roleId = customRole.get('_id');
  const previousName = customRole.get('name');

  this.controller.dispatch([state => state.setIn(['customRole', 'name'], name)]);

  buildersService.updateRole({ name }, { params: { roleId } }).catch(error => {
    this.addAlert('There was a problem renaming this Custom Role. Please try again.', 'danger');
    this.controller.dispatch([state => state.setIn(['customRole', 'name'], previousName)]);
  });
}

export function updateUserPermissions(user, permission, value) {
  const roleId = user.get('_id');

  return buildersService
    .updateRole({ permissions: { [permission]: value } }, { params: { roleId } })
    .then(() => state =>
      state.updateIn(['customRole', 'settings', 'permissions'], permissions =>
        (permissions || Map()).set(permission, value)
      )
    )
    .catch(err => {
      this.alert.error({ message: 'There was a problem updating the permission. Please try again.' });
      throw err;
    });
}
