import { markAsSideEffect } from '@tradetrax/web-common';
import { buildersService } from 'services';
import { fromJS } from 'immutable';
import navigate from 'app/navigate';

export function loadMoreRows({ startIndex, stopIndex }) {
  return buildersService
    .readCommunities({}, { query: { start_index: startIndex, stop_index: stopIndex } })
    .then(data => {
      const totalCount = data.metadata.pagination ? data.metadata.pagination.totalCount : 0;
      return state =>
        state
          .set('totalCount', totalCount)
          .update('communities', communities =>
            communities.splice(startIndex, stopIndex - startIndex + 1, ...fromJS(data).toArray())
          );
    });
}

markAsSideEffect(createNewCommunity);
export function createNewCommunity(form) {
  return buildersService
    .createCommunity(form)
    .then(response => {
      this.addAlert('Community successfully created.', 'success');
      const { _id } = response;
      navigate.to.CommunityDetails({ communityId: _id });
    })
    .catch(() => {
      this.addAlert('There was a problem creating this Community. Please try again.', 'danger');
    });
}
