import React from 'react';
import * as yup from 'yup';
import cn from 'classnames';
import { Modal, Form } from 'react-bootstrap';
import { Typeahead } from '@tradetrax/web-common';
import { useForm, Controller as FormController } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers';
import { PrimaryButton } from '@tradetrax/web-common';
import { useStages } from './useStages';

const schema = yup.object().shape({
  stage: yup
    .object()
    .nullable()
    .required('Stage required'),
  addAnother: yup.boolean(),
});

const emptyState = {
  stage: null,
  // duration: 1, // TODO: remove duration
  addAnother: false,
  message: '',
};

export function AddStageModal({ title, addStage, template, close }) {
  const globalStages = useStages();
  const stageRef = React.useRef();
  const { register, handleSubmit, errors, control, reset } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: emptyState,
  });

  const [stages, setStages] = React.useState([]);

  // TODO: this should a useEffect hook instead.
  React.useMemo(() => {
    const ids = template.get('stages').map(stage => stage.get('_id'));
    const filteredStages = globalStages.filter(stage => ids.indexOf(stage.get('_id')) === -1).toArray();
    setStages(filteredStages);
  }, [setStages, globalStages, template]);

  const onSubmit = async form => {
    const { stage, addAnother } = form;

    try {
      await addStage(template, stage);

      if (!addAnother) return close();

      setStages(stages.filter(stg => stg.get('_id') !== stage.get('_id')));
      reset({ addAnother, stage: null });
    } catch (err) {
      console.log({ err });
    }
  };

  return (
    <Modal show={true} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onSubmit)} data-testid="template-add-stage-form">
        <Modal.Body>
          <Form.Group controlId="formName">
            <FormController
              name="stage"
              control={control}
              defaultValue={null}
              render={({ onChange, onBlur, value }) => (
                <Typeahead
                  id="new-task-name"
                  placeholder="Choose from global library"
                  labelKey={option => option.get('name')}
                  isInvalid={!!errors.stage && !!value}
                  options={stages}
                  filterSelected={(option, selected) => selected._id !== option._id}
                  onChange={([value]) => onChange(value)}
                  onBlur={onBlur}
                  selected={value ? [value] : []}
                  ref={stageRef}
                  icon="magnifying-glass"
                />
              )}
            />
            <Form.Control.Feedback type="invalid" className={cn({ 'd-inline': !!errors.stage })}>
              <FontAwesomeIcon icon="circle-exclamation" className="mr-1" />
              {errors.stage?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="d-flex flex-row justify-content-between">
          <Form.Check type="checkbox" id="add-another" name="addAnother" ref={register} label="Add Another Stage" />
          <PrimaryButton type="submit">Add Stage</PrimaryButton>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
