const { TasksFilter } = require('./Filter.tasks');
const { RecentActivityFilter } = require('./Filter.recentActivity');
const { TemplatesFilter } = require('./Filter.templates');
const { UsersFilter } = require('./Filter.users');
const { HomeOwnersFilter } = require('./Filter.home.owner');
const { JobFilter } = require('./Filter.job');
const { IntakeFilter } = require('./Filter.intake');
const { OuttakeFilterByAccount, OuttakeFilterByUser } = require('./Filter.outtake');

import { FILTER_NAME } from './filter.name';

export const Components = {
  [FILTER_NAME.OPEN_TASKS]: TasksFilter,
  [FILTER_NAME.TO_DO]: TasksFilter,
  [FILTER_NAME.UPCOMING]: TasksFilter,
  [FILTER_NAME.UR_OVERDUE]: TasksFilter,
  [FILTER_NAME.RECENT_ACTIVITY]: RecentActivityFilter,
  [FILTER_NAME.TEMPLATES]: TemplatesFilter,
  [FILTER_NAME.USERS]: UsersFilter,
  [FILTER_NAME.HOME_OWNERS]: HomeOwnersFilter,
  [FILTER_NAME.JOB]: JobFilter,
  [FILTER_NAME.INTAKE_BY_USER]: IntakeFilter,
  [FILTER_NAME.INTAKE_BY_INSTALLER]: IntakeFilter,
  [FILTER_NAME.OUTTAKE_BY_ACCOUNT]: OuttakeFilterByAccount,
  [FILTER_NAME.OUTTAKE_BY_USER]: OuttakeFilterByUser,
};

export function getFilterComponent(filterName) {
  const Filter = Components[filterName];

  if (!Filter) throw new Error(`${filterName} filter not implemented!`);

  return Filter;
}
