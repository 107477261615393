import React from 'react';
import { Col, Form } from 'react-bootstrap';
import { Typeahead } from '@tradetrax/web-common';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function TaskChangeAssignee({ task, controller, hasUpdateRequest, jobContext }) {
  const { appState, hasPermission } = jobContext;
  const { companies, account, user } = appState.toObject();
  const loggedAccountId = account.get('_id');
  const users = account.get('usersActive').toArray();
  const currentCompanyId = task.getIn(['assigneeAccount', 'companyId']);
  const currentSuperId = task.getIn(['userSuper', '_id']);
  const isCurrentSuperInactive = task.getIn(['userSuper', 'status']) === 'inactive';
  const isCurrentSchedulerInactive = task.getIn(['userScheduler', 'status']) === 'inactive';
  const currentSchedulerId = task.getIn(['userScheduler', '_id']);
  const currentSuper = React.useMemo(() => users.find(user => user.get('_id') === currentSuperId), [
    users,
    currentSuperId,
  ]);
  const currentScheduler = React.useMemo(() => users.find(user => user.get('_id') === currentSchedulerId), [
    users,
    currentSchedulerId,
  ]);
  const currentAssignee = React.useMemo(() => companies.find(c => c.get('subAccountId') === currentCompanyId), [
    currentCompanyId,
    companies,
  ]);
  const onChangeAssignee = ([company]) => {
    const subId = company ? company.get('subAccountId') : null;
    controller.assignTask({ task, subId });
  };
  const onChangeScheduler = ([user]) => controller.updateTaskScheduler({ task, user });
  const onChangeSuper = ([user]) => controller.updateTaskSuper({ task, user });
  const isAssigned = userType => !!task.getIn([userType, 'name']);
  const canAssignUsers = !hasUpdateRequest && hasPermission('task_user_assignment');

  return (
    <div className="mb-4 pb-3 mt-4 pt-3">
      <Form.Row>
        <Form.Group as={Col} md="12" data-testid="rbt-task-assignee-account">
          <small>Account Assignee</small>
          <Typeahead
            id="task-assignee-account"
            placeholder="Choose Account"
            options={companies}
            selected={currentAssignee ? [currentAssignee] : []}
            labelKey={option => option.get('name')}
            filterSelected={(option, selected) => selected.get('subAccountId') !== option.get('subAccountId')}
            onChange={onChangeAssignee}
            isMyId={option => loggedAccountId === option.get('subAccountId')}
          />
        </Form.Group>
      </Form.Row>
      {canAssignUsers && (
        <>
          <Form.Row>
            <Form.Group as={Col} md="12">
              <small>Builder Super</small>
              <Typeahead
                id="super-assignee"
                placeholder="Choose Super"
                options={users}
                labelKey={option => `${option.get('firstName')} ${option.get('lastName')}`}
                filterSelected={(option, selected) => selected.get('_id') !== option.get('_id')}
                selected={currentSuper ? [currentSuper] : []}
                onChange={onChangeSuper}
                isMyId={option => user.get('_id') === option.get('_id')}
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="12">
              <small>Builder Scheduler</small>
              <Typeahead
                id="scheduler-assignee"
                placeholder="Choose Scheduler"
                options={users}
                labelKey={option => `${option.get('firstName')} ${option.get('lastName')}`}
                filterSelected={(option, selected) => selected.get('_id') !== option.get('_id')}
                selected={currentScheduler ? [currentScheduler] : []}
                onChange={onChangeScheduler}
                isMyId={option => user.get('_id') === option.get('_id')}
              />
            </Form.Group>
          </Form.Row>
        </>
      )}
      {!canAssignUsers && (
        <div className={cn('d-flex', { 'flex-column': !hasUpdateRequest })}>
          <div className="d-flex flex-column mr-5 mb-3">
            <small className="text-muted">Builder Super</small>
            <span
              className={cn({
                'text-dark': isAssigned('userSuper'),
                'text-gray-200': !isAssigned('userSuper'),
                'deactivated-user': isCurrentSuperInactive,
              })}
            >
              {isCurrentSuperInactive && <FontAwesomeIcon icon="ban" className="mr-1" />}
              {task.getIn(['userSuper', 'name']) || 'Not Assigned'}
            </span>
          </div>
          <div className="d-flex flex-column mr-5">
            <small className="text-muted">Builder Scheduler</small>
            <span
              className={cn({
                'text-dark': isAssigned('userScheduler'),
                'text-gray-200': !isAssigned('userScheduler'),
                'deactivated-user': isCurrentSchedulerInactive,
              })}
            >
              {isCurrentSchedulerInactive && <FontAwesomeIcon icon="ban" className="mr-1" />}
              {task.getIn(['userScheduler', 'name']) || 'Not Assigned'}
            </span>
          </div>
        </div>
      )}
    </div>
  );
}
