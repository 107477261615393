import React from 'react';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { plural } from '@tradetrax/web-common/lib/utils';
import { TaskCardReadOnly } from './TaskCard';
import { UL } from './UL';

export const RecommendedTasks = ({ context }) => {
  const { state } = context;
  const [isCollapsed, setIsCollapsed] = React.useState(true);
  const recommended = state.getIn(['accountability', 'recommended']);

  if (recommended.size === 0 || recommended.get('isLoading')) return null;

  const icon = isCollapsed ? 'chevron-down' : 'chevron-up';
  return (
    <div className="bg-pearl rounded-6 py-4 pl-4 my-3 font-size-14">
      <div className="d-flex justify-content-between">
        <span className="font-weight-bold">{`${recommended.size} Recommended ${plural(
          recommended.size,
          'Task'
        )}`}</span>
        <FontAwesomeIcon
          icon={icon}
          className="mr-2 mt-1 cursor-pointer"
          onClick={() => setIsCollapsed(!isCollapsed)}
        />
      </div>
      <div className={cn('mt-3 pr-1', { 'd-none': isCollapsed })}>
        <span>
          Recently these Tasks where added to the Template and they contain default configurations. You may use or
          dismiss them.
        </span>
        <UL>
          {recommended.map((accountability, key) => (
            <TaskCardReadOnly key={key} context={context} accountability={accountability} />
          ))}
        </UL>
      </div>
    </div>
  );
};
