import { fromJS, Map } from 'immutable';
import { markAsSync } from '@tradetrax/web-common/lib/useController';
import { mongoToMoment } from '@tradetrax/web-common/lib/utils';
import { buildersService } from 'services';

markAsSync(loadTaskCycleTimeReport);
export function loadTaskCycleTimeReport(state) {
  const body = this.getFilters(state);

  buildersService
    .averageCycleTimeByJob(body)
    .then(fromJS)
    .then(data => {
      const model = data
        .groupBy(d => d.get('communityId'))
        .toList()
        .map(group => {
          const first = group.first();
          const jobsByMonth = group
            .map(job => job.set('month', mongoToMoment(job.get('startDate')).format('MMM, YYYY')))
            .groupBy(job => job.get('month'));

          return Map({
            jobsByMonth,
            communityId: first.get('communityId'),
            communityName: first.get('communityName'),
            areaManagers: first.get('areaManagers'),
          });
        });
      this.controller.dispatch([state => state.set('data', model)]);
    })
    .catch(() => {
      this.alert.error({
        message: (
          <>
            There was a problem trying to generate this Report.
            <br />
            Please try again.
          </>
        ),
      });
    });

  return state.set('appliedFilters', state.get('filters'));
}

markAsSync(loadTaskCycleTimeBySuperAssigneeReport);
export function loadTaskCycleTimeBySuperAssigneeReport(state) {
  const body = this.getFilters(state);

  buildersService
    .getAverageCycleTimeBySuperUsers(body)
    .then(fromJS)
    .then(data => {
      const model = data
        .groupBy(d => d.get('communityId'))
        .map(group => {
          const first = group.first();
          const jobsBySuper = group
            .groupBy(job => job.getIn(['userSuper', '_id']))
            .map(bySuper => {
              const userSuper = bySuper.first().get('userSuper');
              const jobs = bySuper.map(bs => bs.get('job'));
              const result = jobs
                .reduce(
                  (totals, job) => {
                    if (job.get('daysBehind') < 0) totals = totals.update('jobsBehind', jobsBehind => jobsBehind - 1);

                    return totals
                      .update('averageDays', averageDays => averageDays + job.get('totalDays'))
                      .update('dateChanges', dateChanges => dateChanges + job.get('dateChanges') || 0);
                  },
                  Map({
                    jobs,
                    userSuper,
                    averageDays: 0,
                    jobsBehind: 0,
                    dateChanges: 0,
                  })
                )
                .update('averageDays', averageDays => {
                  const value = Math.floor(averageDays / jobs.size);
                  return isNaN(value) ? 0 : value;
                });

              return result;
            });

          return Map({
            jobsBySuper,
            communityId: first.get('communityId'),
            communityName: first.get('communityName'),
            areaManagers: first.get('areaManagers'),
          });
        });
      this.controller.dispatch([state => state.set('data', model)]);
    })
    .catch(() => {
      this.alert.error({
        message: (
          <>
            There was a problem trying to generate this Report.
            <br />
            Please try again.
          </>
        ),
      });
    });

  return state.set('appliedFilters', state.get('filters'));
}
