import React from 'react';
import styled from 'styled-components';
import { Redirect, Link } from 'react-router-dom';
import { Row, Col, Breadcrumb } from 'react-bootstrap';
import { TaskLoading, TaskFooter } from '@tradetrax/web-common';
import { TaskHistory } from '@tradetrax/web-common/lib/TaskHistory';
import navigate, { Paths } from 'app/navigate';
import { TaskDetailsContent } from '../Workflows/TaskDetailsContent';
import { emptyTask } from '../JobDetails/JobDetails.shared';
import { TaskHeader } from './TaskHeader';
import { buildersService } from 'services';

export default function JobTaskDetails({ location, match, jobContext }) {
  const { taskId, jobId } = match.params;
  const { state, controller, appState, appContext } = jobContext;
  const { job, tasks } = state.toObject();
  const user = appState.get('user');
  const account = appState.get('account');
  const task = React.useMemo(() => tasks.find(t => t.get('id') === parseInt(taskId, 10)) || emptyTask, [tasks, taskId]);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const accountUsers = account.get('users');

  React.useEffect(() => {
    controller.readTask(jobId, taskId);
  }, [controller, jobId, taskId]);

  if (jobId === job.get('id')) {
    const isTaskMissing = tasks.size && !tasks.find(t => t.get('id') === task.get('id'));
    if (!state.get('hasPermission')) return <Redirect to={Paths.NotPermission} />;
    if (isTaskMissing && !isDeleting) return <Redirect to={Paths.NotPermission} />;
  }
  if (task === emptyTask || !task) return <TaskLoading />;

  const onDropdownMenuSelect = async () => {
    // TODO switch(action) when more actions are added
    setIsDeleting(true);
    const isDeleted = await controller.deleteTask({ task });
    if (isDeleted) navigate.from.JobTaskDetails.to.JobDetails({});
  };

  return (
    <div className="ml-0 mt-4">
      <Row className="pl-3 w-100">
        <Col xl={6} lg={8} md={12} sm={12}>
          <TaskHistoryContainer>
            <Breadcrumbs job={job} task={task} />
            <TaskHistory
              historyService={buildersService}
              jobId={jobId}
              taskId={taskId}
              user={user}
              account={account}
              onDropdownMenuSelect={onDropdownMenuSelect}
              showActions={true}
              modal={appContext.modal}
              accountUsers={accountUsers}
            />
          </TaskHistoryContainer>
          <TaskHeader task={task} controller={controller} job={job} />
          <TaskDetailsContent task={task} controller={controller} jobContext={jobContext} />
        </Col>
      </Row>
      <TaskFooter
        task={task}
        service={buildersService}
        controller={controller}
        appContext={appContext}
        location={location}
      />
    </div>
  );
}

const Breadcrumbs = ({ job, task }) => {
  return (
    <Breadcrumb tag="nav">
      <Breadcrumb.Item linkAs={Link} linkProps={{ to: Paths.Community }}>
        Communities
      </Breadcrumb.Item>
      <Breadcrumb.Item
        linkAs={Link}
        linkProps={{ to: navigate.to.CommunityDetails({ communityId: job.get('communityId') }, false) }}
      >
        {job.get('communityName')}
      </Breadcrumb.Item>
      <Breadcrumb.Item linkAs={Link} linkProps={{ to: navigate.from.JobTaskDetails.to.JobDetails({}, false) }}>
        {job.get('name')}
      </Breadcrumb.Item>
      <Breadcrumb.Item active>{task.get('name')}</Breadcrumb.Item>
    </Breadcrumb>
  );
};

const TaskHistoryContainer = styled.div`
  display: flex;
  margin-right: 10px;
  margin-bottom: 15px;
`;
