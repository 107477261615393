import React from 'react';
import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import { ChangeDateModal } from '@tradetrax/web-common/lib/Dashboard/Requests/ChangeDateModal';
import { ScheduleImpactModal } from 'app/pages/Job/ScheduleImpactModal';
import { TasksAffectedView } from './TasksAffectedView';
import { UpdateRequestView } from './UpdateRequestView';
import { TasksAffectedContext, UR_VIEW, IMPACT_VIEW, CHANGE_DATE_VIEW } from './TasksAffectedContext';

export function TasksAffectedModal({ task, tasksGraph, ...props }) {
  const { cancel } = props;
  const { state, controller } = TasksAffectedContext(task, tasksGraph, props);
  const { modalView } = state.toObject();

  let ModalView = TasksAffectedView;
  if (modalView === UR_VIEW) ModalView = UpdateRequestView;
  else if (modalView === IMPACT_VIEW) ModalView = ScheduleImpactModal;
  else if (modalView === CHANGE_DATE_VIEW) ModalView = ChangeDateModal;

  return (
    <StyledModal show={true} onHide={() => cancel()} size="md">
      <ModalView state={state} controller={controller} {...props} />
    </StyledModal>
  );
}

const StyledModal = styled(Modal)`
  .modal-dialog.modal-md {
    max-width: 700px;
  }
`;
