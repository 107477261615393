import React from 'react';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { plural } from '../../../utils';
import { NoticeWindowInput } from '../../../DurationInput';
import styled from 'styled-components';

export const RelevantTask = ({ task, expanded, toggle, controller, isMyProfile }) => {
  const showSubTaskLabel = task.get('isChildTask') && expanded;
  const parentTaskName = task.get('parentTaskName');
  const subTaskLabel = parentTaskName ? `Sub-Task from ${parentTaskName}` : "This Sub-Task doesn't belong to any Task";
  return (
    <li className={expanded ? 'expanded' : ''}>
      <div>
        <button
          className="btn btn-link p-0 text-secondary"
          onClick={() => controller.deleteRelevantTask(task, isMyProfile)}
        >
          <FontAwesomeIcon icon="trash" />
        </button>
        <TaskNameContainer className="ml-3 mr-auto align-self-center  text-truncate">
          {task.get('name')}
        </TaskNameContainer>
        <button className="btn btn-link p-0 text-secondary" onClick={() => toggle(task)}>
          <FontAwesomeIcon icon={`chevron-${expanded ? 'up' : 'down'}`} className="mt-2" />
        </button>
      </div>
      {showSubTaskLabel && (
        <div className="ml-4 pl-2 text-muted font-size-14 d-block w-75 text-truncate">{subTaskLabel}</div>
      )}
      {expanded && <RelevantTaskDetails task={task} controller={controller} />}
    </li>
  );
};

const RelevantTaskDetails = ({ task, controller }) => {
  const isSubTask = task.get('isChildTask');
  const updateRelevantTask = (event, channels, extra) => controller.updateRelevantTask(task, event, channels, extra);
  const SettingsRow = ({ event, label, children, checkboxDisabled = false, keepLabel = false }) => {
    const channels = task.getIn([event, 'onlyChannels']);
    const pushChecked = channels && channels.indexOf('push') >= 0;
    const emailChecked = channels && channels.indexOf('email') >= 0;
    const extra = event === 'start-date-or-end-date' ? { notice_window: task.getIn([event, 'notice_window']) } : null;
    const update = channels => updateRelevantTask(event, channels, extra);

    return (
      <div className="d-flex flex-column justify-content-start">
        <div className="my-3 ml-4 pl-2">
          <span className="mr-auto">{keepLabel ? label : `Only ${label} updates`}</span>
          {label !== 'Start date' && label !== 'Finish date' && (
            <Form.Check
              id={`relevant_${event}_push`}
              className="mr-1"
              defaultChecked={pushChecked}
              type="switch"
              disabled={checkboxDisabled === 'push'}
              label=""
              onChange={({ target }) => update([target.checked ? 'push' : null, emailChecked ? 'email' : null])}
            />
          )}
          <Form.Check
            className="ml-3"
            id={`relevant_${event}_email`}
            defaultChecked={emailChecked}
            type="switch"
            disabled={checkboxDisabled === 'email'}
            label=""
            onChange={({ target }) => update([target.checked ? 'email' : null, pushChecked ? 'push' : null])}
          />
        </div>
        {!!children && children({ pushChecked, emailChecked, notice_window: task.getIn([event, 'notice_window']) })}
      </div>
    );
  };

  return (
    <>
      <div className="font-size-14 font-weight-bold my-3 ml-4 pl-2 ">
        <span className="mr-auto">Receive Notifications</span>
        <span>Push</span>
        <span className="ml-4">Email</span>
      </div>
      <SettingsRow event="completed" label="Completed" />
      {!isSubTask && (
        <>
          <SettingsRow event="in-progress" label="In Progress" />
          <SettingsRow event="check-in-or-check-out" label="Check-in/Out status" />
          <SettingsRow
            event="commitment-request-accepted"
            label="Confirmed Commitment Request"
            checkboxDisabled="push"
            keepLabel
          />
          <SettingsRow event="start-date-or-end-date" label="Start-Finish Date Changes" keepLabel>
            {({ pushChecked, emailChecked, notice_window }) => {
              const updateDuration = duration =>
                controller.updateRelevantTask(
                  task,
                  'start-date-or-end-date',
                  [emailChecked ? 'email' : null, pushChecked ? 'push' : null],
                  {
                    notice_window: duration,
                  }
                );

              return (
                <div className="d-flex flex-row justify-content-center mt-2 ml-4 pl-2 align-self-start ">
                  <NoticeWindowInput
                    value={notice_window || 7}
                    updateDuration={updateDuration}
                    disabled={!pushChecked && !emailChecked}
                  >
                    {({ duration }) => (
                      <span className="small text-secondary ml-2 pt-1">{`${plural(
                        parseInt(duration),
                        'Day'
                      )} in advance notice window`}</span>
                    )}
                  </NoticeWindowInput>
                </div>
              );
            }}
          </SettingsRow>
        </>
      )}
    </>
  );
};

const TaskNameContainer = styled.strong`
  width: 75%;
  max-width: 250px;
`;
