import navigate from 'app/navigate';

export const COL_WIDTHS = {
  span: 32,
  jobName: 12 * 16,
  assignee: 12 * 16,
  duration: 6 * 16,
  expStart: 8 * 16,
  expFinish: 8 * 16,
  status: 160,
  action: 32,
};

export const WIDTHS = Object.values(COL_WIDTHS).reduce((sum, width) => sum + width, 0);

export const linkToTask = (task, referrer) => ({
  pathname: navigate.to.JobTaskDetails({ jobId: task.getIn(['job', 'id']), taskId: task.get('id') }, false),
  state: { referrer },
});
