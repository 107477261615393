import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { AutoSizer, Column, InfiniteLoader, Table } from 'react-virtualized';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrimaryButton, renderCell, EMPTY_ROW } from '@tradetrax/web-common';
import { EmptyState, EmptyStateCenterContainer } from '@tradetrax/web-common/lib/EmptyState';
import { AssigneeSelect } from '@tradetrax/web-common/lib/AssigneeSelect';
import { HomeownerContext } from './HomeownerContext';
import { useAppContext } from 'app/App.context';
import navigate from 'app/navigate';
import { FilterImplementation } from '@tradetrax/web-common/lib/Filters/Implementation/Filter.implementation';

const COL_WIDTHS = {
  span: 32,
  job: 12 * 16,
  lot: 5 * 16,
  community: 12 * 16,
  assignee: 14 * 16,
  status: 3 * 16,
};
const WIDTHS = Object.values(COL_WIDTHS).reduce((sum, width) => sum + width, 0);

export function HomeownersPage() {
  const { appState } = useAppContext();
  const { state, controller, loaderRef, filterContext, filter } = HomeownerContext();
  const { totalCount, homeowners } = state.toObject();
  const { communities, builderAssignees } = state.toObject();
  const lookups = { communities, builderAssignees };
  const { isFiltering } = filterContext;

  return (
    <>
      <div className="d-flex flex-row justify-content-between mx-4 my-4">
        <h4 className="font-weight-bold">Homeowners</h4>
        <div className="d-flex flex-row align-items-center">
          <PrimaryButton onClick={controller.openAddHomeownerModal}>Add Homeowner</PrimaryButton>
          <FilterImplementation filterContext={filterContext} lookups={lookups} className="ml-3" />
        </div>
      </div>
      <InfiniteLoader
        isRowLoaded={({ index }) => !!homeowners.get(index)}
        loadMoreRows={({ startIndex, stopIndex }) => controller.loadMoreRows({ startIndex, stopIndex, filter })}
        rowCount={totalCount}
        ref={loaderRef}
      >
        {({ onRowsRendered, registerChild }) => (
          <div style={{ flex: '1 1 auto', overflowY: 'hidden' }}>
            <AutoSizer>
              {({ width, height }) => (
                <Table
                  ref={registerChild}
                  onRowsRendered={onRowsRendered}
                  className="trx-table"
                  headerHeight={40}
                  width={width}
                  height={height}
                  overscanRowCount={2}
                  rowHeight={80}
                  rowCount={totalCount}
                  rowClassName={({ index }) => (homeowners.get(index) ? '' : 'loading')}
                  rowGetter={({ index }) => homeowners.get(index) || EMPTY_ROW}
                  headerClassName={`${totalCount ? '' : 'd-none'}`}
                  noRowsRenderer={() => (
                    <EmptyStateCenterContainer top="250px">
                      <EmptyState
                        icon="person-shelter"
                        title={isFiltering ? 'No Matches Found' : 'No Homeowners Added'}
                        body={`When ${
                          isFiltering ? "there's a Homeowner that meets the filters" : 'a Homeowner is added'
                        }, it will appear here.`}
                      />
                    </EmptyStateCenterContainer>
                  )}
                >
                  <Column width={COL_WIDTHS.span} label="" dataKey="" />
                  <Column
                    label="Homeowner"
                    dataKey="firstName"
                    width={(width - WIDTHS) / 2}
                    cellRenderer={renderCell(({ cellData, rowData }) => {
                      const status = rowData.get('status');
                      const isInactive = status === 'deactivated';
                      return (
                        <Link
                          className="main-link"
                          to={navigate.to.HomeownerProfile({ userId: rowData.get('_id') }, false)}
                        >
                          <div className="font-size-16 text-truncate">
                            <span className={cn({ 'text-gray-200': isInactive })}>
                              {isInactive && <FontAwesomeIcon icon="ban" className="mr-2 text-danger" />}
                              {`${cellData} ${rowData.get('lastName')}`}
                            </span>
                          </div>
                        </Link>
                      );
                    })}
                  />
                  <Column
                    label="Job"
                    dataKey="jobs"
                    width={(width - WIDTHS) / 2}
                    cellRenderer={renderCell(({ cellData }) => {
                      const [job] = cellData;
                      return (
                        <Link to={navigate.to.JobDetails({ jobId: job.get('_id') }, false)}>
                          <div className="text-truncate">{job.get('name')}</div>
                        </Link>
                      );
                    })}
                  />
                  <Column
                    label="LOT #"
                    dataKey="jobs"
                    width={COL_WIDTHS.lot}
                    cellRenderer={renderCell(({ cellData }) => {
                      const [job] = cellData;
                      const lotNumber = job.get('lotNumber');
                      return (
                        <span className={cn('font-size-16', { 'text-gray-200': !lotNumber })}>
                          {lotNumber || 'Not Set'}
                        </span>
                      );
                    })}
                  />
                  <Column
                    label="Community"
                    dataKey="jobs"
                    width={COL_WIDTHS.community}
                    cellRenderer={renderCell(({ cellData }) => {
                      const [job] = cellData;
                      return (
                        <Link to={navigate.to.CommunityDetails({ communityId: job.get('communityId') }, false)}>
                          <div className="text-truncate">{job.get('communityName')}</div>
                        </Link>
                      );
                    })}
                  />
                  <Column
                    label="Builder Assignee"
                    dataKey="builderAssignee"
                    width={COL_WIDTHS.assignee}
                    cellRenderer={renderCell(({ cellData, rowData, rowIndex }) => {
                      return (
                        <AssigneeSelect
                          index={rowIndex}
                          currentUser={cellData}
                          users={appState.getIn(['account', 'users'])}
                          options={appState.getIn(['account', 'usersActive'])}
                          loggedUserId={appState.getIn(['user', '_id'])}
                          onChange={assignee => controller.assignBuilder(assignee, rowData)}
                        />
                      );
                    })}
                  />
                  <Column
                    label="Status"
                    dataKey="status"
                    width={COL_WIDTHS.status}
                    cellRenderer={renderCell(({ cellData, rowData, rowIndex }) => {
                      const isSending = rowData.get('sendingInvite');
                      if (cellData === 'invitation-sent')
                        return (
                          <button
                            className="btn btn-link font-size-14 p-0 m-0"
                            disabled={isSending}
                            onClick={() => controller.resendInvite(rowData, rowIndex)}
                          >
                            {isSending && (
                              <span
                                className="spinner-border spinner-border-sm mb-1 mr-1"
                                role="status"
                                aria-hidden="true"
                              />
                            )}
                            {isSending ? 'Sending...' : 'Resend Invite'}
                          </button>
                        );
                      return (
                        <span>
                          {cellData === 'active' && 'Active'}
                          {cellData === 'inactive' && 'Deactivated'}
                          {cellData === 'deactivated' && 'Deactivated'}
                          {cellData === 'invitation-accepted' && 'Invite Accepted'}
                          {cellData === 'profile-created' && 'Profile Created'}
                        </span>
                      );
                    })}
                  />

                  <Column width={COL_WIDTHS.span} label="" dataKey="" />
                </Table>
              )}
            </AutoSizer>
          </div>
        )}
      </InfiniteLoader>
    </>
  );
}
