import React from 'react';
import { Form } from 'react-bootstrap';
import { FilterRow } from '../Components/Filter.row';
import { FilterTypeahead } from '../Components/Filter.typeahead';
import { FilterTaskStatus } from '../Components/Filter.task.status';
import { AdvancedFilter } from '../Components/Filter.advanced';

export const JobFilter = ({
  children,
  filterContext,
  lookups = {},
  showAdvanced = false,
  // ...props
}) => {
  const { filterState, filterController } = filterContext;
  const clearFilter = () => {
    filterController.defer.reset();
  };
  const onCheckChange = e => {
    const { target } = e;
    filterController.defer.updateFieldValue(target.name, target.checked);
  };

  const { taskTypes, assignees } = lookups;
  const hasAssignees = assignees?.size > 0;
  const { values, isFiltering } = filterState.toObject();
  const Typeahead = FilterTypeahead(filterContext);

  return (
    <div className="d-flex settings-container flex-column justify-content-start">
      <div className="d-flex justify-content-between">
        <h5 className="mb-4 font-weight-bold" style={{ width: '250px' }}>
          Job Filters
        </h5>
        {isFiltering ? (
          <button className="btn btn-link text-decoration-none" onClick={clearFilter}>
            Clear all filters
          </button>
        ) : null}
      </div>
      {children}
      <FilterRow>
        <span className=" font-weight-bold">My Tasks Only</span>
        <Form.Check
          className="mr-1"
          id="job-my-tasks-only"
          type="switch"
          label=""
          name="myTasks"
          checked={!!values.get('myTasks')}
          onChange={onCheckChange}
        />
      </FilterRow>
      <Typeahead name="taskNames" field="name" options={taskTypes} text="Task Type" />
      {hasAssignees && (
        <Typeahead
          id="account-assignee"
          field="subAccountId"
          name="assigneeAccounts"
          options={assignees}
          text="Account Assignee"
        />
      )}
      <FilterTaskStatus filterContext={filterContext} text="Status" />
      {showAdvanced ? <AdvancedFilter filterContext={filterContext} /> : null}
    </div>
  );
};
