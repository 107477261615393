import React from 'react';
import { Item, Name, dateField } from './common';
import { WeatherIndicator } from '../WeatherIndicator';

export default ({ item, account, user, isInactive }) => {
  return (
    <Item item={item} user={user}>
      <Name item={item} account={account} user={user} isInactive={isInactive} />
      {` proposed a Start Date Confirmation for ${dateField(item, 'newStartDate')}`}
      <WeatherIndicator item={item} />
    </Item>
  );
};
