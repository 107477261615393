import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from '../../index';

const UnstyledWarning = ({ show, className }) => {
  if (!show) return null;
  return (
    <span className={className}>
      <FontAwesomeIcon icon="exclamation" className="mx-auto" />
    </span>
  );
};

const Warning = styled(UnstyledWarning)`
  background-color: ${colors.yellow100};
  display: inline-block;
  font-size: 8px;
  border-radius: 50%;
  color: white;
  width: 13px;
  height: 12px;
  margin-left: 8px;
  position: absolute;
  left: -30px;
  top: 10px;

  @media (min-width: 1200px) {
    margin-right: 8px;
  }
`;

const PrecedingStyle = styled.div`
  width: 130px;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding-bottom: 10;
  @media (min-width: 1200px) {
    border-left: solid 1px ${colors.gray};
    margin-left: 0;
    flex-direction: column;
  }

  @media (min-width: 1400px) {
    border-left-width: 0px;
    flex-direction: row;
  }
  @media (min-width: 1700px) {
    border-left-width: 1px;
    height: 80px;
    margin-left: 0;
    flex-direction: column;
  }

  &:hover {
    background-color: ${colors.activeState};
    border-radius: 0px 8px 8px 0px;
  }

  .label-status {
    &.ready-to-start {
      color: ${colors.green300};
    }

    &.almost-ready-to-start {
      color: ${colors.gray200};
    }

    &.not-ready-to-start {
      color: ${colors.red};
    }

    &.not-ctr {
      color: ${colors.gray400};
    }
  }

  .badge {
    color: ${colors.white};
    font-size: 12px;
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
    padding: 0.5rem;
    border-radius: 0.5rem;
    border-style: none;

    &.ready-to-start {
      background-color: ${colors.green300};
    }

    &.almost-ready-to-start {
      background-color: ${colors.gray200};
    }

    &.not-ready-to-start {
      background-color: ${colors.red};
    }

    &.not-ctr {
      background-color: ${colors.gray400};
    }
  }
`;

const PRECEDING_STATUS_MAP = {
  'ready-to-start': ['Ready', 'to Start'],
  'almost-ready-to-start': ['Almost Ready', 'to Start'],
  'not-ready-to-start': ['Not Ready', 'to Start'],
  'not-ctr': ['No Definition', 'of Ready'],
};

export const Preceding = ({ task, showPrecedingTasks }) => {
  const ctr = task.get('ctr');
  const ctrStatus = ctr.get('status');
  const inJobPrecedingTasksNumber = ctr.get('inJobPrecedingTasksNumber');
  const resolvedPrecedingTasksNumber = ctr.get('resolvedPrecedingTasksNumber');
  const [line1, line2] = PRECEDING_STATUS_MAP[ctrStatus];
  const showWarning = ctr.get('precedingTasksNotOnJob');

  return (
    <PrecedingStyle onClick={() => showPrecedingTasks(task)} className={`d-flex  preceding ${ctrStatus}`}>
      <WarningContainer className="position-relative">
        <div className={`badge ${ctrStatus} mr-2`}>
          {resolvedPrecedingTasksNumber}/{inJobPrecedingTasksNumber}
        </div>
        <Warning show={showWarning} />
      </WarningContainer>
      <span className={`font-weight-bold label-status ${ctrStatus}`}>
        {line1}
        <br />
        {line2}
      </span>
    </PrecedingStyle>
  );
};

const WarningContainer = styled.div`
  @media (min-width: 1200px) {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }
`;
