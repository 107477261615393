import { CALENDAR_VIEW } from '@tradetrax/web-common/lib/CalendarView/CalendarContext';

const VIEWS = {
  STAGES: 'stages',
  LIST: 'list',
  GRID: 'grid', // TODO: Not sure about this one.
  CALENDAR: CALENDAR_VIEW,
};

export const DEFAULT_VIEW = { icon: 'bars-staggered', view: VIEWS.STAGES, label: 'Stages', id: 'stages-expanded' };
export const LIST_VIEW_MAP = { icon: 'list', view: VIEWS.LIST, label: 'List', id: 'list' };

export const VIEWS_MAP = [
  DEFAULT_VIEW,
  { icon: 'bars-staggered', view: VIEWS.STAGES, label: 'All expanded', radio: true, id: 'stages-expanded' },
  { icon: 'bars-staggered', view: VIEWS.STAGES, label: 'All collapsed', radio: true, id: 'stages-collapsed' },
  { icon: 'calendar-days', view: VIEWS.CALENDAR, label: 'Calendar', id: 'calendar' },
  LIST_VIEW_MAP,
];

export const findView = id => {
  return VIEWS_MAP.find(view => view.id === id) || DEFAULT_VIEW;
};
