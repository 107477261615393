import React from 'react';
import { Item, Name, dateField, isBuilder } from './common';
import { WeatherIndicator } from '../WeatherIndicator';

export default ({ item, account, user, isInactive }) => {
  const accepted = item.getIn(['data', 'accept']);

  return (
    <Item item={item} user={user}>
      <Name item={item} account={account} user={user} isInactive={isInactive} />
      {accepted ? (
        <>
          {` ${isBuilder(account) ? 'proposed' : 'accepted'} exp. finish to 
           ${dateField(item, 'newEndDate')}
          `}
        </>
      ) : (
        <>{` declined exp. finish to ${dateField(item, 'newEndDate')}`}</>
      )}
      <WeatherIndicator item={item} />
    </Item>
  );
};

module.exports.default.visibility = ({ user, item }) => {
  return item.getIn(['data', 'accept']) || item.get('assigneeAccountId') === user.get('accountId');
};
