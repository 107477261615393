import styled from 'styled-components';
import { colors } from '@tradetrax/web-common';

export const UL = styled.ul`
  min-width: 100%;
  margin-top: 0.25rem;
  list-style: none;
  padding: 0;

  li {
    border-top: solid 1px ${colors.gray100};
    &.task-card {
      padding: 1rem 1rem 1.5rem 1rem;

      .gtl-typeahead input {
        min-width: 325px;
      }
    }

    div {
      display: flex;
      flex-direction: row;
    }

    &.expanded {
      background-color: ${colors.activeState};
      flex-direction: column;
      margin-bottom: 1px;
      border-radius: 6px;
    }
  }
`;
