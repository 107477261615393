import { Filters } from './CycleTime.filters';
import { AverageCycleTimeOfJobsReport } from './CycleTime';
import { AverageCycleTimeOfJobsBySuperAssigneeReport } from './CycleTime.SuperAssignee';

export const AverageCycleTimeOfJobs = {
  Filters,
  filter: [],
  _id: 'average_cycle_time_of_jobs',
  name: 'Average Cycle Time for Jobs',
  Report: AverageCycleTimeOfJobsReport,
  apply: controller => controller.loadTaskCycleTimeReport(),
};

export const AverageCycleTimeOfJobsBySuperAsignee = {
  Filters,
  filter: [],
  _id: 'average_cycle_time_of_jobs_by_super_assignee',
  name: 'Average Cycle Time: Builder Assignee',
  Report: AverageCycleTimeOfJobsBySuperAssigneeReport,
  apply: controller => controller.loadTaskCycleTimeBySuperAssigneeReport(),
};
