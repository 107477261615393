import React from 'react';
import cn from 'classnames';
import * as yup from 'yup';
import styled from 'styled-components';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrimaryButton } from '../Button';
import { NullMuted } from './UserProfileContainer';
import { UserStatusCard } from '../UserStatusCard';
import { CardContentMessage } from './UserProfileDetails';

const emptyStringToNull = (value, originalValue) =>
  typeof originalValue === 'string' && originalValue === '' ? null : value;

const profileSchema = yup.object().shape({
  firstName: yup
    .string()
    .required('First Name is required')
    .matches(/^[^+÷=<>≠/!?#$%{}[\]0-9]*$/, 'Character not allowed')
    .min(1, 'Min. 1 character')
    .max(30, 'Max. 30 characters'),
  lastName: yup
    .string()
    .required('Last Name is required')
    .matches(/^[^+÷=<>≠/!?#$%{}[\]0-9]*$/, 'Character not allowed')
    .min(1, 'Min. 1 character')
    .max(30, 'Max. 30 characters'),
  phone: yup
    .string()
    .matches(/^\+?[1-9]\d{9,14}$/, 'Invalid phone format')
    .transform(emptyStringToNull)
    .nullable(),
  email: yup.string().when('role', {
    is: 'installer',
    then: yup
      .string()
      .email('Invalid email address')
      .transform(emptyStringToNull)
      .nullable(),
    otherwise: yup.string().email('Invalid email address'),
  }),
});

const emailAndPhoneValidations = async ({
  field,
  tvalue,
  lastValues,
  getValues,
  setValue,
  setError,
  trigger,
  isInstaller,
}) => {
  const { email, phone } = getValues();
  const isEmail = field === 'email';
  const isPhone = field === 'phone';
  if ((isEmail || isPhone) && !tvalue) {
    const optionalPhoneToTrades = !isInstaller && isPhone;
    if (!optionalPhoneToTrades) {
      const fieldCapitalized = field.charAt(0).toUpperCase() + field.slice(1);
      setError(field, {
        type: 'manual',
        message: `${fieldCapitalized} is recommended to send instructions to reset password`,
      });
      const isValid = await trigger(isEmail ? 'phone' : 'email');
      if (!isValid || (!email && !phone)) setValue(field, lastValues[field]);
    }
  }
  return {
    isEmailOrPhone: isEmail || isPhone,
    emailPhoneValue: email || phone,
  };
};

export const UserProfileForm = ({ user, controller, alert, loggedInUserRole }) => {
  const {
    register,
    errors,
    getValues,
    setValue,
    trigger,
    setError,
    formState: { dirtyFields },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(profileSchema),
    defaultValues: {
      firstName: user.get('firstName'),
      lastName: user.get('lastName'),
      email: user.get('email') || '',
      phone: user.get('phone') || '',
      role: user.get('role') || '',
    },
  });
  const [lastValues, setLastValues] = React.useState({
    email: user.get('email') || '',
    phone: user.get('phone') || '',
  });
  const handleError = err => {
    if (err.params) {
      err.params.forEach(error => setError(error.param, { type: 'manual', message: err.detail }));
    } else {
      const message =
        err.type === 'bad-request-generic'
          ? 'Email and phone can not be empty at the same time. Please specify at least one communication channel for this user'
          : err.detail
          ? err.detail
          : 'There was a problem updating this user. Please try again.';
      alert.error({ message });
    }
  };
  const onBlur = async (e, isInstaller) => {
    const field = e.target.name;
    const tvalue = e.target.value;
    const isValid = await trigger(field);
    const value = getValues(field);
    const { isEmailOrPhone, emailPhoneValue } = emailAndPhoneValidations({
      field,
      tvalue,
      lastValues,
      getValues,
      setValue,
      setError,
      trigger,
      isInstaller,
    });
    if (isValid) {
      if (isEmailOrPhone && emailPhoneValue) setLastValues({ ...lastValues, [field]: value });
      controller.updateUser(user, { [field]: value || null }).catch(handleError);
    }
  };

  const isInactive = user.get('status') === 'inactive';
  const isInviteSent = user?.get('status') === 'invitation-sent';
  const isInviteAccepted = user?.get('status') === 'invitation-accepted';

  const isInstaller = user.get('domain') === 'installer';
  const email = user.get('email');
  const hasEmail = !!getValues('email');
  const hasPhone = !!getValues('phone');
  const buttonsDisabled = isInactive || isInviteSent || isInviteAccepted;
  const isSendPasswordDisabled = buttonsDisabled || (!email && !user.get('phone'));

  return (
    <div className="d-flex flex-column ml-4 mt-4 pt-2" style={{ width: '440px' }}>
      <div className="d-flex flex-row justify-content-between">
        <div className="d-flex flex-column w-50 pr-2">
          <Form.Group controlId="firstName" className="mb-4">
            <span className="font-size-14">First Name</span>
            <Form.Control
              type="text"
              size="lg"
              name="firstName"
              isInvalid={errors.firstName}
              isValid={!errors.firstName && dirtyFields.firstName}
              ref={register}
              onBlur={onBlur}
              disabled={isInactive}
            />
            <Form.Control.Feedback type="invalid">
              <FontAwesomeIcon icon="circle-exclamation" /> {errors.firstName?.message}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid">
              <FontAwesomeIcon icon="circle-check" /> First Name is valid
            </Form.Control.Feedback>
          </Form.Group>
        </div>
        <div className="d-flex flex-column w-50 pl-2">
          <Form.Group controlId="lastName" className="mb-4">
            <span className="font-size-14">Last Name</span>
            <Form.Control
              type="text"
              size="lg"
              name="lastName"
              isInvalid={errors.lastName}
              isValid={!errors.lastName && dirtyFields.lastName}
              ref={register}
              onBlur={onBlur}
              disabled={isInactive}
            />
            <Form.Control.Feedback type="invalid">
              <FontAwesomeIcon icon="circle-exclamation" /> {errors.lastName?.message}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid">
              <FontAwesomeIcon icon="circle-check" /> Last Name is valid
            </Form.Control.Feedback>
          </Form.Group>
        </div>
      </div>
      <span className="font-size-14">Type of User</span>
      <UserRole user={user} />
      <input type="hidden" name="role" ref={register} />
      {isInstaller && <span className="font-size-14">Username</span>}
      {isInstaller && <span className="mb-4">{user.get('username') || 'Not Set'}</span>}
      <span className="font-size-14">Email</span>
      {!isInstaller && <NullMuted className="mb-3" value={email} />}
      {isInstaller && (
        <Form.Group className="mb-4" controlId="email">
          <Form.Control
            type="text"
            size="lg"
            name="email"
            isInvalid={errors.email}
            isValid={!errors.email && hasEmail && dirtyFields.email}
            ref={register}
            onBlur={onBlur}
            placeholder="Ex. username@email.com"
            disabled={isInactive}
          />
          <span className="text-secondary font-size-14">*Recommended</span>
          <Form.Control.Feedback type="invalid">
            <FontAwesomeIcon icon="circle-exclamation" /> {errors.email?.message}
          </Form.Control.Feedback>
          <Form.Control.Feedback type="valid">
            <FontAwesomeIcon icon="circle-check" /> Email is valid
          </Form.Control.Feedback>
        </Form.Group>
      )}
      <Form.Group className="mb-4" controlId="phone">
        <span className="font-size-14">Phone</span>
        <Form.Control
          type="text"
          size="lg"
          name="phone"
          isInvalid={errors.phone}
          isValid={hasPhone && !errors.phone && dirtyFields.phone}
          ref={register}
          onBlur={e => onBlur(e, isInstaller)}
          placeholder="Ex. 602 555 7890"
          disabled={isInactive}
        />
        <span className="text-secondary font-size-14">{`*${isInstaller ? 'Recommended' : 'Optional'}`}</span>
        <Form.Control.Feedback type="invalid">
          <FontAwesomeIcon icon="circle-exclamation" /> {errors.phone?.message}
        </Form.Control.Feedback>
        <Form.Control.Feedback type="valid">
          <FontAwesomeIcon icon="circle-check" /> Phone number is valid
        </Form.Control.Feedback>
      </Form.Group>
      <UserStatusCardContainer>
        <UserStatusCard user={user}>
          <CardContentMessage user={user} controller={controller} />
        </UserStatusCard>
      </UserStatusCardContainer>
      <div className="d-flex flex-row mt-4 pt-2 mr-n5 mb-1">
        {isInstaller && (
          <PrimaryButton
            className="mr-3 text-nowrap"
            disabled={buttonsDisabled}
            variant="secondary"
            onClick={() => controller.resetPassword(user)}
          >
            Reset Password
          </PrimaryButton>
        )}
        <PrimaryButton
          className="text-nowrap"
          disabled={isSendPasswordDisabled}
          onClick={() => controller.sendPasswordReset(user)}
        >
          Send Password Reset
        </PrimaryButton>
      </div>
      {buttonsDisabled && (
        <span className="text-muted font-size-14">* Password can be reset when user becomes active.</span>
      )}

      {loggedInUserRole === 'regular' && user.get('role') === 'manager' ? null : (
        <>
          <hr className="border-muted w-100" />
          <button
            className={cn('my-1 btn btn-link mr-auto pl-0', {
              'text-danger': !isInactive,
              'text-success': isInactive,
            })}
            onClick={() => controller.toggleActive(user)}
          >
            <FontAwesomeIcon icon={isInactive ? 'circle-plus' : 'ban'} /> {`${isInactive ? 'Rea' : 'Dea'}ctivate User`}
          </button>
        </>
      )}
    </div>
  );
};

const UserRole = ({ user }) => {
  const { role, domain } = user.toObject();
  return (
    <span className="mb-4 text-capitalize">
      {role === 'manager' ? 'Account Manager' : domain === 'installer' ? 'Installer' : 'Regular'}
    </span>
  );
};

const UserStatusCardContainer = styled.div`
  display: block;
  @media (min-width: 1200px) {
    display: none;
  }
`;
