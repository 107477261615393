import styled from 'styled-components';
import { Form } from 'react-bootstrap';

export const FormRadio = styled(Form.Check)`
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-top: 28px;
  .form-check-label {
    margin-top: 4px;
  }
`;

FormRadio.displayName = 'Filter.FormRadio';
