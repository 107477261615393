import React from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import { IconButton } from '../../Button';
import { childrenWithProps } from './Filter.shared';

export const ActiveFilterIcon = styled.small`
  width: 10px;
  height: 10px;
  top: -2px;
  left: 28px;
  position: absolute;
`;

export const FilterTrigger = ({
  isFiltering = false,
  iconCustomClass = {},
  disabled = false,
  className,
  children,
  ...props
}) => {
  const [visible, setVisible] = React.useState(false);
  const onToggle = () => setVisible(!visible);

  return (
    <div className="position-relative">
      {visible ? childrenWithProps(children, { setVisible, ...props }) : null}
      <IconButton
        data-testid="icon-filter"
        icon="filter"
        disabled={disabled}
        onClick={onToggle}
        className={cn('position-relative', className, {
          active: isFiltering,
          filter_white: props.backGroundWhite,
          filterSet: isFiltering,
          ...iconCustomClass,
        })}
      >
        {isFiltering ? <ActiveFilterIcon className="badge-danger rounded-pill" /> : null}
      </IconButton>
    </div>
  );
};
