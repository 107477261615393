import React from 'react';
import { fromJS } from 'immutable';
import { useController } from '@tradetrax/web-common';
import { useChannel, CHANNELS } from '@tradetrax/web-common/lib/useRealTime';
import { useFilterContext, FILTER_NAME } from '@tradetrax/web-common/lib/Filters/Filters.context';
import { LIST_VIEW, CALENDAR_VIEW } from '@tradetrax/web-common/lib/CalendarView/CalendarContext';
import { buildersService as openTasksService } from 'services';
import * as actions from './TasksPageActions';

const emptyTasks = fromJS({
  tasks: [],
  totalCount: 10,
  maxCount: 10,
});

const emptyState = fromJS({
  open: emptyTasks,
  completed: emptyTasks,
  view: LIST_VIEW,
});

export const MINIMUM_BATCH_SIZE = 25;

export function TasksPageContext(defaultTab, appContext) {
  const filterContext = useFilterContext(FILTER_NAME.OPEN_TASKS);
  const { filterState } = filterContext;
  const loaderRef = React.useRef();
  const currentURL = new URL(document.URL);
  const initView = currentURL.hash === '#calendar' ? CALENDAR_VIEW : LIST_VIEW;
  const [state, controller] = useController(actions, emptyState.set('tab', defaultTab).set('view', initView), {
    loaderRef,
    ...appContext,
    openTasksService,
    filterState,
  });
  const account = appContext.appState.get('account');
  const tab = state.get('tab');
  const filter = filterState.get('values');

  React.useEffect(() => {
    controller.invalidateFilter();
  }, [filter, controller]);

  React.useEffect(() => {
    const kind = tab === 'open' ? 'completed' : 'open';
    controller.dispatch([state => state.set(kind, emptyTasks)]);
  }, [tab, controller]);

  useChannel(CHANNELS.ACCOUNT_TASK, `${account.get('_id')}`, controller.updateTaskRealTime);

  return { state, controller, account, loaderRef, openTasksService, filterContext };
}
