import React from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import { IconButton } from '../Button';
import { colors } from '../theme';
import { Form } from 'react-bootstrap';

// TODO: depreacte this file.
export const JOB_SCHEDULE_FILTER = 'JOB_SCHEDULE_FILTER';
export const RECENT_ACTIVITY_FILTER = 'RECENT_ACTIVITY_FILTER';
export const USERS_FILTER = 'USERS_FILTER';
export const TEMPLATES_FILTER = 'TEMPLATES_FILTER';
export const INTAKE_FILTER = 'INTAKE_FILTER';
export const HOMEOWNERS_FILTER = 'HOMEOWNERS_FILTER';

export const ActiveFilterIcon = styled.small`
  width: 10px;
  height: 10px;
  top: -2px;
  left: 28px;
  position: absolute;
`;

export const FormRadio = styled(Form.Check)`
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-top: 28px;
  .form-check-label {
    margin-top: 4px;
  }
`;

export const FilterSwitch = ({ text, children }) => (
  <div className="d-flex justify-content-between py-4 border-top">
    <span className="font-weight-bold">{text}</span>
    {children}
  </div>
);

const Wrapper = styled.div`
  ${props => `
    transition: right 600ms ease;
    mix-blend-mode: normal;
    z-index:1000;

    &.hidden {
      pointer-events: none;
    }

    .settings-container {
      background: ${colors.pearl};
      border-radius: 10px;
      padding: 1.75rem;
      border-top: solid 10px ${colors.blue300};
      position: absolute;
      top: 5px;
      right:0;
      box-shadow: 0px 1px 2px rgb(51 51 51 / 20%);
      @media (max-width: 1670px) {
        right:${props.floatToRight ? '-410px' : '0px'};
      }

      &.hidden {
        display: none;
      }

      .rbt ul.dropdown-menu {
        max-height: 175px !important;
      }
    }

    &.size-sm {
      position: absolute;
      right: 0;
      top: 44px;

      .settings-container { 
        width: 450px;
      }

      .status-section {
        padding: 0.5rem 0 0 1.5rem;
        flex-wrap: wrap;
        div {
          width: 170px;
        }
      
      }
    }

    &.size-lg {
      position: absolute;
      right: 0;
      top: 44px;


      .settings-container {
        overflow: scroll;
        max-height: 100%;
        min-height: 535px;
        @media (min-width: 1020px) {
          width: 700px;
        }
      }

      .status-section {
        justify-content: space-between;
        margin-right: 4rem;
        margin-bottom: 2rem;
      }

      .form-control.size-sm {
        width: 72px;
        padding-right: 0.5rem !important;
      }
    }

    .secondary-title {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 0.5rem;
    }
  `}
`;

export const Filter = ({
  isFilterActive,
  filter,
  iconCustomClass,
  controller,
  children,
  formContext,
  isFilterSet,
  insideFilter = ['icon-filter'],
  className = 'size-sm',
  ...props
}) => {
  const onToggle = props.toggleFilter || controller.toggleFilter;
  const showRedDot = isFilterSet && !props.disabled;

  React.useEffect(() => {
    formContext.reset(filter?.toJS());
  }, [filter]);

  React.useEffect(() => {
    const isInsideFilter = node => {
      if (!node) return false;
      if (insideFilter.includes(node.id)) return true;
      if (node.classList.contains('settings-container')) return true;
      if (node.classList.contains('rbt-close')) return true;
      if (node.classList.contains('rbt-menu-pagination-option')) return true;
      if (node.classList.contains('modal')) return true;
      if (node.classList.contains('react-datepicker__month-container')) return true;
      return isInsideFilter(node.parentElement);
    };

    const onClick = e => {
      if (isFilterActive) {
        if (isInsideFilter(e.target)) return;
        e.preventDefault();
        e.stopPropagation();
        onToggle();
      }
    };

    global.document.addEventListener('click', onClick);
    return () => {
      global.document.removeEventListener('click', onClick);
    };
  }, [isFilterActive]);

  return (
    <div className="position-relative ml-3">
      {isFilterActive ? (
        <Wrapper id="tasks-filter" className={className}>
          {children}
        </Wrapper>
      ) : null}
      <IconButton
        id="icon-filter"
        icon="filter"
        className={cn(`position-relative`, {
          active: isFilterActive,
          filter_white: props.backGroundWhite,
          filterSet: isFilterSet,
          ...iconCustomClass,
        })}
        onClick={() => onToggle()}
        disabled={props.disabled}
      >
        {showRedDot ? <ActiveFilterIcon className="badge-danger rounded-pill" /> : null}
      </IconButton>
    </div>
  );
};
