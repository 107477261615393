import React from 'react';
import cn from 'classnames';
import { FilterTypeahead } from '../Components/Filter.typeahead';
import { FormRadio } from '../Components/Filter.form.radio';
import { RadioBox } from '../Components/Filter.radio.box';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const STATUS_TEXT = {
  all: 'All',
  active: 'Active',
  pending: 'Pending Invite',
  deactivated: 'Deactivated',
};

export const HomeOwnersFilter = ({ filterContext, lookups = {} }) => {
  const [isCollapsed, setIsCollapsed] = React.useState(true);
  const { filterState, filterController } = filterContext;
  const clearFilter = () => {
    setTimeout(() => {
      filterController.reset();
    }, 1);
  };

  const { builderAssignees, communities } = lookups;
  const { values, isFiltering } = filterState.toObject();
  const Typeahead = FilterTypeahead(filterContext);
  const status = values.get('status');

  return (
    <div className="d-flex settings-container flex-column justify-content-start">
      <div className="d-flex justify-content-between">
        <h5 className="mb-4 font-weight-bold" style={{ width: '250px' }}>
          Homeowners Filters
        </h5>
        {isFiltering ? (
          <button className="btn btn-link text-decoration-none" onClick={clearFilter}>
            Clear all filters
          </button>
        ) : null}
      </div>
      <Typeahead name="assigneeAccounts" field="_id" options={builderAssignees} text="Builder Assignee" />
      <Typeahead name="communityIds" field="_id" options={communities} text="Communities" />
      <div className="d-flex flex-column border-top pt-4">
        <div className="d-flex justify-content-between">
          <span className="font-weight-bold">Status</span>
          <span>
            {STATUS_TEXT[status]}
            <FontAwesomeIcon
              className="mr-3 ml-4 cursor-pointer"
              icon={`chevron-${isCollapsed ? 'down' : 'up'}`}
              onClick={() => setIsCollapsed(!isCollapsed)}
            />
          </span>
        </div>
        <div className={cn({ 'd-none': isCollapsed })}>
          <RadioBox filterContext={filterContext} suffix="HomeOwners">
            <div className="d-flex flex-row">
              <FormRadio label="All" value="all" className="w-25" />
              <FormRadio label="Pending Invite" value="pending" className="ml-5" />
            </div>
            <div className="d-flex flex-row">
              <FormRadio label="Active" value="active" className="w-25" />
              <FormRadio label="Deactivated" value="deactivated" className="ml-5" />
            </div>
          </RadioBox>
        </div>
      </div>
    </div>
  );
};
