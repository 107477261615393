import React from 'react';
import { AddNewMilestone } from './AddNewMilestone';
import { HomeownerJobProgressContext } from './HomeownerJobProgressContext';
import { MilestonesCards } from './MilestonesCards';
import { IncompleteMilestones } from './IncompleteMilestones';
import { LoadingPlaceHolder } from './LoadingPlaceHolder';

export const HomeownerJobProgress = () => {
  const { state, controller } = HomeownerJobProgressContext();
  const { isLoading } = state.toObject();

  return (
    <div className="d-flex flex-column" style={{ maxWidth: '700px' }}>
      <p className="font-size-14 pr-2" style={{ maxWidth: '700px' }}>
        Configure the start and finish of Milestones in Jobs, so the Homeowner know the overall progress. This
        configuration will be applied to new Jobs. You can update the criteria later on each Homeowner's Job details.
      </p>
      <AddNewMilestone controller={controller} state={state} />
      {isLoading ? (
        <LoadingPlaceHolder />
      ) : (
        <>
          <IncompleteMilestones state={state} />
          <MilestonesCards controller={controller} state={state} />
        </>
      )}
    </div>
  );
};
