import React from 'react';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const popover = (
  <Popover className="trx-popover">
    <Popover.Content>
      Pre-Construction Task. It will
      <br />
      not affect Job Exp. Start date.
    </Popover.Content>
  </Popover>
);

export const PreConstructionIcon = ({ task, isPreConstruction }) => {
  if (task) {
    isPreConstruction = task.get('isPreConstruction');
  }

  if (isPreConstruction !== true) return null;

  return (
    <OverlayTrigger placement="bottom" overlay={popover} open>
      <FontAwesomeIcon icon="helmet-safety" className="text-secondary ml-2 small" />
    </OverlayTrigger>
  );
};
