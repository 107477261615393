import styled from 'styled-components';
import { colors } from '../../theme';

export const Section = styled.div`
  ${props => `
    background: ${props.blue ? colors.activeState : colors.white};
    /* Gray */
    border: 1px solid ${colors.gray100};
    box-sizing: border-box;
    /* Dropdown-shadow */
    box-shadow: 0px 1px 2px rgba(51, 51, 51, 0.2);
    border-radius: 10px;
    padding: 1.5rem;

    .section-title {
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      color: ${colors.gray800};
    }

    .toggle-filter {
      position: absolute;
      right: 24px;
    }
`}
`;
