import React from 'react';
import { fromJS } from 'immutable';
import { useController } from '../useController';
import { useAppContext } from '../globalAppContext';
import { useEvents } from '../useEvents';

export const BulkContext = React.createContext(null);
export const useBulkContext = () => React.useContext(BulkContext);

const emptyState = fromJS({
  isManaging: false,
  selectedTasks: [],
});

export function BulkActionsContext(actions, bulkService, parentContext) {
  const events = useEvents();
  const appContext = useAppContext();
  const context = { ...appContext, bulkService, events, parentContext };
  const [state, controller] = useController(actions, emptyState, context);

  return { state, controller };
}
