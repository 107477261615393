import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { colors } from '@tradetrax/web-common';
import { StageCard } from './StageCard';

export const StageCards = ({ controller, state }) => {
  const stages = state.getIn(['template', 'stages']);
  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    controller.Reorder(result.draggableId, result.source.index, result.destination.index);
  };

  if (stages.size === 0) return null;

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
              {stages.map((stage, index) => (
                <Draggable
                  key={stage.get('_id')}
                  draggableId={stage.get('_id')}
                  index={index}
                  isDragDisabled={stages.size === 1}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                    >
                      <StageCard
                        key={stage.get('_id')}
                        stages={stages}
                        stage={stage}
                        index={index}
                        setStageStartTask={controller.setStageStartTask}
                        setStageFinishTask={controller.setStageFinishTask}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

const grid = 0;

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  // background: isDragging ? 'transparent' : '',
  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? colors.pearl : '',
  padding: grid,
});
