import moment from 'moment';
import { TASK_STATUS, mongoToMoment } from '../utils';

const ISO = 'YYYY-MM-DD';
global.moment = moment;

export function jobTaskFilter(filterState, collection, userId) {
  if (!filterState.get('isFiltering')) return collection;

  const {
    myTasks,
    taskNames,
    assigneeAccounts,
    inProgressStatus,
    completedStatus,
    notStartedStatus,
    notReadyStatus,
    dateConfirmation,
    criticalTasks,
    duration,
    startDate,
    finishDate,
  } = filterState.get('values').toObject();

  const statusFilterApplied = [inProgressStatus, completedStatus, notStartedStatus, notReadyStatus].filter(
    status => status
  ).length;
  // const filterByStatus = inProgressStatus || completedStatus || notStartedStatus || notReadyStatus;
  const filterByStatus = statusFilterApplied !== 0 && statusFilterApplied !== 4;
  const today = moment();

  const filterDates = (date, dateFilter, checkStatus) => {
    if (dateFilter.get('type') === 'any') return true;

    const taskDate = moment(date, ISO);

    if (dateFilter.get('type') === 'missed') {
      if (taskDate.isSameOrAfter(today, 'day')) return false;
      if (checkStatus) return false;
      // if (hasStarted(task) && taskDate.isSameOrAfter(moment(task.get('lateStartDate')), 'day')) return false;
    } else if (dateFilter.get('type') === 'equal') {
      if (!taskDate.isSame(mongoToMoment(dateFilter.get('dateEqual')), 'day')) return false;
    } else if (dateFilter.get('type') === 'from') {
      const dateFrom = mongoToMoment(dateFilter.get('dateFrom'));
      const dateTo = mongoToMoment(dateFilter.get('dateTo'));
      console.log(taskDate.format('DD-MM-YYYY'), dateFrom.format('DD-MM-YYYY'), dateTo.format('DD-MM-YYYY'));
      if (!taskDate.isBetween(dateFrom, dateTo, 'day', '[]')) return false;
    }
    return true;
  };

  return collection.filter(task => {
    // Important: Do not filter stage tasks!
    if (task.get('isStageTask')) return true;

    if (myTasks && !(task.getIn(['userScheduler', '_id']) === userId || task.getIn(['userSuper', '_id']) === userId))
      return false;

    if (taskNames.size && !taskNames.includes(task.get('name'))) return false;

    if (assigneeAccounts?.size && !assigneeAccounts.includes(task.getIn(['assigneeAccount', 'companyId'])))
      return false;

    if (criticalTasks !== 'all') {
      if (criticalTasks === 'critical-only' && !task.get('isCritical')) return false;
      if (criticalTasks === 'non-critical-only' && task.get('isCritical')) return false;
    }

    if (dateConfirmation !== 'all') {
      if (dateConfirmation === 'confirmed' && !task.get('startDateConfirmed')) return false;
      if (dateConfirmation === 'not-confirmed' && task.get('startDateConfirmed')) return false;
    }

    if (duration.get('type') !== 'any') {
      const durationDays = task.get('durationDays');
      if (duration.get('type') === 'equal' && durationDays !== duration.get('value')) return false;
      if (duration.get('type') === 'more' && durationDays <= duration.get('value')) return false;
      if (duration.get('type') === 'less' && durationDays >= duration.get('value')) return false;
    }

    if (!filterDates(task.get('startDate'), startDate, hasStarted(task))) return false;
    if (!filterDates(task.get('expectedFinishDate'), finishDate, isCompleted(task))) return false;

    if (filterByStatus) {
      const status = task.get('status');

      if (inProgressStatus && status === TASK_STATUS.IN_PROGRESS) return true;
      if (completedStatus && status === TASK_STATUS.COMPLETED) return true;
      if (notStartedStatus && status === TASK_STATUS.NOT_STARTED) return true;
      if (notReadyStatus && status === TASK_STATUS.NOT_READY_TO_START) return true;

      return false;
    }

    return true;
  });
}

const NO_YET_STARTED = [TASK_STATUS.NOT_STARTED, TASK_STATUS.NOT_READY_TO_START];
const STARTED = [TASK_STATUS.IN_PROGRESS, TASK_STATUS.COMPLETED];

const hasStarted = task => STARTED.includes(task.get('status'));
const isCompleted = task => task.get('status') === TASK_STATUS.COMPLETED;
// const notStarted = task => NO_YET_STARTED.includes(task.get('status'));
