import React from 'react';
import { Map } from 'immutable';
import { JobFeed as SBFeed } from '@tradetrax/web-common';
import navigate from 'app/navigate';

export const JobFeed = ({ jobFeed, loadMoreRows, jobFeedCount, loaderRef, account, user, tasks, appContext }) => {
  const linkToTask = React.useMemo(() => {
    const url = navigate.from.JobDetails.to.JobTaskDetails;
    const byId = tasks.reduce((memo, task) => memo.set(task.get('id'), task), Map());
    return ({ taskId }) => (byId.get(taskId) ? url({ taskId }, false) : null);
  }, [tasks]);

  return (
    <SBFeed
      jobFeed={jobFeed}
      loadMoreRows={loadMoreRows}
      loaderRef={loaderRef}
      jobFeedCount={jobFeedCount}
      linkToTask={linkToTask}
      appContext={appContext}
      account={account}
      user={user}
      emptyStateText="When a Job is started all schedule and status updates will appear here."
    />
  );
};
