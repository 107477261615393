import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, FormCheck } from 'react-bootstrap';
import { PrimaryButton } from '@tradetrax/web-common';

const RemoveStageModalUnstyled = ({ close, accept, className }) => {
  const [option, setOption] = React.useState('only-stage');

  return (
    <Modal centered show={true} onHide={close} dialogClassName={className}>
      <Modal.Header closeButton>
        <Modal.Title>
          <FontAwesomeIcon icon="circle-exclamation" className="text-danger" />
          Remove Stage
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>What do you want to remove</p>
        <FormCheck className="mb-2">
          <FormCheck.Input onChange={() => setOption('only-stage')} checked={option === 'only-stage'} type="radio" />
          <FormCheck.Label onClick={() => setOption('only-stage')}>Only the Stage</FormCheck.Label>
        </FormCheck>
        <FormCheck>
          <FormCheck.Input onChange={() => setOption('with-tasks')} checked={option === 'with-tasks'} type="radio" />
          <FormCheck.Label onClick={() => setOption('with-tasks')}>Stage and its Tasks</FormCheck.Label>
        </FormCheck>
      </Modal.Body>
      <Modal.Footer className="d-flex flex-row justify-content-end">
        <PrimaryButton className="mr-3" variant="secondary" onClick={() => close()}>
          Cancel
        </PrimaryButton>
        <PrimaryButton onClick={() => accept({ option })}>Remove</PrimaryButton>
      </Modal.Footer>
    </Modal>
  );
};

export const RemoveStageModal = styled(RemoveStageModalUnstyled)`
  max-width: 540px;
`;
