import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { Typeahead } from 'react-bootstrap-typeahead';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from '@tradetrax/web-common';

export const StageCard = ({ setStageStartTask, setStageFinishTask, stages, stage, index }) => {
  const prevStage = index === 0 ? null : stages.get(index - 1);
  const nextStage = stages.get(index + 1);
  const isValid = !!stage.get('endTaskId');
  const isStartValid = !!stage.get('startTaskId');
  const letter = getAlphabetByIndex(index);

  const tasks = React.useMemo(() => stage.get('tasks').toArray(), [stage]);
  const selected = React.useMemo(() => stage.get('tasks').find(task => task.get('id') === stage.get('endTaskId')), [
    stage,
  ]);
  const setSelectedFinish = ([task]) => setStageFinishTask(stage, task);

  const selectedStart = React.useMemo(
    () => stage.get('tasks').find(task => task.get('id') === stage.get('startTaskId')),
    [stage]
  );
  const setSelectedStart = ([task]) => setStageStartTask(stage, task);

  const StartKey = () => {
    const placeholder =
      tasks.length === 0 ? `No Tasks in ${stage.get('name')}` : `Choose Key Start Task from ${stage.get('name')}`;

    if (!prevStage) {
      return <small className="mt-3 mb-2 py-1 ml-3">It will start when the Job is updated to 'In Progress'.</small>;
    }

    return (
      <div className="d-flex flex-column">
        <div className="mt-3 ml-3 mb-2 mr-1 small">
          It will start when <strong>{prevStage.get('name')}</strong> finishes or...
        </div>
        <div className="d-flex flex-row">
          <small className="mt-2 ml-3">by the early start of</small>
          <div className="ml-2 mb-2 d-flex flex-column flex-fill">
            <Typeahead
              id={`start-key-task-${stage.get('_id')}`}
              placeholder={placeholder}
              labelKey={option => option.get('name')}
              options={tasks}
              onChange={setSelectedStart}
              selected={selectedStart ? [selectedStart] : []}
              multiple={false}
              disabled={tasks.length === 0}
              clearButton
            />
            {isStartValid ? (
              <small className="text-secondary">This Key Start Task will be marked with a blue flag.</small>
            ) : (
              <small className="text-muted">
                <FontAwesomeIcon className="text-danger mr-1" icon="circle-exclamation" />
                {tasks.length === 0 ? 'Go to the Stage and add at least 1 Task' : 'Missing Key Start Task.'}
              </small>
            )}
          </div>
          <Finishes hidden />
        </div>
      </div>
    );
  };
  const Finishes = ({ hidden = false }) => (
    <small style={hidden ? { visibility: 'hidden' } : {}} className="mt-2 ml-2 mr-5">
      finishes.
    </small>
  );

  const EndKey = () => {
    const placeholder =
      tasks.length === 0 ? `No Tasks in ${stage.get('name')}` : `Choose Key Finish Task from ${stage.get('name')}`;

    if (!nextStage) {
      return <small className="mt-2 ml-3">It will finish when the Job is updated to 'Completed'.</small>;
    }

    return (
      <div className="d-flex flex-row">
        <small className="mt-2 mr-1 ml-3">It will finish when</small>
        <div className="d-flex flex-column flex-fill">
          <Typeahead
            id="new-task-name"
            placeholder={placeholder}
            labelKey={option => option.get('name')}
            options={tasks}
            onChange={setSelectedFinish}
            selected={selected ? [selected] : []}
            multiple={false}
            disabled={tasks.length === 0}
            clearButton
          />
          {isValid ? (
            <small className="text-secondary">This Key Finish Task will be marked with an orange flag.</small>
          ) : (
            <small className="text-muted">
              <FontAwesomeIcon className="text-danger mr-1" icon="circle-exclamation" />
              {tasks.length === 0 ? 'Go to the Stage and add at least 1 Task' : 'Missing Key Finish Task.'}
            </small>
          )}
        </div>
        <Finishes />
      </div>
    );
  };

  return (
    <Card>
      <div>
        <FontAwesomeIcon
          icon="grip-lines-vertical"
          className={cn('mr-2 draggable-start', { 'd-none': stages.size === 1 })}
        />
        <span className="mr-2">{letter}</span>
        <span className="h6 font-weight-bold">{stage.get('name')}</span>
      </div>
      <StartKey />
      <EndKey />
      {/* <small className="mt-3 mb-2 py-1 ml-3">It will start when {prevStage.get('name')} finishes.</small> */}
    </Card>
  );
};

export const Card = styled.div`
  background: ${colors.activeState};
  box-sizing: border-box;
  border-radius: 0.375rem;
  margin-bottom: 0.5rem;
  padding: 1rem 1rem 1rem 1.5rem;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 178px;
  max-width: 660px;
`;

// TODO: refactor this function to support more stages
// then move to web-common.
const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');
function getAlphabetByIndex(index) {
  if (alphabet[index]) {
    return alphabet[index].toUpperCase();
  }
  // only works for a template with less than 53 stages
  return `A${getAlphabetByIndex(index - alphabet.length)}`;
}
