import React, { useState, useEffect } from 'react';
import { Form, FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import cn from 'classnames';
import * as yup from 'yup';

const EMPTY_ERRORS = {};

const inputValidation = yup
  .number()
  .typeError('#')
  .required()
  .min(1, '1')
  .max(999, '999');

export const useDuration = (controller, task) => {
  const [duration, setDuration] = useState(task.get('durationDays'));
  const [errors, setErrors] = useState(EMPTY_ERRORS);

  useEffect(() => {
    setDuration(task.get('durationDays'));
  }, [task]);

  const onBlur = () => {
    if (errors.duration) {
      setDuration(task.get('durationDays'));
      setErrors(EMPTY_ERRORS);
    } else {
      if (parseInt(duration) !== task.get('durationDays')) {
        controller.updateTaskDuration({ task, duration });
      }
    }
  };

  const onChange = async ({ target }) => {
    const { value } = target;
    const duration = value.replace(/[^0-9]/, '');

    if (target !== global.document.activeElement) target.focus();

    setDuration(duration);
    inputValidation
      .validate(value)
      .then(() => setErrors(EMPTY_ERRORS))
      .catch(response => setErrors({ duration: { type: response.type, message: response.message } }));
  };

  const Feedback = () => (
    <Form.Control.Feedback type="invalid" className={cn('position-absolute', { 'd-inline': !!errors.duration })}>
      <FontAwesomeIcon icon="circle-exclamation" />
      {errors.duration && errors.duration.type === 'min' && <FontAwesomeIcon icon={faArrowUp} />}
      {errors.duration && errors.duration.type === 'max' && <FontAwesomeIcon icon={faArrowDown} />}
      {errors.duration && ` ${errors.duration.message}`}
    </Form.Control.Feedback>
  );

  return { duration, errors, onBlur, onChange, Feedback };
};

export function TaskDurationInput({ task, controller }) {
  const { duration, errors, Feedback, ...handlers } = useDuration(controller, task);

  return (
    <>
      <FormControl
        className="field-editable edit-durationDays"
        style={{ maxWidth: '5rem' }}
        name={task.get('id')}
        type="number"
        value={duration}
        isInvalid={errors.duration}
        {...handlers}
      />
      <Feedback />
    </>
  );
}
