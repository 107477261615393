export const Home = '/';
export const DashboardV2 = `${Home}dashboard`;
export const RequestAndOverdueDetails = `${DashboardV2}/update-request-and-overdue`;

export const WorkloadPlanning = `${DashboardV2}/workload-planning`;

export const ToDo = `${Home}to-do`;
export const UpcomingTasks = `${Home}upcoming`;

export const Templates = `${Home}templates`;
export const TemplateDetails = `${Templates}/:templateId([a-f\\d]{24})`;
export const TemplateSettings = `${TemplateDetails}/settings`;

export const CustomReport = `${Home}custom-report`;

export const Community = `${Home}community`;
export const CommunityNew = `${Community}/new`;
export const CommunityDelete = `${Community}/:communityId/delete`;
export const CommunityDetails = `${Community}/:communityId([a-f\\d]{24})`;
export const CommunityOuttake = `${CommunityDetails}/outtake`;

export const Job = `${Home}jobs`;
export const JobNew = `${Job}/new`;
export const JobDelete = `${Job}/:jobId/delete`;
export const JobDetails = `${Job}/:jobId([a-f\\d]{24})`;
export const TaskAssign = `${JobDetails}/assign/:taskId`;
export const JobTaskDetails = `${JobDetails}/task/:taskId(\\d+)`;

export const Task = `${Home}tasks`;
export const TaskOpen = `${Home}task/open`; // this is to comply with mobile implementation, used for redirect.
export const TaskNew = `${Task}/new`;

export const Homeowners = `${Home}homeowners`;
export const HomeownerProfile = `${Homeowners}/profile/:userId`;
export const HomeownerDetails = `${HomeownerProfile}/details`;

export const Settings = `${Home}settings`;
export const SettingsTab = `${Home}settings/:tab?`;
export const SettingsCompany = `${Settings}/company`;
export const SettingsJobs = `${Settings}/jobs`;
export const SettingsStartDateConfirmations = `${Settings}/confirmations`;
export const SettingsHomeOwner = `${Settings}/homeowner`;
export const SettingsCustomRoles = `${Settings}/custom-roles`;
export const SettingsCustomRoleDetails = `${SettingsCustomRoles}/:customRoleId([a-f\\d]{24})/:customRoleTab?`;
export const SettingsCustomRoleNotifications = `${SettingsCustomRoles}/:customRoleId([a-f\\d]{24})/notifications`;
export const SettingsCustomRoleFilterSets = `${SettingsCustomRoles}/:customRoleId([a-f\\d]{24})/filter-sets`;
export const SettingsCustomRolePermissions = `${SettingsCustomRoles}/:customRoleId([a-f\\d]{24})/permisions`;

export const MyProfile = `${Home}my-profile`;
export const Users = `${Home}users`;
export const UserProfile = `${Users}/profile/:userId([a-f\\d]{24})`;

export const NotPermission = `${Home}not-permission`;
