import React from 'react';
import { List, fromJS } from 'immutable';
import { buildersService } from 'services';

export const useTaskAccountability = (task, templateId) => {
  const initialState = task ? List() : [false];
  const [accountability, setAccountability] = React.useState(initialState);

  React.useEffect(() => {
    if (task) {
      const taskIds = [task.get('_id')];
      buildersService
        .getRecommendedAccountabilities({}, { params: { templateId }, query: { taskIds } })
        .then(fromJS)
        .then(response => {
          if (response.size) setAccountability(response);
          else setAccountability([false]);
        });
    } else {
      setAccountability([false]);
    }
  }, [task, templateId]);

  return accountability;
};
