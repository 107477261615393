import React from 'react';
import throttle from 'lodash/throttle';
import styled from 'styled-components';
import { FilterSets } from './FilterSets';

const PADDING_LEFT_UL = 40;
const PADDING_RIGHT = 24;
const MARGIN = 16;
const SPACING = PADDING_LEFT_UL + PADDING_RIGHT + MARGIN;
const SPACE_BULKACTION_LINK = 160;

export function FilterSetsPanel({
  children,
  filterContext,
  visible,
  className = '',
  childrenClassName = 'd-flex flex-row',
  disabled = false,
  isUpcomingView = false,
  filter,
  isToDo = false,
}) {
  const panelRef = React.useRef();
  const childRef = React.useRef();
  const [maxWidth, setMaxWidth] = React.useState(100);
  // const [className, setClassName] = React.useState('');
  const { filterSets } = filterContext;
  const noFiltersClassName = filterSets.size === 0 ? 'mt-n5' : '';

  React.useEffect(() => {
    if (!panelRef.current || !visible) return;

    const panelElem = panelRef.current;
    const childElem = childRef.current;
    const onResize = throttle(() => {
      setMaxWidth(
        panelElem.clientWidth - childElem.clientWidth - SPACING - (isUpcomingView ? SPACE_BULKACTION_LINK : 0)
      );
    }, 200);

    global.addEventListener('resize', onResize);
    onResize();

    return () => {
      global.removeEventListener('resize', onResize);
    };
  }, [panelRef, childRef, visible]);

  if (!visible) return null;

  return (
    <FilterSetContainer filterSetsSize={filterSets.size} isToDo={isToDo}>
      <div ref={panelRef} className={`d-flex flex-row pr-4 ${noFiltersClassName} ${className}`}>
        <FilterSets context={filterContext} maxWidth={maxWidth} disabled={disabled} currectFilterApplied={filter} />
        <div className={childrenClassName} ref={childRef}>
          {children}
        </div>
      </div>
      {filterSets.size > 0 && <hr className="mx-3 my-0 p-0 mb-4" />}
    </FilterSetContainer>
  );
}

const FilterSetContainer = styled.div`
  @media (max-width: 1150px) {
    margin-top: ${props => (props.isToDo ? '0' : props.filterSetsSize > 0 ? 'auto' : '50px')};
  }
`;
