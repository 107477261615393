import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { Form } from 'react-bootstrap';

export const ValidationFeedback = ({ field, errors }) => {
  if (!errors || !errors.has(field)) return null;
  const error = errors.get(field);

  return (
    <Form.Control.Feedback type="invalid" className="position-absolute d-inline">
      <FontAwesomeIcon icon="circle-exclamation" className="mr-1" />
      {error.type === 'min' ? <FontAwesomeIcon icon={faArrowUp} /> : null}
      {error.type === 'max' ? <FontAwesomeIcon icon={faArrowDown} /> : null}
      {error.message}
    </Form.Control.Feedback>
  );
};
