import React, { useEffect, useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { useAppContext } from 'app/App.context';
import { ToggleButtons, ToggleButton, useCommunities, useTaskTypes } from '@tradetrax/web-common';
import { StartToday, FinishToday, UpdateToday, TodoSelectDate } from '@tradetrax/web-common/lib/ToDo';
import { ToDoContext } from './ToDoContext';
import { buildersService } from 'services';
import { FilterSets } from '@tradetrax/web-common/lib/Filters/FilterSets';
import { FilterImplementation } from '@tradetrax/web-common/lib/Filters/Implementation/Filter.implementation';

export const ToDoPage = () => {
  const appContext = useAppContext();
  const { containerRef, ...todoContext } = ToDoContext(appContext);
  const { state, controller, filterContext } = todoContext;
  const { assignees } = appContext.appState.toObject();
  const communities = useCommunities(buildersService);
  const taskTypes = useTaskTypes(buildersService);
  const { tab } = state.toObject();
  //TODO: replace this logic with responsive CSS
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1200);
  const lookups = { communities, taskTypes, assignees };

  useEffect(() => {
    const handleResize = () => setIsSmallScreen(window.innerWidth < 1200);
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <div className="d-flex flex-row align-items-center justify-content-between my-4 mx-4">
        <span className="h4 mb-0 font-weight-bold">To-Dos</span>
      </div>
      <FilterSets.Panel filterContext={filterContext} visible isToDo>
        <TodoSelectDate {...filterContext} />
        <FilterImplementation filterContext={filterContext} lookups={lookups} title="To-Dos Filters" className="ml-3">
          <FilterSets.Section filterContext={filterContext} />
        </FilterImplementation>
        {/* <TasksFilter top="8rem" /> */}
      </FilterSets.Panel>
      <Container fluid="lg" className="ml-0 pt-3 pr-3 pl-0 mr-auto" ref={containerRef}>
        {isSmallScreen && (
          <div className="mb-4">
            <ToggleButtons onChange={controller.setTab} type="radio" name="options" value={tab}>
              <ToggleButton value="expectedStart">Expected to Start</ToggleButton>
              <ToggleButton value="expectedFinish">Expected to Finish</ToggleButton>
              <ToggleButton value="expectedUpdate">Expected to Update</ToggleButton>
            </ToggleButtons>
          </div>
        )}
        <Row className="pl-4">
          {(tab === 'expectedStart' || !isSmallScreen) && (
            <Col xl={4} lg={10} md={10} sm={12} data-testid="todo-start-today">
              <StartToday {...todoContext} />
            </Col>
          )}
          {(tab === 'expectedFinish' || !isSmallScreen) && (
            <Col xl={4} lg={7} md={9} sm={12} data-testid="todo-finish-today">
              <FinishToday {...todoContext} />
            </Col>
          )}
          {(tab === 'expectedUpdate' || !isSmallScreen) && (
            <Col xl={4} lg={7} md={9} sm={12} data-testid="todo-update-today">
              <UpdateToday {...todoContext} />
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
};
