import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { Typeahead } from 'react-bootstrap-typeahead';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from '@tradetrax/web-common';
import { Form } from 'react-bootstrap';
import { PopoverStickOnHover } from '@tradetrax/web-common/lib/Popover';
import { useForm, Controller as FormController } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { capitalize, preventConsecutiveSpaceChar } from '@tradetrax/web-common/lib/utils';
import { Map } from 'immutable';

import * as Yup from 'yup';

const STATUS_OPTIONS = ['starts', 'finishes'];

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required')
    .matches(/^[a-zA-Z0-9\s]+$/, 'Character not allowed')
    .min(2, 'Min. 2 characters')
    .max(30, 'Max. 30 characters'),
  startTriggerTask: Yup.object().required('Missing Start of the Milestone'),
  startTriggerTaskStatus: Yup.string()
    .oneOf(STATUS_OPTIONS)
    .required(),
  endTriggerTask: Yup.object().required('Missing End of the Milestone'),
  endTriggerTaskStatus: Yup.string()
    .oneOf(STATUS_OPTIONS)
    .required(),
});

export const MilestoneCard = ({ milestone, isSingleMilestone, index, tasksTemplates, controller }) => {
  const { order } = milestone.toObject();

  const defaultValues = React.useMemo(() => getFormDefaultValues(milestone), [milestone]);
  const [showValidName, setShowValidName] = React.useState(true);
  const startTypeahead = React.useRef();
  const endTypeahead = React.useRef();

  const {
    register,
    errors,
    setError,
    trigger,
    control,
    formState: { dirtyFields },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues,
  });

  const handleOnBlur = async ({ target }) => {
    try {
      const name = target.value;
      const isValid = await trigger('name');

      if (!isValid || name === milestone.get('name')) return;

      await controller.updateMilestone({ name }, index, milestone);
    } catch (error) {
      if (error.type === 'entity-conflict') {
        setError('name', { type: 'notMatch', message: 'Name already exists in this Job' });
      }
    }
    setShowValidName(false);
  };

  const handleOnChangeTask = (selected, name) => {
    if (name === 'startTriggerTaskId' && !selected) {
      startTypeahead.current.toggleMenu();
    }
    if (name === 'endTriggerTaskId' && !selected) {
      endTypeahead.current.toggleMenu();
    }

    if (!selected || !name) return selected;
    controller.updateMilestone({ [name]: selected.get('_id') }, index, milestone);
    return selected;
  };

  const handleOnChangeStatus = (value, name) => {
    if (!value || !name) return value;
    controller.updateMilestone({ [name]: value }, index, milestone);
    return value;
  };

  React.useEffect(() => {
    const startTaskExist = tasksTemplates.find(gt => gt.get('_id') === milestone.get('startTriggerTaskId'));
    const endTaskExist = tasksTemplates.find(gt => gt.get('_id') === milestone.get('endTriggerTaskId'));

    if (!startTaskExist) {
      setError('startTriggerTask', { type: 'validate', message: 'This Task cannot be currently chosen' });
    }
    if (!endTaskExist) {
      setError('endTriggerTask', { type: 'validate', message: 'This Task cannot be currently chosen' });
    }
    if (!milestone.get('startTriggerTaskId'))
      setError('startTriggerTask', { type: 'required', message: 'Missing Start of the Milestone' });

    if (!milestone.get('endTriggerTaskId'))
      setError('endTriggerTask', { type: 'required', message: 'Missing End of the Milestone' });
  }, [setError, tasksTemplates, milestone]);

  return (
    <Card>
      <Form>
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-start w-100">
            <div className="mt-2">
              <FontAwesomeIcon
                icon="grip-lines-vertical"
                className={cn('mr-2 draggable-start', { 'd-none': isSingleMilestone })}
              />
              <span>{order}</span>
            </div>
            <div className="ml-3 flex-grow-1">
              <Form.Group controlId="formName">
                <InputMilestoneName
                  type="text"
                  name="name"
                  ref={register}
                  placeholder="Ex. Foundation"
                  isInvalid={!!errors.name}
                  isValid={dirtyFields.name && !errors.name && showValidName}
                  defaultValue={milestone.get('name')}
                  onChange={() => setShowValidName(true)}
                  onKeyDown={preventConsecutiveSpaceChar}
                  onBlur={e => handleOnBlur(e)}
                />
                <Form.Control.Feedback type="invalid">
                  <FontAwesomeIcon icon="circle-exclamation" /> {errors.name?.message}
                </Form.Control.Feedback>
                <Form.Control.Feedback type="valid">
                  <FontAwesomeIcon icon="circle-check" /> Milestone name is valid
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <div
              className="text-secondary ml-3 mt-2 "
              onClick={() => controller.openRemoveMilestoneModal(milestone.get('_id'))}
            >
              <FontAwesomeIcon icon="trash" />
            </div>
          </div>
        </div>
        <div className="d-flex align-items-start mt-2  pl-4 mb-4">
          <small className="mt-2 text-nowrap">will start when</small>

          <Form.Group controlId="startTriggerTask" className="mb-2 ml-1 position-relative flex-grow-1">
            <FormController
              control={control}
              name="startTriggerTask"
              render={({ onChange, value }) => {
                return (
                  <LargeTypeahead
                    id="startTriggerTask"
                    ref={startTypeahead}
                    className="ml-2 gtl-typeahead"
                    options={tasksTemplates.toArray()}
                    filterBy={filterBy}
                    labelKey={option => (option.size ? option.get('name') : '')}
                    onChange={([selected]) => onChange(handleOnChangeTask(selected, 'startTriggerTaskId'))}
                    placeholder="Choose a Task from Job"
                    selected={value ? [value] : []}
                    clearButton
                  >
                    {!value && <FontAwesomeIcon icon="magnifying-glass" />}
                  </LargeTypeahead>
                );
              }}
            />
            <ErrorContainer>
              <Form.Control.Feedback
                type="invalid"
                className={cn('d-inline ml-2', {
                  visible: errors.startTriggerTask,
                  invisible: !errors.startTriggerTask,
                })}
              >
                <FontAwesomeIcon icon="circle-exclamation" />
                {` `}
                {errors.startTriggerTask && ` ${errors.startTriggerTask.message}`}
                {errors.startTriggerTask?.type === 'validate' && (
                  <PopoverStickOnHover
                    popoverId="warning-popover"
                    popover={popoverText()}
                    delay={600}
                    placement="bottom-start"
                    className="d-inline"
                  >
                    <FontAwesomeIcon icon="square-info" className="ml-2 text-primary cursor-pointer" />
                  </PopoverStickOnHover>
                )}
              </Form.Control.Feedback>
            </ErrorContainer>
          </Form.Group>

          <Form.Group controlId="startTriggerTaskStatus" className="mb-0 ml-1" style={{ marginRight: '30px' }}>
            <FormController
              control={control}
              name="startTriggerTaskStatus"
              render={({ onChange, value }) => {
                return (
                  <SmallTypeahead
                    id="startTriggerTaskStatus"
                    className="ml-1 gtl-typeahead"
                    options={STATUS_OPTIONS}
                    filterBy={filterBy}
                    labelKey={option => capitalize(option)}
                    onChange={([selected]) => onChange(handleOnChangeStatus(selected, 'startTriggerTaskStatus'))}
                    selected={value ? [value] : []}
                  >
                    <FontAwesomeIcon icon="chevron-down" />
                  </SmallTypeahead>
                );
              }}
            />
          </Form.Group>
        </div>
        <div className="d-flex align-items-start mt-4  pl-4 mb-4">
          <small className="mt-2 ">and it will finish when</small>
          <Form.Group controlId="endTriggerTask" className="mb-0 ml-1 position-relative flex-grow-1">
            <FormController
              control={control}
              name="endTriggerTask"
              render={({ onChange, value }) => {
                return (
                  <LargeTypeahead
                    id="endTriggerTask"
                    ref={endTypeahead}
                    className="ml-1 gtl-typeahead"
                    options={tasksTemplates.toArray()}
                    filterBy={filterBy}
                    labelKey={option => (option.size ? option.get('name') : '')}
                    onChange={([selected]) => onChange(handleOnChangeTask(selected, 'endTriggerTaskId'))}
                    placeholder="Choose a Task from Job"
                    selected={value ? [value] : []}
                    clearButton
                  >
                    {!value && <FontAwesomeIcon icon="magnifying-glass" />}
                  </LargeTypeahead>
                );
              }}
            />
            <ErrorContainer>
              <Form.Control.Feedback
                type="invalid"
                className={cn('d-inline ml-2', {
                  visible: errors.endTriggerTask,
                  invisible: !errors.endTriggerTask,
                })}
              >
                <FontAwesomeIcon icon="circle-exclamation" />
                {` `}
                {errors.endTriggerTask && ` ${errors.endTriggerTask.message}`}
                {errors.endTriggerTask?.type === 'validate' && (
                  <PopoverStickOnHover
                    popoverId="warning-popover"
                    popover={popoverText()}
                    delay={600}
                    placement="bottom-start"
                    className="d-inline"
                  >
                    <FontAwesomeIcon icon="square-info" className="ml-2 text-primary cursor-pointer" />
                  </PopoverStickOnHover>
                )}
              </Form.Control.Feedback>
            </ErrorContainer>
          </Form.Group>

          <Form.Group controlId="endTriggerTaskStatus" className="mb-0 ml-1" style={{ marginRight: '30px' }}>
            <FormController
              control={control}
              name="endTriggerTaskStatus"
              render={({ onChange, onBlur, value }) => {
                return (
                  <SmallTypeahead
                    id="endTriggerTaskStatus"
                    className="ml-2 gtl-typeahead"
                    options={STATUS_OPTIONS}
                    filterBy={filterBy}
                    labelKey={option => capitalize(option)}
                    onChange={([selected]) => onChange(handleOnChangeStatus(selected, 'endTriggerTaskStatus'))}
                    selected={value ? [value] : []}
                  >
                    <FontAwesomeIcon icon="chevron-down" />
                  </SmallTypeahead>
                );
              }}
            />
          </Form.Group>
        </div>
      </Form>
    </Card>
  );
};

const popoverText = () => (
  <div className="d-flex py-2 px-3">
    <span className="text-gray-400">
      This Task cannot be used in Job Progress Milestones, because it does not appear in ALL Templates. Please add this
      Task to all Templates or choose another Task.
    </span>
  </div>
);

const ErrorContainer = styled.div`
  position: absolute;
  bottom: -25px;
  left: 0;
  width: 500px;
`;
const InputMilestoneName = styled(Form.Control)`
  width: 100%;
`;

const Card = styled.div`
  background: ${colors.activeState};
  box-sizing: border-box;
  border-radius: 0.375rem;
  margin-bottom: 0.63rem;
  padding: 1rem 1rem 0.5rem 1.5rem;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 178px;
  padding-right: 20px;
`;

const SmallTypeahead = styled(Typeahead)`
  max-width: 110px;

  .rbt-input-main {
    padding-right: 2rem;
  }
`;

const LargeTypeahead = styled(Typeahead)`
  .rbt-input-main {
    padding-right: 2rem;
  }
`;

const getFormDefaultValues = task => {
  const name = task.get('name') || null;
  const startTriggerTaskBody = task.get('startTriggerTaskId')
    ? Map({ name: task.get('startTriggerTaskName'), _id: task.get('startTriggerTaskId') })
    : null;
  const startTriggerTaskStatus = task.get('startTriggerTaskStatus') || null;

  const endTriggerTaskBody = task.get('endTriggerTaskId')
    ? Map({ name: task.get('endTriggerTaskName'), _id: task.get('endTriggerTaskId') })
    : null;
  const endTriggerTaskStatus = task.get('endTriggerTaskStatus') || null;

  return {
    name,
    startTriggerTask: startTriggerTaskBody,
    startTriggerTaskStatus,
    endTriggerTask: endTriggerTaskBody,
    endTriggerTaskStatus,
  };
};

const filterBy = (option, props) => {
  if (props.selected.length) return props.labelKey(option) !== props.labelKey(props.selected[0]);
  return (
    props
      .labelKey(option)
      .toLowerCase()
      .indexOf(props.text.toLowerCase()) !== -1
  );
};
