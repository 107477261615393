import { fromJS, List, Map } from 'immutable';
import { markAsSideEffect } from '@tradetrax/web-common';
import { buildersService } from 'services';
import { RemoveMilestoneModal } from './RemoveMilestoneModal';

markAsSideEffect(readMilestonesData);
export function readMilestonesData() {
  Promise.all([
    buildersService
      .viewMilestones({}, { params: {} })
      .then(fromJS)
      .then(response => this.controller.dispatch([state => state.set('milestones', response)])),
    buildersService
      .getAllTemplateTasks({}, { params: {} })
      .then(fromJS)
      .then(response => this.controller.dispatch([state => state.set('templateTasks', response)])),
  ])
    .then(() => this.controller.dispatch([state => state.set('isLoading', false)]))
    .catch(() => this.alert.error({ message: 'There was a problem fetching the job data. Please try again.' }));
}

markAsSideEffect(updateMilestone);
export function updateMilestone(data, index, milestone) {
  const [key, value] = Object.entries(data)[0];
  const isRenamed = key === 'name';

  return buildersService
    .updateMilestone(data, { params: { milestoneId: milestone.get('_id') } })
    .then(() => {
      const alertMsg = isRenamed ? 'Milestone successfully renamed.' : 'Milestone configuration successfully saved.';
      if (key === 'startTriggerTaskId') {
        this.controller.dispatch([
          state =>
            state
              .setIn(['milestones', index, 'missingStartTriggerTask'], false)
              .setIn(['milestones', index, key], value),
        ]);
      } else if (key === 'endTriggerTaskId') {
        this.controller.dispatch([
          state =>
            state.setIn(['milestones', index, 'missingEndTriggerTask'], false).setIn(['milestones', index, key], value),
        ]);
      } else {
        this.controller.dispatch([state => state.setIn(['milestones', index, key], value)]);
      }
      this.addAlert(alertMsg);
    })
    .catch(error => {
      if (error.type !== 'entity-conflict') {
        const errorMsg = isRenamed
          ? 'There was a problem renaming this Milestone. Please try again.'
          : 'There was a problem saving this Milestone configuration. Please try again.';

        this.addAlert(errorMsg, 'danger');
      }
      throw error;
    });
}

markAsSideEffect(createMilestone);
export function createMilestone(name) {
  return buildersService
    .createMilestone({ name, startTriggerTaskStatus: 'starts', endTriggerTaskStatus: 'finishes' })
    .then(fromJS)
    .then(response => {
      const { order, _id } = response.toObject();
      const newMilestone = Map({
        _id,
        name,
        order,
        startTriggerTaskId: null,
        startTriggerTaskStatus: 'starts',
        endTriggerTaskId: null,
        endTriggerTaskStatus: 'finishes',
      });

      this.controller.dispatch([
        state =>
          state.update('milestones', milestones => {
            const isEmpty = !milestones.size;
            if (isEmpty) return List([newMilestone]);
            return milestones.push(newMilestone);
          }),
      ]);
    })
    .catch(error => {
      if (error.type !== 'entity-conflict') {
        this.alert.error({ message: 'There was a problem to create a new Milestone. Please try again.' });
      }
      throw error;
    });
}

markAsSideEffect(reorder);
export function reorder(draggableId, startIndex, endIndex) {
  const milestones = this.state.get('milestones');
  const majorIndex = startIndex > endIndex ? startIndex : endIndex;
  const minorIndex = startIndex < endIndex ? startIndex : endIndex;
  const milestone = milestones.filter(item => item.get('_id') === draggableId).toObject();
  const newOrder = setNewOrder(draggableId, milestone[0], majorIndex, minorIndex, startIndex, endIndex);

  buildersService
    .reorderMilestone({ newOrder }, { params: { milestoneId: draggableId } })
    .then(fromJS)
    .then(response => {
      this.alert.success({ message: 'Milestone configuration successfully saved.' });
      this.controller.dispatch([state => state.set('milestones', response)]);
    })
    .catch(() => {
      this.addAlert('There was a problem saving the Milestone configuration. Please try again.', 'danger');
      this.controller.dispatch([state => state.set('milestones', milestones)]);
    });
}

markAsSideEffect(openRemoveMilestoneModal);
export async function openRemoveMilestoneModal(milestoneId) {
  const { isAccept } = await this.modal.open(RemoveMilestoneModal, {});
  if (!isAccept) return;

  buildersService
    .deleteMilestone({}, { params: { milestoneId } })
    .then(fromJS)
    .then(response => {
      this.alert.success({ message: 'Milestone successfully removed.' });
      this.controller.dispatch([state => state.set('milestones', response)]);
    })
    .catch(() => {
      this.addAlert('There was a problem removing this Milestone.', 'danger');
    });
}

function setNewOrder(draggableId, milestone, mayor, menor, startIndex, endIndex) {
  const diff = mayor - menor;
  const orderUp = startIndex > endIndex;
  const milestoneOrder = milestone.get('order');

  if (draggableId === milestone.get('_id')) {
    return orderUp ? milestoneOrder - diff : milestoneOrder + diff;
  }
  return orderUp ? milestoneOrder + 1 : milestoneOrder - 1;
}
