import React from 'react';
import cn from 'classnames';
import { List } from 'immutable';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrimaryButton } from '../../Button';
import { OutSelectionsTypeahead } from '../../Typeahead/OutSelectionsTypeahead';

export function EditView({ state, controller, allOptions, accept }) {
  const { selected, isSaveDisabled } = state.toObject();
  const options = React.useMemo(() => {
    if (!selected.size) return allOptions;
    return allOptions.filter(el => !selected.find(sel => sel._id === el.get('_id')));
  }, [allOptions]);

  const showMessage = !isSaveDisabled && selected.some(item => item.status === 'inactive');

  const onClick = () => {
    const areaManagers = selected.filter(assignee => assignee.status === 'active');
    accept({ areaManagers });
  };

  return (
    <>
      <Modal.Body>
        <OutSelectionsTypeahead
          id="area-manager-typeahead"
          size="lg"
          labelKey={option => option.name}
          placeholder="Choose Area Manager"
          filterSelected={(option, selected) => selected._id !== option._id}
          itemClassName={option => option.status}
          options={options.toJS()}
          selected={List(selected)}
          updateSelected={selected => controller.addAreaManager(selected)}
          clearButton
        />
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex flex-row">
          <span className={cn({ 'd-none': !showMessage })}>
            <FontAwesomeIcon icon="circle-exclamation" className="text-danger mr-2" />
            Deactivated Users will be automatically removed from this list.
          </span>
          <div className="d-flex justify-content-end">
            <PrimaryButton onClick={onClick} disabled={isSaveDisabled}>
              Save
            </PrimaryButton>
          </div>
        </div>
      </Modal.Footer>
    </>
  );
}
