import { fromJS } from 'immutable';
import { markAsSync, markAsSideEffect } from '@tradetrax/web-common/lib/useController';
import { buildersService } from 'services';
export { deleteRelevantTask } from '../../../../Users/MyProfile/MyProfileActions';

const formatRelevantTask = gtlTask => {
  let task = gtlTask;
  const onlyChannels = { onlyChannels: [] };

  if (!task.completed) task.completed = onlyChannels;
  if (!task['in-progress']) task['in-progress'] = onlyChannels;
  if (!task['start-date-or-end-date']) {
    task['start-date-or-end-date'] = { onlyChannels: [], notice_window: 7 };
  }
  if (!task['check-in-or-check-out']) task['check-in-or-check-out'] = onlyChannels;
  if (!task['commitment-request-accepted']) task['commitment-request-accepted'] = onlyChannels;

  delete task._id;
  delete task.__v;
  delete task.children;
  delete task.trade;

  return task;
};

const relevantTaskViewModel = task => {
  const onlyChannels = fromJS({ onlyChannels: [] });
  return task
    .set('completed', onlyChannels)
    .set('in-progress', onlyChannels)
    .set('start-date-or-end-date', fromJS({ onlyChannels: [], notice_window: 7 }))
    .set('check-in-or-check-out', onlyChannels)
    .set('commitment-request-accepted', onlyChannels);
};

markAsSync(addRelevantTask);
export function addRelevantTask(state, task) {
  const { customRole } = state.toObject();
  const roleId = customRole.get('_id');
  const relevantTasks = customRole.getIn(['settings', 'notifications', 'relevantTasks']).toJS();

  const notifications = {
    relevantTasks: relevantTasks.concat(task.toJS()).map(formatRelevantTask),
  };

  buildersService.updateRole({ notifications }, { params: { roleId } }).catch(error => {
    this.alert.error({ message: 'There was a problem adding this Relevant Task. Please try again.' });
    this.controller.dispatch([
      state => state.setIn(['customRole', 'settings', 'notifications', 'relevantTasks'], fromJS(relevantTasks)),
    ]);
  });

  return state.updateIn(['customRole', 'settings', 'notifications', 'relevantTasks'], relevantTasks =>
    relevantTasks.unshift(relevantTaskViewModel(task))
  );
}

markAsSync(updateRelevantTask);
export function updateRelevantTask(state, task, event, channels, extra) {
  const { customRole } = state.toObject();
  const roleId = customRole.get('_id');
  const relevantTasksImmuted = customRole.getIn(['settings', 'notifications', 'relevantTasks']);
  const relevantTasks = relevantTasksImmuted.toJS();
  const index = relevantTasksImmuted.indexOf(task);
  const update = task =>
    task.setIn(
      [event, 'onlyChannels'],
      channels.filter(x => !!x)
    );
  const body = update(task);
  const payload = {
    [event]: body.get(event).toJS(),
  };
  const notice_window = extra?.notice_window;
  if (event === 'start-date-or-end-date') payload['start-date-or-end-date'].notice_window = notice_window || 7;

  const updatedRelevantTasks = relevantTasks.map((item, i) => (i === index ? { ...item, ...payload } : item));

  const notifications = {
    relevantTasks: updatedRelevantTasks.map(formatRelevantTask),
  };
  buildersService.updateRole({ notifications }, { params: { roleId } }).catch(error => {
    this.alert.error({ message: 'There was an error updating the Relevant Task' });
    this.controller.dispatch([
      state => state.setIn(['customRole', 'settings', 'notifications', 'relevantTasks'], relevantTasksImmuted),
    ]);
  });

  const updatedState = notice_window
    ? state.setIn(
        ['customRole', 'settings', 'notifications', 'relevantTasks', index, event, 'notice_window'],
        notice_window
      )
    : state;
  return updatedState.updateIn(['customRole', 'settings', 'notifications', 'relevantTasks', index], update);
}

markAsSideEffect(doDeleteRelevantTask);
export function doDeleteRelevantTask(task) {
  const { customRole } = this.state.toObject();
  const roleId = customRole.get('_id');
  const relevantTasks = customRole.getIn(['settings', 'notifications', 'relevantTasks']);
  const index = relevantTasks.indexOf(task);
  const updated = index > -1 ? relevantTasks.splice(index, 1).toJS() : relevantTasks.toJS();
  const notifications = {
    relevantTasks: updated.map(formatRelevantTask),
  };
  this.controller.dispatch([
    state =>
      state.updateIn(['customRole', 'settings', 'notifications', 'relevantTasks'], relevantTasks =>
        relevantTasks.splice(index, 1)
      ),
  ]);
  return buildersService
    .updateRole({ notifications }, { params: { roleId } })
    .then(() =>
      this.alert.success({
        message: 'Task successfully removed from the Relevant Tasks list.',
      })
    )
    .catch(error => {
      this.alert.error({ message: 'There was a problem removing this Relevant Task from the list. Please try again.' });
      this.controller.dispatch([
        state => state.setIn(['customRole', 'settings', 'notifications', 'relevantTasks'], fromJS(relevantTasks)),
      ]);
    });
}
