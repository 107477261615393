import React from 'react';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSettingsContext } from './SettingsController';

const DAYS = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

export const JobSettings = () => {
  const { formContext, controller } = useSettingsContext();
  const { register, errors } = formContext;
  return (
    <div className="d-flex flex-column col-9 col-lg-6">
      <span className="font-size-12 text-gray-400">
        This setting will only modify the dates of tasks with dependencies.
      </span>
      <Form.Group>
        <h6 className="mb-2 mt-3 font-weight-bold">Working Days</h6>
        <div className="d-flex flex-row pl-2">
          {DAYS.map(day => (
            <div key={day} className="mx-2">
              <Form.Check className="d-flex flex-column flex-column-reverse align-items-center pl-0" type="checkbox">
                <input
                  type="checkbox"
                  name={`workingDays.${day}`}
                  ref={register}
                  onChange={e => controller.onSelectWorkingDay(e, day)}
                />
                <label className="text-capitalize">{day.slice(0, 3)}</label>
              </Form.Check>
            </div>
          ))}
        </div>
      </Form.Group>
      <Form.Group className="border-top">
        <h6 className="mb-2 mt-3 font-weight-bold">Non-Working Days</h6>
        <Form.Label>Holidays and Exceptions</Form.Label>
        <Form.Control
          as="textarea"
          rows="3"
          name="holidays"
          isInvalid={!!errors.holidays}
          ref={register({ validate: controller.validateHolidays })}
          onBlur={controller.onBlurHolidays}
          placeholder="mm/dd/yyyy, mm/dd/yyyy"
        ></Form.Control>
        <span className="font-size-12 text-gray-400">Type or paste dates separated by commas</span>
        <Form.Control.Feedback type="invalid">
          <FontAwesomeIcon icon="circle-exclamation" /> {errors.holidays?.message}
        </Form.Control.Feedback>
      </Form.Group>
    </div>
  );
};
