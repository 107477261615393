import { fromJS } from 'immutable';
import { markAsSideEffect, markAsSync } from '@tradetrax/web-common/lib/useController';
import { isoToTrxFull, trxFullToISO, isValidTrx } from '@tradetrax/web-common/lib/utils';
import { buildersService } from 'services';

const getHolidays = settings => settings.get('holidays').toArray().map(isoToTrxFull).join(', ');

markAsSync(setTab);
export function setTab(state, tab) {
  return state.set('tab', tab);
}

markAsSync(updateSettings);
export function updateSettings(state, settings) {
  return state.set('settings', settings);
}

export function parseHolidays(value) {
  return value
    .split(',')
    .map(value => value.trim())
    .filter(value => value);
}

markAsSideEffect(validateHolidays);
export function validateHolidays(value) {
  return parseHolidays(value).every(isValidTrx);
}

markAsSideEffect(onSelectWorkingDay);
export function onSelectWorkingDay({ target }, day) {
  const { checked } = target;
  const workingDays = this.state.getIn(['settings', 'workingDays']).toJS();
  const updated = { ...workingDays, [day]: checked };
  this.controller.dispatch([state => state.setIn(['settings', 'workingDays'], fromJS(updated))]);
  this.appController.updateSettings({ workingDays: updated });
}

markAsSideEffect(onBlurHolidays);
export function onBlurHolidays({ target }) {
  const { value } = target;
  const isValid = value && validateHolidays(value);
  if (value === getHolidays(this.state.get('settings'))) return;
  if (isValid) {
    const holidays = parseHolidays(value).map(trxFullToISO);
    this.appController.updateSettings({ holidays });
  } else {
    if (value)
      this.formContext.setError('holidays', {
        type: 'manual',
        message: 'Invalid format',
      });
    else this.formContext.clearErrors(['holidays']);
  }
}

markAsSideEffect(loadGTL);
export function loadGTL() {
  buildersService
    .readGTL()
    .then(fromJS)
    .then(tasks => this.controller.dispatch([state => state.set('globalTasks', tasks)]));
}
