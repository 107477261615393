import React from 'react';
import styled from 'styled-components';
import { useTimeAgo } from '../useTimeAgo';
import { colors } from '../theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TIMESTAMP_TEXT = {
  'invitation-accepted': 'Accepted',
  'profile-created': 'Created',
  'invitation-sent': 'Sent',
  active: 'Last Activity',
  inactive: 'Deactivated since',
  deactivated: 'Deactivated since',
};

export const UserStatusCard = ({ user, children, className = '' }) => {
  const status = user.get('status');
  const timeAgo =
    status === 'inactive' || status === 'deactivated' ? user.get('deactivatedAt') : user.get('lastActivity');
  return (
    <div className={`d-flex flex-column mr-5 ${className}`} style={{ width: '440px' }}>
      <Card className="d-flex flex-column p-3 rounded font-size-14">
        <span className="font-size-14 font-weight-bold">
          {`Status: `}
          <span>
            {status === 'active' && 'Active'}
            {status === 'inactive' && 'Inactive'}
            {status === 'deactivated' && 'Inactive'}
            {status === 'invitation-sent' && 'Invite Sent'}
            {status === 'invitation-accepted' && 'Invite Accepted'}
            {status === 'profile-created' && 'Profile Created'}
          </span>
        </span>
        <span className="text-gray-400 font-size-16">
          <FontAwesomeIcon icon="clock" className="mr-2" />
          <span className="font-size-14">{children}</span>
        </span>
      </Card>
      <div className="d-flex flex-row justify-content-end mt-1 font-size-14">
        <span className="text-gray-400 font-size-14">
          {TIMESTAMP_TEXT[status] || 'Sent'}
          {` `}
          <span>{useTimeAgo(timeAgo || user.get('lastActivity'))}</span>
        </span>
      </div>
    </div>
  );
};

const Card = styled.div`
  background-color: ${colors.blue50};
`;
