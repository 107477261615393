import React from 'react';
import { ToggleButtons, ToggleButton } from '../ToggleButtons';

export const ALL_TASKS = 'all-tasks';
export const TASK_TYPE = 'task-type';

export const TasksBySelect = ({ tasksBy, onChange }) => (
  <div className="d-flex flex-row align-items-center mr-2">
    <span className="mr-3">Show Task by:</span>
    <ToggleButtons type="radio" name="option" value={tasksBy} onChange={onChange} className="sub-section mr-2">
      <ToggleButton value={TASK_TYPE}>Task Type</ToggleButton>
      <ToggleButton value={ALL_TASKS}>All Tasks</ToggleButton>
    </ToggleButtons>
  </div>
);
