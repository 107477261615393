import React from 'react';
import { Modal, Form } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { yupResolver } from '@hookform/resolvers';
import { useForm, Controller as FormController } from 'react-hook-form';
import { PrimaryButton } from '@tradetrax/web-common';
import { trimSpaces, emptyStringToNull } from '@tradetrax/web-common/lib/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Yup from 'yup';

const schema = Yup.object().shape({
  firstName: Yup.string()
    .required('First Name is required')
    .matches(/^[^+÷=<>≠/!?#$%{}[\]0-9]*$/, 'Character not allowed')
    .transform(trimSpaces)
    .min(1, 'Min. 1 character')
    .max(30, 'Max. 30 characters'),
  lastName: Yup.string()
    .required('Last Name is required')
    .matches(/^[^+÷=<>≠/!?#$%{}[\]0-9]*$/, 'Character not allowed')
    .transform(trimSpaces)
    .min(1, 'Min. 1 character')
    .max(30, 'Max. 30 characters'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  phone: Yup.string()
    .matches(/^\+?[1-9]\d{9,14}$/, 'Invalid phone format')
    .transform(emptyStringToNull)
    .nullable(),
});

export function AddUserModal({ close, controller, alert, customRoles }) {
  const emailRef = React.useRef();
  const phoneRef = React.useRef();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const { control, register, handleSubmit, errors, getValues, setError, trigger } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      firstName: '',
      lastName: '',
      roles: '',
      email: '',
      phone: '',
      customRoles: null,
    },
  });

  const email = emailRef.current?.value || '';
  const phone = phoneRef.current?.value || '';
  const hasEmail =
    !!email &&
    !errors.email &&
    Yup.string()
      .email()
      .required()
      .isValidSync(email);
  const hasPhone =
    !errors.phone &&
    Yup.string()
      .matches(/^\+?[1-9]\d{9,14}$/)
      .isValidSync(phone);

  const handleError = err => {
    if (err.params) {
      const [error] = err.params;
      let message = err.detail;
      if (error?.param === 'email' && err.type === 'entity-conflict')
        message = 'This email is associated with an existing user.';
      err.params.forEach(error => setError(error.param, { type: 'manual', message }));
    } else {
      alert.error({
        message: 'There was a problem creating this user. Please try again.',
      });
    }
  };

  const submitUser = ({ customRoles, ...form }, e) => {
    e.preventDefault();
    if (isSubmitting) return;
    setIsSubmitting(true);
    const [customRole = {}] = customRoles || [];

    if (form.phone === null) delete form.phone;

    const user = { ...form, role: 'regular', customRoleId: customRole._id };

    return controller.defer
      .createUser(user)
      .then(close)
      .catch(handleError)
      .finally(() => setIsSubmitting(false));
  };

  const { firstName, lastName } = getValues();
  return (
    <Modal show={true} onHide={close} data-testid="modal-add-user">
      <Form noValidate onSubmit={handleSubmit(submitUser)}>
        <Modal.Header closeButton>
          <Modal.Title>Add User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="firstName">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="text"
              name="firstName"
              autoComplete="off"
              size="lg"
              isInvalid={errors.firstName}
              isValid={firstName && !errors.firstName}
              placeholder="Ex. Elijah"
              onChange={() => trigger(['firstName'])}
              ref={register}
            />
            <Form.Control.Feedback type="invalid">
              <FontAwesomeIcon icon="circle-exclamation" />
              {` ${errors.firstName?.message}`}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid">
              <FontAwesomeIcon icon="circle-check" /> First Name is valid
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="lastName">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              name="lastName"
              size="lg"
              autoComplete="off"
              isInvalid={errors.lastName}
              isValid={lastName && !errors.lastName}
              placeholder="Ex. Thompson"
              onChange={() => trigger(['lastName'])}
              ref={register}
            />
            <Form.Control.Feedback type="invalid">
              <FontAwesomeIcon icon="circle-exclamation" />
              {` ${errors.lastName?.message}`}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid">
              <FontAwesomeIcon icon="circle-check" /> Last Name is valid
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="custom-role">
            <Form.Label>Custom Role</Form.Label>
            <FormController
              control={control}
              name="customRoles"
              render={({ onChange, onBlur, value }) => (
                <Typeahead
                  id="custom-role"
                  bsSize="lg"
                  placeholder="Choose Custom Role"
                  labelKey={option => option.name}
                  options={customRoles.toJS()}
                  onBlur={onBlur}
                  onChange={onChange}
                  selected={value || []}
                  multiple={false}
                  clearButton
                  emptyLabel="No Custom Roles created yet."
                >
                  {!value && <FontAwesomeIcon icon="chevron-down" />}
                </Typeahead>
              )}
            />
            <div className="text-secondary font-size-14">* Optional</div>
          </Form.Group>
          <Form.Group controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="text"
              name="email"
              placeholder="Ex. username@email.com"
              size="lg"
              autoComplete="off"
              isInvalid={errors.email}
              isValid={hasEmail}
              onBlur={() => trigger(['email'])}
              ref={e => {
                register(e);
                emailRef.current = e;
              }}
            />
            <Form.Control.Feedback type="invalid">
              <FontAwesomeIcon icon="circle-exclamation" /> {errors.email?.message}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid">
              {hasEmail && (
                <>
                  <FontAwesomeIcon icon="circle-check" /> Email is valid
                </>
              )}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="phone">
            <Form.Label>Phone</Form.Label>
            <Form.Control
              type="text"
              name="phone"
              size="lg"
              placeholder="Ex. 602 555 7890"
              autoComplete="off"
              onBlur={() => trigger(['phone'])}
              isInvalid={errors.phone}
              isValid={hasPhone}
              ref={e => {
                register(e);
                phoneRef.current = e;
              }}
            />
            <div className="text-secondary font-size-14">* Optional</div>
            <Form.Control.Feedback type="invalid">
              <FontAwesomeIcon icon="circle-exclamation" /> {errors.phone?.message}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid">
              {hasPhone && (
                <>
                  <FontAwesomeIcon icon="circle-check" /> Phone number is valid
                </>
              )}
            </Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="pt-1">
          <PrimaryButton type="submit" disabled={isSubmitting}>
            Invite User
          </PrimaryButton>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
