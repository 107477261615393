import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'react-bootstrap';
import { EmptyState, EmptyStateCenterContainer } from '@tradetrax/web-common/lib/EmptyState';
import { useAppContext } from 'app/App.context';
import { Paths } from 'app/navigate';
import { PlanningWeeks } from './Week';
import { WorkloadPlanningContext } from './WorkloadPlanningContext';
import { LoadingPlaceHolder } from './LoadingPlaceholder';

export const WorkloadPlanningPage = () => {
  const appContext = useAppContext();
  const context = WorkloadPlanningContext(appContext);
  const { state } = context;
  const title = `No Denied Commitment Requests`;
  const body = `When there's a Denied Commitment Request, it will appear here.`;
  const isLoading = !!state.getIn(['deniedRequests', 'isLoading']);
  const isEmpty = !isLoading && state.get('deniedRequests').size === 0;

  return (
    <>
      <div className=" px-4 mr-auto w-100" style={{ maxWidth: '1070px' }}>
        <Breadcrumb tag="nav">
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: Paths.DashboardV2 }}>
            Dashboard
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Denied Commitment Requests</Breadcrumb.Item>
        </Breadcrumb>
        <div className="pb-2 mb-4">
          <span className="h4 font-weight-bold d-block">Denied Commitment Requests</span>
          {/* <span className="d-block font-size-14 mb-4">Move to In Progress or Update Start Date.</span> */}
        </div>
        {isLoading ? <LoadingPlaceHolder /> : <PlanningWeeks context={context} visible={!isEmpty} />}
        <EmptyStateCenterContainer top="120px">
          <EmptyState pt={5} visible={isEmpty} icon="handshake" title={title} body={body} />
        </EmptyStateCenterContainer>
      </div>
    </>
  );
};
