import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../theme';

export function TaskList({ tasks, showJob = false }) {
  return (
    <>
      <Header className="text-gray-400 font-size-14 mt-3 mb-2 d-flex">
        <div>Tasks</div>
        {showJob && <div>Job or LOT #</div>}
      </Header>
      <Container>
        {tasks.map(task => (
          <TaskRow key={task.get('key')} className="pt-3 mb-3 font-weight-bold w-100">
            <div>{task.get('name')}</div>
            {showJob && <div>{task.getIn(['job', 'lotNumber']) || task.getIn(['job', 'name'])}</div>}
          </TaskRow>
        ))}
      </Container>
    </>
  );
}

const Container = styled.div`
  overflow-y: scroll;
  max-height: 260px;
`;

const Header = styled.div`
  div {
    flex-basis: 50%;

    &:nth-child(2) {
      padding-left: 2rem;
    }
  }
`;

const TaskRow = styled.div`
  border-top: 1px solid ${colors.gray500};
  padding-top: 1rem;
  display: flex;

  div {
    width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:nth-child(2) {
      font-weight: normal;
      padding-left: 2rem;
    }
  }
`;
