import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { PrimaryButton } from '@tradetrax/web-common';
import { plural, datepickerFormatIncremented, formatTrx } from '../../../utils';
import Datepicker from '../../../Datepicker';
const getLatestDate = (tasks, dateField) => {
  const maxDate = new Date(Math.max(...tasks.map(task => new Date(task.get(dateField)))));
  return datepickerFormatIncremented(formatTrx(maxDate), 2);
};

export const ChangeDateUpcomingModal = ({ close, accept, tasksList }) => {
  const lateStartDate = getLatestDate(tasksList, 'startDate');
  const [date, setDate] = useState(lateStartDate);
  const [isChecked, setIsChecked] = React.useState(false);

  const containerStyle = {
    maxHeight: '242px',
    overflowY: tasksList.size > 4 ? 'auto' : 'scroll',
  };

  return (
    <Modal centered show={true} onHide={close} dialogClassName="width-600">
      <Modal.Header closeButton>
        <Modal.Title>
          <span className="ml-3">Change Start Date</span>
          <span className="d-block ml-3 mt-2 font-size-16">
            {tasksList.size} {plural(tasksList.size, 'Task')}
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span className="d-block">Expected Start</span>

        <Datepicker
          name="proposedDate"
          selected={date}
          minDate={null}
          dateFormat={`MM/dd/yyyy`}
          onChange={selectedDate => setDate(selectedDate)}
        />
        <div className="d-flex align-items-center mt-2">
          <input type="checkbox" checked={isChecked} onChange={() => setIsChecked(!isChecked)} />
          <span className="ml-2">Due to Weather</span>
        </div>

        <div className="mt-2">
          <p className="font-size-14 text-muted">{plural(tasksList.size, 'Task')}</p>
          <hr />
          <div style={containerStyle}>
            {tasksList.map((task, index) => (
              <div key={index}>
                <div className="text-truncate">{task.get('name')}</div>
                <hr />
              </div>
            ))}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex flex-row ">
        <PrimaryButton variant="secondary" onClick={() => close()} className="mr-3">
          Cancel
        </PrimaryButton>
        <PrimaryButton onClick={() => accept({ date, isDueWeather: isChecked })}>Confirm Date Change</PrimaryButton>
      </Modal.Footer>
    </Modal>
  );
};
